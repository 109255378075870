import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { passwordValidator } from 'src/app/shared/validator/password.validator';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'app-users-dialog-reset-password',
    templateUrl: './users-dialog-reset-password.component.html',
    styleUrls: ['./users-dialog-reset-password.component.scss']
})
export class UsersDialogResetPasswordComponent implements OnInit {

    applicationUserFormGroup: FormGroup;
    isSaving: boolean = false;
    updateSubscription: Subscription;
    showPwd: boolean = false;
    showNewPwd: boolean = false;
    showRepeatPwd: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public applicationUserModel: ApplicationUserModel,
        public dialogRef: MatDialogRef<UsersDialogResetPasswordComponent>,
        private formBuilder: FormBuilder,
        private usersService: UsersService,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.applicationUserFormGroup = this.formBuilder.group(
            {
                id: this.formBuilder.control(this.applicationUserModel.id),
                username: this.formBuilder.control({
                    value: this.applicationUserModel.username,
                    disabled: true,
                }),
                oldPassword: this.formBuilder.control(null),
                password: this.formBuilder.control(this.applicationUserModel.password),
                newPassword: this.formBuilder.control(null, Validators.required),
                repeatPassword: this.formBuilder.control(null,Validators.required),
                active: this.formBuilder.control(
                    this.applicationUserModel.active,
                    Validators.required
                ),
            },
            {
                validators: [passwordValidator()],
            }
        );
    }

    ngOnDestroy() {
        if (this.updateSubscription) {
            this.updateSubscription.unsubscribe();
        }
    }

    save(): void {
        this.isSaving = true;
        if (this.applicationUserFormGroup.get('id').value == null) {
            this.updateSubscription = this.usersService.resetPassword(this.applicationUserFormGroup.getRawValue()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.dialogRef.close('ok');
                },
                error: error => {
                        this.isSaving = false;
                        this.snackBar.open(error.error.message, 'Close');
                    }
            });
        } else {
            this.updateSubscription = this.usersService.resetPasswordAdmin(
                this.applicationUserFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                    next: () => {
                        this.isSaving = false;
                        this.dialogRef.close('ok');
                    },
                    error: error => {
                        this.isSaving = false;
                        this.snackBar.open(error.error.message, 'Close');
                    }
                });
        }
    }

    checkUser() {
        if (this.applicationUserFormGroup.get('id').value == null) {
            return true;
        } else {
            return false;
        }
    }

    showPassword(){
        this.showPwd= !this.showPwd;
    }

    showNewPassword(){
        this.showNewPwd= !this.showNewPwd;
    }

    showRepeatPassword(){
        this.showRepeatPwd= !this.showRepeatPwd;
    }
}
