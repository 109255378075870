<div class="login-page">
    <mat-card class="login-card" [formGroup]="loginFormGroup">
        <mat-card-header class="d-flex justify-content-center">
            <mat-card-title class="logo-img-login">
                <img mat-card-image src="assets/nbt-logo.png" alt="Mandrill Base Project">
            </mat-card-title>
        </mat-card-header>

        <mat-card-content class="login-section">
            <div class="nbt-form">
                <label>Email address or username</label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="username" placeholder="Enter username"
                        [required]="loginFormGroup.get('username').errors !== null && loginFormGroup.get('username').errors.required" />
                    <mat-error>Username is required</mat-error>
                </mat-form-field>
            </div>

            <div class="nbt-form">
                <label>Password</label>
                <mat-form-field appearance="outline">
                    <input matInput [type]="isShowPassword ? 'text' : 'password'" formControlName="password" placeholder="Enter password" class="relative"
                        [required]="loginFormGroup.get('password').errors !== null && loginFormGroup.get('password').errors.required" />
                    <mat-error>Password is required</mat-error>
                    <mat-icon class="view-password" (click)="showPassword()" *ngIf="!isShowPassword" matTooltip="Show Password">lock</mat-icon>
                    <mat-icon class="view-password" (click)="showPassword()" *ngIf="isShowPassword" matTooltip="Hide Password">no_encryption_gmailerrorred</mat-icon>
                </mat-form-field>
            </div>
            <div *ngIf="invalidCredentials" class="d-flex align-items-center text-danger mb8">
                <mat-icon class="me-2">error_outline</mat-icon>
                Username or password is incorrect
            </div>
            <div *ngIf="loginError" class="d-flex align-items-center text-danger mb8">
                <mat-icon class="me-2">error_outline</mat-icon>
                Unable to login at the moment. Please contact the administrator for assistance.
            </div>
        </mat-card-content>
        <button class="nbt-btn-primary w-100" (click)="login()" [disabled]="loginFormGroup.invalid">
            Sign In
        </button>
        <div class="forgot-password-link">
            <span (click)="requestPasswordEmail()">Forgotten your password?</span>
        </div>
    </mat-card>
</div>