<div class="container-fluid nbt-container">
    <div class="nbt-header" *ngIf="!loading">
        <div class="survey-header-title" >
            <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
                {{ surveyModel?.surveyNumber}} - {{ surveyModel?.surveyForm?.name}}
            </ng-container>
            <ng-template #elseTemplate>
                View {{ surveyModel?.surveyNumber}} - {{ surveyModel?.surveyForm?.name}}
            </ng-template>
        </div>
    </div>
    <br>
    <div class="loading-indicator" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px;" >
        <div class="ssi-form">
            <div class="row text-center">
                <div class="survey-introduction" >{{ formContentM.intro }}</div>
                <div class="survey-introduction">{{ formContentM.tq }}</div>
            </div>
            <br>
            <div class="row text-center">
                <div class="survey-introduction" >{{ formContent.intro }}</div>
                <div class="survey-introduction">{{ formContent.tq }}</div>
            </div>
            <br>
            <br>
            <form [formGroup]="ssiFormGroup">
                <div class="row">
                    <div class="col-md-6 nbt-form">
                        <label for=""><b>Jenis</b></label>
                        <label for=""><b><i>Model</i></b></label>
                        <label>{{ surveyModel?.vehicle?.model?.brand}} {{surveyModel?.vehicle?.model?.name}}</label>
                    </div>
                    <div class="col-md-6 nbt-form">
                        <br>
                        <label for=""><b>No. Pendaftaran Kereta</b></label>
                        <label for=""><b><i>Vehicle Registration No</i></b></label>
                        <label>{{ surveyModel?.vehicle?.registrationNo}}</label>
                    </div>
                    <div class="col-md-6 nbt-form">
                        <br>
                        <label for=""><b>Bilik Pameran</b></label>
                        <label for=""><b><i>Showroom</i></b></label>
                        <label>{{ surveyModel?.department?.name}}</label>
                    </div>
                    <div class="col-md-6 nbt-form">
                    </div>
                </div>
                <br>
                <div class="row nbt-form-new" >
                    <div *ngIf="!surveyModel.department.name.includes('QUCC')" class="row" formArrayName="surveyDetails">
                        <div *ngFor="let survey of formArraySurvey() ; let i = index" [formGroupName]="i">
                            <div class="row">
                                <div class="col-md-6 question-align">
                                    <span><strong>Q{{i+1}} : {{questionChecking(survey.value.question,0)}}</strong>
                                    <br *ngIf="questionChecking(survey.value.question,1)!==''">
                                    <i>Q{{i+1}} : {{questionChecking(survey.value.question,1)}}</i></span>
                                </div>
                                <div *ngIf="!survey.value.isReceive" class="col-md-4 mb8">
                                    <mat-radio-group aria-labelledby="example-radio-group-label"  formControlName="surveyFormAnswer" required>
                                        <mat-radio-button *ngFor="let ans of survey.value.answers" [value]="ans" name="radio_survey_{{survey.value.id}}" aria-label="Select an option">
                                            <mat-radio-label>
                                                <img [src]="ans.answerURL" class="img-size"/>
                                            </mat-radio-label>
                                        </mat-radio-button>
                                        <div *ngIf="!survey.value.isReceive">
                                            <mat-checkbox class="example-margin" (change)="checkRadio($event.checked, i)" [disabled]="!surveyModel?.isActive">
                                                <img [src]="na" class="na-size"/>
                                            </mat-checkbox>
                                        </div>
                                    </mat-radio-group>
                                </div>
                                <div *ngIf="survey.value.isReceive" class="col-md-4 ">
                                    <div *ngIf="survey.value?.surveyFormAnswer!==null">
                                        <mat-radio-label>
                                            <img [src]="survey.value?.answerURL"  class="img-size"/>
                                        </mat-radio-label>
                                    </div>
                                    <div *ngIf="survey.value?.surveyFormAnswer===null">
                                        <span>&nbsp;TB/NA</span>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                    <div *ngIf="surveyModel.department.name.includes('QUCC')" class="row" formArrayName="surveyDetails">
                        <div *ngFor="let survey of formArraySurvey() ; let i = index" [formGroupName]="i">
                            <div *ngIf="survey.value.answers.length < 3">
                                <div class="row">
                                    <div class="col-md-6 question-align">
                                        <span><strong>Q{{i+1}} : {{questionChecking(survey.value.question,0)}}</strong>
                                        <br *ngIf="questionChecking(survey.value.question,1)!==''">
                                        <i>Q{{i+1}} : {{questionChecking(survey.value.question,1)}}</i></span>
                                    </div>
                                    <div *ngIf="!survey.value.isReceive" class="col-md-4 ">
                                        <mat-radio-group aria-labelledby="example-radio-group-label"  formControlName="surveyFormAnswer" required>
                                            <mat-radio-button *ngFor="let ans of survey.value.answers" [value]="ans" name="radio_survey_{{survey.value.id}}" aria-label="Select an option">
                                                <mat-radio-label>
                                                    <img [src]="ans.answerURL" class="img-size"/>
                                                </mat-radio-label>
                                            </mat-radio-button>
                                            <div *ngIf="!survey.value.isReceive">
                                                <mat-checkbox class="example-margin" (change)="checkRadio($event.checked, i)" [disabled]="!surveyModel?.isActive">
                                                    <img [src]="na" class="na-size"/>
                                                </mat-checkbox>
                                            </div>
                                        </mat-radio-group>
                                    </div>
                                    <div *ngIf="survey.value.isReceive" class="col-md-4 ">
                                        <div *ngIf="survey.value?.surveyFormAnswer!==null">
                                            <mat-radio-label>
                                                <img [src]="survey.value?.answerURL"  class="img-size"/>
                                            </mat-radio-label>
                                        </div>
                                        <div *ngIf="survey.value?.surveyFormAnswer===null">
                                            <span>&nbsp;TB/NA</span>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div *ngIf="i===4" class="col-md-12 nbt-form-textarea">
                                        <mat-form-field appearance="outline">
                                        <textarea matInput appAutofocus type="text" formControlName="remark" required
                                                  placeholder="Enter remark" maxlength="2000" rows="4" #myInput></textarea>
                                            <span>{{myInput.value.length}} / 2000</span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="survey.value.answers.length >3">
                                <div class="row">
                                    <div class="col-md-6 question-align">
                                        <span><strong>Q{{i+1}} : {{questionChecking(survey.value.question,0)}}</strong>
                                        <br *ngIf="questionChecking(survey.value.question,1)!==''">
                                        <i>Q{{i+1}} : {{questionChecking(survey.value.question,1)}}</i></span>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div *ngIf="!survey.value.isReceive" class="col-md-4 row">
                                        <mat-radio-group aria-labelledby="example-radio-group-label"  formControlName="surveyFormAnswer" required>
                                            <div class="row">
                                                <div *ngFor="let ans of survey.value.answers; let x = index">
                                                    <mat-checkbox [disabled]="(disableCheckbox[i] && !ans.selected) || !surveyModel?.isActive"
                                                                  appSurveyAnswerSelected
                                                                  [ssiFormGroupInput]="ssiFormGroup"
                                                                  [answerAnswerModelInput]="surveyFormAnswerModel"
                                                                  (change)="updateSelectedCheckboxAnswer($event.checked, ans, i)"
                                                                  class="checkbox-multiple-answers">
                                                        {{ans.answerImage.replaceAll('_',' ')}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div *ngIf="survey.value.isReceive" class="col-md-4 ">
                                    <div *ngFor="let ans of survey.value.surveyFormAnswer; let x = index">
                                         - {{ans.answerImage.replaceAll('_',' ')}}
                                        <br>
                                    </div>
                                </div>
                                <br>
                                <div *ngIf="checkIfContainsOtherAnswer(i)" class="col-md-12 nbt-form-textarea">
                                    <mat-form-field appearance="outline">
                                        <textarea matInput appAutofocus type="text" formControlName="remark"
                                                  placeholder="Enter remark" maxlength="2000" rows="4" #myInput></textarea>
                                        <span>{{myInput.value.length}} / 2000</span>
                                    </mat-form-field>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                </div>
                <div class="row lower-section">
                    <div class="col-md-4 nbt-form">
                        <label>Nombor telefon bimbit <span class="required">*</span></label>
                        <label><i>Phone Number</i><span class="required">*</span></label>
                        <mat-form-field appearance="outline">
                            <input matInput appAutofocus type="text" formControlName="phoneNumber" placeholder="Enter Phone Number" maxlength="200" required />
                            <mat-error *ngIf="ssiFormGroup.get('phoneNumber').errors?.pattern">Invalid phone number input</mat-error>
                            <mat-icon class="material-symbols-text" [matTooltip]="phoneContent" matTooltipPosition="above">
                                info
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 nbt-form-textarea">
                        <label>Adakah awda mempunyai sebarang komen atau cadangan untuk Sales Consultant bagi menambah baik lagi perkhidmatan kami?<span class="required">*</span></label>
                        <br>
                        <label><i>Do you have any comment or suggestions for our Sales Consultant to further improve our service?</i><span class="required">*</span></label>
                        <mat-form-field appearance="outline">
                            <textarea matInput appAutofocus type="text" formControlName="remark" required
                            placeholder="Enter remark" maxlength="2000" rows="4" #myInput></textarea>
                            <span>{{myInput.value.length}} / 2000</span>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <span class="required">* Required Field For Submission</span>
            <br>
            <div class="nbt-dialog-footer">
                <button *ngIf="dialogType === 'create'" type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="back()">
                    <mat-icon class="mr8">clear</mat-icon>
                    Cancel
                </button>
                <button appScreenPermission [permissionsInput]="[MODIFY_SSI]" *ngIf="dialogType === 'create'" type="button" class="nbt-btn-primary ml8" (click)="modification()" [disabled]="isSaving || !surveyModel.isActive">
                    <mat-icon class="mr8">save</mat-icon>
                    Modification
                </button>
                <button *ngIf="dialogType === 'create'" type="button" class="nbt-btn-primary ml8" (click)="saveSurvey()" [disabled]="isSaving || ssiFormGroup.invalid || !surveyModel.isActive">
                    <mat-icon class="mr8">save</mat-icon>
                    Save
                </button>
                <button *ngIf="dialogType === 'read'" type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="back()">
                    <mat-icon class="mr8">arrow_back_ios</mat-icon>
                    Back
                </button>
            </div>
        </div>
    </mat-card-content>
</div>
