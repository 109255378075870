import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { SlaModel } from 'src/app/model/sla.model';
import { DepartmentService } from 'src/app/services/department.service';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { SlaService } from 'src/app/services/sla.service';

@Component({
    selector: 'app-sla-dialog-new-or-edit',
    templateUrl: './sla-dialog-new-or-edit.component.html',
    styleUrls: ['./sla-dialog-new-or-edit.component.scss']
})
export class SlaDialogNewOrEditComponent implements OnInit {

    dialogType: string ='';
    slaFormGroup: FormGroup;
    isSaving: boolean = false;
    institutions: InstitutionModel[] = [];
    selectedValue: string;
    loading: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public slaModel: SlaModel,
        public dialogRef: MatDialogRef<SlaDialogNewOrEditComponent>,
        private departmentService: DepartmentService,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private institutionsService: InstitutionsService,
        private snackBar: MatSnackBar,
        private slaService: SlaService
    ) { }

    ngOnInit(): void {
        if (this.slaModel) {
            this.dialogType = 'update';
            this.slaFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.slaModel.id),
                name: this.formBuilder.control(this.slaModel.name, Validators.required),
                description: this.formBuilder.control(this.slaModel.description,Validators.required),
                institution: this.formBuilder.control(this.slaModel.institution),
                slaDetails: this.formBuilder.array(
                    [
                        this.formBuilder.group({
                            id: this.slaModel.slaDetails[0].id,
                            priority: this.slaModel.slaDetails[0].priority,
                            responseTime: this.slaModel.slaDetails[0].responseTime,
                            responseTimeDay: this.slaModel.slaDetails[0].responseTime.split('|')[0],
                            responseTimeHrs: this.slaModel.slaDetails[0].responseTime.split('|')[1],
                            responseTimeMins: this.slaModel.slaDetails[0].responseTime.split('|')[2],
                            resolutionTime: this.slaModel.slaDetails[0].resolutionTime,
                            resolutionTimeDay: this.slaModel.slaDetails[0].resolutionTime.split('|')[0],
                            resolutionTimeHrs: this.slaModel.slaDetails[0].resolutionTime.split('|')[1],
                            resolutionTimeMins: this.slaModel.slaDetails[0].resolutionTime.split('|')[2]
                        }),
                        this.formBuilder.group({
                            id: this.slaModel.slaDetails[1].id,
                            priority: this.slaModel.slaDetails[1].priority,
                            responseTime: this.slaModel.slaDetails[1].responseTime,
                            responseTimeDay: this.slaModel.slaDetails[1].responseTime.split('|')[0],
                            responseTimeHrs: this.slaModel.slaDetails[1].responseTime.split('|')[1],
                            responseTimeMins: this.slaModel.slaDetails[1].responseTime.split('|')[2],
                            resolutionTime: this.slaModel.slaDetails[1].resolutionTime,
                            resolutionTimeDay: this.slaModel.slaDetails[1].resolutionTime.split('|')[0],
                            resolutionTimeHrs: this.slaModel.slaDetails[1].resolutionTime.split('|')[1],
                            resolutionTimeMins: this.slaModel.slaDetails[1].resolutionTime.split('|')[2]
                        }),
                        this.formBuilder.group({
                            id: this.slaModel.slaDetails[2].id,
                            priority: this.slaModel.slaDetails[2].priority,
                            responseTime: this.slaModel.slaDetails[2].responseTime,
                            responseTimeDay: this.slaModel.slaDetails[2].responseTime.split('|')[0],
                            responseTimeHrs: this.slaModel.slaDetails[2].responseTime.split('|')[1],
                            responseTimeMins: this.slaModel.slaDetails[2].responseTime.split('|')[2],
                            resolutionTime: this.slaModel.slaDetails[2].resolutionTime,
                            resolutionTimeDay: this.slaModel.slaDetails[2].resolutionTime.split('|')[0],
                            resolutionTimeHrs: this.slaModel.slaDetails[2].resolutionTime.split('|')[1],
                            resolutionTimeMins: this.slaModel.slaDetails[2].resolutionTime.split('|')[2]
                        }),
                        this.formBuilder.group({
                            id: this.slaModel.slaDetails[3].id,
                            priority: this.slaModel.slaDetails[3].priority,
                            responseTime: this.slaModel.slaDetails[3].responseTime,
                            responseTimeDay: this.slaModel.slaDetails[3].responseTime.split('|')[0],
                            responseTimeHrs: this.slaModel.slaDetails[3].responseTime.split('|')[1],
                            responseTimeMins: this.slaModel.slaDetails[3].responseTime.split('|')[2],
                            resolutionTime: this.slaModel.slaDetails[3].resolutionTime,
                            resolutionTimeDay: this.slaModel.slaDetails[3].resolutionTime.split('|')[0],
                            resolutionTimeHrs: this.slaModel.slaDetails[3].resolutionTime.split('|')[1],
                            resolutionTimeMins: this.slaModel.slaDetails[3].resolutionTime.split('|')[2]
                        })
                    ], 
                )
            });
        } else {
            this.dialogType = 'create';
            this.slaFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null, Validators.required),
                description: this.formBuilder.control(null,Validators.required),
                institution: this.formBuilder.control(this.authService.getCurIns()),
                createdBy: this.formBuilder.control(null),
                createdDate: this.formBuilder.control(null),
                slaDetails: this.formBuilder.array(
                    [
                        this.formBuilder.group({
                            priority: "URGENT",
                            responseTime:'',
                            responseTimeDay:[0],
                            responseTimeHrs:[0],
                            responseTimeMins:[0],
                            resolutionTime:'',
                            resolutionTimeDay:[0],
                            resolutionTimeHrs:[0],
                            resolutionTimeMins:[0]
                        }),
                        this.formBuilder.group({
                            priority: "HIGH",
                            responseTime:'',
                            responseTimeDay:[0],
                            responseTimeHrs:[0],
                            responseTimeMins:[0],
                            resolutionTime:'',
                            resolutionTimeDay:[0],
                            resolutionTimeHrs:[0],
                            resolutionTimeMins:[0]
                        }),
                        this.formBuilder.group({
                            priority: "MEDIUM",
                            responseTime:'',
                            responseTimeDay:[0],
                            responseTimeHrs:[0],
                            responseTimeMins:[0],
                            resolutionTime:'',
                            resolutionTimeDay:[0],
                            resolutionTimeHrs:[0],
                            resolutionTimeMins:[0]
                        }),
                        this.formBuilder.group({
                            priority: "LOW",
                            responseTime:'',
                            responseTimeDay:[0],
                            responseTimeHrs:[0],
                            responseTimeMins:[0],
                            resolutionTime:'',
                            resolutionTimeDay:[0],
                            resolutionTimeHrs:[0],
                            resolutionTimeMins:[0]
                        })
                    ],
                )
            });

        }
        this.deactiveLoading();
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    save(): void {
        this.isSaving = true;
        this.activeLoading();
        const slaModel = this.slaFormGroup.getRawValue() as SlaModel;

        for (let i in slaModel.slaDetails) {
            slaModel.slaDetails[i].responseTime = `${slaModel.slaDetails[i].responseTimeDay}|${slaModel.slaDetails[i].responseTimeHrs}|${slaModel.slaDetails[i].responseTimeMins}`;
            delete slaModel.slaDetails[i].responseTimeDay;
            delete slaModel.slaDetails[i].responseTimeHrs;
            delete slaModel.slaDetails[i].responseTimeMins;
            slaModel.slaDetails[i].resolutionTime = `${slaModel.slaDetails[i].resolutionTimeDay}|${slaModel.slaDetails[i].resolutionTimeHrs}|${slaModel.slaDetails[i].resolutionTimeMins}`;
            delete slaModel.slaDetails[i].resolutionTimeDay;
            delete slaModel.slaDetails[i].resolutionTimeHrs;
            delete slaModel.slaDetails[i].resolutionTimeMins;
        }

        if (this.dialogType === 'create') {
            this.slaService.save(slaModel, this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                    });
                }
            })
        } else {
            this.slaService.update(slaModel, this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                    });
                }
            })
        }
    }

    formArraySlaDetails() {
        const array = (this.slaFormGroup.get('slaDetails') as FormArray).controls;
        return array;
    }

    bgColorPriorityDot(index: number): string {
        switch (index) {
            case 0: return 'red';
            case 1: return 'yellow';
            case 2: return 'blue';
            case 3: return 'green';
        }
    }
}
