<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        View Vehicle Model '{{vehicleModelModel.name}}'
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <div class="nbt-details row">
        <div class="col-md-6">
            <label>Brand</label>
            <div class="value" maxlength="100">{{vehicleModelModel.brand || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Name</label>
            <div class="value" maxlength="100">{{vehicleModelModel.name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Active</label>
            <div class="value">{{vehicleModelModel.active ? 'Active' : 'Not Active'}}</div>
        </div>
    </div>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Close
    </button>
    <button type="button" *ngIf="authService.hasAuthorityMenu(UPDATE_VEHICLE_MODEL)" class="nbt-btn-primary ml8" (click)="dialogRef.close('edit')">
        <mat-icon class="mr8">edit</mat-icon>
        Edit
    </button>
</div>