import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Model } from '../model/model.model';
import { AuthService } from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ModelService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
    ) { }

  public save(
      model: Model,
      curIns: string
  ): Observable<HttpResponse<Model>> {
      let httpParams = new HttpParams();
      httpParams = httpParams.set('curIns', curIns);
      return this.httpClient.post<Model>(`/web/model`, model, {
          observe: 'response',
          params: httpParams
      });
  }

  public getAll(
      pageNumber: number,
      pageSize: number,
      sortOrder: string,
      criteria
    ): Observable<HttpResponse<Model[]>> {
        let httpParams = new HttpParams();
        if (pageNumber) {
            httpParams = httpParams
                .set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams
                .set('size', `${pageSize}`);
        }
        if (sortOrder) {
            httpParams = httpParams
                .set('sort', sortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('name', criteria.name);
            }
            if (criteria.institution) {
                httpParams = httpParams.set('institutionId', criteria.institution);
            }
            if (criteria.currentInstitution) {
                httpParams = httpParams.set('curIns', criteria.currentInstitution);
            }
        }
        return this.httpClient.get<Model[]>(`/web/model/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    public update(
      model: Model,
      curIns: string
    ): Observable<HttpResponse<Model>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<Model>(`/web/model`, model, {
            observe: 'response',
            params: httpParams
        });
    }

    getAllModelNoPagination(): Observable<HttpResponse<Model[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        return this.httpClient.get<Model[]>(`/web/model/all/no-pagination`, {
            observe: 'response',
            params: httpParams,
        });
    }


}
