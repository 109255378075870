import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationPermissionModel } from '../model/application-permission.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationPermissionsService {

  public static PREFIX = 'SCOPE_';

  public static CREATE_APPLICATION_USER = ApplicationPermissionsService.PREFIX + 'CREATE_APPLICATION_USER';
  public static READ_APPLICATION_USER = ApplicationPermissionsService.PREFIX + 'READ_APPLICATION_USER';
  public static UPDATE_APPLICATION_USER = ApplicationPermissionsService.PREFIX + 'UPDATE_APPLICATION_USER';
  public static RESET_PASSWORD_APPLICATION_USER = ApplicationPermissionsService.PREFIX + 'RESET_PASSWORD_APPLICATION_USER';

  public static CREATE_APPLICATION_ROLE = ApplicationPermissionsService.PREFIX + 'CREATE_APPLICATION_ROLE';
  public static READ_APPLICATION_ROLE = ApplicationPermissionsService.PREFIX + 'READ_APPLICATION_ROLE';
  public static UPDATE_APPLICATION_ROLE = ApplicationPermissionsService.PREFIX + 'UPDATE_APPLICATION_ROLE';
  public static DELETE_APPLICATION_ROLE = ApplicationPermissionsService.PREFIX + 'DELETE_APPLICATION_ROLE';

  public static CREATE_INSTITUTION = ApplicationPermissionsService.PREFIX + 'CREATE_INSTITUTION';
  public static READ_INSTITUTION = ApplicationPermissionsService.PREFIX + 'READ_INSTITUTION';
  public static UPDATE_INSTITUTION = ApplicationPermissionsService.PREFIX + 'UPDATE_INSTITUTION';

  public static CREATE_SYS_CONFIG = ApplicationPermissionsService.PREFIX + 'CREATE_SYS_CONFIG';
  public static READ_SYS_CONFIG = ApplicationPermissionsService.PREFIX + 'READ_SYS_CONFIG';
  public static UPDATE_SYS_CONFIG = ApplicationPermissionsService.PREFIX + 'UPDATE_SYS_CONFIG';

  public static CREATE_DEPARTMENT = ApplicationPermissionsService.PREFIX + 'CREATE_DEPARTMENT';
  public static READ_DEPARTMENT = ApplicationPermissionsService.PREFIX + 'READ_DEPARTMENT';
  public static UPDATE_DEPARTMENT = ApplicationPermissionsService.PREFIX + 'UPDATE_DEPARTMENT';

  public static CREATE_HOLIDAY = ApplicationPermissionsService.PREFIX + 'CREATE_HOLIDAY';
  public static READ_HOLIDAY = ApplicationPermissionsService.PREFIX + 'READ_HOLIDAY';
  public static UPDATE_HOLIDAY = ApplicationPermissionsService.PREFIX + 'UPDATE_HOLIDAY';
  public static DELETE_HOLIDAY = ApplicationPermissionsService.PREFIX + 'DELETE_HOLIDAY'

  public static CREATE_BUSINESS_HOUR = ApplicationPermissionsService.PREFIX + 'CREATE_BUSINESS_HOUR';
  public static READ_BUSINESS_HOUR = ApplicationPermissionsService.PREFIX + 'READ_BUSINESS_HOUR';
  public static UPDATE_BUSINESS_HOUR = ApplicationPermissionsService.PREFIX + 'UPDATE_BUSINESS_HOUR';
  public static DELETE_BUSINESS_HOUR = ApplicationPermissionsService.PREFIX + 'DELETE_BUSINESS_HOUR';

  public static CREATE_SLA = ApplicationPermissionsService.PREFIX + "CREATE_SLA";
  public static READ_SLA = ApplicationPermissionsService.PREFIX + "READ_SLA";
  public static UPDATE_SLA = ApplicationPermissionsService.PREFIX + "UPDATE_SLA";
  public static DELETE_SLA = ApplicationPermissionsService.PREFIX + "DELETE_SLA";

  public static CREATE_VEHICLE_MODEL = ApplicationPermissionsService.PREFIX + "CREATE_MODEL";
  public static READ_VEHICLE_MODEL = ApplicationPermissionsService.PREFIX + "READ_MODEL";
  public static UPDATE_VEHICLE_MODEL = ApplicationPermissionsService.PREFIX + "UPDATE_MODEL";

  public static CREATE_CUSTOMER = ApplicationPermissionsService.PREFIX + "CREATE_CUSTOMER";
  public static READ_CUSTOMER = ApplicationPermissionsService.PREFIX + "READ_CUSTOMER";
  public static UPDATE_CUSTOMER = ApplicationPermissionsService.PREFIX + "UPDATE_CUSTOMER";

  public static CREATE_TICKET = ApplicationPermissionsService.PREFIX + "CREATE_TICKET";
  public static READ_TICKET = ApplicationPermissionsService.PREFIX + "READ_TICKET";
  public static UPDATE_TICKET = ApplicationPermissionsService.PREFIX + "UPDATE_TICKET";
  public static MODIFY_TICKET = ApplicationPermissionsService.PREFIX + "MODIFY_TICKET";
  public static ON_HOLD_TICKET = ApplicationPermissionsService.PREFIX + "ON_HOLD_TICKET";
  public static CANCEL_TICKET = ApplicationPermissionsService.PREFIX + "CANCEL_TICKET";
  public static READ_PERSONAL_TICKET = ApplicationPermissionsService.PREFIX + "READ_PERSONAL_TICKET";
  public static READ_PERSONAL_TICKET_DEFAULT_DEPARTMENT = ApplicationPermissionsService.PREFIX + "READ_PERSONAL_TICKET_DEFAULT_DEPARTMENT";
  public static READ_TICKET_DEPARTMENT_STATISTIC =  ApplicationPermissionsService.PREFIX + "READ_TICKET_DEPARTMENT_STATISTIC";
  public static READ_TICKET_DEFAULT_DEPARTMENT_STATISTIC =  ApplicationPermissionsService.PREFIX + "READ_TICKET_DEFAULT_DEPARTMENT_STATISTIC";
  public static READ_TICKET_CATEGORY = ApplicationPermissionsService.PREFIX + "READ_TICKET_CATEGORY";
  public static READ_TICKET_SLA_COMPLIANCE_DEPARTMENT = ApplicationPermissionsService.PREFIX + "READ_TICKET_SLA_COMPLIANCE_DEPARTMENT";
  public static READ_TICKET_SLA_COMPLIANCE_DEFAULT_DEPARTMENT = ApplicationPermissionsService.PREFIX + "READ_TICKET_SLA_COMPLIANCE_DEFAULT_DEPARTMENT";


  public static CREATE_CATEGORY = ApplicationPermissionsService.PREFIX + "CREATE_CATEGORY";
  public static READ_CATEGORY = ApplicationPermissionsService.PREFIX + "READ_CATEGORY";
  public static UPDATE_CATEGORY = ApplicationPermissionsService.PREFIX + "UPDATE_CATEGORY";

  public static READ_REPORT = ApplicationPermissionsService.PREFIX + 'READ_REPORT';
  public static READ_REPORT_TICKET = ApplicationPermissionsService.READ_REPORT + '_TICKET';
  public static READ_REPORT_TICKET_LISTING = ApplicationPermissionsService.READ_REPORT_TICKET + '_LISTING';
  public static READ_REPORT_TICKET_TIER_ONE = ApplicationPermissionsService.READ_REPORT_TICKET + '_TIER_ONE';
  public static READ_REPORT_TICKET_TIER_TWO = ApplicationPermissionsService.READ_REPORT_TICKET + '_TIER_TWO';
  public static READ_REPORT_TICKET_TIER_THREE = ApplicationPermissionsService.READ_REPORT_TICKET + '_TIER_THREE';
  public static READ_REPORT_SSI = ApplicationPermissionsService.READ_REPORT + '_SSI';
  public static READ_REPORT_SSI_SCORE = ApplicationPermissionsService.READ_REPORT_SSI + '_SCORE';
  public static READ_REPORT_CSI = ApplicationPermissionsService.READ_REPORT + '_CSI';
  public static READ_REPORT_CSI_SCORE = ApplicationPermissionsService.READ_REPORT_CSI + '_SCORE';
  public static READ_REPORT_SSI_AVERAGE_ANSWER = ApplicationPermissionsService.READ_REPORT_SSI + '_AVERAGE_ANSWER';
  public static READ_REPORT_CSI_AVERAGE_ANSWER = ApplicationPermissionsService.READ_REPORT_CSI + '_AVERAGE_ANSWER';
  public static READ_REPORT_SSI_RESPONSE_RATE = ApplicationPermissionsService.READ_REPORT_SSI + '_RESPONSE_RATE';
  public static READ_REPORT_CSI_RESPONSE_RATE = ApplicationPermissionsService.READ_REPORT_CSI + '_RESPONSE_RATE';
  public static READ_REPORT_NPS = ApplicationPermissionsService.READ_REPORT + '_NPS';
  public static READ_REPORT_NPS_FULL_LISTING = ApplicationPermissionsService.READ_REPORT_NPS + '_FULL_LISTING';
  public static READ_REPORT_NPS_INDIVIDUAL_LISTING = ApplicationPermissionsService.READ_REPORT_NPS + '_INDIVIDUAL_LISTING';


  public static CREATE_SSI = ApplicationPermissionsService.PREFIX + "CREATE_SSI";
  public static READ_INDIVIDUAL_SSI = ApplicationPermissionsService.PREFIX + "READ_INDIVIDUAL_SSI";
  public static READ_FULL_SSI = ApplicationPermissionsService.PREFIX + "READ_FULL_SSI";
  public static MODIFY_SSI = ApplicationPermissionsService.PREFIX + "MODIFY_SSI";

  public static CREATE_CSI = ApplicationPermissionsService.PREFIX + "CREATE_CSI";
  public static READ_INDIVIDUAL_CSI = ApplicationPermissionsService.PREFIX + "READ_INDIVIDUAL_CSI";
  public static READ_FULL_CSI = ApplicationPermissionsService.PREFIX + "READ_FULL_CSI";
  public static MODIFY_CSI = ApplicationPermissionsService.PREFIX + "MODIFY_CSI";

  public static CREATE_NPS = ApplicationPermissionsService.PREFIX + "CREATE_NPS";
  public static UPDATE_NPS = ApplicationPermissionsService.PREFIX + "UPDATE_NPS";
  public static READ_FULL_NPS = ApplicationPermissionsService.PREFIX + "READ_FULL_NPS";
  public static READ_INDIVIDUAL_NPS = ApplicationPermissionsService.PREFIX + "READ_INDIVIDUAL_NPS";

  public static CREATE_MYSTERY_SHOPPER = ApplicationPermissionsService.PREFIX + "CREATE_MYSTERY_SHOPPER";
  public static READ_MYSTERY_SHOPPER = ApplicationPermissionsService.PREFIX + "READ_MYSTERY_SHOPPER";
  public static READ_REPORT_MYSTERY_SHOPPER = ApplicationPermissionsService.READ_REPORT + "_MYSTERY_SHOPPER";

  constructor(private httpClient: HttpClient) {
  }

  public getAll(roleId: number | undefined): Observable<HttpResponse<ApplicationPermissionModel[]>> {
    let params = new HttpParams();
    if(roleId !== undefined) {
      params = params.set('roleId',roleId);
    }
    return this.httpClient.get<ApplicationPermissionModel[]>('/web/application-permission/all', {
      observe: 'response',
      params
    });
  }
}
