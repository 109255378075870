import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authService.user.value) {
      return next.handle(request);
    }

    const userDetails = this.authService.user.value;
    if (environment.security.basicAuthenticationEnabled) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Basic ' + btoa(`${userDetails.username}:${userDetails.password}`)
        }
      });
    } else if (environment.security.jwtAuthenticationEnabled || environment.security.oauth2Enabled) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${userDetails.token}`
        }
      });
    }

    return next.handle(request);
  }
}
