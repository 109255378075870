<div class="container-fluid nbt-container">
    <div class="nbt-header">
        <div class="nbt-header-title">System Config</div>
        <div class="nbt-btn-right">
            <button class="nbt-btn-primary" appScreenPermission [permissionsInput]="[CREATE_SYS_CONFIG]"
                (click)="openSystemConfigDialogCreateOrEdit()">
                <mat-icon class="mr8">add</mat-icon>
                Add System Config
            </button>
        </div>
    </div>
    <br>
    <form [formGroup]="searchFormGroup" (ngSubmit)="search(0)">
        <div class="nbt-form nbt-search-bar">
            <mat-form-field appearance="outline">
                <input matInput id="username" formControlName="searchCode" placeholder="Search code" />
                <mat-icon class="mr8 close" (click)="refreshForm()"
                    *ngIf="searchFormGroup?.value?.searchName?.length > 0">close</mat-icon>
                <mat-icon class="mr8 search" (click)="search(0)">search</mat-icon>
            </mat-form-field>
        </div>
    </form>
    <br>
    <div><b>{{numberOfRecords}}</b> result<span *ngIf="numberOfRecords > 1">s</span></div>
    <br>
    <mat-card-content class="mat-card-content-table">
        <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="dataSource" *ngIf="dataSource.data">
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.code || '-'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.description || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="institution">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Institution</th>
                <td mat-cell *matCellDef="let element">
                    {{ '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Active
                </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.active" class="nbt-status status-eco-blue">
                        Active
                    </div>
                    <div *ngIf="!element.active" class="nbt-status status-mid-grey">
                        Inactive
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_SYS_CONFIG)" (click)="openSystemConfigDialogCreateOrEdit(element)" matTooltip="Edit">edit</mat-icon>
                    <mat-icon (click)="openSystemConfigDialogDetail(element)" matTooltip="View">remove_red_eye</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
        </table>
    </mat-card-content>
    <mat-paginator (page)="navigateToPage($event)" [length]="numberOfRecords" [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
    </mat-paginator>
</div>