import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import {
    CustomerRecordModel,
    CustomerRecordVehiclesModel,
} from 'src/app/model/customer-records.model';
import { CustomerRecordsService } from 'src/app/services/customer-records.service';

@Component({
    selector: 'app-customer-records-dialog-transfer-ownership',
    templateUrl: './customer-records-dialog-transfer-ownership.component.html',
    styleUrls: ['./customer-records-dialog-transfer-ownership.component.scss'],
})
export class CustomerRecordsDialogTransferOwnershipComponent implements OnInit {
    windowWidth: number = window.innerWidth;
    customerRecords: CustomerRecordModel[] = [];
    customer: CustomerRecordModel;
    customerControl = new FormControl('');
    loading = true;
    totalCustomers = 0;
    currentTotalCustomers = 0;
    pageCustomer = 0;
    sizeCustomer = 20;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public customerRecordVehiclesModel: CustomerRecordVehiclesModel,
        public dialogRef: MatDialogRef<CustomerRecordsDialogTransferOwnershipComponent>,
        private customerRecordsService: CustomerRecordsService,
        private snackBar: MatSnackBar,
        public authService: AuthService
    ) {}

    ngOnInit(): void {
        this.searchCustomers();
    }

    onScroll() {
        if (this.totalCustomers > this.currentTotalCustomers) {
            this.pageCustomer++;
            this.searchCustomerRequest();
        }
    }

    searchCustomers() {
        this.customer = null;
        this.customerRecords = [];
        this.pageCustomer = 0;
        this.currentTotalCustomers = 0;
        this.searchCustomerRequest();
    }

    searchCustomerRequest() {
        if (this.customerControl.value.id) {
            this.customer = this.customerControl.value;
            return;
        }

        this.customerRecordsService
            .searchCustomers({
                currentInstitution: this.authService.getCurInsId(),
                page: this.pageCustomer,
                size: this.sizeCustomer,
                name: this.customerControl.value,
            })
            .subscribe({
                next: (value) => {
                    this.totalCustomers = Number(
                        value.headers.get('X-Total-Count')
                    );
                    this.currentTotalCustomers =
                        this.currentTotalCustomers + value.body.length;
                    this.customerRecords.push(...value.body);
                    this.deactiveLoading();
                },
                error: (error) => {
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000,
                    });
                },
            });
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    displayFn(customer: CustomerRecordModel): string {
        return customer && customer.firstName ? `${customer.firstName}` : '';
    }

    update() {
        this.activeLoading();
        this.customerRecordsService
            .chageOwnership(
                this.customerRecordVehiclesModel.id,
                this.customer.id,
                this.authService.getCurInsId()
            )
            .subscribe({
                next: () => {
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000,
                    });
                },
            });
    }

    @HostListener('window:resize')
      onResize() {
          this.windowWidth = window.innerWidth;
    }
}
