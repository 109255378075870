import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { CategoryModel } from 'src/app/model/category.model';
import { DepartmentModel } from 'src/app/model/department.model';
import { Model } from 'src/app/model/model.model';
import { SYS_CONFIG, SystemConfigurationModel } from 'src/app/model/system-configuration.model';
import { CategoryService } from 'src/app/services/category.service';
import { DepartmentService } from 'src/app/services/department.service';
import { ModelService } from 'src/app/services/model.service';
import { TicketService } from 'src/app/services/ticket.service';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { SnackBarStatus } from 'src/app/shared/constant';

@Component({
    selector: 'app-voice-of-customer-dialog-modification',
    templateUrl: './voice-of-customer-dialog-modification.component.html',
    styleUrls: ['./voice-of-customer-dialog-modification.component.scss']
})

export class VoiceOfCustomerDialogModificationComponent implements OnInit {
    readonly snackBarConfig: MatSnackBarConfig = { duration:60000 };

    modifyRespond = " has successfully modified ";
    loading: boolean = true;
    isSaving: boolean = false;
    ticketFormGroup: FormGroup;
    isShowRoom: boolean = true;
    serviceAdvisor: ApplicationUserModel;
    serviceAdvisorRecords: ApplicationUserModel[];
    models: Model[];
    close = SnackBarStatus.CLOSE;

    groups: CategoryModel[];
    categories: CategoryModel[];
    types: CategoryModel[];
    incidentBranchs: DepartmentModel[] = [];
    channels = SYS_CONFIG.CHANNEL;
    channelShow: boolean = false;
    channelSelected:string = '';
    sysConfigChannel: SystemConfigurationModel[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public ticketModel: any,
        public dialogRef: MatDialogRef<VoiceOfCustomerDialogModificationComponent>,
        private departmentService: DepartmentService,
        private modelService: ModelService,
        public authService: AuthService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private categoryService: CategoryService,
        private ticketService: TicketService,
        private systemConfigurationService: SystemConfigurationService,
    ) { }

    ngOnInit(): void {
        this.categoryService.getAllByParentIdNoPagination(null,true).subscribe({
            next: response => {
                this.groups = response.body.filter(cat => cat.path.split("|").length === 1);
                this.categories = response.body.filter(cat => cat.path.split("|").length === 2);
                this.types = response.body.filter(cat => cat.path.split("|").length === 3);
            }
        });

        this.departmentService.getAllNoPagination(true,null,null).subscribe({
            next: value => { this.incidentBranchs = value.body }
        });

        this.modelService.getAllModelNoPagination().subscribe({
            next: (value) => (this.models = value.body),
        });

        this.systemConfigurationService.getSystemConfigurationValueByCode(this.channels, this.authService.getCurInsId()).subscribe({
            next: (value) => (this.sysConfigChannel = value.body),
        });

        this.ticketFormGroup = this.formBuilder.group({
            id: this.formBuilder.control(this.ticketModel.id),
            action: this.formBuilder.control(this.ticketModel.status),
            group: this.formBuilder.control(this.ticketModel.group, [Validators.required]),
            category: this.formBuilder.control(this.ticketModel.category, [Validators.required]),
            type: this.formBuilder.control(this.ticketModel.type, [Validators.required]),
            title: this.formBuilder.control(this.ticketModel.title, [Validators.required]),
            detail: this.formBuilder.control(this.ticketModel.detail, [Validators.required]),
            incidentBranch: this.formBuilder.control(this.ticketModel.incidentBranch, [Validators.required]),
            channel: this.formBuilder.control(this.ticketModel.channel, [Validators.required]),
            serviceDeliveryDate: this.formBuilder.control(this.ticketModel.serviceDeliveryDate),
            channelOther: this.formBuilder.control(this.ticketModel.channelOther),
            model: this.formBuilder.control(this.ticketModel.vehicle?.model),
            registrationNo: this.formBuilder.control(this.ticketModel.vehicle?.registrationNo),
            justification: this.formBuilder.control(null, Validators.required),
        });

        if(this.ticketModel.channelOther?.length > 0){
            this.channelShow = true;
        }

        this.loading = false;
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    changeOption(event){
        this.channelSelected = event.value;
        if(event.value == 'Others'){
            this.channelShow = true;
            this.ticketFormGroup.get('channelOther').setValidators([Validators.required]);
            this.ticketFormGroup.get('channelOther').updateValueAndValidity()
        }else{
            this.channelShow = false;
            this.ticketFormGroup.get('channelOther').setValue(null);
            this.ticketFormGroup.get('channelOther').clearValidators();
            this.ticketFormGroup.get('channelOther').updateValueAndValidity()
        }
    }

    changeSelectGroup(event: any) {
        this.categories = [];
        this.types = [];
        this.ticketFormGroup.controls['category'].reset();
        this.ticketFormGroup.controls['type'].reset();
        this.categoryService.getAllByParentIdNoPagination(event.value.id).subscribe({
            next: response => {
                this.categories = response.body;
            }
        })
    }

    changeSelectCategory(event: any) {
        this.categoryService.getAllByParentIdNoPagination(event.value.id).subscribe({
            next: response => {
                this.types = response.body;
            }
        })
    }

    save(){
        this.isSaving = true;
        this.activeLoading();
        const ticketValue = this.ticketFormGroup.getRawValue();

        if (ticketValue.model && ticketValue.registrationNo) {
            ticketValue.vehicle = {
                model: ticketValue.model,
                registrationNo: ticketValue.registrationNo
            }
        }

        this.ticketService.modificationUpdate(ticketValue, this.authService.getCurInsId()).subscribe({
            next:(response)=>{
                this.isSaving = false;
                let theMessage = response.body.lastModifiedBy + this.modifyRespond + response.body.ticketNumber;
                this.deactiveLoading();
                this.snackBar.open(theMessage, this.close, this.snackBarConfig);
                this.dialogRef.close('ok');
            },
            error: (error) => {
                this.isSaving = false;
                this.deactiveLoading();
                this.snackBar.open(error.error.debugMessage, 'Close', this.snackBarConfig);
            }
        });
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    back() {
        window.history.back();
    }
}
