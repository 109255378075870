import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AutofocusDirective } from './directive/autofocus.directive';
import { ScreenPermissionDirective } from './directive/screen-permission.directive';
import { DialogSimpleComponent } from './dialog-simple/dialog-simple.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
    declarations: [
        AutofocusDirective,
        ScreenPermissionDirective,
        DialogSimpleComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        AutofocusDirective,
        ScreenPermissionDirective,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        DatePipe,
    ],
    providers: [
        DatePipe
    ]
})
export class SharedModule {
}
