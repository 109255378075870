import { Property, propertyNameCompare } from '../util';

export const IMAGE_ENUM: Property[] = [
    {
        name: 'VERY_HAPPY',
        value: '../assets/survey/veryGood.png',
    },
    {
        name: 'HAPPY',
        value: '../assets/survey/good.png',
    },
    {
        name: 'NEUTRAL',
        value: '../assets/survey/neutral.png',
    },
    {
        name: 'VERY_SAD',
        value: '../assets/survey/veryPoor.png',
    },
    {
        name: 'SAD',
        value: '../assets/survey/poor.png',
    },
    {
        name: 'AGREE',
        value: '../assets/survey/agree.png',
    },
    {
        name: 'DISAGREE',
        value: '../assets/survey/disagree.png',
    },
    {
        name: 'YES',
        value: '../assets/survey/ya.png',
    },
    {
        name: 'NO',
        value: '../assets/survey/no.png',
    },
    {
        name: 'RANGE_1',
        value: '../assets/survey/range1.png'
    },
    {
        name: 'RANGE_2',
        value: '../assets/survey/range2.png'
    },
    {
        name: 'RANGE_3',
        value: '../assets/survey/range3.png'
    },
    {
        name: 'RANGE_4',
        value: '../assets/survey/range4.png'
    },
    {
        name: 'RANGE_5',
        value: '../assets/survey/range5.png'
    },
    {
        name: 'RANGE_6',
        value: '../assets/survey/range6.png'
    },
    {
        name: 'RANGE_7',
        value: '../assets/survey/range7.png'
    },
    {
        name: 'RANGE_8',
        value: '../assets/survey/range8.png'
    },
    {
        name: 'RANGE_9',
        value: '../assets/survey/range9.png'
    },
    {
        name: 'RANGE_10',
        value: '../assets/survey/range10.png'
    },
    {
        name: 'ADVERTISEMENT',
        value: '../assets/survey/advertisement.png'
    },
    {
        name: 'EVENT',
        value: '../assets/survey/event.png'
    },
    {
        name: 'LEXUS_SHOWROOM',
        value: '../assets/survey/lexusShowroom.png'
    },
    {
        name: 'TOYOTA_SHOWROOM',
        value: '../assets/survey/toyotaShowroom.png'
    },
    {
        name: 'NBT_EMPLOYEE',
        value: '../assets/survey/nbtEmployee.png'
    },
    {
        name: 'OTHER',
        value: '../assets/survey/other.png'
    },
    {
        name: 'OUTSTANDING_SERVICE',
        value: '../assets/survey/outstandingService.png'
    },
    {
        name: 'PRICE',
        value: '../assets/survey/price.png'
    },
    {
        name: 'PRODUCT_QUALITY',
        value: '../assets/survey/productQuality.png'
    },
    {
        name: 'QUCC_SOCIAL_MEDIA',
        value: '../assets/survey/quccSocialMedia.png'
    },
    {
        name: 'RADIO',
        value: '../assets/survey/radio.png'
    },
    {
        name: 'RECOMMENDATION',
        value: '../assets/survey/recommendation.png'
    },
    {
        name: 'RELIABLE',
        value: '../assets/survey/reliable.png'
    },
    {
        name: 'SALES_CONSULTANT_SOCIAL_MEDIA',
        value: '../assets/survey/salesConsultantSocialMedia.png'
    },
    {
        name: 'TRUSTED',
        value: '../assets/survey/trusted.png'
    }
]
