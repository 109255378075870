import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { DepartmentModel } from 'src/app/model/department.model';
import { Model } from 'src/app/model/model.model';
import { SurveyModel } from 'src/app/model/survey.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DepartmentService } from 'src/app/services/department.service';
import { ModelService } from 'src/app/services/model.service';
import { SurveyService } from 'src/app/services/survey.service';
import { UsersService } from 'src/app/services/users.service';
import { REGEXS, SnackBarStatus } from 'src/app/shared/constant';

@Component({
    selector: 'app-sales-dialog-new-or-edit',
    templateUrl: './sales-dialog-new-or-edit.component.html',
    styleUrls: ['./sales-dialog-new-or-edit.component.scss']
})

export class SalesDialogNewOrEditComponent implements OnInit, OnDestroy {
    readonly CREATE_SSI = ApplicationPermissionsService.CREATE_SSI;
    readonly snackBarConfig: MatSnackBarConfig = { duration:60000 };
    loading: boolean = true;
    dialogType: string = '';
    isSaving: boolean = false;
    ssiFormGroup: FormGroup;
    departments: DepartmentModel[];
    isShowRoom: boolean = true;
    salesConsultant: ApplicationUserModel[];
    salesConsultantRecords: ApplicationUserModel[];
    models: Model[];
    close = SnackBarStatus.CLOSE;
    respond =" has issued a new SSI form : ";
    modifyRespond = " has modified a form : ";

    departmentId: number = null;
    salesConsultantControl = new FormControl('', Validators.required);
    totalSalesConsultant = 0;
    currentTotalSalesConsultant = 0;
    pageSalesConsultant = 0;
    sizeSalesConsultant = 20;
    searchConsultantSubscription: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SurveyModel,
        public dialogRef: MatDialogRef<SalesDialogNewOrEditComponent>,
        private departmentService: DepartmentService,
        private modelService: ModelService,
        private userService: UsersService,
        public authService: AuthService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private surveyService: SurveyService,
    ) { }

    ngOnInit(): void {
        if(this.data) {
            this.dialogType = 'update';
            if(this.data.department) {
                this.changeSelectDepartment(parseInt(this.data.department.id));
            }
            this.salesConsultantControl.setValue(this.data.salesConsultant);
            this.ssiFormGroup = this.formBuilder.group({
                id: this.data.id,
                department: this.formBuilder.control(this.data.department, Validators.required),
                salesConsultant: this.salesConsultantControl,
                vehicle : this.formBuilder.group({
                    model: this.formBuilder.control(this.data.vehicle.model, Validators.required),
                    registrationNo: this.formBuilder.control(this.data.vehicle.registrationNo, [Validators.required,Validators.pattern(REGEXS.VEHICLE)]),
                }),
                justification: this.formBuilder.control(null, Validators.required),
                surveyType: this.formBuilder.control(this.data.surveyType),
            });
        }
        else {
            this.dialogType = 'create';
            this.ssiFormGroup = this.formBuilder.group({
                department: this.formBuilder.control(null, Validators.required),
                salesConsultant: this.salesConsultantControl,
                vehicle : this.formBuilder.group({
                    model: this.formBuilder.control(null, Validators.required),
                    registrationNo: this.formBuilder.control(null,[Validators.required,Validators.pattern(REGEXS.VEHICLE)]),
                }),
                surveyType: this.formBuilder.control(this.data.surveyType),
            });
        }

        this.modelService.getAllModelNoPagination().subscribe({
            next: (value) => (this.models = value.body),
        });
        // GET All ShowRoom
        this.getDepartmentsWithDefaultDepartmentChecking();
    }

    ngOnDestroy(): void {
        if(this.searchConsultantSubscription)
            this.searchConsultantSubscription.unsubscribe();
    }

   changeSelectDepartment(departmentId: number){
        this.departmentId = departmentId;
        this.salesConsultantRecords = [];
        this.salesConsultant = null;
        this.pageSalesConsultant = 0;
        this.currentTotalSalesConsultant = 0;
        this.salesConsultantControl.reset("");
        this.searchSalesConsultantRequest();
    }

    searchSalesConsultant() {
        this.salesConsultantRecords = [];
        this.salesConsultant = null;
        this.pageSalesConsultant = 0;
        this.currentTotalSalesConsultant = 0;
        this.searchSalesConsultantRequest();
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    onScroll() {
        if (this.totalSalesConsultant > this.currentTotalSalesConsultant) {
            this.pageSalesConsultant++;
            this.searchSalesConsultantRequest();
        }
    }

    searchSalesConsultantRequest() {
        if(this.departmentId!=null) {
            if (this.salesConsultantControl.value.id) {
                this.salesConsultant = this.salesConsultantControl.value;
                return;
            }

            if(this.searchConsultantSubscription)
                this.searchConsultantSubscription.unsubscribe();

            this.searchConsultantSubscription = this.userService
                .searchAllByDepartment(
                    this.pageSalesConsultant,
                    this.sizeSalesConsultant,
                    this.salesConsultantControl.value,
                    this.authService.getCurInsId(),
                    this.departmentId,
                    { reminderInd: false, defaultDepartmentInd: false },
                    'sales consultant')
                .subscribe({
                    next: (value) => {
                        this.totalSalesConsultant = Number(value.headers.get('X-Total-Count'));
                        this.currentTotalSalesConsultant = this.currentTotalSalesConsultant + value.body.length;
                        this.salesConsultantRecords.push(...value.body);
                        this.deactiveLoading();
                    },
                    error: (error) => {
                        this.deactiveLoading();
                        this.snackBar.open(error.error.debugMessage, 'Close', {
                            duration: 60000,
                        });
                    },
            });
        }
    }

    displayFn(salesConsultant: ApplicationUserModel): string {
        return salesConsultant && salesConsultant.username ? `${salesConsultant.username}` : '';
    }

    save(){
        this.isSaving = true;
        this.activeLoading();
        if(this.dialogType == 'create') {
            this.surveyService.createSurvey(this.ssiFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                next:(response)=>{
                    this.isSaving = false;
                    let theMessage = this.authService.getUsername() + this.respond + response.body.surveyNumber;
                    this.deactiveLoading();
                    this.snackBar.open(theMessage, this.close, this.snackBarConfig);
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.debugMessage, this.close, this.snackBarConfig);
                }
            });
        } else if(this.dialogType == 'update') {
            this.surveyService.modificationUpdate(this.ssiFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                next:(response)=>{
                    this.isSaving = false;
                    let theMessage = this.authService.getUsername() + this.modifyRespond + response.body.surveyNumber;
                    this.deactiveLoading();
                    this.snackBar.open(theMessage, this.close, this.snackBarConfig);
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.debugMessage, this.close, this.snackBarConfig);
                }
            });
        }
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    back() {
        window.history.back();
    }

    //TODO: REFACTOR FUNCTION
    getDepartmentsWithDefaultDepartmentChecking() {
        this.departmentService.isDefaultDepartment(this.authService.getCurInsId()).subscribe({
            next: (isDefaultDepartment) => {
                if (isDefaultDepartment.body) {
                    this.departmentService.getAllNoPagination(null, false, true).subscribe({
                        next: (res) => {
                            this.departments = res.body;
                        },
                        error: (res) => {
                            this.snackBar.open(
                                res.error.message,
                                'Close',
                                this.snackBarConfig
                            );
                        },
                        complete: () => {
                            this.deactiveLoading();
                        },
                    });
                } else {
                    this.departmentService.getDepartmentByUser(this.authService.getCurInsId(), null, false, true).subscribe({
                            next: (res) => {
                                this.departments = res.body;
                            },
                            error: (res) => {
                                this.snackBar.open(
                                    res.error.message,
                                    'Close',
                                    this.snackBarConfig
                                );
                            },
                            complete: () => {
                                this.deactiveLoading();
                            },
                        });
                }
            },
            error: (err) => {
                this.snackBar.open(
                    err.error.message,
                    'Close',
                    this.snackBarConfig
                );
            },
            complete: () => {
                this.deactiveLoading();
            },
        });
    }
}
