<div class="container-fluid nbt-container">
    <div class="nbt-header">
        <div class="nbt-header-title">Holiday</div>
        <div class="nbt-btn-right">
            <button class="nbt-btn-primary" appScreenPermission [permissionsInput]="[CREATE_HOLIDAY]"
                (click)="openHolidayDialogCreateOrEdit()">
                <mat-icon class="mr8">add</mat-icon>
                Add Holiday
            </button>
        </div>
    </div>
    <br>
    <div class="upper-buttons">
        <ng-container *ngIf="windowWidth > 768">
            <div class="d-flex">
                <button class="nbt-btn-primary"
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Previous
                </button>
                <button class="nbt-btn-primary" id="todayDateBtn"
                    mwlCalendarToday
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Today
                </button>
                <input type="month" class="btn btn-outline-secondary" (change)="changeDate($event)" [(ngModel)]="inputSearchDate"> 
                <button class="nbt-btn-primary"
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Next
                </button>
            </div>
            <div style="font-size: 32px; font-weight: 600;">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</div>
        </ng-container>
        <ng-container *ngIf="windowWidth <= 768">
            <div class="d-flex upper">
                <button class="nbt-btn-primary mr8"
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Previous
                </button>
                <button class="nbt-btn-primary mr8" id="todayDateBtn"
                    mwlCalendarToday
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Today
                </button>
                <button class="nbt-btn-primary"
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Next
                </button>
            </div>
            <br>
            <div class="d-flex lower">
                <input type="month" class="btn btn-outline-secondary w-50 mr8" (change)="changeDate($event)" [(ngModel)]="inputSearchDate"> 
                <div style="font-size: 32px; font-weight: 600;">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</div>
            </div>
        </ng-container>
    </div>
    <br>
    <div *ngIf="loading" class="nbt-empty-result">
        <div class="nbt-data-loading"></div>
    </div>
    <div *ngIf="!loading">
        <div style="overflow: auto;">
            <mwl-calendar-month-view 
                [viewDate]="viewDate"
                [events]="events"
                [refresh]="refreshCalendar"
                (beforeViewRender)="beforeMonthViewRender($event)"
                (columnHeaderClicked)="clickedColumn = $event.isoDayNumber"
                (dayClicked)="dayChoosed($event.day.date)">
            </mwl-calendar-month-view>
        </div>
    </div>
    <br>
    <div class="legend-table">
        <table>
            <tr>
            <th>        
                <div class="desc-legend">Legend</div>
            </th>
            </tr>
            <tr>
                <td>
                    <div class="flex-center-legend mb8">
                        <div class="color-legend" style="background-color: #faef83;"></div>
                        <div class="desc-legend"> - Date is recurring date</div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="flex-center-legend">
                        <div class="color-legend" style="background-color: #9cd8f4;"></div>
                        <div class="desc-legend"> - Date is non-recurring date</div>
                    </div>
                </td>
            </tr>
        </table>
    </div>  
    <br>
    <mat-card-content class="mat-card-content-table">
        <table mat-table matSort [dataSource]="dataSource" *ngIf="dataSource.data.length > 0">

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef style="color: white;">
                    Date
                </th>
                <td mat-cell *matCellDef="let element">
                    <div>{{(element.date | date: 'dd MMMM') || '-'}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef style="color: white;">
                    Name
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.name || '-'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_HOLIDAY)" (click)="openHolidayDialogCreateOrEdit(element)" matTooltip="Edit">edit</mat-icon>
                    <mat-icon *ngIf="authService.hasAuthorityMenu(DELETE_HOLIDAY)" (click)="openHolidayDialogDelete(element)" matTooltip="Delete">delete</mat-icon>
                    <mat-icon (click)="openHolidayDialogDetail(element)" matTooltip="View">remove_red_eye</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
        </table>
    </mat-card-content>
</div>