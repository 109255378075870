import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DepartmentService } from 'src/app/services/department.service';

@Component({
    selector: 'app-departments-dialog-detail',
    templateUrl: './departments-dialog-detail.component.html',
    styleUrls: ['./departments-dialog-detail.component.scss']
})
export class DepartmentsDialogDetailComponent implements OnInit {

    readonly UPDATE_DEPARTMENT = ApplicationPermissionsService.UPDATE_DEPARTMENT;
    triggerTimeResolutions = [];
    triggerTimeResponses = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public departmentModel: any,
        public dialogRef: MatDialogRef<DepartmentsDialogDetailComponent>,
        public authService: AuthService,
        private departmentService: DepartmentService,
    ) { }

    ngOnInit(): void {
        this.departmentService.getTriggerTimeResolution().subscribe({
            next: value => {
                this.triggerTimeResolutions = [];
                if (value) {
                    for (let i in value) {
                        this.triggerTimeResolutions.push(value[i]);
                    }
                }
            }
        })
        this.departmentService.getTriggerTimeResponse().subscribe({
            next: value => {
                this.triggerTimeResponses = [];
                for (let i in value) {
                    this.triggerTimeResponses.push(value[i]);
                }
            }
        })
    }

    mapTriggerTime(triggerTime: string, type: string): string {
        if (type === "RESOLUTION") return this.triggerTimeResolutions.find(trigger => trigger.triggerTime === triggerTime)?.message;
        else return this.triggerTimeResponses.find(trigger => trigger.triggerTime === triggerTime)?.message;
    }
}
