import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/auth/auth.service';
import { CustomerRecordsService } from 'src/app/services/customer-records.service';
import { NpsModel } from 'src/app/model/nps.model';
import { CustomerRecordModel, CustomerRecordActivityModel } from 'src/app/model/customer-records.model';
import { TimeZone, SnackBarStatus, ACTION_ENUM } from 'src/app/shared/constant';

@Component({
  selector: 'app-nps-call-in-dialog-detail',
  templateUrl: './nps-call-in-dialog-detail.component.html',
  styleUrls: ['./nps-call-in-dialog-detail.component.scss']
})

export class NpsCallInDialogDetailComponent implements OnInit {

    windowWidth: number = window.innerWidth;
    readonly snackBarConfig: MatSnackBarConfig = { duration:60000 };
    loading: boolean = false;
    npsFormGroup: FormGroup;
    npsCallInDetailFormGroup: FormGroup;
    customerRecordActivityModel: CustomerRecordActivityModel;
    callDatetimeString: String;
    close = SnackBarStatus.CLOSE;
    actionEnums: any[] = ACTION_ENUM;


    constructor(
        @Inject(MAT_DIALOG_DATA) public npsModel: NpsModel,
        public dialogRef: MatDialogRef<NpsCallInDialogDetailComponent>,
        private formBuilder : FormBuilder,
        private snackBar: MatSnackBar,
        private datePipe: DatePipe,
        private authService: AuthService,
        private customerRecordsService: CustomerRecordsService,
    ) {
    }

    ngOnInit(): void {
        this.activeLoading();
        this.getActivityRecord();
    }

    getActivityRecord() {
        this.customerRecordsService.getOneActivity(this.npsModel.activity.id,
                                                    this.npsModel.activity.customer.id,
                                                    this.authService.getCurInsId()).subscribe({
            next: value => {
                this.customerRecordActivityModel = value.body;
                //Calling customer record here because when call activity not returning all customer data
                this.getCustomerRecord();
            },
            error: error => {
                this.deactiveLoading();
                this.snackBar.open(error.message, this.close, this.snackBarConfig);
            }
        });
    }

    getCustomerRecord() {
        this.customerRecordsService.getOne(this.customerRecordActivityModel.customer.id, this.authService.getCurInsId()).subscribe({
            next: value => {
                this.customerRecordActivityModel.customer = value.body;
                this.defineNpsCallInDetailFormGroup();
                this.deactiveLoading();
            },
            error: error => {
                this.deactiveLoading();
                this.snackBar.open(error.message, this.close, this.snackBarConfig);
            }
        });
    }

    defineNpsCallInDetailFormGroup() {
        if(this.npsModel.callDatetime!=null || this.npsModel.callDatetime!=undefined) {
            this.callDatetimeString = this.datePipe.transform(new Date(this.npsModel.callDatetime),"yyyy-MM-dd'T'HH:mm");
        }

        this.npsCallInDetailFormGroup = this.formBuilder.group({
            id: this.formBuilder.control(this.npsModel.id),
            status: this.formBuilder.control(this.npsModel.status),
            callDatetimeString: this.formBuilder.control(this.callDatetimeString, [Validators.required]),
            callDetail: this.formBuilder.control(this.npsModel.callDetail, [Validators.required]),
        })
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    statusValue(value: string): string {
        return this.actionEnums.find(status => status.value === value)?.name;
    }

    getStatusColor(value: string) {
        if (value === 'OPEN') {
          return 'status-color-new';
        }
        if (value === 'RESCHEDULED') {
          return 'status-color-rescheduled';
        }
        if (value === 'COMPLETED') {
          return 'status-color-completed';
        }
        if (value === 'DECLINED') {
          return 'status-color-declined';
        }
    }

    @HostListener('window:resize')
      onResize() {
          this.windowWidth = window.innerWidth;
    }
}
