import { Component, Inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { CustomerRecordModel, CustomerRecordVehiclesModel } from 'src/app/model/customer-records.model';
import { DepartmentModel } from 'src/app/model/department.model';
import { SystemConfigurationModel, SYS_CONFIG } from 'src/app/model/system-configuration.model';
import { CustomerRecordsService } from 'src/app/services/customer-records.service';
import { DepartmentService } from 'src/app/services/department.service';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { REGEXS } from 'src/app/shared/constant/regex.constant';
import { TOOLTIPS } from 'src/app/shared/constant/tooltips-enum.constant';
import { CustomerRecordsDialogNewOrEditVehiclesComponent } from '../customer-records-dialog-new-or-edit-vehicles/customer-records-dialog-new-or-edit-vehicles.component';
import { CustomerRecordsDialogTransferOwnershipComponent } from '../customer-records-dialog-transfer-ownership/customer-records-dialog-transfer-ownership.component';
import { faJava } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'app-customer-records-dialog-new-or-edit',
    templateUrl: './customer-records-dialog-new-or-edit.component.html',
    styleUrls: ['./customer-records-dialog-new-or-edit.component.scss']
})
export class CustomerRecordsDialogNewOrEditComponent implements OnInit {

    customerRecordFormGroup: FormGroup;
    dialogType: string = '';
    isSaving: boolean = false;
    satulations = SYS_CONFIG.SALUTATION;
    sysConfigSal: SystemConfigurationModel[];
    imgUrl: any
    fileList: FileList;
    emailContent: string = TOOLTIPS.emailContent;
    phoneContent: string = TOOLTIPS.phoneContent;
    icContent: string = TOOLTIPS.icContent;
    customerAttContent: string = TOOLTIPS.customerAttContent;
    loading: boolean = true;
    isShowing:boolean = false;
    branchs: DepartmentModel[];
    showrooms: DepartmentModel[];
    isMysteryShopper: boolean = false;
    mysteryShopperStartDate: Date;
    mysteryShopperEndDate: Date;
    mysteryShopperDepartmentList: DepartmentModel[];
    isShowMysteryShopperCheckbox: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public customerRecordModel: CustomerRecordModel,
        public dialogRef: MatDialogRef<CustomerRecordsDialogNewOrEditComponent>,
        private formBuilder: FormBuilder,
        public authService: AuthService,
        private snackBar: MatSnackBar,
        private customerRecordsService: CustomerRecordsService,
        private matDialog: MatDialog,
        private sysConfigService: SystemConfigurationService,
        private departmentService : DepartmentService,
        private datePipe: DatePipe,
    ) {
    }

    ngOnInit(): void {
        if (this.customerRecordModel) {
            this.dialogType = 'update';
            this.isMysteryShopper = this.customerRecordModel.isMysteryShopper;

            if (this.authService.getAuthorities().some(e => e.authority.includes('CREATE_MYSTERY_SHOPPER'))) {
                this.isShowMysteryShopperCheckbox = true;
            }

            this.customerRecordFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.customerRecordModel.id),
                salutation: this.formBuilder.control(this.customerRecordModel.salutation),
                isBuyer: this.formBuilder.control(this.customerRecordModel.isBuyer),
                isMysteryShopper: this.formBuilder.control(this.isMysteryShopper),
                firstName: this.formBuilder.control(this.customerRecordModel.firstName, Validators.required),
                mainPhone: this.formBuilder.control(this.customerRecordModel.mainPhone, [Validators.required, Validators.pattern(REGEXS.PHONE_FILTER)]),
                ic: this.formBuilder.control(this.customerRecordModel.ic, [Validators.required, Validators.pattern(REGEXS.IC)]),
                email: this.formBuilder.control(this.customerRecordModel.email, [Validators.required,Validators.email]),
                homePhone: this.formBuilder.control(this.customerRecordModel.homePhone,Validators.pattern(REGEXS.PHONE_FILTER)),
                officePhone: this.formBuilder.control(this.customerRecordModel.officePhone, Validators.pattern(REGEXS.PHONE_FILTER)),
                remarks: this.formBuilder.control(this.customerRecordModel.remarks, Validators.maxLength(1000)),
                vehicles: this.formBuilder.array(this.customerRecordModel.vehicles.map(vehicle => this.formBuilder.group({
                    id: vehicle.id,
                    model: vehicle.model,
                    registrationNo: vehicle.registrationNo,
                    purchaseDate: vehicle.purchaseDate,
                    lastServiceDate: vehicle.lastServiceDate,
                    institution: vehicle.institution,
                }))),
                mysteryShopperStartDate: this.formBuilder.control(this.customerRecordModel.mysteryShopperStartDate),
                mysteryShopperEndDate: this.formBuilder.control(this.customerRecordModel.mysteryShopperEndDate),
                mysteryShopperDepartmentList: this.formBuilder.control(this.customerRecordModel.mysteryShopperDepartmentList),
            })
            if (this.customerRecordModel.profilePicture) {
                this.getPictureFromProfilePictureUrl(this.customerRecordModel.profilePicture);
            }

            if(this.customerRecordModel.isMysteryShopper) {
                this.customerRecordFormGroup.controls['mysteryShopperStartDate'].disable();
                this.customerRecordFormGroup.controls['mysteryShopperEndDate'].disable();
                this.customerRecordFormGroup.controls['mysteryShopperDepartmentList'].disable();
                this.customerRecordFormGroup.controls['isMysteryShopper'].disable();
            }
        } else {
            this.dialogType = 'create';
            this.customerRecordModel = new CustomerRecordModel();

            if (this.authService.getAuthorities().some(e => e.authority.includes('CREATE_MYSTERY_SHOPPER'))) {
                this.isShowMysteryShopperCheckbox = true;
            }

            this.customerRecordFormGroup = this.formBuilder.group({
                salutation: this.formBuilder.control(null),
                isBuyer: this.formBuilder.control(true),
                isMysteryShopper: this.formBuilder.control(false),
                firstName: this.formBuilder.control(null, Validators.required),
                mainPhone: this.formBuilder.control(null, [Validators.required, Validators.pattern(REGEXS.PHONE_FILTER)]),
                ic: this.formBuilder.control(null, [Validators.required, Validators.pattern(REGEXS.IC)]),
                email: this.formBuilder.control(null,[Validators.required, Validators.email]),
                homePhone: this.formBuilder.control(null, Validators.pattern(REGEXS.PHONE_FILTER)),
                officePhone: this.formBuilder.control(null,Validators.pattern(REGEXS.PHONE_FILTER)),
                remarks: this.formBuilder.control(null),
                registeredDepartment: this.formBuilder.control(null, Validators.required),
                vehicles: this.formBuilder.array([]),
                mysteryShopperStartDate: this.formBuilder.control(null),
                mysteryShopperEndDate: this.formBuilder.control(null),
                mysteryShopperDepartmentList: this.formBuilder.control(null),
            });
            this.isShowing = true
        }
        this.sysConfigService.getSystemConfigurationValueByCode(this.satulations, this.authService.getCurInsId()).subscribe({
            next: (value) => {
                this.sysConfigSal = value.body;
                this.deactiveLoading();
            }
        });
        this.departmentService.getAllNoPagination(true,null,null).subscribe({
            next: value => {
                this.branchs = value.body;
            }
        });
        this.departmentService.getAllNoPagination(null,null,true).subscribe({
            next: value => {
                this.showrooms = value.body;
            }
        });

    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    setMysteryShopper(value){
        if(value){
            this.isMysteryShopper = true;
            this.customerRecordFormGroup.controls['mysteryShopperStartDate'].setValidators([Validators.required]);
            this.customerRecordFormGroup.controls['mysteryShopperEndDate'].setValidators([Validators.required]);
            this.customerRecordFormGroup.controls['mysteryShopperDepartmentList'].setValidators([Validators.required]);
        }
        if(!value.checked) {
            this.isMysteryShopper = false;
            this.customerRecordFormGroup.controls['mysteryShopperStartDate'].clearValidators();
            this.customerRecordFormGroup.controls['mysteryShopperEndDate'].clearValidators();
            this.customerRecordFormGroup.controls['mysteryShopperDepartmentList'].clearValidators();
            this.customerRecordFormGroup.get('mysteryShopperStartDate').updateValueAndValidity();
            this.customerRecordFormGroup.get('mysteryShopperEndDate').updateValueAndValidity();
            this.customerRecordFormGroup.get('mysteryShopperDepartmentList').updateValueAndValidity();
        }
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    getPictureFromProfilePictureUrl(profilePicture: string) {
        this.customerRecordsService.getPicture(
            this.authService.getCurInsId(),
            profilePicture
        ).subscribe({
            next: value => {
                this.createImageFromBlob(value.body);
            },
            error: error => {
                console.log(error);
            }
        })
    }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.imgUrl = reader.result;
        }, false);
        if (image) {
            reader.readAsDataURL(image);
        }
    }

    save() {
        this.isSaving = true;
        this.activeLoading();
        const data = this.customerRecordFormGroup.getRawValue();
        if(!this.isMysteryShopper) {
            data.mysteryShopperStartDate = null;
            data.mysteryShopperEndDate = null;
            data.mysteryShopperDepartmentList = null;
        } else {
            if(data.mysteryShopperStartDate > data.mysteryShopperEndDate) {
                this.snackBar.open("Mystery Shopper start date can't be greater than end date", 'Close', {
                    duration: 60000
                });
                this.isSaving = false;
                this.deactiveLoading();
                return;
            }

            data.mysteryShopperStartDate = this.datePipe.transform(
                data.mysteryShopperStartDate,
                'yyyy-MM-dd',
                '+800'
            );
            data.mysteryShopperEndDate = this.datePipe.transform(
                data.mysteryShopperEndDate,
                'yyyy-MM-dd',
                '+800'
            );
        }

        if (this.dialogType === 'create') {
            this.customerRecordsService.create(this.customerRecordFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                next: value => {
                    this.customerRecordModel = value.body;
                    this.onFileSelected();
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    if(this.compileErrorMessage(error.error)){
                        this.snackBar.open(this.compileErrorMessage(error?.error), 'Close', {
                            duration: 60000
                          });
                    }
                    else{
                        this.snackBar.open(error.error.message, 'Close', {
                            duration: 60000
                          });
                    }
                }
            })
        } else {
            this.customerRecordsService.update(this.customerRecordFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    if(this.compileErrorMessage(error.error)){
                        this.snackBar.open(this.compileErrorMessage(error?.error), 'Close', {
                            duration: 60000
                          });
                    }
                    else{
                        this.snackBar.open(error.error.message, 'Close', {
                            duration: 60000
                          });
                    }
                }
            })
        }
    }

    compileErrorMessage(error: any)  {
        let theMessage  = '';
        error.subErrors?.forEach(subError => {
            theMessage += subError.field + ': '+ subError.message + ' \n ';
        });
        return theMessage;
    }

    addOrEditCustomerVehicle(customerRecordVehiclesModel?: CustomerRecordVehiclesModel, i?: number) {
        const modalRef = this.matDialog.open(CustomerRecordsDialogNewOrEditVehiclesComponent, {
            data: customerRecordVehiclesModel,
            panelClass: 'mat-dialog-md'
        })

        modalRef.afterClosed().subscribe({
            next: (value) => {
                if (value) {
                    const vehicles = this.customerRecordFormGroup.get('vehicles') as FormArray;
                    const vehicle = this.formBuilder.group({
                        id: value.data.id,
                        model: value.data.model,
                        registrationNo: value.data.registrationNo,
                        purchaseDate: value.data.purchaseDate,
                        lastServiceDate: value.data.lastServiceDate,
                        institution: this.authService.getCurIns()
                    })

                    if (value.type === 'create') {
                        vehicles.push(vehicle);
                    } else {
                        vehicles.insert(i, vehicle);
                        vehicles.removeAt(i + 1);
                    }
                }
            }
        })
    }

    formArrayVehicles() {
        return (this.customerRecordFormGroup.get('vehicles') as FormArray).controls;
    }

    changeOwnershipCustomerVehicle(customerRecordVehiclesModel: CustomerRecordVehiclesModel, idx: number) {
        const modalRef = this.matDialog.open(CustomerRecordsDialogTransferOwnershipComponent, {
            data: customerRecordVehiclesModel,
            panelClass: 'mat-dialog-md'
        })

        modalRef.afterClosed().subscribe({
            next: value => {
                if (value === 'ok') {
                    this.formArrayVehicles().splice(idx, 1);
                }
            }
        })
    }

    onFileSelected(event?: any) {
        if (event) this.fileList = event.target.files
        if (this.fileList && this.fileList.length > 0) {
            let file: File = this.fileList[0];
            if (this.customerRecordModel?.id) {
                this.customerRecordsService.uploadPicture(
                    this.authService.getCurInsId(),
                    this.customerRecordModel.id,
                    file
                ).subscribe({
                    next: value => {
                        if (this.dialogType === 'update') this.getPictureFromProfilePictureUrl(value.profilePicture);
                    },
                    error: error => {
                        this.snackBar.open(error.error.message, 'Close', {
                            duration: 60000
                          });
                        console.log(error);
                    }
                })
            }
        }
    }
}
