import { Property, propertyNameCompare } from '../util';

export const DEFAULT_DEPARTMENT_OVERDUE_ENUM: Property[] = [
    {
        value: 'RESPONSE_OVERDUE',
        name: 'Assigned Response',
    },
    {
        value: 'RESOLUTION_OVERDUE',
        name: 'Assigned Resolution',
    },
    {
        value: 'DEFAULT_DEPARTMENT_RESPONSE_OVERDUE',
        name: 'Help-Desk Response',
    },
    {
        value: 'DEFAULT_DEPARTMENT_RESOLUTION_OVERDUE',
        name: 'Help-Desk Resolution',
    },
].sort(propertyNameCompare);
