import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { createSurveyModel } from '../model/create-survey.model';
import { UpdateSurveyModel } from '../model/update-survey.model';
import { SurveyFormModel } from '../model/survey-form.model';
import { SurveyModel } from '../model/survey.model';

@Injectable({
    providedIn: 'root'
})
export class SurveyService {

    surveyValue: SurveyModel;

    constructor(
            private httpClient: HttpClient,
            private authService: AuthService
    ) { }

    createSurvey(
        model: createSurveyModel,
        curIns: string
    ): Observable<HttpResponse<createSurveyModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<createSurveyModel>(`/web/survey`, model, {
            observe: 'response',
            params: httpParams
        });
    }

    getAllSurveyRecord(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        surveyType: string,
        isReceive: boolean,
        departmentId: number,
        salesConsultantId: number,
        customerId: number
    ):Observable<HttpResponse<SurveyModel[]>>{
        let httpParams = new HttpParams();
        if (pageNumber) httpParams = httpParams.set('page', `${pageNumber}`);
        if (pageSize) httpParams = httpParams.set('size', `${pageSize}`);
        if (sortOrder) httpParams = httpParams.set('sort', sortOrder);
        if (surveyType) httpParams = httpParams.set('surveyType', surveyType);
        if (typeof isReceive === 'boolean') httpParams = httpParams.set('isReceive', isReceive);
        if (departmentId) httpParams = httpParams.set('departmentId', departmentId);
        if (salesConsultantId) httpParams = httpParams.set('salesConsultantId', salesConsultantId);
        if (customerId) httpParams = httpParams.set('customerId', customerId);

        httpParams = httpParams.set('curIns', this.authService.getCurInsId());

        return this.httpClient.get<SurveyModel[]>(`/web/survey/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllSurveysNoPagination(surveyType: string):Observable<HttpResponse<SurveyFormModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        if(surveyType)httpParams = httpParams.set('surveyType', surveyType);
        return this.httpClient.get<SurveyFormModel[]>('/web/survey-form/all/no-pagination', {
            observe: 'response',
            params: httpParams
        });
    }

    getOneSurvey(id: string):Observable<HttpResponse<SurveyModel>>{
        let httpParams = new HttpParams();
        httpParams = httpParams.set('id', id);
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        return this.httpClient.get<SurveyModel>('/web/survey', {
            observe: 'response',
            params: httpParams
        });
    }

  updateSurvey(
    model: UpdateSurveyModel,
    curIns: string
  ): Observable<HttpResponse<UpdateSurveyModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<UpdateSurveyModel>(`/web/survey`, model, {
            observe: 'response',
            params: httpParams
      });
  }

  modificationUpdate(
    model: SurveyModel,
    curIns: string
  ): Observable<HttpResponse<SurveyModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<SurveyModel>(`/web/survey/modification-update`, model, {
            observe: 'response',
            params: httpParams
      });
  }
}
