import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { CategoryModel } from 'src/app/model/category.model';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-types-dialog-new-or-edit',
  templateUrl: './types-dialog-new-or-edit.component.html',
  styleUrls: ['./types-dialog-new-or-edit.component.scss']
})
export class TypesDialogNewOrEditComponent implements OnInit {

  dialogType: string = '';
  typeFormGroup: FormGroup;
  isSaving: boolean = false;
  parentGroup: CategoryModel[];
  loading: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public type: CategoryModel,
    public dialogRef: MatDialogRef<TypesDialogNewOrEditComponent>,
    private formBuilder : FormBuilder,
    private categoryService: CategoryService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    if(this.type?.id){
      this.dialogType = 'update';
      this.typeFormGroup = this.formBuilder.group({
        id: this.formBuilder.control(this.type.id),
        name: this.formBuilder.control(this.type.name,Validators.required),
        description: this.formBuilder.control(this.type.description),
        active: this.formBuilder.control(this.type.active),
        parentCategory: this.formBuilder.control(this.type.parentCategory),
        path: this.formBuilder.control(this.type.path),
      })

    }else{
      this.dialogType = 'create';
      this.typeFormGroup = this.formBuilder.group({
        name: this.formBuilder.control(null,Validators.required),
        description: this.formBuilder.control(null),
        active: this.formBuilder.control(true),
        parentCategory: this.formBuilder.control(this.type?.parentCategory),
      })
    }
    this.categoryService.getAllCategories(null, null,null,true).subscribe({
      next: response =>{
        this.parentGroup = response.body.filter( cat => cat.path.split("|").length === 2 );
        this.deactiveLoading();
      }
    });
  }

  activeLoading(){
    this.loading = true;
  }

  deactiveLoading(){
      this.loading = false;
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id === object2.id;
  }

  save(): void {
    this.isSaving = true;
    this.activeLoading();
    if (this.dialogType === 'create') {
        this.categoryService.save(this.typeFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
            next: () => {
                this.isSaving = false;
                this.deactiveLoading();
                this.dialogRef.close('ok');
            },
            error: error => {
                this.isSaving = false;
                this.deactiveLoading();
                this.snackBar.open(error.error.message, 'Close', {
                  duration: 60000
                });
            }
        });
    } else {
        this.categoryService.update(this.typeFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
            next: () => {
                this.isSaving = false;
                this.deactiveLoading();
                this.dialogRef.close('ok');
            },
            error: error => {
                this.isSaving = false;
                this.deactiveLoading();
                this.snackBar.open(error.error.message, 'Close', {
                  duration: 60000
                });
            }
        });
    }
  }

  checkParent() {
    if (this.typeFormGroup.get('id') && this.typeFormGroup.get('path')) {
          return true;
      } else {
          return false;
      }
    }
}
