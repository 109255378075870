import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';

@Component({
    selector: 'app-system-config-dialog-detail',
    templateUrl: './system-config-dialog-detail.component.html',
    styleUrls: ['./system-config-dialog-detail.component.scss']
})
export class SystemConfigDialogDetailComponent implements OnInit {

    readonly UPDATE_SYS_CONFIG = ApplicationPermissionsService.UPDATE_SYS_CONFIG;

    constructor(
        @Inject(MAT_DIALOG_DATA) public systemConfigurationModel: any,
        public dialogRef: MatDialogRef<SystemConfigDialogDetailComponent>,
        public authService: AuthService,
    ) { }

    ngOnInit(): void {
    }
}
