import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import {
    CustomerRecordActivityModel,
    CustomerRecordModel,
    CustomerRecordVehiclesModel,
} from 'src/app/model/customer-records.model';
import { MysteryShopperModel } from 'src/app/model/mystery-shopper.model';
import { SurveyModel } from 'src/app/model/survey.model';
import { TicketModel } from 'src/app/model/ticket.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { CustomerRecordsService } from 'src/app/services/customer-records.service';
import { SurveyService } from 'src/app/services/survey.service';
import { TicketService } from 'src/app/services/ticket.service';
import { MysteryShopperService } from 'src/app/services/mystery-shopper.service';
import { SurveyType } from 'src/app/shared/constant';
import { CustomerRecordsDetailActivityComponent } from '../customer-records-detail-activity/customer-records-detail-activity.component';
import { CustomerRecordsDialogAddActivityComponent } from '../customer-records-dialog-add-activity/customer-records-dialog-add-activity.component';
import { CustomerRecordsDialogNewOrEditVehiclesComponent } from '../customer-records-dialog-new-or-edit-vehicles/customer-records-dialog-new-or-edit-vehicles.component';
import { CustomerRecordsDialogNewOrEditComponent } from '../customer-records-dialog-new-or-edit/customer-records-dialog-new-or-edit.component';
import { CustomerRecordsDialogTransferOwnershipComponent } from '../customer-records-dialog-transfer-ownership/customer-records-dialog-transfer-ownership.component';

@Component({
    selector: 'app-customer-records-detail',
    templateUrl: './customer-records-detail.component.html',
    styleUrls: ['./customer-records-detail.component.scss'],
})
export class CustomerRecordsDetailComponent implements OnInit {
    @ViewChild('paginatorActivity') paginator: MatPaginator;
    @ViewChild('paginatorTicket') paginatorTicket: MatPaginator;
    @ViewChild('paginatorSSI') paginatorSSI: MatPaginator;
    @ViewChild('paginatorCSI') paginatorCSI: MatPaginator;
    @ViewChild('paginatorMS') paginatorMS: MatPaginator;

    readonly CREATE_CUSTOMER = ApplicationPermissionsService.CREATE_CUSTOMER;
    readonly UPDATE_CUSTOMER = ApplicationPermissionsService.UPDATE_CUSTOMER;
    readonly UPDATE_TICKET = ApplicationPermissionsService.UPDATE_TICKET;
    readonly READ_TICKET = ApplicationPermissionsService.READ_TICKET;
    readonly READ_INDIVIDUAL_CSI = ApplicationPermissionsService.READ_INDIVIDUAL_CSI;
    readonly READ_FULL_CSI = ApplicationPermissionsService.READ_FULL_CSI;
    readonly CREATE_CSI = ApplicationPermissionsService.CREATE_CSI;
    readonly READ_INDIVIDUAL_SSI = ApplicationPermissionsService.READ_INDIVIDUAL_SSI;
    readonly READ_FULL_SSI = ApplicationPermissionsService.READ_FULL_SSI;
    readonly CREATE_SSI = ApplicationPermissionsService.CREATE_SSI;
    readonly READ_MYSTERY_SHOPPER = ApplicationPermissionsService.READ_MYSTERY_SHOPPER;
    readonly snackBarConfig: MatSnackBarConfig = { duration: 60000 };

    customerDetailId: any;
    customerRecordModel: CustomerRecordModel;
    dataSourceActivities: MatTableDataSource<CustomerRecordActivityModel> =
        new MatTableDataSource<CustomerRecordActivityModel>();
    dataSourceTicket: MatTableDataSource<TicketModel> =
        new MatTableDataSource<TicketModel>();
    dataSourceSsi: MatTableDataSource<SurveyModel> =
        new MatTableDataSource<SurveyModel>();
    dataSourceCsi: MatTableDataSource<SurveyModel> =
        new MatTableDataSource<SurveyModel>();
    dataSourceMs: MatTableDataSource<MysteryShopperModel> =
        new MatTableDataSource<MysteryShopperModel>();

    displayColumnsActivities: string[] = [
        'department',
        'type',
        'actualDate',
        'actions',
    ];
    displayColumnsTicket: string[] = [
        'ticketNumber',
        'title',
        'type',
        'createdDate',
        'actions',
    ];
    displayColumnsSatisfactoryIndex = [
        'ssiNo',
        'regNo',
        'issuedBy',
        'issuedDate',
        'isReceived',
        'isActive',
        'actions',
    ];
    displayColumnMysteryShopper = [
        'mysteryShopperNumber',
        'department',
        'issuedDate',
        'isReceived',
        'submitDatetime',
        'actions',
    ];

    currentPage = 0;
    pageSize = 20;
    sortOrder = 'id,desc';
    numberOfRecords: number;

    ticketPageSize = 20;
    ticketSortOrder = 'id,desc';
    ticketCurrentPage = 0;
    ticketNumberOfRecords: number;

    csiPageSize = 20;
    csiSortOrder = 'id,desc';
    csiCurrentPage = 0;
    csiNumberOfRecords: number;

    ssiPageSize = 20;
    ssiSortOrder = 'id,desc';
    ssiCurrentPage = 0;
    ssiNumberOfRecords: number;

    msPageSize = 20;
    msSortOrder = 'id,desc';
    msCurrentPage = 0;
    msNumberOfRecords: number;

    loading = false;
    loadingSsi = false;
    loadingCsi = false;
    loadingMs = false;
    imgUrl: any;

    isShowMysteryShopper: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private customerRecordsService: CustomerRecordsService,
        private ticketService: TicketService,
        private surveyService: SurveyService,
        private mysteryShopperService: MysteryShopperService,
        public authService: AuthService,
        private matDialog: MatDialog,
        private router: Router,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        if (this.authService.getAuthorities().some(e => e.authority.includes('READ_MYSTERY_SHOPPER') || e.authority.includes('CREATE_MYSTERY_SHOPPER'))) {
            this.isShowMysteryShopper = true;
        }

        this.getCustomerRecordDetail();
    }

    getCustomerRecordDetail() {
        this.route.queryParams.subscribe({
            next: (param) => {
                if (param.id) {
                    this.customerDetailId = param.id;
                    this.customerRecordsService
                        .getOne(
                            this.customerDetailId,
                            this.authService.getCurInsId()
                        )
                        .subscribe({
                            next: (value) => {
                                this.customerRecordModel = value.body;
                                this.getPictureFromProfilePictureUrl(
                                    this.customerRecordModel.profilePicture
                                );
                            },
                        });
                    this.loading = true;
                    this.searchActivities(0);
                    this.searchTicket();
                    this.loadingCsi = true;
                    this.searchSurveyCsi();
                    this.loadingSsi = true;
                    this.searchSurveySsi();
                    this.loadingMs = true;
                    if(this.isShowMysteryShopper) {
                        this.searchMysteryShopper();
                    }
                }
            },
        });
    }

    getPictureFromProfilePictureUrl(profilePicture: string) {
        this.customerRecordsService
            .getPicture(this.authService.getCurInsId(), profilePicture)
            .subscribe({
                next: (value) => {
                    this.createImageFromBlob(value.body);
                },
                error: (error) => {
                    console.log(error);
                },
            });
    }

    createImageFromBlob(image: Blob) {
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                this.imgUrl = reader.result;
            },
            false
        );
        if (image) {
            reader.readAsDataURL(image);
        }
    }

    searchActivities(currentPage: number) {
        const requestGet = {
            page: currentPage,
            size: this.pageSize,
            sort: this.sortOrder,
            curIns: this.authService.getCurInsId(),
            customerId: this.customerDetailId,
        };
        this.customerRecordsService.getAllActivities(requestGet).subscribe({
            next: (value) => {
                this.dataSourceActivities.data = value.body;
                this.numberOfRecords = Number(
                    value.headers.get('X-Total-Count')
                );
            },
        });
    }

    searchTicket() {
        if (!this.authService.hasAuthorityMenu(this.READ_TICKET)) {
            return;
        }
        const requestGet = {
            page: this.ticketCurrentPage,
            size: this.ticketPageSize,
            sort: this.ticketSortOrder,
            curIns: this.authService.getCurInsId(),
            customerId: this.customerDetailId,
        };
        this.customerRecordsService.getAllTickets(requestGet).subscribe({
            next: (value) => {
                this.dataSourceTicket.data = value.body;
                this.ticketNumberOfRecords = Number(
                    value.headers.get('X-Total-Count')
                );
                this.loading = false;
            },
            error: (value) => {
                this.snackBar.open(
                    value.error.message,
                    'Close',
                    this.snackBarConfig
                );
                this.loading = false;
            },
        });
    }

    searchSurveyCsi() {
        if (!this.authService.hasAuthorityMenu(this.READ_INDIVIDUAL_CSI) && !this.authService.hasAuthorityMenu(this.READ_FULL_CSI)) {
            return;
        }
        this.surveyService.getAllSurveyRecord(this.csiCurrentPage,this.csiPageSize,this.csiSortOrder,SurveyType.CSI,null,null,null,this.customerDetailId)
            .subscribe({
                next: (value) => {
                    this.dataSourceCsi.data = value.body;
                    this.csiNumberOfRecords = Number(
                        value.headers.get('X-Total-Count')
                    );
                    this.loadingCsi = false;
                },
                error: (value) => {
                    this.snackBar.open(
                        value.error.message,
                        'Close',
                        this.snackBarConfig
                    );
                    this.loadingCsi = false;
                },
            });
    }

    searchSurveySsi() {
        if (!this.authService.hasAuthorityMenu(this.READ_INDIVIDUAL_SSI) && !this.authService.hasAuthorityMenu(this.READ_FULL_SSI)) {
            return;
        }
        this.surveyService.getAllSurveyRecord(this.ssiCurrentPage,this.ssiPageSize,this.ssiSortOrder,SurveyType.SSI,null,null,null,this.customerDetailId)
            .subscribe({
                next: (value) => {
                    this.dataSourceSsi.data = value.body;
                    this.ssiNumberOfRecords = Number(
                        value.headers.get('X-Total-Count')
                    );
                    this.loadingSsi = false;
                },
                error: (value) => {
                    this.snackBar.open(
                        value.error.message,
                        'Close',
                        this.snackBarConfig
                    );
                    this.loadingSsi = false;
                },
            });
    }

    addActivity() {
        const modalRef = this.matDialog.open(
            CustomerRecordsDialogAddActivityComponent,
            {
                data: this.customerRecordModel,
                panelClass: 'mat-dialog-md',
            }
        );

        modalRef.afterClosed().subscribe({
            next: (value) => {
                this.dataSourceActivities.data.unshift({
                    department: value.department,
                    type: value.type,
                    actualDate: value.actualDate,
                    details: value.details,
                    model: value.model,
                    applicationUser: value.applicationUser,
                    vehicle: value.vehicle,
                });
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                }, 1);
            },
        });
    }

    addOrEditCustomerVehicle(
        customerRecordVehiclesModel?: CustomerRecordVehiclesModel,
        i?: number
    ) {
        const modalRef = this.matDialog.open(
            CustomerRecordsDialogNewOrEditVehiclesComponent,
            {
                data: customerRecordVehiclesModel,
                panelClass: 'mat-dialog-md',
            }
        );

        modalRef.afterClosed().subscribe({
            next: (value) => {
                if (value) {
                    const vehicles = this.customerRecordModel.vehicles;
                    const vehicle = {
                        id: value.data.id,
                        model: value.data.model,
                        registrationNo: value.data.registrationNo,
                        purchaseDate: value.data.purchaseDate,
                        lastServiceDate: value.data.lastServiceDate,
                        institution: this.authService.getCurIns(),
                    };

                    if (value.type === 'create') {
                        vehicles.push(vehicle);
                    } else {
                        vehicles.splice(i, 1, vehicle);
                    }

                    this.customerRecordsService
                        .update(
                            this.customerRecordModel,
                            this.authService.getCurInsId()
                        )
                        .subscribe({
                            next: () => {
                                console.log('ok');
                            },
                            error: (error) => {
                                this.snackBar.open(
                                    error.error.message,
                                    'Close',
                                    {
                                        duration: 60000,
                                    }
                                );
                            },
                        });
                }
            },
        });
    }

    openCustomerDialogCreateOrEdit() {
        const modalRef = this.matDialog.open(
            CustomerRecordsDialogNewOrEditComponent,
            {
                data: this.customerRecordModel,
                panelClass: 'mat-dialog-lg',
            }
        );

        modalRef.afterClosed().subscribe({
            next: () => {
                this.getCustomerRecordDetail();
            },
        });
    }

    changeOwnershipCustomerVehicle(
        customerRecordVehiclesModel: CustomerRecordVehiclesModel,
        idx: number
    ) {
        const modalRef = this.matDialog.open(
            CustomerRecordsDialogTransferOwnershipComponent,
            {
                data: customerRecordVehiclesModel,
                panelClass: 'mat-dialog-md',
            }
        );

        modalRef.afterClosed().subscribe({
            next: (value) => {
                if (value === 'ok') {
                    this.customerRecordModel.vehicles.splice(idx, 1);
                }
            },
        });
    }

    openActivityDialogDetail(activity: CustomerRecordActivityModel) {
        this.matDialog.open(CustomerRecordsDetailActivityComponent, {
            data: activity,
            panelClass: 'mat-dialog-lg',
        });
    }

    openVOCDetail(ticketModel?: TicketModel) {
        this.ticketService.ticketValue = ticketModel;
        this.router.navigate(['/operations/voice-of-customer-edit'], {
            queryParams: {
                id: ticketModel?.id,
            },
        });
    }

    sortPage(event): void {
        this.sortOrder = `${event.active},${event.direction}`;
        this.searchActivities(0);
    }

    navigateToPage(event): void {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        this.searchActivities(event.pageIndex);
    }

    sortPageTicket(event): void {
        this.ticketSortOrder = `${event.active},${event.direction}`;
        this.searchTicket();
    }

    navigateToPageTicket(event) {
        this.ticketCurrentPage = event.pageIndex;
        this.ticketPageSize = event.pageSize;
        this.searchTicket();
    }

    sortPageCsi(event): void {
        this.csiSortOrder = `${event.active},${event.direction}`;
        this.searchSurveyCsi();
    }

    navigateToPageCsi(event) {
        this.csiCurrentPage = event.pageIndex;
        this.csiPageSize = event.pageSize;
        this.searchSurveyCsi();
    }

    sortPageSsi(event): void {
        this.ssiSortOrder = `${event.active},${event.direction}`;
        this.searchSurveySsi();
    }

    navigateToPageSsi(event) {
        this.ssiCurrentPage = event.pageIndex;
        this.ssiPageSize = event.pageSize;
        this.searchSurveySsi();
    }

    sortPageMs(event): void {
        this.msSortOrder = `${event.active},${event.direction}`;
        this.searchMysteryShopper();
    }

    navigateToPageMs(event) {
        this.msCurrentPage = event.pageIndex;
        this.msPageSize = event.pageSize;
        this.searchMysteryShopper();
    }

    openCsiSurveyForm(surveyModel?: SurveyModel) {
        this.router.navigate(['/operations/cust-satisfactory-form'], {
            queryParams: { id: surveyModel.id },
        });
    }

    openSsiSurveyForm(surveyModel?: SurveyModel) {
        this.router.navigate(['/operations/sales-satisfactory-form'], {
            queryParams: { id: surveyModel.id },
        });
    }

    openMsDetailForm(MysteryShopperModel?: MysteryShopperModel) {
        this.router.navigate(['/customer-record/mystery-shopper-detail'], {
            queryParams: { token: MysteryShopperModel.token },
        });
    }

    searchMysteryShopper() {
        if (!this.authService.hasAuthorityMenu(this.READ_MYSTERY_SHOPPER)) {
            return;
        }
        this.mysteryShopperService.getAllMysteryShopperRecord(
                this.msCurrentPage,
                this.msPageSize,
                this.msSortOrder,
                this.customerDetailId
            ).subscribe({
                next: (value) => {
                    this.dataSourceMs.data = value.body;
                    this.msNumberOfRecords = Number(
                        value.headers.get('X-Total-Count')
                    );
                    this.loadingMs = false;
                },
                error: (value) => {
                    this.snackBar.open(
                        value.error.message,
                        'Close',
                        this.snackBarConfig
                    );
                    this.loadingMs = false;
                },
            });
    }
}
