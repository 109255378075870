<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        View User '{{applicationUserRoleModel.name}}'
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <div class="nbt-details row">
        <div class="col-md-6">
            <label>Name</label>
            <div class="value" maxlength="100">{{applicationUserRoleModel.name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Institution</label>
            <div class="value">{{applicationUserRoleModel.institution?.name || '-'}}</div>
        </div>
        <div class="col-md-12">
            <label>Permissions</label>
            <ul class="value" *ngIf="applicationUserRoleModel.applicationPermissions?.length > 0">
                <li *ngFor="let permission of applicationUserRoleModel.applicationPermissions">
                    {{permission.name}}
                </li>
            </ul>
            <div class="value" *ngIf="applicationUserRoleModel.applicationPermissions?.length === 0">-</div>
        </div>
    </div>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Close
    </button>
    <button type="button" *ngIf="authService.hasAuthorityMenu(UPDATE_APPLICATION_ROLE)" class="nbt-btn-primary ml8" (click)="dialogRef.close('edit')">
        <mat-icon class="mr8">edit</mat-icon>
        Edit
    </button>
</div>