<div *ngIf="!loading">
    <div class="nbt-dialog-header">
        <div class="d-flex">
            <div class="nbt-status" [ngClass]="getStatusColor(npsModel.status)" style="margin-right: 15px;">
                {{ statusValue(npsModel.status) }}
            </div>
            <div class="nbt-dialog-title">
                Calling - {{customerRecordActivityModel.customer.firstName}} {{customerRecordActivityModel.customer.lastName}}
            </div>
        </div>
        <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
    </div>
    <div class="nbt-dialog-body">
        <form [formGroup]="npsCallInFormGroup">
            <div class="row">
                <div class="col-md-4 nbt-form">
                    <label>Date/Time of Callings <span class="required">*</span></label>
                    <input class="date-picker" formControlName="callDatetimeString" type="datetime-local" required
                           placeholder="Select Date, Time"/>
                </div>
                <br>
                <div class="col-md-12 nbt-form-textarea">
                    <label>Call Details <span class="required">*</span></label>
                    <mat-form-field appearance="outline">
                    <textarea matInput appAutofocus type="text" formControlName="callDetail" required
                              placeholder="Enter description" rows="5" maxlength="2000"
                              #myInput>
                    </textarea>
                        <span>{{myInput.value.length}} / 2000</span>
                    </mat-form-field>
                </div>
            </div>
            <!-- INFORMATION -->
            <div class="information">
                <label><b>Information</b></label>
                <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px; display:flex;">
                    <div class="row left-list-detail">
                        <div class="col-xl-12 nbt-details row">
                            <div class="col-xl-7">
                                <div class="nbt-details row">
                                    <div class="col-md-4">
                                        <label>Phone Number(Main)</label>
                                        <div>{{customerRecordActivityModel.customer?.mainPhone || '-'}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Phone Number(Home)</label>
                                        <div>{{customerRecordActivityModel.customer?.homePhone || '-'}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Phone Number(Office)</label>
                                        <div>{{customerRecordActivityModel.customer?.officePhone || '-'}}</div>
                                    </div>
                                </div>
                                <div class="nbt-details row">
                                    <label>Remarks</label>
                                    <div>{{customerRecordActivityModel.customer?.remarks || '-'}}</div>
                                </div>
                                <br>
                                <div class="nbt-details row">
                                    <div class="col-md-4">
                                        <label>Vehicle Model</label>
                                        <div>{{customerRecordActivityModel.model?.name || '-'}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Vehicle Number</label>
                                        <div>{{customerRecordActivityModel.vehicle?.registrationNo || '-'}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-checkbox disabled [checked]="customerRecordActivityModel.customer.isBuyer">Buyer</mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="windowWidth >= 1200"  class="col-xl-1" style="text-align: -webkit-center;">
                                <hr width="3" size="40" style="margin-top: -5px; height: 100%;">
                            </div>
                            <div class="col-xl-4" style="align-self: center;">
                                <div class="nbt-details row">
                                    <label>(SSI/CSI/Activity) Date</label>
                                    <div>{{customerRecordActivityModel.actualDate || '-'}}</div>
                                </div>
                                <br>
                                <div class="nbt-details row">
                                    <label>Branch</label>
                                    <div>{{customerRecordActivityModel.department?.name || '-'}}</div>
                                </div>
                                <br>
                                <div class="nbt-details row">
                                    <label>SC/SA/Staff Name</label>
                                    <div>{{customerRecordActivityModel.applicationUser?.username || '-'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </div>
        </form>
        <span class="required">* Required Field For Submission</span>
    </div>
    <div class="nbt-dialog-footer" *ngIf="!loading">
        <button type="button" class="nbt-btn-fifth ml8" (click)="save('DECLINED');" [disabled]="isSaving || npsCallInFormGroup.invalid">
            Declined
        </button>
        <button type="button" *ngIf="npsModel.status ==='OPEN'" class="nbt-btn-sixth ml8" (click)="save('RESCHEDULED');" [disabled]="isSaving || npsCallInFormGroup.invalid ">
            Rescheduled
        </button>
        <button type="button" class="nbt-btn-seventh ml8" (click)="save('COMPLETED');" [disabled]="isSaving || npsCallInFormGroup.invalid ">
            Completed
        </button>
    </div>
</div>
