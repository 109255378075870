import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersModule } from './users/users.module';
import { UserRolesModule } from './user-roles/user-roles.module';
import { DepartmentsModule } from './departments/departments.module';
import { SystemConfigModule } from './system-config/system-config.module';
import { HolidayModule } from './holiday/holiday.module';
import { InstitutionsModule } from './institutions/institutions.module';
import { BusinessHoursModule } from './business-hours/business-hours.module';
import { SlaModule } from './sla/sla.module';
import { VehicleModelModule } from './vehicle-model/vehicle-model.module';
import { CategoriesModule } from './categories/categories.module';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        UsersModule,
        UserRolesModule,
        DepartmentsModule,
        SystemConfigModule,
        HolidayModule,
        InstitutionsModule,
        BusinessHoursModule,
        SlaModule,
        VehicleModelModule,
        CategoriesModule
    ]
})
export class ConfigurationModule { }
