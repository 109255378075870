<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        View SLA '{{slaModel.name}}'
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <div class="nbt-details row">
        <div class="col-md-6">
            <label>Name</label>
            <div class="value" maxlength="100">{{slaModel.name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Institution</label>
            <div class="value">{{this.authService.getCurIns().name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Description</label>
            <div class="value" maxlength="255">{{slaModel.description || '-'}}</div>
        </div>
        <div class="col-md-12 nbt-form">
            <label>SLA details</label>
            <br>
            <div style="overflow-x: auto;">
                <table class="sla-table">
                    <thead>
                        <tr>
                            <th><div style="padding-left: 16px;">Priority</div></th>
                            <th><div>Response Time</div></th>
                            <th><div>Resolution Time</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let sla of slaModel.slaDetails; let i = index">
                            <td>
                                <div class="div-head">
                                    <div class="priority-dot" [ngStyle]="{'background-color': bgColorPriorityDot(i)}"></div>
                                    {{sla.priority}}
                                </div>
                            </td>
                            <td>
                                <div class="input-time-sla">
                                    <div>
                                        {{sla.responseTime?.split('|')[0]}} day&nbsp;
                                    </div>
                                    <div>
                                        {{sla.responseTime?.split('|')[1]}} hrs&nbsp;
                                    </div>
                                    <div>
                                        {{sla.responseTime?.split('|')[2]}} mins
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="input-time-sla">
                                    <div>
                                        {{sla.resolutionTime?.split('|')[0]}} day&nbsp;
                                    </div>
                                    <div>
                                        {{sla.resolutionTime?.split('|')[1]}} hrs&nbsp;
                                    </div>
                                    <div>
                                        {{sla.resolutionTime?.split('|')[2]}} mins
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Close
    </button>
    <button type="button" *ngIf="authService.hasAuthorityMenu(UPDATE_SLA)" class="nbt-btn-primary ml8" (click)="dialogRef.close('edit')">
        <mat-icon class="mr8">edit</mat-icon>
        Edit
    </button>
</div>