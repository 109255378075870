<div class="container-fluid nbt-container">
    <div class="nbt-header">
        <div class="nbt-header-title">Customer Detail</div>
        <div class="nbt-btn-right">
            <button class="nbt-btn-primary" appScreenPermission [permissionsInput]="[UPDATE_CUSTOMER]"
                (click)="openCustomerDialogCreateOrEdit()">
                <mat-icon class="mr8">edit</mat-icon>
                Edit Customer Record
            </button>
        </div>
    </div>
    <br>
    <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px;">
        <div class="row customer-detail">
            <div class="col-xl-4 profile-section">
                <div class="left-profile-customer-mobile">
                    <div class="placeholder-img">
                        <mat-icon *ngIf="!imgUrl">person_outline</mat-icon>
                        <img *ngIf="imgUrl" style="width: 100%; height: 100%; object-fit: contain;" [src]="imgUrl" alt="profile-img"/>
                    </div>
                    <button class="nbt-btn-secondary mt8 mb8 w-100" (click)="addActivity()" *ngIf="authService.hasAuthorityMenu(CREATE_CUSTOMER)">
                        <mat-icon class="mr8">add</mat-icon>
                        Add New Activity
                    </button>
                    <button class="nbt-btn-primary w-100 mb8" (click)="addOrEditCustomerVehicle()" *ngIf="authService.hasAuthorityMenu(UPDATE_CUSTOMER)">
                        <mat-icon class="mr8">add</mat-icon>
                        Add Customer Vehicle
                    </button>
                    <div class="nbt-details name-mobile">
                        <div class="value name">{{customerRecordModel?.salutation ? customerRecordModel?.salutation + ' ' : ''}}{{customerRecordModel?.firstName | titlecase}} {{customerRecordModel?.lastName | titlecase}}</div>
                        <label>IC Number</label>
                        <div>{{customerRecordModel?.ic || '-'}}</div>
                    </div>
                </div>
                <div class="nbt-details">
                    <div class="detail-mobile">
                        <div class="value name">{{customerRecordModel?.salutation ? customerRecordModel?.salutation + ' ' : ''}}{{customerRecordModel?.firstName | titlecase}} {{customerRecordModel?.lastName | titlecase}}</div>
                        <label>IC Number</label>
                        <div class="value">{{customerRecordModel?.ic || '-'}}</div>
                    </div>
                    <label>Phone Number (Main)</label>
                    <div class="value">{{customerRecordModel?.mainPhone || '-'}}</div>
                    <label>Buyer</label>
                    <div class="value">{{customerRecordModel?.isBuyer ? 'Yes' : 'No'}}</div>
                    <label>Email</label>
                    <div class="value">{{customerRecordModel?.email || '-'}}</div>
                    <label>Phone Number (Home)</label>
                    <div class="value">{{customerRecordModel?.homePhone || '-'}}</div>
                    <label>Phone Number (Office)</label>
                    <div class="value">{{customerRecordModel?.officePhone || '-'}}</div>
                    <label>Remarks</label>
                    <div class="value">{{customerRecordModel?.remarks || '-'}}</div>
                </div>
            </div>
            <div class="col-xl detail-section">
                <mat-tab-group>
                    <mat-tab label="VOC" *ngIf="authService.hasAuthorityMenu(READ_TICKET)">
                        <br>
                        <br>
                        <div *ngIf="loading" class="nbt-empty-result">
                            <div class="nbt-data-loading"></div>
                        </div>
                        <div *ngIf="!loading">
                            <ng-container *ngIf="dataSourceTicket.data.length > 0">
                                <mat-card-content class="mat-card-content-table">
                                    <table mat-table matSort (matSortChange)="sortPageTicket($event)" [dataSource]="dataSourceTicket" aria-label="table">
                                        <ng-container matColumnDef="ticketNumber">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticket Number</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element?.ticketNumber || '-'}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="title">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>VOC Title</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element?.title || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="type">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticket Type</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.type?.name || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="createdDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ (element?.createdDate | date: 'dd/MM/yyyy hh:mm a') || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_TICKET)" (click)="openVOCDetail(element)" matTooltip="View">remove_red_eye</mat-icon>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayColumnsTicket"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayColumnsTicket"></tr>
                                    </table>
                                </mat-card-content>
                                <mat-paginator #paginatorTicket="matPaginator" (page)="navigateToPageTicket($event)" [length]="ticketNumberOfRecords" [pageSize]="ticketPageSize"
                                    [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                </mat-paginator>
                            </ng-container>
                        </div>
                    </mat-tab>
                    <mat-tab label="Vehicles">
                        <br>
                        <br>
                        <div class="overflow-scroll" *ngIf="customerRecordModel?.vehicles?.length > 0">
                            <table class="customer-record-table" aria-label="table-customer-records">
                                <thead>
                                    <tr>
                                        <th><div style="padding-left: 16px;">Plate Number</div></th>
                                        <th><div style="padding-left: 16px;">Model</div></th>
                                        <th><div style="padding-left: 16px;">Purchase Date</div></th>
                                        <th><div style="padding-left: 16px;">Last Service Date</div></th>
                                        <th><div style="padding-left: 16px;">Actions</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let vehicle of customerRecordModel?.vehicles; let i = index">
                                        <td><div style="padding-left: 16px;">{{vehicle.registrationNo}}</div></td>
                                        <td><div style="padding-left: 16px;">{{vehicle.model?.brand}} {{vehicle.model?.name}}</div></td>
                                        <td><div style="padding-left: 16px;">{{(vehicle.purchaseDate | date: 'd MMMM yyyy') || '-'}}</div></td>
                                        <td><div style="padding-left: 16px;">{{(vehicle.lastServiceDate | date: 'd MMMM yyyy') || '-'}}</div></td>
                                        <td>
                                            <div style="padding-left: 16px;">
                                                <mat-icon (click)="addOrEditCustomerVehicle(vehicle, i)" matTooltip="Edit">edit</mat-icon>
                                                <mat-icon (click)="changeOwnershipCustomerVehicle(vehicle, i)" matTooltip="Transfer Ownership">arrow_circle_right</mat-icon>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-tab>
                    <mat-tab label="Activities" style="overflow: unset;">
                        <br>
                        <br>
                        <div *ngIf="loading" class="nbt-empty-result">
                            <div class="nbt-data-loading"></div>
                        </div>
                        <div *ngIf="!loading">
                            <ng-container *ngIf="dataSourceActivities.data.length > 0">
                                <mat-card-content class="mat-card-content-table">
                                    <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="dataSourceActivities" aria-label="table">
                                        <ng-container matColumnDef="department">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.department?.name || '-'}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="type">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Type</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.type || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actualDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Date</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ (element.actualDate | date: 'd MMMM yyyy') || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-icon (click)="openActivityDialogDetail(element)" matTooltip="View">remove_red_eye</mat-icon>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayColumnsActivities"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayColumnsActivities"></tr>
                                    </table>
                                </mat-card-content>
                                <mat-paginator #paginatorActivity="matPaginator" (page)="navigateToPage($event)" [length]="numberOfRecords" [pageSize]="pageSize"
                                    [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                </mat-paginator>
                            </ng-container>
                        </div>
                    </mat-tab>
                    <mat-tab label="SSI" *ngIf="authService.hasAuthorityMenu(READ_INDIVIDUAL_SSI) || authService.hasAuthorityMenu(READ_FULL_SSI)">
                        <br>
                        <br>
                        <div *ngIf="loadingSsi" class="nbt-empty-result">
                            <div class="nbt-data-loading"></div>
                        </div>
                        <div *ngIf="!loadingSsi">
                            <ng-container *ngIf="dataSourceSsi.data.length > 0">
                                <mat-card-content class="mat-card-content-table">
                                    <table mat-table matSort (matSortChange)="sortPageSsi($event)" [dataSource]="dataSourceSsi" aria-label="table">
                                        <ng-container matColumnDef="ssiNo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>SSI Number</th>
                                            <td mat-cell *matCellDef="let element">
                                                <p>{{ element.surveyNumber || '-'}}</p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="regNo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Number</th>
                                            <td mat-cell *matCellDef="let element">
                                                <p>{{ element.vehicle.registrationNo || '-' }}</p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="issuedBy">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Issued By</th>
                                            <td mat-cell *matCellDef="let element">
                                                <ng-container *ngIf="element.salesConsultant.firstName !== null && element.salesConsultant.firstName !== undefined; else elseData">
                                                    {{ element.salesConsultant.firstName + ' ' + (element.salesConsultant.lastName || '') }}
                                                </ng-container>
                                                <ng-template #elseData>
                                                    {{ element.salesConsultant.username }}
                                                </ng-template>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="issuedDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Issued Date</th>
                                            <td mat-cell *matCellDef="let element">
                                                <p>{{ (element.createdDate| date: 'd MMMM yyyy')  || '-' }}</p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="isReceived">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header matColumnDef="surveyHeader">Responded</th>
                                            <td mat-cell *matCellDef="let element" class="text-center">
                                                <mat-icon *ngIf="element.isReceive;then checkCircle else checkCircleOutline"></mat-icon>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="isActive">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header matColumnDef="isActiveHeader">Active</th>
                                            <td mat-cell *matCellDef="let element" class="text-center">
                                                <mat-icon *ngIf="element.isActive;then checkCircle else checkCircleOutline"></mat-icon>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-icon appScreenPermission [permissionsInput]="[CREATE_SSI]" (click)="openSsiSurveyForm(element)" matTooltip="View">remove_red_eye</mat-icon>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayColumnsSatisfactoryIndex"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayColumnsSatisfactoryIndex"></tr>
                                    </table>
                                </mat-card-content>
                                <mat-paginator #paginatorSSI="matPaginator" (page)="navigateToPageSsi($event)" [length]="ssiNumberOfRecords" [pageSize]="ssiPageSize"
                                               [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                </mat-paginator>
                            </ng-container>
                        </div>
                    </mat-tab>
                    <mat-tab label="CSI" *ngIf="authService.hasAuthorityMenu(READ_INDIVIDUAL_CSI) || authService.hasAuthorityMenu(READ_FULL_CSI)">
                        <br>
                        <br>
                        <div *ngIf="loadingCsi" class="nbt-empty-result">
                            <div class="nbt-data-loading"></div>
                        </div>
                        <div *ngIf="!loadingCsi">
                            <ng-container *ngIf="dataSourceCsi.data.length > 0">
                                <mat-card-content class="mat-card-content-table">
                                    <table mat-table matSort (matSortChange)="sortPageCsi($event)" [dataSource]="dataSourceCsi" aria-label="table">
                                        <ng-container matColumnDef="ssiNo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>SSI Number</th>
                                            <td mat-cell *matCellDef="let element">
                                                <p>{{ element.surveyNumber || '-'}}</p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="regNo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Number</th>
                                            <td mat-cell *matCellDef="let element">
                                                <p>{{ element.vehicle.registrationNo || '-' }}</p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="issuedBy">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Issued By</th>
                                            <td mat-cell *matCellDef="let element">
                                                <ng-container *ngIf="element.salesConsultant.firstName !== null && element.salesConsultant.firstName !== undefined; else elseData">
                                                    {{ element.salesConsultant.firstName + ' ' + (element.salesConsultant.lastName || '') }}
                                                </ng-container>
                                                <ng-template #elseData>
                                                    {{ element.salesConsultant.username }}
                                                </ng-template>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="issuedDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Issued Date</th>
                                            <td mat-cell *matCellDef="let element">
                                                <p>{{ (element.createdDate| date: 'd MMMM yyyy')  || '-' }}</p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="isReceived">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header matColumnDef="surveyHeader">Responded</th>
                                            <td mat-cell *matCellDef="let element" class="text-center">
                                                <mat-icon *ngIf="element.isReceive;then checkCircle else checkCircleOutline"></mat-icon>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="isActive">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header matColumnDef="isActiveHeader">Active</th>
                                            <td mat-cell *matCellDef="let element" class="text-center">
                                                <mat-icon *ngIf="element.isActive;then checkCircle else checkCircleOutline"></mat-icon>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-icon appScreenPermission [permissionsInput]="[CREATE_CSI]" (click)="openCsiSurveyForm(element)" matTooltip="View">remove_red_eye</mat-icon>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayColumnsSatisfactoryIndex"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayColumnsSatisfactoryIndex"></tr>
                                    </table>
                                </mat-card-content>
                                <mat-paginator #paginatorCSI="matPaginator" (page)="navigateToPageCsi($event)" [length]="csiNumberOfRecords" [pageSize]="csiPageSize"
                                               [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                </mat-paginator>
                            </ng-container>
                        </div>
                    </mat-tab>
                    <mat-tab label="Registered Branch">
                        <div class="overflow-scroll">
                            <table class="customer-record-table" aria-label="table-customer-records">
                                <thead>
                                    <tr>
                                        <th><div style="padding-left: 16px;">Deparment Name</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dept of customerRecordModel?.departments let i = index">
                                        <td><div style="padding-left: 16px;">{{dept.name}}</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-tab>

                    <mat-tab label="Mystery Shopper" *ngIf="isShowMysteryShopper">
                        <br>
                        <br>
                        <div *ngIf="loadingMs" class="nbt-empty-result">
                            <div class="nbt-data-loading"></div>
                        </div>
                        <div *ngIf="!loadingCsi">
                            <ng-container *ngIf="dataSourceMs.data.length > 0">
                                <mat-card-content class="mat-card-content-table">
                                    <table mat-table matSort (matSortChange)="sortPageMs($event)" [dataSource]="dataSourceMs" aria-label="table">
                                        <ng-container matColumnDef="mysteryShopperNumber">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header >Feedback Number</th>
                                            <td mat-cell *matCellDef="let element">
                                                <p>{{ element.mysteryShopperNumber || '-'}}</p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="department">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Showroom</th>
                                            <td mat-cell *matCellDef="let element">
                                                <p>{{ element.department?.name || '-'}}</p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="issuedDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Issued Date</th>
                                            <td mat-cell *matCellDef="let element">
                                                <p>{{ (element.createdDate| date: 'd MMMM yyyy')  || '-' }}</p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="isReceived">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header matColumnDef="mysteryShopperHeader">Survey Status</th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-icon *ngIf="element.isReceive;then checkCircle else checkCircleOutline"></mat-icon>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="submitDatetime">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Feedback Date</th>
                                            <td mat-cell *matCellDef="let element">
                                                <p>{{ (element.submitDatetime| date: 'd MMMM yyyy. hh:mm:ss') || '-'}}</p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-icon (click)="openMsDetailForm(element)" matTooltip="View">remove_red_eye</mat-icon>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayColumnMysteryShopper"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayColumnMysteryShopper"></tr>
                                    </table>
                                </mat-card-content>
                                <mat-paginator #paginatorMS="matPaginator" (page)="navigateToPageMs($event)" [length]="msNumberOfRecords" [pageSize]="msPageSize"
                                               [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                </mat-paginator>
                            </ng-container>
                        </div>
                    </mat-tab>
                </mat-tab-group>

            </div>
        </div>
    </mat-card-content>

    <ng-template #checkCircleOutline>
        <mat-icon style="cursor: default">check_circle_outline</mat-icon>
    </ng-template>
    <ng-template #checkCircle>
        <mat-icon style="cursor: default">check_circle</mat-icon>
    </ng-template>
</div>
