import { Property, propertyNameCompare } from '../util';

export const SEARCHBY: Property[] = [
    {
        value: 'NAME',
        name: 'Name.',
    },
    {
        value: 'ID',
        name: 'Identification No.',
    },
    {
        value: 'CONTACT',
        name: 'Contact No.',
    },
    {
        value: 'VEHICLE_NUMBER',
        name: 'Vehicle Number',
    },
].sort(propertyNameCompare);
