<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create New SLA Policy
        </ng-container>
        <ng-template #elseTemplate>
            Edit SLA Policy '{{slaModel.name}}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="slaFormGroup">
        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Name <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="name" placeholder="Enter name" maxlength="100"/>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form-textarea">
                <label>Description <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <textarea matInput appAutofocus type="text" formControlName="description"
                      placeholder="Enter description" rows="5" maxlength="255"></textarea>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form">
                <label>Set SLA target as:</label>
                <br>
                <div style="overflow-x: auto;">
                    <table class="sla-table" aria-label="table-sla">
                        <thead>
                            <tr>
                                <th><div style="padding-left: 16px;">Priority</div></th>
                                <th><div>Response Time</div></th>
                                <th><div>Resolution Time</div></th>
                            </tr>
                        </thead>
                        <tbody formArrayName="slaDetails">
                            <tr *ngFor="let sla of formArraySlaDetails(); let i = index" [formGroupName]="i">
                                <td>
                                    <div class="div-head">
                                        <div class="priority-dot" [ngStyle]="{'background-color': bgColorPriorityDot(i)}"></div>
                                        {{sla.value?.priority}}
                                    </div>
                                </td>
                                <td>
                                    <div class="input-time-sla" >
                                        <div>
                                            <input type="number" required formControlName="responseTimeDay" min="0" max="99">
                                            <br>
                                            day
                                        </div>
                                        <div>
                                            <input type="number" required formControlName="responseTimeHrs" min="0" max="99">
                                            <br>
                                            hrs
                                        </div>
                                        <div>
                                            <input type="number" required formControlName="responseTimeMins" min="0" max="99">
                                            <br>
                                            mins
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="input-time-sla">
                                        <div>
                                            <input type="number" required formControlName="resolutionTimeDay" min="0" max="99">
                                            <br>
                                            day
                                        </div>
                                        <div>
                                            <input type="number" required formControlName="resolutionTimeHrs" min="0" max="99">
                                            <br>
                                            hrs
                                        </div>
                                        <div>
                                            <input type="number" required formControlName="resolutionTimeMins" min="0" max="99">
                                            <br>
                                            mins
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || slaFormGroup.invalid"
        (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>
