import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { VehicleModelModel } from 'src/app/model/vehicle-dialog.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';

@Component({
    selector: 'app-vehicle-model-dialog-detail',
    templateUrl: './vehicle-model-dialog-detail.component.html',
    styleUrls: ['./vehicle-model-dialog-detail.component.scss']
})
export class VehicleModelDialogDetailComponent {

    readonly UPDATE_VEHICLE_MODEL = ApplicationPermissionsService.UPDATE_VEHICLE_MODEL;

    constructor(
        @Inject(MAT_DIALOG_DATA) public vehicleModelModel: VehicleModelModel,
        public dialogRef: MatDialogRef<VehicleModelDialogDetailComponent>,
        public authService: AuthService,
    ) { }
}
