import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ApplicationPositionModel } from '../model/application-position.model';
import { ApplicationUserModel } from '../model/application-user.model';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {}

    public save(
        applicationUserModel: ApplicationUserModel,
        curIns: string
    ): Observable<HttpResponse<ApplicationUserModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<ApplicationUserModel>(
            '/web/application-user',
            applicationUserModel,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }

    public getAll(
        pageNumber?: number,
        pageSize?: number,
        sortOrder?: string,
        criteria?: any
    ): Observable<HttpResponse<ApplicationUserModel[]>> {
        let httpParams = new HttpParams();
        if (pageNumber) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder) {
            httpParams = httpParams.set('sort', sortOrder);
        }
        if (criteria) {
            if (criteria.username) {
                httpParams = httpParams.set('username', criteria.username);
            }
            if (criteria.currentInstitution) {
                httpParams = httpParams.set(
                    'curIns',
                    criteria.currentInstitution
                );
            }
        }
        return this.httpClient.get<ApplicationUserModel[]>(
            `/web/application-user/institution/all`,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }

    public getAllApplicationPositions(): Observable<
        HttpResponse<ApplicationPositionModel[]>
    > {
        return this.httpClient.get<ApplicationPositionModel[]>(
            `/web/application-position/all`,
            {
                observe: 'response',
            }
        );
    }

    public update(
        applicationUserModel: ApplicationUserModel,
        curIns: string
    ): Observable<HttpResponse<ApplicationUserModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        applicationUserModel.applicationRoles.forEach((applicationRole) => {
            applicationRole.applicationPermissions = [];
            applicationRole.institution.parentInstitution = null;
        });
        return this.httpClient.put<ApplicationUserModel>(
            '/web/application-user',
            applicationUserModel,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }

    public resetPasswordAdmin(
        applicationUserModel: ApplicationUserModel,
        curIns: string
    ): Observable<HttpResponse<ApplicationUserModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<ApplicationUserModel>(
            '/web/application-user/reset-password',
            applicationUserModel,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }

    public resetPassword(
        applicationUserModel: ApplicationUserModel
    ): Observable<HttpResponse<ApplicationUserModel>> {
        return this.httpClient.post<ApplicationUserModel>(
            '/web/application-user/reset-password-user',
            applicationUserModel,
            {
                observe: 'response',
            }
        );
    }

    public requestEmail(
        applicationUserModel: ApplicationUserModel
    ): Observable<HttpResponse<ApplicationUserModel>> {
        return this.httpClient.post<ApplicationUserModel>(
            '/web/application-user/reset-password-email',
            applicationUserModel,
            {
                observe: 'response',
            }
        );
    }

    public resetPasswordKey(
        applicationUserModel: ApplicationUserModel
    ): Observable<HttpResponse<ApplicationUserModel>> {
        return this.httpClient.post<ApplicationUserModel>(
            '/web/application-user/reset-password-key',
            applicationUserModel,
            {
                observe: 'response',
            }
        );
    }

    public getAllByDepartment(
        curIns: number,
        departmentId: number,
        criteria?: {
            reminderInd?: boolean;
            defaultDepartmentInd?: boolean;
            isChampion?: boolean;
            officerType?: string;
        }
    ): Observable<HttpResponse<ApplicationUserModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('departmentId', departmentId);
        if (criteria) {
            if (criteria.reminderInd !== null) {
                httpParams = httpParams.set(
                    'reminderInd',
                    criteria.reminderInd
                );
            }
            if (criteria.defaultDepartmentInd) {
                httpParams = httpParams.set(
                    'defaultDepartmentInd',
                    criteria.defaultDepartmentInd
                );
            }
           if (criteria.isChampion) {
                httpParams = httpParams.set(
                    'isChampion',
                    criteria.isChampion
                );
           }
           if(criteria.officerType) {
                httpParams = httpParams.set(
                    'officerType',
                    criteria.officerType
                );
           }
        }
        return this.httpClient.get<ApplicationUserModel[]>(
            `/web/application-user/department`,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }

    searchAllByDepartment(
        page?: number,
        size?: number,
        username?: string,
        curIns?: number,
        departmentId?: number,
        criteria?: { reminderInd?: boolean; defaultDepartmentInd?: boolean },
        officerType?: string
    ): Observable<HttpResponse<ApplicationUserModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('departmentId', departmentId);
        httpParams = httpParams.set('officerType', officerType);
        if (page) httpParams = httpParams.set('page', page);
        if (size) httpParams = httpParams.set('size', size);
        if (username) httpParams = httpParams.set('username', username);
        if (criteria) {
            if (criteria.reminderInd!== null) httpParams = httpParams.set('reminderInd', criteria.reminderInd);
            if (criteria.defaultDepartmentInd!== null) httpParams = httpParams.set('defaultDepartmentInd', criteria.defaultDepartmentInd);
        }

        return this.httpClient.get<ApplicationUserModel[]>(
           `/web/application-user/department/all`,
           {
               observe: 'response',
               params: httpParams,
           }
        );
    }

    public getAllSurveySalesConsultantByDepartment(
        departmentId: number,
        surveyType: string
    ): Observable<HttpResponse<ApplicationUserModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        httpParams = httpParams.set('departmentId', departmentId);
        httpParams = httpParams.set('surveyType', surveyType);
        return this.httpClient.get<ApplicationUserModel[]>(
            `/web/application-user/survey/department`,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }

    public getAllNPSPromotersByDepartment(
        departmentId: number
    ): Observable<HttpResponse<ApplicationUserModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        httpParams = httpParams.set('departmentId', departmentId);
        return this.httpClient.get<ApplicationUserModel[]>(
            `/web/application-user/nps/department`,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }
}
