import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CustomerRecordVehiclesModel } from '../model/customer-records.model';
import { HolidayModel } from '../model/holiday.model';
import { VehicleModelModel } from '../model/vehicle-dialog.model';

@Injectable({
    providedIn: 'root',
})
export class VehicleModelService {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {}

    getAllModels(request: any): Observable<HttpResponse<any[]>> {
        let httpParams = new HttpParams();
        if (request.search) {
            httpParams = httpParams.set('name', request.search);
        }
        if (request.currentInstitution) {
            httpParams = httpParams.set('curIns', request.currentInstitution);
        }
        if (request.currentPage) {
            httpParams = httpParams.set('page', request.currentPage);
        }
        if (request.pageSize) {
            httpParams = httpParams.set('size', request.pageSize);
        }
        if (request.sortOrder) {
            httpParams = httpParams.set('sort', request.sortOrder);
        }

        return this.httpClient.get<any[]>(`/web/model/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllModelsNoPagination(): Observable<HttpResponse<VehicleModelModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());

        return this.httpClient.get<VehicleModelModel[]>(
            `/web/model/all/no-pagination`,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }

    create(
        model: VehicleModelModel,
        curIns: string
    ): Observable<HttpResponse<HolidayModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<HolidayModel>(`/web/model`, model, {
            observe: 'response',
            params: httpParams,
        });
    }

    update(
        model: VehicleModelModel,
        curIns: string
    ): Observable<HttpResponse<HolidayModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<HolidayModel>(`/web/model`, model, {
            observe: 'response',
            params: httpParams,
        });
    }

    createVehicle(
        model: CustomerRecordVehiclesModel,
        curIns: string
    ): Observable<HttpResponse<HolidayModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<HolidayModel>(`/web/vehicle`, model, {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllNoPagination(
        curIns: string
    ): Observable<HttpResponse<VehicleModelModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.get<VehicleModelModel[]>(`/web/vehicle/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    updateVehicle(
        model: CustomerRecordVehiclesModel,
        curIns: string
    ): Observable<HttpResponse<HolidayModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<HolidayModel>(`/web/vehicle`, model, {
            observe: 'response',
            params: httpParams,
        });
    }
}
