import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogSimpleComponent } from 'src/app/shared/dialog-simple/dialog-simple.component';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';

@Component({
    selector: 'app-users-dialog-detail',
    templateUrl: './users-dialog-detail.component.html',
    styleUrls: ['./users-dialog-detail.component.scss']
})
export class UsersDialogDetailComponent implements OnInit {

    readonly UPDATE_APPLICATION_USER = ApplicationPermissionsService.UPDATE_APPLICATION_USER;

    constructor(
        @Inject(MAT_DIALOG_DATA) public applicationUserModel: any,
        public dialogRef: MatDialogRef<UsersDialogDetailComponent>,
        public authService: AuthService,
    ) { }

    ngOnInit(): void {
    }
}
