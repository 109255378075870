import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TicketReportService {
  constructor(private httpClient: HttpClient) {}

  exportVOC(request: any) {
    let httpParams = new HttpParams();
    if (request.page || request.size) {
      httpParams = httpParams.set('page', request.page);
      httpParams = httpParams.set('size', request.size);
    }
    if (request.curIns) {
      httpParams = httpParams.set('curIns', request.curIns);
    }
    if (request.currentPage) {
      httpParams = httpParams.set('currentPage', request.currentPage);
    }
    if (request.ticketNumber) {
      httpParams = httpParams.set('ticketNumber', request.ticketNumber);
    }
    if (request.groupName) {
      httpParams = httpParams.set('groupName', request.groupName);
    }
    if (request.categoryName) {
      httpParams = httpParams.set('categoryName', request.categoryName);
    }
    if (request.typeName) {
      httpParams = httpParams.set('typeName', request.typeName);
    }
    if (request.assigneeName) {
      httpParams = httpParams.set('assigneeName', request.assigneeName);
    }
    if (request.customerName) {
      httpParams = httpParams.set('customerName', request.customerName);
    }
    if (request.vehicleRegNumber) {
      httpParams = httpParams.set('vehicleRegNumber', request.vehicleRegNumber);
    }
    if (request.departmentName) {
      httpParams = httpParams.set('departmentName', request.departmentName);
    }
    if (request.status && request.status !== 'all') {
      httpParams = httpParams.set('status', request.status);
    }
    if (request.overdue) {
      httpParams = httpParams.set('overdue', request.overdue);
    }
    if (request.includeDeactivate) {
      httpParams = httpParams.set(
        'includeDeactivate',
        request.includeDeactivate
      );
    }
    return this.httpClient.get(`/web/report/ticket/listing`, {
      observe: 'response',
      params: httpParams,
      responseType: 'blob',
    });
  }

  exportTierOneReport(request: any) {
    return this.httpClient.get('/web/report/ticket/raw-data/tier-one', {
      observe: 'response',
      params: request,
      responseType: 'blob',
    });
  }

  exportTierTwoReport(request: any) {
    return this.httpClient.get('/web/report/ticket/raw-data/tier-two', {
      observe: 'response',
      params: request,
      responseType: 'blob',
    });
  }

  exportTierThreeReport(request: any) {
    return this.httpClient.get('/web/report/ticket/raw-data/tier-three', {
      observe: 'response',
      params: request,
      responseType: 'blob',
    });
  }
}
