<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        View Institution '{{institutionModel.name}}'
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <div class="nbt-details row">
        <div class="col-md-6">
            <label>Name</label>
            <div class="value" maxlength="100">{{institutionModel.name || '-'}}
            </div>
        </div>
        <div class="col-md-6">
            <label>Short Name</label>
            <div class="value" maxlength="20">{{institutionModel.shortName || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Parent Institution</label>
            <div class="value">{{institutionModel.parentInstitution?.name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Business Registration Number</label>
            <div class="value" maxlength="50">{{institutionModel.businessRegNo || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Industry</label>
            <div class="value" maxlength="100">{{institutionModel.industry || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Address</label>
            <div class="value" maxlength="500">{{institutionModel.address || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Phone</label>
            <div class="value" maxlength="20">{{institutionModel.phone || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Fax</label>
            <div class="value" maxlength="50">{{institutionModel.fax || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Email</label>
            <div class="value" maxlength="50">{{institutionModel.email || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Website</label>
            <div class="value" maxlength="50">{{institutionModel.website || '-'}}</div>
        </div>
    </div>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Close
    </button>
    <button type="button" *ngIf="authService.hasAuthorityMenu(UPDATE_INSTITUTION)" class="nbt-btn-primary ml8" (click)="dialogRef.close('edit')">
        <mat-icon class="mr8">edit</mat-icon>
        Edit
    </button>
</div>