export enum ReportType {
    TIER_ONE = 'VOC Tier 1',
    TIER_TWO = 'VOC Tier 2',
    TIER_THREE = 'VOC Tier 3',
    CSI_SCORING = 'CSI Scoring',
    SSI_SCORING = 'SSI Scoring',
    CSI_AVERAGE_ANSWER = 'CSI Average Answer',
    SSI_AVERAGE_ANSWER = 'SSI Average Answer',
    CSI_RESPONSE_RATE = 'CSI Response Rate',
    SSI_RESPONSE_RATE = 'SSI Response Rate',
    NPS_FULL_LISTING = 'NPS Full Listing',
    NPS_INDIVIDUAL_LISTING = 'NPS Individual Listing',
    MYSTERY_SHOPPER = 'Mystery Shopper'
}
