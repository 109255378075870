<div class="container-fluid nbt-container">
    <div class="nbt-header" *ngIf="!loading">
        <div class="survey-header-title">
            <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
                {{ surveyModel?.surveyNumber}} - {{ surveyModel?.surveyForm?.name}}
            </ng-container>
            <ng-template #elseTemplate>
                View {{ surveyModel?.surveyNumber}} - {{ surveyModel?.surveyForm?.name}}
            </ng-template>
        </div>
    </div>
    <br>
    <div class="loading-indicator" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px;">
        <div class="csi-form">
            <div class="row justify-content-center" style="text-align: center;">
                {{ formContentM.header }}
            </div>
            <div class="row justify-content-center">
                {{ formContent.header }}
            </div>
            <br>
            <br>
            <div class="row font-bold">
                <i>{{ formContentM.valueCust}}</i>
            </div>
            <br>
            <div class="row">
                <i>{{ formContentM.tq }}</i>
            </div>
            <br>
            <div class="row font-bold">
                <i>{{ formContent.valueCust}}</i>
            </div>
            <br>
            <div class="row">
                <i>{{ formContent.tq }}</i>
            </div>
            <br>
            <div class="row">
                <i>{{ formContentM.tq2 }} / {{ formContent.tq2 }}</i>
            </div>
            <br>
            <br>
            <hr>
            <form [formGroup]="csiFormGroup">
                <div class="row">
                    <div class="col-md-6 nbt-form">
                        <label for=""><b>Jenis</b></label>
                        <label for=""><b><i>Model</i></b></label>
                        <label>{{ surveyModel?.vehicle?.model?.brand}} {{surveyModel?.vehicle?.model?.name}}</label>
                    </div>
                    <div class="col-md-6 nbt-form">
                        <br>
                        <label for=""><b>No. Pendaftaran Kereta</b></label>
                        <label for=""><b><i>Vehicle Registration No</i></b></label>
                        <label>{{ surveyModel?.vehicle?.registrationNo}}</label>
                    </div>
                    <div class="col-md-6 nbt-form">
                        <br>
                        <label for=""><b>Pusat Servis</b></label>
                        <label for=""><b><i>Service Center</i></b></label>
                        <label>{{ surveyModel?.department?.name}}</label>
                    </div>
                    <div class="col-md-6 nbt-form">
                        <br>
                        <label for=""><b>Tarikh Servis</b></label>
                        <label for=""><b><i>Service Date</i></b></label>
                        <input class="date-picker" formControlName="serviceDatetimeString" type="datetime-local" placeholder="Select last service date" />
                    </div>
                </div>
                <hr>
                <div class="row">
                    <label for=""><b>{{ formContentM.feedback }}</b></label>
                    <br>
                    <label for=""><b>{{ formContent.feedback }}</b></label>
                </div>
                <br>
                <br>
                <div class="col-md-12 nbt-form" >
                    <div class="row" formArrayName="surveyDetails">
                        <div *ngFor="let survey of formArraySurvey() ; let i = index" [formGroupName]="i">
                            <div class="row">
                            <div class="col-md-5 question-align">
                                <span><strong>Q{{i+1}} : {{questionChecking(survey.value.question,0)}}</strong>
                                <br *ngIf="questionChecking(survey.value.question,1)!==''">
                                <i>Q{{i+1}} : {{questionChecking(survey.value.question,1)}}</i></span>
                            </div>
                            <div *ngIf="!survey.value.isReceive" class="col-md-5 mb8">
                                <mat-radio-group aria-labelledby="example-radio-group-label"  formControlName="surveyFormAnswer" required>
                                    <mat-radio-button *ngFor="let ans of survey.value.answers" [value]="ans" name="radio_survey_{{survey.value.id}}" aria-label="Select an option">
                                        <mat-radio-label>
                                            <img [src]="ans.answerURL" class="img-size"/>
                                        </mat-radio-label>
                                    </mat-radio-button>
                                    <div *ngIf="!survey.value.isReceive">
                                        <mat-checkbox class="example-margin" (change)="checkRadio($event.checked, i)" [disabled]="!surveyModel?.isActive">
                                            <img [src]="na" class="na-size"/>
                                        </mat-checkbox>
                                    </div>
                                </mat-radio-group>
                            </div>
                            <div *ngIf="survey.value.isReceive" class="col-md-6 ">
                                <mat-radio-label>
                                    <img [src]="survey.value?.answerURL" [ngClass]="isNASize(survey.value?.answerURL) ? 'na-size' : 'img-size'"/>
                                </mat-radio-label>
                            </div>
                        </div>
                            <hr/>
                        </div>
                    </div>
                </div>
                <div class="row lower-section">
                    <div class="col-md-4 nbt-form">
                        <label for="">Nombor telefon bimbit <span class="required">*</span></label>
                        <label for=""><i>Phone Number</i> <span class="required">*</span></label>
                        <mat-form-field appearance="outline">
                            <input matInput appAutofocus type="text" formControlName="phoneNumber" placeholder="Enter Phone Number" maxlength="200" required />
                            <mat-error *ngIf="csiFormGroup.get('phoneNumber').errors?.pattern">Invalid phone number input</mat-error>
                            <mat-icon class="material-symbols-text" [matTooltip]="phoneContent" matTooltipPosition="above">
                                info
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 nbt-form-textarea">
                        {{ formContentM.compliments }}
                        <span class="required">*</span>
                        <br>
                        <i>{{ formContent.compliments }}</i>
                        <span class="required">*</span>
                        <mat-form-field appearance="outline">
                            <textarea matInput appAutofocus type="text" formControlName="remark" required
                            placeholder="Enter remark" maxlength="2000" rows="4" #myInput></textarea>
                            <span>{{myInput.value.length}} / 2000</span>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="nbt-dialog-footer">
                <button *ngIf="dialogType === 'create'" type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="back()">
                    <mat-icon class="mr8">clear</mat-icon>
                    Cancel
                </button>
                <button appScreenPermission [permissionsInput]="[MODIFY_CSI]" *ngIf="dialogType === 'create'" type="button" class="nbt-btn-primary ml8" (click)="modification()" [disabled]="isSaving || !surveyModel.isActive">
                    <mat-icon class="mr8">save</mat-icon>
                    Modification
                </button>
                <button *ngIf="dialogType === 'create'" type="button" class="nbt-btn-primary ml8" (click)="saveSurvey()" [disabled]="isSaving || csiFormGroup.invalid || !surveyModel.isActive">
                    <mat-icon class="mr8">save</mat-icon>
                    Save
                </button>
                <button *ngIf="dialogType === 'read'" type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="back()">
                    <mat-icon class="mr8">arrow_back_ios</mat-icon>
                    Back
                </button>
            </div>
        </div>
    </mat-card-content>
</div>
