import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { DepartmentModel } from 'src/app/model/department.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DepartmentService } from 'src/app/services/department.service';
import { DepartmentsDialogDetailComponent } from '../departments-dialog-detail/departments-dialog-detail.component';
import { DepartmentsDialogNewOrEditComponent } from '../departments-dialog-new-or-edit/departments-dialog-new-or-edit.component';
import { BusinessHoursService } from 'src/app/services/business-hours.service';

@Component({
    selector: 'app-departments-listing',
    templateUrl: './departments-listing.component.html',
    styleUrls: ['./departments-listing.component.scss']
})
export class DepartmentsListingComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns: string[];
    dataSource: MatTableDataSource<DepartmentModel>;
    obs: BehaviorSubject<DepartmentModel[]>;

    readonly CREATE_DEPARTMENT = ApplicationPermissionsService.CREATE_DEPARTMENT;
    readonly UPDATE_DEPARTMENT = ApplicationPermissionsService.UPDATE_DEPARTMENT;

    searchFormGroup: FormGroup;
    institutions: InstitutionModel[];
    DepartmentFormGroup: FormGroup;

    pageNumbers: number[];
    numberOfRecords: number;
    countLoad = 0;
    currentPage: number;
    pageSize: number;
    sortOrder: string;

    constructor(
        private departmentService: DepartmentService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private institutionsService: InstitutionsService,
        public authService: AuthService,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private businessHourService: BusinessHoursService
    ) {
        this.dataSource = new MatTableDataSource<DepartmentModel>();
        this.dataSource.paginator = this.paginator;

        this.pageNumbers = [];
        this.numberOfRecords = 0;
        this.currentPage = 0;
        this.pageSize = 20;
        this.sortOrder = 'name,asc';
        this.countLoad = 0;
    }

    ngOnInit(): void {
        this.displayColumns = [
            'name',
            'description',
            'parentDepartment',
            'active',
            'actions'
        ];
        this.institutionsService.getAll().subscribe({
            next: (value) => (this.institutions = value.body),
        });

        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.currentPage = this.activatedRoute.snapshot.queryParams['page'] ? this.activatedRoute.snapshot.queryParams['page'] : undefined;
            this.sortOrder = this.activatedRoute.snapshot.queryParams['sort'] ? this.activatedRoute.snapshot.queryParams['sort'] : undefined;
            this.pageSize = this.activatedRoute.snapshot.queryParams['pageSize'] ? this.activatedRoute.snapshot.queryParams['pageSize'] : undefined;
        }
        this.createForm();
        this.refreshForm();
    }

    private createForm() {
        let name: string;
        let institution: string;
        let createdDate: string;
        let updatedDate: string;

        if (this.activatedRoute.snapshot.queryParams['page']) {
            name = this.activatedRoute.snapshot.queryParams['name']
                ? this.activatedRoute.snapshot.queryParams['name']
                : null;
            institution = this.activatedRoute.snapshot.queryParams['institutions']
                ? this.activatedRoute.snapshot.queryParams['institutions']
                : null;
            createdDate = this.activatedRoute.snapshot.queryParams['createdDate']
                ? this.activatedRoute.snapshot.queryParams['createdDate']
                : null;
            updatedDate = this.activatedRoute.snapshot.queryParams['updatedDate']
                ? this.activatedRoute.snapshot.queryParams['updatedDate']
                : null;
        }

        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(name ? name : null),
            searchInstitution: this.formBuilder.control(
                institution ? institution : null
            ),
            searchCreatedDate: this.formBuilder.control(
                createdDate ? createdDate : null
            ),
            searchUpdatedDate: this.formBuilder.control(
                updatedDate ? updatedDate : null
            ),
        });
    }

    search(currentPage: number) {
        let name: string;
        let institution: string;
        let createdDate: string;
        let updatedDate: string;

        name = this.searchFormGroup.controls.searchName.value
            ? this.searchFormGroup.controls.searchName.value
            : null;

        institution = this.searchFormGroup.controls.searchInstitution.value
            ? this.searchFormGroup.controls.searchInstitution.value
            : null;

        createdDate = this.searchFormGroup.controls.searchCreatedDate.value
            ? this.getTimeString(
                this.searchFormGroup.controls.searchCreatedDate.value
            )
            : null;

        updatedDate = this.searchFormGroup.controls.searchUpdatedDate.value
            ? this.getTimeString(
                this.searchFormGroup.controls.searchUpdatedDate.value
            )
            : null;
        this.departmentService.getAll(
            this.currentPage,
            this.pageSize,
            this.sortOrder,
            {
                name: name,
                institution: institution,
                createdDate: createdDate ? createdDate : null,
                updatedDate: updatedDate ? updatedDate : null,
                currentInstitution: this.authService.getCurInsId()
            }
        ).subscribe({
            next: (value) => {
                this.dataSource.data = value.body;
                this.numberOfRecords = Number(value.headers.get('X-Total-Count'));
            },
            error: (value) => {
                this.snackBar.open(value.error.message, 'Close', {
                    duration: 60000
                });
            },
        });
    }

    refreshForm() {
        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(null),
            searchInstitution: this.formBuilder.control(null),
            searchCreatedDate: this.formBuilder.control(null),
            searchUpdatedDate: this.formBuilder.control(null),
        });
        this.search(0);
    }

    getTimeString(dateToChange: Date): string {
        let newDate: Date = new Date(dateToChange.getTime() + 8 * 60 * 60 * 1000);
        return newDate.toISOString();
    }

    ngOnDestroy(): void {
        if (this.dataSource) {
            this.dataSource.disconnect();
        }
    }

    create(): void {
        this.router
            .navigate(['create'], {
                relativeTo: this.activatedRoute,
            })
            .then();
    }

    edit(DepartmentModel: DepartmentModel): void {
        this.router
            .navigate(['edit'], {
                relativeTo: this.activatedRoute,
                state: {
                    DepartmentModel,
                },
            })
            .then();
    }

    sortPage(event): void {
        this.sortOrder = `${event.active},${event.direction}`;
        this.search(this.currentPage);
    }

    navigateToPage(event): void {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        this.search(this.currentPage);
    }

    openDepartmentsDialogCreateOrEdit(departmentModel?: DepartmentModel) {
        const modalRef = this.matDialog.open(DepartmentsDialogNewOrEditComponent, {
            data: departmentModel,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(0);
            }
        })
    }

    openDepartmentsDialogDetail(departmentModel?: DepartmentModel) {
        const modalRef = this.matDialog.open(DepartmentsDialogDetailComponent, {
            data: departmentModel,
            panelClass: 'mat-dialog-md'
        })

        modalRef.afterClosed().subscribe({
            next: (edit) => {
                if (edit) this.openDepartmentsDialogCreateOrEdit(departmentModel);
            }
        })
    }
}
