import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AuthService, Authority } from 'src/app/auth/auth.service';
import { DepartmentModel } from 'src/app/model/department.model';
import { SurveyFormModel } from 'src/app/model/survey-form.model';
import { MysteryShopperFormModel } from 'src/app/model/mystery-shopper-form.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DepartmentService } from 'src/app/services/department.service';
import { NpsReportService } from 'src/app/services/nps-report.service';
import { MysteryShopperReportService } from 'src/app/services/mystery-shopper-report.service';
import { SurveyReportService } from 'src/app/services/survey-report.service';
import { SurveyService } from 'src/app/services/survey.service';
import { TicketReportService } from 'src/app/services/ticket-report.service';
import { ReportType, SurveyType } from 'src/app/shared/constant';
import { MONTH } from 'src/app/shared/constant/month-enum.constant';

@Component({
    selector: 'app-voc-reports',
    templateUrl: './report-main.component.html',
})
export class VOCReportsComponent implements OnInit {
    @ViewChild('departmentSelect') departmentSelect: MatSelect;
    @ViewChild('selectAllCheckbox') selectAllCheckbox: MatCheckbox;

    readonly snackBarConfig: MatSnackBarConfig = { duration: 60000 };
    readonly READ_REPORT = ApplicationPermissionsService.READ_REPORT;
    readonly READ_REPORT_TICKET_TIER_ONE =
        ApplicationPermissionsService.READ_REPORT_TICKET_TIER_ONE;
    readonly READ_REPORT_TICKET_TIER_TWO =
        ApplicationPermissionsService.READ_REPORT_TICKET_TIER_TWO;
    readonly READ_REPORT_TICKET_TIER_THREE =
        ApplicationPermissionsService.READ_REPORT_TICKET_TIER_THREE;

    readonly READ_REPORT_SSI_SCORE =
        ApplicationPermissionsService.READ_REPORT_SSI_SCORE;
    readonly READ_REPORT_CSI_SCORE =
        ApplicationPermissionsService.READ_REPORT_CSI_SCORE;
    readonly READ_REPORT_SSI_AVERAGE_ANSWER =
        ApplicationPermissionsService.READ_REPORT_SSI_AVERAGE_ANSWER;
    readonly READ_REPORT_CSI_AVERAGE_ANSWER =
        ApplicationPermissionsService.READ_REPORT_CSI_AVERAGE_ANSWER;
    readonly READ_REPORT_SSI_RESPONSE_RATE =
        ApplicationPermissionsService.READ_REPORT_SSI_RESPONSE_RATE;
    readonly READ_REPORT_CSI_RESPONSE_RATE =
        ApplicationPermissionsService.READ_REPORT_CSI_RESPONSE_RATE;

    readonly READ_REPORT_NPS_FULL_LISTING =
        ApplicationPermissionsService.READ_REPORT_NPS_FULL_LISTING;
    readonly READ_REPORT_NPS_INDIVIDUAL_LISTING =
        ApplicationPermissionsService.READ_REPORT_NPS_INDIVIDUAL_LISTING;

    readonly READ_REPORT_MYSTERY_SHOPPER =
        ApplicationPermissionsService.READ_REPORT_MYSTERY_SHOPPER;

    reportType = this.getReportTypes();

    curIns: any;
    departments: DepartmentModel[];
    surveyForms: SurveyFormModel[];
    mysteryShopperSurveyForms: MysteryShopperFormModel[];
    months = MONTH;
    departmentIds = [];
    departmentId: number;
    surveyFormId: number;
    mysteryShopperSurveyFormId: number;
    selectedReportType: string;
    useTicketNumberParam = false;
    useDepartmentIdsParam = false;
    useDepartmentIdParam = false;
    useMonthYearParam = false;
    startDate: string;
    endDate: string;
    month: string;
    year: string;
    ticketNumber: string;
    isLoading = false;

    constructor(
        private departmentService: DepartmentService,
        private authService: AuthService,
        private snackBar: MatSnackBar,
        private ticketReportService: TicketReportService,
        private surveyReportService: SurveyReportService,
        private surveyService: SurveyService,
        private npsReportService: NpsReportService,
        private mysteryShopperReportService: MysteryShopperReportService
    ) {}

    ngOnInit(): void {
        this.curIns = this.authService.getCurInsId();
    }

    getDepartmentsWithDefaultDepartmentChecking() {
        this.departmentService.isDefaultDepartment(this.curIns).subscribe({
            next: (isDefaultDepartment) => {
                if (isDefaultDepartment.body) {
                    this.departmentService.getAllNoPagination().subscribe({
                        next: (res) => {
                            this.departments = res.body;
                        },
                        error: (res) => {
                            this.snackBar.open(
                                res.error.message,
                                'Close',
                                this.snackBarConfig
                            );
                        },
                    });
                } else {
                    this.departmentService
                        .getDepartmentByUser(this.curIns)
                        .subscribe({
                            next: (res) => {
                                this.departments = res.body;
                            },
                            error: (res) => {
                                this.snackBar.open(
                                    res.error.message,
                                    'Close',
                                    this.snackBarConfig
                                );
                            },
                        });
                }
            },
            error: (err) => {
                this.snackBar.open(
                    err.error.message,
                    'Close',
                    this.snackBarConfig
                );
            },
        });
    }

    getDepartments() {
        this.departmentService.getAllNoPagination().subscribe({
            next: (res) => {
                this.departments = res.body;
            },
            error: (res) => {
                this.snackBar.open(
                    res.error.message,
                    'Close',
                    this.snackBarConfig
                );
            },
        });
    }

    displayParams(type: string) {
        if (type === ReportType.TIER_ONE || type === ReportType.TIER_TWO) {
            this.getDepartmentsWithDefaultDepartmentChecking();
            this.useDepartmentIdsParam = true;
            this.useTicketNumberParam = false;
            this.useDepartmentIdParam = false;
        } else if (
            type === ReportType.NPS_FULL_LISTING ||
            type === ReportType.NPS_INDIVIDUAL_LISTING
        ) {
            this.getDepartments();
            this.useDepartmentIdsParam = true;
            this.useTicketNumberParam = false;
            this.useDepartmentIdParam = false;
        } else if (
            type === ReportType.CSI_SCORING ||
            type === ReportType.SSI_SCORING ||
            type === ReportType.CSI_AVERAGE_ANSWER ||
            type === ReportType.SSI_AVERAGE_ANSWER ||
            type === ReportType.CSI_RESPONSE_RATE ||
            type === ReportType.SSI_RESPONSE_RATE
        ) {
            this.getDepartmentsWithDefaultDepartmentChecking();
            if (
                type === ReportType.CSI_RESPONSE_RATE ||
                type === ReportType.SSI_RESPONSE_RATE
            ) {
                this.useDepartmentIdsParam = true;
                this.useTicketNumberParam = false;
                this.useDepartmentIdParam = false;
            } else {
                this.useDepartmentIdsParam = false;
                this.useTicketNumberParam = false;
                this.useDepartmentIdParam = true;
            }

            let surveyType: SurveyType;
            if (type.includes(SurveyType.CSI)) {
                surveyType = SurveyType.CSI;
            } else if (type.includes(SurveyType.SSI)) {
                surveyType = SurveyType.SSI;
            }

            this.surveyService.getAllSurveysNoPagination(surveyType).subscribe({
                next: (res) => {
                    this.surveyForms = res.body;
                },
                error: (err) => {
                    this.snackBar.open(
                        err.error.message,
                        'Close',
                        this.snackBarConfig
                    );
                },
            });
        } else if (type === ReportType.TIER_THREE) {
            this.useDepartmentIdsParam = false;
            this.useTicketNumberParam = true;
            this.useDepartmentIdParam = false;
        } else if(type === ReportType.MYSTERY_SHOPPER) {
            this.useMonthYearParam = true;
            this.useDepartmentIdsParam = false;
            this.useDepartmentIdParam = false;

            this.mysteryShopperReportService.getAllMysteryShopperFormVersion().subscribe({
                next: (res) => {
                    this.mysteryShopperSurveyForms = res.body;
                },
                error: (err) => {
                    this.snackBar.open(
                        err.error.message,
                        'Close',
                        this.snackBarConfig
                    );
                },
            });
        }
    }

    generate() {
        if(this.useMonthYearParam) {
            if(!(/^[0-9]{1,4}$/.test(this.year)) || this.year.length!==4) {
                this.snackBar.open(
                    "Input Year is Incorrect",
                    'Close',
                    this.snackBarConfig
                );
                return;
            }
        }

        this.isLoading = true;
        this.snackBar.open(
            `Generating ${this.selectedReportType} Report.`,
            'Close',
            this.snackBarConfig
        );

        if (this.selectedReportType === ReportType.TIER_TWO) {
            this.ticketReportService
                .exportTierTwoReport({
                    curIns: this.curIns,
                    departmentIds: this.getDepartmentIds(),
                    startDate: this.startDate,
                    endDate: this.endDate,
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        } else if (this.selectedReportType === ReportType.TIER_ONE) {
            this.ticketReportService
                .exportTierOneReport({
                    curIns: this.curIns,
                    departmentIds: this.getDepartmentIds(),
                    startDate: this.startDate,
                    endDate: this.endDate,
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        } else if (this.selectedReportType === ReportType.TIER_THREE) {
            this.ticketReportService
                .exportTierThreeReport({
                    curIns: this.curIns,
                    ticketNumber: this.ticketNumber,
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        } else if (this.selectedReportType === ReportType.CSI_SCORING) {
            this.surveyReportService
                .generateCSIScoringReport({
                    curIns: this.curIns,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    departmentId: this.departmentId,
                    surveyFormId: this.surveyFormId,
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        } else if (this.selectedReportType === ReportType.SSI_SCORING) {
            this.surveyReportService
                .generateSSIScoringReport({
                    curIns: this.curIns,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    departmentId: this.departmentId,
                    surveyFormId: this.surveyFormId,
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        } else if (this.selectedReportType === ReportType.CSI_AVERAGE_ANSWER) {
            this.surveyReportService
                .generateCSIAverageAnswerReport({
                    curIns: this.curIns,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    departmentId: this.departmentId,
                    surveyFormId: this.surveyFormId,
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        } else if (this.selectedReportType === ReportType.SSI_AVERAGE_ANSWER) {
            this.surveyReportService
                .generateSSIAverageAnswerReport({
                    curIns: this.curIns,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    departmentId: this.departmentId,
                    surveyFormId: this.surveyFormId,
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        } else if (this.selectedReportType === ReportType.CSI_RESPONSE_RATE) {
            this.surveyReportService
                .generateCSIResponseRateReport({
                    curIns: this.curIns,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    departmentIds: this.getDepartmentIds(),
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        } else if (this.selectedReportType === ReportType.SSI_RESPONSE_RATE) {
            this.surveyReportService
                .generateSSIResponseRateReport({
                    curIns: this.curIns,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    departmentIds: this.getDepartmentIds(),
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        } else if (this.selectedReportType === ReportType.NPS_FULL_LISTING) {
            this.npsReportService
                .generateFullNpsListingReport({
                    curIns: this.curIns,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    departmentIds: this.getDepartmentIds(),
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        } else if (
            this.selectedReportType === ReportType.NPS_INDIVIDUAL_LISTING
        ) {
            this.npsReportService
                .generateIndividualNpsListingReport({
                    curIns: this.curIns,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    departmentIds: this.getDepartmentIds(),
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        } else if (
            this.selectedReportType === ReportType.MYSTERY_SHOPPER
        ) {
            this.mysteryShopperReportService
                .generateMysteryShopperReport({
                    curIns: this.curIns,
                    month: this.month,
                    year: this.year,
                    mysteryShopperSurveyFormId: this.mysteryShopperSurveyFormId
                })
                .subscribe({
                    next: (res) => {
                        this.download(res);
                    },
                    error: (res) => {
                        this.handleError(res);
                    },
                });
        }
    }

    getDepartmentIds() {
        const idList = [];

        this.departmentIds.forEach((dept) => {
            this.departments.forEach((deptt) => {
                if (deptt.name === dept) {
                    idList.push(deptt.id);
                }
            });
        });

        return idList;
    }

    download(res: HttpResponse<Blob>) {
        const url = window.URL.createObjectURL(res.body);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.headers.get('content-disposition').split('=')[1];
        a.click();
        this.snackBar.open('File Downloaded', 'Close', this.snackBarConfig);
        this.isLoading = false;
    }

    getReportTypes(): ReportType[] {
        const authorities = this.authService.getAuthorities();
        const result: ReportType[] = [];

        Object.values(ReportType).forEach((type) => {
            switch (type) {
                case ReportType.TIER_ONE: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_TICKET_TIER_ONE
                        )
                    ) {
                        result.push(ReportType.TIER_ONE);
                    }
                    break;
                }
                case ReportType.TIER_TWO: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_TICKET_TIER_TWO
                        )
                    ) {
                        result.push(ReportType.TIER_TWO);
                    }
                    break;
                }
                case ReportType.TIER_THREE: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_TICKET_TIER_THREE
                        )
                    ) {
                        result.push(ReportType.TIER_THREE);
                    }
                    break;
                }
                case ReportType.SSI_SCORING: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_SSI_SCORE
                        )
                    ) {
                        result.push(ReportType.SSI_SCORING);
                    }
                    break;
                }
                case ReportType.CSI_SCORING: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_CSI_SCORE
                        )
                    ) {
                        result.push(ReportType.CSI_SCORING);
                    }
                    break;
                }
                case ReportType.SSI_AVERAGE_ANSWER: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_SSI_AVERAGE_ANSWER
                        )
                    ) {
                        result.push(ReportType.SSI_AVERAGE_ANSWER);
                    }
                    break;
                }
                case ReportType.CSI_AVERAGE_ANSWER: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_CSI_AVERAGE_ANSWER
                        )
                    ) {
                        result.push(ReportType.CSI_AVERAGE_ANSWER);
                    }
                    break;
                }
                case ReportType.SSI_RESPONSE_RATE: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_SSI_RESPONSE_RATE
                        )
                    ) {
                        result.push(ReportType.SSI_RESPONSE_RATE);
                    }
                    break;
                }
                case ReportType.CSI_RESPONSE_RATE: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_CSI_RESPONSE_RATE
                        )
                    ) {
                        result.push(ReportType.CSI_RESPONSE_RATE);
                    }
                    break;
                }
                case ReportType.NPS_FULL_LISTING: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_NPS_FULL_LISTING
                        )
                    ) {
                        result.push(ReportType.NPS_FULL_LISTING);
                    }
                    break;
                }
                case ReportType.NPS_INDIVIDUAL_LISTING: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_NPS_INDIVIDUAL_LISTING
                        )
                    ) {
                        result.push(ReportType.NPS_INDIVIDUAL_LISTING);
                    }
                    break;
                }
                case ReportType.MYSTERY_SHOPPER: {
                    if (
                        this.checkPermission(
                            authorities,
                            this.READ_REPORT_MYSTERY_SHOPPER
                        )
                    ) {
                        result.push(ReportType.MYSTERY_SHOPPER);
                    }
                    break;
                }
            }
        });
        return result;
    }

    checkPermission(authorities: Authority[], permission: string): boolean {
        return authorities.some(
            (authority) => authority.authority === permission
        );
    }

    async handleError(res: HttpErrorResponse) {
        this.snackBar.open(
            JSON.parse(await res.error.text()).message,
            'Close',
            this.snackBarConfig
        );
        this.isLoading = false;
    }

    toggleDepartmentAllSelection() {
        if (this.departmentIds?.length === this.departments?.length) {
            this.departmentSelect.options.forEach((item: MatOption) =>
                item.deselect()
            );
        } else {
            this.departmentSelect.options.forEach((item: MatOption) =>
                item.select()
            );
        }
    }

    handleOption(isSelected: boolean) {
        if (!isSelected && this.selectAllCheckbox.checked) {
            this.selectAllCheckbox.toggle();
        }

        if (
            isSelected &&
            this.departmentIds?.length === this.departments?.length &&
            !this.selectAllCheckbox.checked
        ) {
            this.selectAllCheckbox.toggle();
        }
    }

    disableGenerateButton(): boolean {
        let isDisable: boolean;
        if (!this.useTicketNumberParam) {
            if (this.useDepartmentIdsParam) {
                isDisable =
                    !(this.startDate && this.endDate) ||
                    this.departmentIds.length === 0;
            } else if (this.useDepartmentIdParam) {
                isDisable =
                    !(this.startDate && this.endDate) ||
                    !this.departmentId ||
                    !this.surveyFormId;
            }
        } else {
            isDisable = !this.ticketNumber;
        }
        return isDisable || this.isLoading;
    }
}
