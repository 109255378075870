import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlaListingComponent } from './sla-listing/sla-listing.component';
import { SlaDialogDetailComponent } from './sla-dialog-detail/sla-dialog-detail.component';
import { SlaDialogNewOrEditComponent } from './sla-dialog-new-or-edit/sla-dialog-new-or-edit.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';

const routes: Routes = [
    {
        path: 'sla-policy',
        component: SlaListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    }
];

@NgModule({
    declarations: [
        SlaListingComponent,
        SlaDialogDetailComponent,
        SlaDialogNewOrEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes)
    ]
})
export class SlaModule { }
