import { Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { CategoryModel } from 'src/app/model/category.model';
import { CustomerRecordModel } from 'src/app/model/customer-records.model';
import { DepartmentModel } from 'src/app/model/department.model';
import { VehicleModelModel } from 'src/app/model/vehicle-dialog.model';
import { CategoryService } from 'src/app/services/category.service';
import { CustomerRecordsService } from 'src/app/services/customer-records.service';
import { DepartmentService } from 'src/app/services/department.service';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { TicketService } from 'src/app/services/ticket.service';
import { UsersService } from 'src/app/services/users.service';
import { VehicleModelService } from 'src/app/services/vehicle-model.service';
import { ACTION_ENUM } from 'src/app/shared/constant/action-enum.constant';
import { VoiceOfCustomerDialogConfirmationComponent } from '../voice-of-customer-dialog-confirmation/voice-of-customer-dialog-confirmation.component';
import { DomSanitizer } from '@angular/platform-browser';
import { VoiceOfCustomerDialogPreviewImageComponent } from '../voice-of-customer-dialog-preview-image/voice-of-customer-dialog-preview-image.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SEARCHBY } from 'src/app/shared/constant/searchBy-enum.constant';
import { TOOLTIPS } from 'src/app/shared/constant/tooltips-enum.constant';
import { SystemConfigurationModel, SYS_CONFIG } from 'src/app/model/system-configuration.model';
import { REGEXS } from 'src/app/shared/constant/regex.constant';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { TicketModel } from 'src/app/model/ticket.model';
import { ModelService } from 'src/app/services/model.service';
import { Model } from 'src/app/model/model.model';
import { dataURLtoBlob } from 'src/app/shared/util';
import { VoiceOfCustomerDialogModificationComponent } from '../voice-of-customer-dialog-modification/voice-of-customer-dialog-modification.component';

@Component({
    selector: 'app-voice-of-customer-detail',
    templateUrl: './voice-of-customer-detail.component.html',
    styleUrls: ['./voice-of-customer-detail.component.scss']
})
export class VoiceOfCustomerDetailComponent implements OnInit {

    readonly ON_HOLD_TICKET = ApplicationPermissionsService.ON_HOLD_TICKET;
    readonly CANCEL_TICKET = ApplicationPermissionsService.CANCEL_TICKET;
    readonly MODIFY_TICKET = ApplicationPermissionsService.MODIFY_TICKET;
    readonly snackBarConfig: MatSnackBarConfig = { duration: 60000 };
    windowWidth: number = window.innerWidth;

    status: string = '';
    actionEnums: any[] = ACTION_ENUM;
    ticketModel: any;
    chatModel: any;
    chats: any[];
    state: number = 1;
    groups: CategoryModel[];
    categories: CategoryModel[];
    types:CategoryModel[];
    customerSearchResultShow: boolean = false;
    vocVerificationFormGroup: FormGroup;
    vocVerificationAssigneeFormGroup: FormGroup;
    assignees: ApplicationUserModel[] = [];
    assigneesManager: ApplicationUserModel[] = [];
    assigneesHod: ApplicationUserModel[] = [];
    showStartWorkBtn: boolean = false;
    ticketFormGroup: FormGroup;
    customerFormGroup: FormGroup;
    models: Model[] = [];
    incidentBranchs: DepartmentModel[] = [];
    assignDepts: DepartmentModel[] = [];
    sysConfigSal: SystemConfigurationModel[];
    sysConfigChannel: SystemConfigurationModel[];
    satulations = SYS_CONFIG.SALUTATION;
    channels = SYS_CONFIG.CHANNEL;
    channelShow: boolean = false;
    channelResult : TicketModel[] = [];
    searchBy = SEARCHBY;
    emailContent: string = TOOLTIPS.emailContent;
    phoneContent: string = TOOLTIPS.phoneContent;
    icContent: string = TOOLTIPS.icContent;
    attContent:string = TOOLTIPS.attContent;
    customerSearchResult: CustomerRecordModel[] = [];
    vocFollowUpFormGroup: FormGroup;
    vocChatFormGroup: FormGroup;
    isOpenMore: boolean = false;
    fileList: FileList;
    customerAttachments: any[] = [];
    customerAttachmentUrls: any[] = [];
    factFindingAttachments: any[] = [];
    factFindingAttachmentUrls: any[] = [];
    resolveAttachments: any[] = [];
    resolveAttachmentUrls: any[] = [];
    vocReviewFormGroup: FormGroup;
    loading: boolean = true;
    isChecked:string;
    isBuyer:boolean = false;
    currentDate :Date;
    localCurrentDate: string;
    isShowing:boolean = true;
    registeredBranchs: DepartmentModel[];
    channelSelected:string = '';
    dateFilter :string = REGEXS.DATE;
    acceptedMimeTypes = `image/png, image/jpeg, application/pdf,
    video/mp4, video/quicktime, video/x-matroska,
    audio/mpeg, audio/x-wav, audio/x-aac, audio/x-hx-aac-adts`;
    disableSearchCustomer = true;
    totalCustomers = 0;
    currentTotalCustomers = 0;
    customerPage = 0;
    customerSize = 20;
    isCreate = true;
    fromListing = false;
    isShowCc = false;

    constructor(
        private ticketService: TicketService,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private customerRecordService: CustomerRecordsService,
        private departmentService: DepartmentService,
        private vehicleService: VehicleModelService,
        private modelService: ModelService,
        private snackBar: MatSnackBar,
        private categoryService: CategoryService,
        private systemConfigurationService: SystemConfigurationService,
        private usersService: UsersService,
        private matDialog: MatDialog,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        public datePipe: DatePipe,
        private router: Router,
        private _location: Location
    ) {
        this.currentDate = new Date();
        this.localCurrentDate = new Date(this.currentDate.getTime() - (this.currentDate.getTimezoneOffset() * 60000)).toISOString();

        window.addEventListener('click', (e: any) => {
            let element = document.getElementById('moreVocDropdown');
            if (element && element.contains(e.target)) {
                this.isOpenMore = true;
            } else {
                this.isOpenMore = false;
            }
        });
    }


    ngOnInit(): void {
        this.customerFormGroup = this.formBuilder.group({
            searchByType: this.formBuilder.control(null),
            searchParameter: this.formBuilder.control(null),
            id: this.formBuilder.control(null),
            salutation: this.formBuilder.control(null),
            firstName: this.formBuilder.control(null, [Validators.required]),
            lastName: this.formBuilder.control(null),
            ic: this.formBuilder.control(null, [Validators.required, Validators.pattern(REGEXS.IC)]),
            email: this.formBuilder.control(null, [Validators.required]),
            isBuyer: this.formBuilder.control(true),
            mainPhone: this.formBuilder.control(null, [Validators.required,Validators.pattern(REGEXS.PHONE_FILTER)]),
            homePhone: this.formBuilder.control(null,Validators.pattern(REGEXS.PHONE_FILTER)),
            officePhone: this.formBuilder.control(null,Validators.pattern(REGEXS.PHONE_FILTER)),
            model: this.formBuilder.control(null),
            registrationNo: this.formBuilder.control(null),
            registeredDepartment: this.formBuilder.control(null, Validators.required),
            remarks: this.formBuilder.control(null),
        });
        this.ticketFormGroup = this.formBuilder.group({
            action: this.formBuilder.control("NEW"),
            group: this.formBuilder.control(null, [Validators.required]),
            category: this.formBuilder.control(null, [Validators.required]),
            type: this.formBuilder.control(null, [Validators.required]),
            title: this.formBuilder.control(null, [Validators.required]),
            detail: this.formBuilder.control(null, [Validators.required]),
            incidentBranch: this.formBuilder.control(null, [Validators.required]),
            channel: this.formBuilder.control(null, [Validators.required]),
            serviceDeliveryDate: this.formBuilder.control(null),
            ticketDatetimeString: this.formBuilder.control(this.localCurrentDate.substring(0, 16), [Validators.required]),
            channelOther: this.formBuilder.control(null),
            model: this.formBuilder.control(null),
            registrationNo: this.formBuilder.control(null),
        });
        this.vocVerificationFormGroup = this.formBuilder.group({
            id: this.formBuilder.control(null),
            verificationCallDatetimeString: this.formBuilder.control(null, [Validators.required]),
            verificationDetail: this.formBuilder.control(null, [Validators.required]),
            priority: this.formBuilder.control("HIGH", [Validators.required]),
            classification: this.formBuilder.control("Product", [Validators.required]),
            department: this.formBuilder.control(null, [Validators.required]),
        })
        this.vocVerificationAssigneeFormGroup = this.formBuilder.group({
            assignee: this.formBuilder.control(null, [Validators.required]),
        })
        this.vocReviewFormGroup = this.formBuilder.group({
            reviewDatetimeString: this.formBuilder.control(null, [Validators.required]),
            reviewDetail: this.formBuilder.control(null, [Validators.required]),
        })
        this.route.queryParams.subscribe({
            next: response => {
                if(response["fromListing"])
                    this.fromListing = response.fromListing;

                if (response["id"]) {
                    this.isCreate = false;
                    this.ticketService.getAllChat(response["id"], this.authService.getCurInsId()).subscribe({
                        next: chats => {
                            this.chats = chats.body;
                        }
                    });
                    this.ticketService.getTicketById(response["id"], this.authService.getCurInsId()).subscribe({
                        next: ticket => {
                            this.ticketModel = ticket.body;
                            this.status = this.ticketModel.status;
                            this.state = 2;
                            this.isShowing = false;
                            this.customerFormGroup = this.formBuilder.group({
                                searchByType: this.formBuilder.control(null),
                                searchParameter: this.formBuilder.control(null),
                                salutation: this.formBuilder.control(this.ticketModel.customer?.salutation),
                                firstName: this.formBuilder.control(this.ticketModel.customer?.firstName, [Validators.required]),
                                lastName: this.formBuilder.control(this.ticketModel.customer?.lastName),
                                ic: this.formBuilder.control(this.ticketModel.customer?.ic, [Validators.required]),
                                email: this.formBuilder.control(this.ticketModel.customer?.email, [Validators.required]),
                                isBuyer: this.formBuilder.control(this.ticketModel.customer.isBuyer),
                                mainPhone: this.formBuilder.control(this.ticketModel.customer?.mainPhone, [Validators.required]),
                                homePhone: this.formBuilder.control(this.ticketModel.customer?.homePhone),
                                officePhone: this.formBuilder.control(this.ticketModel.customer?.officePhone),
                                model: this.formBuilder.control(this.ticketModel.customerVehicle?.model),
                                registrationNo: this.formBuilder.control(this.ticketModel.customerVehicle?.registrationNo),
                                remarks: this.formBuilder.control(this.ticketModel.vehicle?.remarks),
                            });
                            this.customerFormGroup.disable();
                            this.ticketFormGroup = this.formBuilder.group({
                                id: this.formBuilder.control(this.ticketModel.id),
                                action: this.formBuilder.control(this.ticketModel.status),
                                group: this.formBuilder.control(this.ticketModel.group, [Validators.required]),
                                category: this.formBuilder.control(this.ticketModel.category, [Validators.required]),
                                type: this.formBuilder.control(this.ticketModel.type, [Validators.required]),
                                title: this.formBuilder.control(this.ticketModel.title, [Validators.required]),
                                detail: this.formBuilder.control(this.ticketModel.detail, [Validators.required]),
                                incidentBranch: this.formBuilder.control(this.ticketModel.incidentBranch, [Validators.required]),
                                channel: this.formBuilder.control(this.ticketModel.channel, [Validators.required]),
                                serviceDeliveryDate: this.formBuilder.control(this.ticketModel.serviceDeliveryDate, [Validators.required]),
                                ticketDatetimeString: this.formBuilder.control(this.ticketModel.ticketDatetime?.substring(0, 16), [Validators.required]),
                                channelOther: this.formBuilder.control(this.ticketModel.channelOther),
                                model: this.formBuilder.control(this.ticketModel.vehicle?.model),
                                registrationNo: this.formBuilder.control(this.ticketModel.vehicle?.registrationNo),
                            });
                            this.categoryService.getAllByParentIdNoPagination(null,true).subscribe({
                                next: response => {
                                    this.groups = response.body.filter(cat => cat.path.split("|").length === 1);
                                    this.categories = response.body.filter(cat => cat.path.split("|").length === 2);
                                    this.types = response.body.filter(cat => cat.path.split("|").length === 3);
                                }
                            })
                            this.ticketFormGroup.disable();
                            this.vocVerificationFormGroup = this.formBuilder.group({
                                id: this.formBuilder.control(this.ticketModel.id),
                                verificationCallDatetimeString: this.formBuilder.control( this.localCurrentDate.substring(0, 16) , [Validators.required]),
                                verificationDetail: this.formBuilder.control(this.ticketModel.verificationDetail, [Validators.required]),
                                priority: this.formBuilder.control(this.ticketModel.priority ? this.ticketModel.priority : "HIGH", [Validators.required]),
                                classification: this.formBuilder.control(this.ticketModel.classification ? this.ticketModel.classification : "Product", [Validators.required]),
                                department: this.formBuilder.control(this.ticketModel.department, [Validators.required]),
                            })
                            this.vocVerificationAssigneeFormGroup = this.formBuilder.group({
                                assignee: this.formBuilder.control(this.ticketModel.assignee, [Validators.required]),
                            })
                            this.vocReviewFormGroup = this.formBuilder.group({
                                reviewDatetimeString: this.formBuilder.control(this.ticketModel.reviewDatetimeString? this.ticketModel.reviewDatetimeString.substring(0, 16) : this.localCurrentDate.substring(0, 16), [Validators.required]),
                                reviewDetail: this.formBuilder.control(this.ticketModel.reviewDetail, [Validators.required]),
                            })
                            if (this.status === 'ASSIGNED') {
                                this.vocVerificationFormGroup.disable();
                                if (this.authService.getUsername() === this.ticketModel.assignee?.username) this.showStartWorkBtn = true;
                            }
                            if(this.ticketModel.department){
                                this.usersService.getAllByDepartment(this.authService.getCurInsId(),this.ticketModel.department.id,{ reminderInd: false }).subscribe({
                                    next: response => {
                                        this.assignees = response.body;
                                    }
                                })
                                this.usersService.getAllByDepartment(this.authService.getCurInsId(),this.ticketModel.department.id,{ reminderInd: false, officerType: 'manager' }).subscribe({
                                    next: response => {
                                        this.assigneesManager = response.body;
                                    }
                                })
                                this.usersService.getAllByDepartment(this.authService.getCurInsId(),this.ticketModel.department.id,{ reminderInd: false, officerType: 'head of department' }).subscribe({
                                    next: response => {
                                        this.assigneesHod = response.body;
                                        this.isShowCc = true;
                                    }
                                })
                            }
                            this.vocFollowUpFormGroup = this.formBuilder.group({
                                action: this.formBuilder.control(this.ticketModel.status),
                                detail: this.formBuilder.control(this.ticketModel.resolutionDetail, [Validators.required]),
                                cancelReason: this.formBuilder.control(this.ticketModel.cancelReason),
                                onHoldReason: this.formBuilder.control(this.ticketModel.onHoldReason),
                                onHoldStatus: this.formBuilder.control(this.ticketModel.onHoldStatus)
                            })
                            this.vocChatFormGroup = this.formBuilder.group({
                                detail: this.formBuilder.control(null, [Validators.required]),
                            })
                            if (this.status === 'IN_PROGRESS') {
                                this.vocVerificationFormGroup.disable();
                                this.vocVerificationAssigneeFormGroup.disable();
                            }
                            if (this.status === 'RESOLVED' || this.status === 'RE_OPEN' || this.status === 'CLOSED'  || this.status === 'CANCEL' || this.status === "ON_HOLD") {
                                this.vocVerificationFormGroup.disable();
                                this.vocVerificationAssigneeFormGroup.disable();
                                this.vocFollowUpFormGroup.disable();
                            }
                            if (this.status === 'CLOSED' || this.status === 'RE_OPEN') {
                                this.vocReviewFormGroup.disable();
                            }
                            if (this.ticketModel.customerAttachments?.length > 0) {
                                for (let att of this.ticketModel.customerAttachments) {
                                    this.ticketService.getAttachment(att.attachment).subscribe({
                                        next: response => {
                                            this.createImageFromBlob(response.body, att.actualName, 'customer', att.id);
                                        }
                                    })
                                }
                            }
                            if (this.ticketModel.factFindings?.length > 0) {
                                for (let i in this.ticketModel.factFindings) {
                                    for (let j in this.ticketModel.factFindings[i].factFindingAttachments) {
                                        this.ticketService.getAttachment(this.ticketModel.factFindings[i].factFindingAttachments[j].attachment).subscribe({
                                            next: response => {
                                                this.createImageFromBlobFactFinding(response.body, this.ticketModel.factFindings[i].factFindingAttachments[j].actualName, +i, +j);
                                            }
                                        })

                                    }
                                }
                            }
                            if (this.ticketModel.resolveAttachments?.length > 0) {
                                for (let att of this.ticketModel.resolveAttachments) {
                                    this.ticketService.getAttachment(att.attachment).subscribe({
                                        next: response => {
                                            this.createImageFromBlob(response.body, att.actualName, 'resolve',att.id);
                                        }
                                    })
                                }
                            }
                            this.loading = false;
                            if(this.ticketModel.channelOther?.length > 0){
                                this.channelShow = true;
                            }
                        }

                    });
                } else this.loading = false;
            }
        })

        this.departmentService.getAllNoPagination(true,null,null).subscribe({
            next: value => {
                this.incidentBranchs = value.body
            }
        });
        this.departmentService.getAllNoPagination().subscribe({
            next: value => {
                this.assignDepts = value.body
            }
        });
        this.modelService.getAllModelNoPagination().subscribe({
            next: (value) => (this.models = value.body),
        });
        this.systemConfigurationService.getSystemConfigurationValueByCode(this.channels, this.authService.getCurInsId()).subscribe({
            next: (value) => (this.sysConfigChannel = value.body),
        });
        this.systemConfigurationService.getSystemConfigurationValueByCode(this.satulations, this.authService.getCurInsId()).subscribe({
            next: (value) => (this.sysConfigSal = value.body),
        });
        this.categoryService.getAllByParentIdNoPagination(null,true).subscribe({
            next: response => {
               this.groups = response.body.filter(cat => cat.path.split('|').length === 1);
               this.categories = response.body.filter(cat => cat.path.split('|').length === 2);
               this.types = response.body.filter(cat => cat.path.split('|').length === 3);
           }
        });
        this.departmentService.getAllNoPagination(true).subscribe({
            next: value => {
                this.registeredBranchs = value.body
            }
        });
    }

    changeSelectDepartment(event: any) {
        this.isShowCc = false;
        this.assigneesManager = [];
        this.assigneesHod = [];
        this.assignees = [];
        this.vocVerificationAssigneeFormGroup = this.formBuilder.group({
            assignee: this.formBuilder.control(null, [Validators.required]),
        })

        this.usersService.getAllByDepartment(this.authService.getCurInsId(),event.value.id,{ reminderInd: false, isChampion: true }).subscribe({
            next: response => {
                this.assignees = response.body;
            },
            error: (error) => {
                this.snackBar.open(error.error.message, 'Close', {
                    duration: 60000,
                });
            },
        })

        this.usersService.getAllByDepartment(this.authService.getCurInsId(),event.value.id,{ reminderInd: false, officerType: 'manager' }).subscribe({
            next: response => {
                this.assigneesManager = response.body;
            },
            error: (error) => {
                this.snackBar.open(error.error.message, 'Close', {
                    duration: 60000,
                });
            },
        })

        this.usersService.getAllByDepartment(this.authService.getCurInsId(),event.value.id,{ reminderInd: false, officerType: 'head of department' }).subscribe({
            next: response => {
                this.assigneesHod = response.body;
            },
            error: (error) => {
                this.snackBar.open(error.error.message, 'Close', {
                    duration: 60000,
                });
            },
        })
    }

    changeSelectAssignee() {
        this.isShowCc = true;
    }

    statusValue(value: string): string {
        return this.actionEnums.find(status => status.value === value)?.name;
    }

    getStatusColor(value: string) {
        if (value === 'NEW') return 'status-color-new';
        if (value === 'ASSIGNED') return 'status-color-assigned';
        if (value === 'IN_PROGRESS') return 'status-color-inprogress';
        if (value === 'RESOLVED') return 'status-color-resolved';
        if (value === 'CLOSED') return 'status-color-closed';
        if (value === 'ON_HOLD') return 'status-color-onhold';
        if (value === 'CANCEL') return 'status-color-cancel';
        if (value === 'RE_OPEN') return 'status-color-reopen';
        if (value === 'RESUME') return 'status-color-resume';
        if (value === 'OPEN') return 'status-color-open';
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    changeState() {
        const customerValue = this.customerFormGroup.getRawValue();
        if(customerValue.id){
            this.state++;
            this.customerFormGroup.disable();
            this.setCustomerRegisterNumberAndModel();
        }else{
            this.createCustomer();
        }
    }

    back() {
        window.history.back();
    }

    previousState() {
        if(!this.fromListing)
            this.back();
        else {
            this.router.navigate(['operations/voice-of-customer'], {
                queryParams: {
                  fromTicketDetails: true
                }
            })
        }
    }

    changeOption(event){
        this.channelSelected = event.value;
        if(event.value == 'Others'){
            this.channelShow = true;
            this.ticketFormGroup.get('channelOther').setValidators([Validators.required]);
            this.ticketFormGroup.get('channelOther').updateValueAndValidity()
        }else{
            this.channelShow = false;
            this.ticketFormGroup.get('channelOther').setValue(null);
            this.ticketFormGroup.get('channelOther').clearValidators();
            this.ticketFormGroup.get('channelOther').updateValueAndValidity()
        }
    }

    saveVOC() {
        this.loading = true;
        const customerValue = this.customerFormGroup.getRawValue();
        const ticketValue = this.ticketFormGroup.getRawValue()
        let vocNewToAssign;
        let vocFollowUp;

        if (customerValue.id || this.status !== '') {
            ticketValue.customer = customerValue;
            if (ticketValue.model && ticketValue.registrationNo) {
                ticketValue.vehicle = {
                    model: ticketValue.model,
                    registrationNo: ticketValue.registrationNo
                }
            }
            ticketValue.ticketDatetimeString = ticketValue.ticketDatetimeString + ':00+0800';
            ticketValue.attachments = this.customerAttachments
            if (this.status === "NEW" || this.status === "ASSIGNED" || this.status === "OPEN") {
                vocNewToAssign = this.vocVerificationFormGroup.getRawValue();
                ticketValue.action = "ASSIGNED";
                ticketValue.verificationCallDatetimeString = vocNewToAssign.verificationCallDatetimeString ? vocNewToAssign.verificationCallDatetimeString + ':00+0800' : '';
                ticketValue.verificationDetail = vocNewToAssign.verificationDetail;
                ticketValue.priority = vocNewToAssign.priority;
                ticketValue.classification = vocNewToAssign.classification;
                ticketValue.department = vocNewToAssign.department;
                ticketValue.assignee = this.vocVerificationAssigneeFormGroup.getRawValue().assignee;
            }
            if (this.status === "IN_PROGRESS") {
                vocFollowUp = this.vocFollowUpFormGroup.getRawValue();
                ticketValue.action = vocFollowUp.action;

                if (ticketValue.action === "IN_PROGRESS") {
                    ticketValue.factFindingInput = {
                        detail: vocFollowUp.detail,
                        attachments: this.factFindingAttachments
                    }
                } else {
                    ticketValue.resolutionDetail = vocFollowUp.detail;
                    ticketValue.attachments = this.resolveAttachments;
                }
            }

            if (this.status === "") {
                this.ticketService.save(ticketValue, this.authService.getCurInsId()).subscribe({
                    next: response => {
                        this.ticketModel = response.body;
                        let theMessage = '';
                        theMessage = this.ticketModel.ticketNumber + ' has been successfully created. Current status is ' + this.ticketModel.status;
                        this.snackBar.open(theMessage, 'Close', this.snackBarConfig);
                        this.back();
                    },
                    error: error => {
                        this.loading = false;
                        this.showPopupError(error);
                    }
                })
            } else {
                this.ticketService.update(ticketValue, this.authService.getCurInsId()).subscribe({
                    next: response => {
                        this.ticketModel = response.body;
                        let theMessage = '';
                        theMessage = this.ticketModel.ticketNumber + ' has been successfully updated. Current status is ' + this.ticketModel.status;
                        this.snackBar.open(theMessage, 'Close', this.snackBarConfig);
                        this.back();
                    },
                    error: error => {
                        this.loading = false;
                        this.showPopupError(error);
                    }
                })
            }
        }
    }
    public vocTabIndex;
    createChat() {
        this.loading = true;
        let chat = this.vocChatFormGroup.getRawValue();
        this.ticketService.saveChat(chat, this.ticketModel.id, this.authService.getCurInsId()).subscribe({
            next: result => {
                this.ticketService.getAllChat(this.ticketModel.id, this.authService.getCurInsId()).subscribe({
                    next: chats => {
                        this.chats = chats.body;
                        this.loading = false;
                        this.vocTabIndex = 3;
                        this.vocChatFormGroup.get('detail').setValue(null);
                    }
                });
            }
        })
    }

    createCustomer() {
        const customerForm = this.customerFormGroup.getRawValue();
        if (customerForm.model && customerForm.registrationNo) {
            this.vehicleService.createVehicle({
                model: customerForm.model,
                registrationNo: customerForm.registrationNo
            }, this.authService.getCurInsId()).subscribe({
                next: response => {
                    if (response) {
                        const vehicles = [];
                        vehicles.push(response.body);
                        customerForm.vehicles = vehicles;
                        this.createCustomerRecords(customerForm);
                    }
                },
                error: () => {
                    this.createCustomerRecords(customerForm);
                }
            })
        } else {
            this.createCustomerRecords(customerForm);
        }
    }

    createCustomerRecords(customer: CustomerRecordModel) {
        this.customerRecordService.create(customer, this.authService.getCurInsId()).subscribe({
            next: (value) => {
                if (value.body) {
                    this.customerFormGroup.patchValue({
                        id: value.body.id,
                    })
                    this.state++;
                    this.customerFormGroup.disable();
                    this.setCustomerRegisterNumberAndModel();
                }
            },
            error: (error) => {
                this.showPopupError(error);
            }
        })
    }

    setCustomerRegisterNumberAndModel(){
        this.ticketFormGroup.get('registrationNo').setValue(this.customerFormGroup.get('registrationNo').value);
        this.ticketFormGroup.get('model').setValue(this.customerFormGroup.get('model').value);
    }

    loadMoreCustomers() {
        this.customerPage = this.customerPage + 1;
        this.searchCustomerRequest();
    }

    searchCust() {
        this.customerPage = 0;
        this.currentTotalCustomers = 0;
        this.customerSearchResult = [];
        this.searchCustomerRequest();
    }

    searchCustomerRequest() {
        let ic: string = null;
        let contactNumber: string = null;
        let name: string = null;
        let vehicleNumber: string = null;
        if ('ID' === this.customerFormGroup.get('searchByType').value) {
            ic = this.customerFormGroup.get('searchParameter').value;
        } else if ('CONTACT' === this.customerFormGroup.get('searchByType').value) {
            contactNumber = this.customerFormGroup.get('searchParameter').value;
        } else if ('VEHICLE_NUMBER' === this.customerFormGroup.get('searchByType').value) {
            vehicleNumber = this.customerFormGroup.get('searchParameter').value;
        } else {
            name = this.customerFormGroup.get('searchParameter').value;
        }
        this.customerRecordService.searchCustomers(
            {
                page: this.customerPage,
                size: this.customerSize,
                ic,
                name,
                phone: contactNumber,
                vehicleNumber,
                currentInstitution: this.authService.getCurInsId()
            }
        ).subscribe({
            next: (value) => {
                this.totalCustomers = Number(value.headers.get('X-Total-Count'));
                this.currentTotalCustomers = this.currentTotalCustomers + value.body.length;
                for(const customer of value.body) {
                  this.customerSearchResult.push(customer);
                  if(customer.vehicles?.length > 0) {
                    for(const vehicle of customer.vehicles) {
                      const newCust: CustomerRecordModel = JSON.parse(JSON.stringify(customer));
                      newCust.vehicle = vehicle;

                      this.customerSearchResult.push(newCust);
                    }
                  }
                }
                this.customerSearchResultShow = true;
            },
            error: error => {
                this.showPopupError(error);
            },
        })
    }

    selectCustomer(customer: CustomerRecordModel) {
        this.customerSearchResultShow = false;
        this.customerSearchResult = [];
        this.customerFormGroup.patchValue({
            id: customer.id,
            salutation: customer.salutation,
            firstName: customer.firstName,
            lastName: customer.lastName,
            ic: customer.ic,
            email: customer.email,
            mainPhone: customer.mainPhone,
            homePhone: customer.homePhone,
            officePhone: customer.officePhone,
            model: customer.vehicle ? customer.vehicle.model : null,
            registrationNo: customer.vehicle ? customer.vehicle.registrationNo: null,
            isBuyer: customer.isBuyer,
        });
        this.customerFormGroup.disable();
        this.customerFormGroup.get('searchByType').enable();
        this.customerFormGroup.get('searchParameter').enable();
    }

    changeSelectGroup(event: any) {
        this.categories = [];
        this.types = [];
        this.ticketFormGroup.controls['category'].reset();
        this.ticketFormGroup.controls['type'].reset();
        this.categoryService.getAllByParentIdNoPagination(event.value.id).subscribe({
            next: response => {
                this.categories = response.body;
            }
        })
    }

    changeSelectCategory(event: any) {
        this.categoryService.getAllByParentIdNoPagination(event.value.id).subscribe({
            next: response => {
                this.types = response.body;
            }
        })
    }

    changeStatusAction(toStatus: string) {
        this.loading = true;
        let body;
        if (toStatus === 'CLOSED') {
            const vocReviewFormGroup = this.vocReviewFormGroup.getRawValue();
            body = {
                id: this.ticketModel.id,
                action: toStatus,
                reviewDatetimeString: vocReviewFormGroup.reviewDatetimeString + ':00+0800',
                reviewDetail: vocReviewFormGroup.reviewDetail
            }
        } else {
            body = {
                id: this.ticketModel.id,
                action: toStatus
            }
        }

        this.ticketService.update(body, this.authService.getCurInsId()).subscribe({
            next: () => {
                this.back();
            },
            error: error => {
                this.loading = false;
                this.showPopupError(error);
            }
        })
    }

    openMore() {
        this.isOpenMore = !this.isOpenMore;
    }

    confirmation(status: string) {
        this.isOpenMore = false;
        const vocReviewFormGroup = this.vocReviewFormGroup.getRawValue();
        var modalRef =null;
        if(status==='RE_OPEN'){
            modalRef = this.matDialog.open(VoiceOfCustomerDialogConfirmationComponent, {
                data: {
                    id: this.ticketModel.id,
                    action: status,
                    reviewDatetimeString: vocReviewFormGroup.reviewDatetimeString + ':00+0800',
                    reviewDetail: vocReviewFormGroup.reviewDetail
                },
                panelClass: 'mat-dialog-md'
            })
        }else{
            modalRef = this.matDialog.open(VoiceOfCustomerDialogConfirmationComponent, {
                data: {
                    id: this.ticketModel.id,
                    action: status
                },
                panelClass: 'mat-dialog-md'
            })
        }

        modalRef.afterClosed().subscribe({
            next: (result) => {
                if (result) this.back();
            }
        })
    }

    onFileSelected(event: any, arrayType: string) {
        if (event?.target?.files?.length > 0) {
            for (let file of event?.target?.files) {
                this.createImageFromBlob(file, file.name, arrayType,null);
            }
        }
        event.target.value = null;
    }

    triggerFileUpload(id: string){
        document.getElementById(id).click();
    }

    downloadFile(file: {url: string | {changingThisBreaksApplicationSecurity: string}; name: string; type: string}) {
        if(typeof file.url !== 'string') {
            window.open(file.url.changingThisBreaksApplicationSecurity);
            return;
        }

        const url = URL.createObjectURL(dataURLtoBlob(file.url));
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file.name;
        a.click();
        this.snackBar.open(`File Downloaded: ${file.name}`, 'Close', this.snackBarConfig);
    }

    createImageFromBlob(image: any, name: string, arrayType: string, id: string) {
        let reader = new FileReader(); // for image type (png, jpeg)
        let appUrl = URL.createObjectURL(image); // for application type (pdf)

        if (image) reader.readAsDataURL(image);

        if (arrayType === 'customer') {
            reader.addEventListener("load", () => {
                this.customerAttachmentUrls.push({
                    url: image.type === "application/pdf" ? this.sanitizer.bypassSecurityTrustUrl(appUrl) : reader.result,
                    type: image.type.split("/")[0],
                    name: name,
                    id: id
                });
                if(id===null){
                    this.customerAttachments.push({
                        blobFile: reader.result.toString().split(",")[1],
                        fileName: name
                    })
                }

            }, false);
        } else if (arrayType === 'factFinding') {
            reader.addEventListener("load", () => {
                this.factFindingAttachmentUrls.push({
                    url: image.type === "application/pdf" ? this.sanitizer.bypassSecurityTrustUrl(appUrl) : reader.result,
                    type: image.type.split("/")[0],
                    name: name
                });
                this.factFindingAttachments.push({
                    blobFile: reader.result.toString().split(",")[1],
                    fileName: name
                })
            }, false);
        } else {
            reader.addEventListener("load", () => {
                this.resolveAttachmentUrls.push({
                    url: image.type === "application/pdf" ? this.sanitizer.bypassSecurityTrustUrl(appUrl) : reader.result,
                    type: image.type.split("/")[0],
                    name: name
                });
                this.resolveAttachments.push({
                    blobFile: reader.result.toString().split(",")[1],
                    fileName: name
                })
            }, false);
        }
    }

    removeImage(i: any, arrayType:string) {
        if (arrayType === 'customer') {
            this.customerAttachments.splice(i, 1);
            this.customerAttachmentUrls.splice(i, 1);
        }else if (arrayType === 'factFinding') {
            this.factFindingAttachments.splice(i, 1);
            this.factFindingAttachmentUrls.splice(i, 1);
        }else{
            this.resolveAttachments.splice(i, 1);
            this.resolveAttachmentUrls.splice(i, 1);
        }
    }

    createImageFromBlobFactFinding(image: any, name: string, indexI: number, indexJ: number) {
        let reader = new FileReader(); // for image type (png, jpeg)
        let appUrl = URL.createObjectURL(image); // for application type (pdf)

        if (image) reader.readAsDataURL(image);
        reader.addEventListener("load", () => {
            this.ticketModel.factFindings[indexI].factFindingAttachments[indexJ] = {
                ...this.ticketModel.factFindings[indexI].factFindingAttachments[indexJ],
                url: image.type === "application/pdf" ? this.sanitizer.bypassSecurityTrustUrl(appUrl) : reader.result,
                type: image.type.split("/")[0],
                name: name
            };
        }, false);
    }

    previewImage(url: any) {
        if (url.type === "image") {
            this.matDialog.open(VoiceOfCustomerDialogPreviewImageComponent, {
                data: url,
                panelClass: 'mat-dialog-xl'
            })
        }
    }

    refreshForm(){
        this.customerFormGroup.enable();
        this.customerFormGroup = this.formBuilder.group({
            searchByType: this.formBuilder.control(null),
            searchParameter: this.formBuilder.control(null),
            id: this.formBuilder.control(null),
            salutation: this.formBuilder.control(null),
            firstName: this.formBuilder.control(null, [Validators.required]),
            lastName: this.formBuilder.control(null),
            ic: this.formBuilder.control(null, [Validators.required]),
            email: this.formBuilder.control(null),
            isBuyer: this.formBuilder.control(true),
            mainPhone: this.formBuilder.control(null, [Validators.required]),
            homePhone: this.formBuilder.control(null),
            officePhone: this.formBuilder.control(null),
            model: this.formBuilder.control(null),
            registrationNo: this.formBuilder.control(null),
            remarks: this.formBuilder.control(null),
        });
    }

    checkBuyer(event:boolean){
        if(event){
            this.customerFormGroup.get('model').enable();
            this.customerFormGroup.get('registrationNo').enable();
        }else{
            this.customerFormGroup.get('model').disable();
            this.customerFormGroup.get('model').setValue(null);
            this.customerFormGroup.get('registrationNo').disable();
            this.customerFormGroup.get('model').setValue(null);
        }
    }

    toggleEnableSearchCustomer(param: string) {
        if(param) {
            this.disableSearchCustomer = false;
        }
        else{
            this.disableSearchCustomer = true;
        }
    }

    showPopupError(error: any) {
        let theMessage  = '';
        error.error.subErrors?.forEach(subError => {
            theMessage += subError.message + ' \n ';
        });
        if(theMessage){
            this.snackBar.open(theMessage, 'Close', this.snackBarConfig);
        }else{
            this.snackBar.open(error?.error?.message, 'Close', this.snackBarConfig);
        }
    }

    modification() {
        if(this.ticketModel) {
            const modalRef = this.matDialog.open(VoiceOfCustomerDialogModificationComponent, {
                data: this.ticketModel,
                panelClass: 'mat-dialog-lg'
            })

            modalRef.afterClosed().subscribe({
                next: (yes) => {
                    if (yes) {
                        this.loading = true;
                        this.route.queryParams.subscribe({
                            next: response => {
                                if (response["id"]) {
                                    this.ticketService.getTicketById(response["id"], this.authService.getCurInsId()).subscribe({
                                        next: ticket => {
                                            this.ticketModel = ticket.body;
                                            this.isShowing = false;
                                            this.ticketFormGroup = this.formBuilder.group({
                                                id: this.formBuilder.control(this.ticketModel.id),
                                                action: this.formBuilder.control(this.ticketModel.status),
                                                group: this.formBuilder.control(this.ticketModel.group, [Validators.required]),
                                                category: this.formBuilder.control(this.ticketModel.category, [Validators.required]),
                                                type: this.formBuilder.control(this.ticketModel.type, [Validators.required]),
                                                title: this.formBuilder.control(this.ticketModel.title, [Validators.required]),
                                                detail: this.formBuilder.control(this.ticketModel.detail, [Validators.required]),
                                                incidentBranch: this.formBuilder.control(this.ticketModel.incidentBranch, [Validators.required]),
                                                channel: this.formBuilder.control(this.ticketModel.channel, [Validators.required]),
                                                serviceDeliveryDate: this.formBuilder.control(this.ticketModel.serviceDeliveryDate, [Validators.required]),
                                                ticketDatetimeString: this.formBuilder.control(this.ticketModel.ticketDatetime?.substring(0, 16), [Validators.required]),
                                                channelOther: this.formBuilder.control(this.ticketModel.channelOther),
                                                model: this.formBuilder.control(this.ticketModel.vehicle?.model),
                                                registrationNo: this.formBuilder.control(this.ticketModel.vehicle?.registrationNo),
                                            });
                                            this.ticketFormGroup.disable();
                                            this.loading = false;
                                        }

                                    });
                                } else this.loading = false;
                            }
                        })
                    }
                }
            })
        }
    }

    @HostListener('window:resize')
        onResize() {
            this.windowWidth = window.innerWidth;
    }
}
