<div class="container-fluid nbt-container">
    <div>
        <p>Thank you for your feedback. Drive Safe and see you again!</p>
        <p>Terima Kasih untuk maklum balas anda. Pandu selamat dan semoga berjumpa lagi!</p>
    </div>
    <div class="nbt-dialog-footer" style="border:none; padding:0px !important;">
        <button  mat-dialog-close cdkFocusInitial class="nbt-btn-secondary">
            Okay
        </button>
    </div>
</div>

