<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        View Business Hour '{{businessHourModel.name}}'
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <div class="nbt-details row">
        <div class="col-md-6">
            <label>Name</label>
            <div class="value" maxlength="100">{{businessHourModel.name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Description</label>
            <div class="value">{{businessHourModel.description || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Institution</label>
            <div class="value">{{this.authService.getCurIns().name || '-'}}</div>
        </div>
        <div class="col-md-12" *ngIf="businessHourModel.businessHourDetails.length > 0">
            <label>Business Hour Details</label>
            <br>
            <br>
            <div class="value" *ngFor="let item of businessHourModel.businessHourDetails">
                <b>{{item.day}}: </b> From {{convertTime(item.startTime)}} To {{convertTime(item.endTime)}}
            </div>
        </div>
    </div>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Close
    </button>
    <button type="button" *ngIf="authService.hasAuthorityMenu(UPDATE_BUSINESS_HOUR)" class="nbt-btn-primary ml8" (click)="dialogRef.close('edit')">
        <mat-icon class="mr8">edit</mat-icon>
        Edit
    </button>
</div>