import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { ApplicationRoleModel } from 'src/app/model/application-role.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { ApplicationRolesService } from 'src/app/services/application-roles.service';
import { DialogSimpleComponent } from 'src/app/shared/dialog-simple/dialog-simple.component';
import { UserRolesDetailComponent } from '../user-roles-detail/user-roles-detail.component';
import { UserRolesNewOrEditComponent } from '../user-roles-new-or-edit/user-roles-new-or-edit.component';

@Component({
    selector: 'app-user-roles-listing',
    templateUrl: './user-roles-listing.component.html',
    styleUrls: ['./user-roles-listing.component.scss']
})
export class UserRolesListingComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns: string[];
    dataSource: MatTableDataSource<ApplicationRoleModel>;
    obs: BehaviorSubject<ApplicationRoleModel[]>;

    searchFormGroup: FormGroup;
    institutions: InstitutionModel[];
    RoleFormGroup: FormGroup;

    pageNumbers: number[];
    numberOfRecords: number;
    countLoad = 0;
    currentPage: number;
    pageSize: number;
    sortOrder: string;

    readonly CREATE_APPLICATION_ROLE = ApplicationPermissionsService.CREATE_APPLICATION_ROLE;
    readonly UPDATE_APPLICATION_ROLE = ApplicationPermissionsService.UPDATE_APPLICATION_ROLE;
    readonly DELETE_APPLICATION_ROLE = ApplicationPermissionsService.DELETE_APPLICATION_ROLE;

    constructor(
        private applicationRolesService: ApplicationRolesService,
        private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private institutionsService: InstitutionsService,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar
    ) {
        this.dataSource = new MatTableDataSource<ApplicationRoleModel>();
        this.dataSource.paginator = this.paginator;

        this.pageNumbers = [];
        this.numberOfRecords = 0;
        this.currentPage = 0;
        this.pageSize = 20;
        this.sortOrder = 'name,asc';
        this.countLoad = 0;
    }

    ngOnInit(): void {
        this.displayColumns = [
            'name',
            'institution',
            'active',
            'actions'
        ];
        this.institutionsService.getAll().subscribe({
            next: (value) => (this.institutions = value.body),
        });

        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.currentPage = this.activatedRoute.snapshot.queryParams['page']
                ? this.activatedRoute.snapshot.queryParams['page']
                : undefined;
            this.sortOrder = this.activatedRoute.snapshot.queryParams['sort']
                ? this.activatedRoute.snapshot.queryParams['sort']
                : undefined;
            this.pageSize = this.activatedRoute.snapshot.queryParams['pageSize']
                ? this.activatedRoute.snapshot.queryParams['pageSize']
                : undefined;
        }
        this.createForm();
        this.refreshForm();
    }

    private createForm() {
        let name: string;
        let institution: string;

        if (this.activatedRoute.snapshot.queryParams['page']) {
            name = this.activatedRoute.snapshot.queryParams['name']
                ? this.activatedRoute.snapshot.queryParams['name']
                : null;
            institution = this.activatedRoute.snapshot.queryParams['institutions']
                ? this.activatedRoute.snapshot.queryParams['institutions']
                : null;
        }

        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(name ? name : null),
            searchInstitution: this.formBuilder.control(
                institution ? institution : null
            ),
        });
    }

    search(currentPage: number) {
        let name: string;
        let institution: string;

        name = this.searchFormGroup.controls.searchName.value
            ? this.searchFormGroup.controls.searchName.value
            : null;

        institution = this.searchFormGroup.controls.searchInstitution.value
            ? this.searchFormGroup.controls.searchInstitution.value
            : null;

        this.applicationRolesService
            .getAllbyInstitution(this.currentPage, this.pageSize, this.sortOrder, {
                name: name,
                institution: institution,
                currentInstitution: this.authService.getCurInsId()
            })
            .subscribe({
                next: (value) => {
                    this.dataSource.data = value.body;
                    this.numberOfRecords = Number(value.headers.get('X-Total-Count'));
                },
                error: (value) => {
                    this.snackBar.open(value.error.message, 'Close', {
                        duration: 60000
                    });
                },
            });
    }

    refreshForm() {
        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(null),
            searchInstitution: this.formBuilder.control(null),
            searchCreatedDate: this.formBuilder.control(null),
            searchUpdatedDate: this.formBuilder.control(null),
        });
        this.search(0);
    }

    getTimeString(dateToChange: Date): string {
        let newDate: Date = new Date(dateToChange.getTime() + 8 * 60 * 60 * 1000);
        return newDate.toISOString();
    }

    ngOnDestroy(): void {
        if (this.dataSource) {
            this.dataSource.disconnect();
        }
    }

    createRole(): void {
        this.router
            .navigate(['create'], {
                relativeTo: this.activatedRoute,
            })
            .then();
    }

    editRole(applicationRoleModel: ApplicationRoleModel): void {
        this.router
            .navigate(['edit'], {
                relativeTo: this.activatedRoute,
                state: {
                    applicationRoleModel,
                },
            })
            .then();
    }

    openUserRolesDialogDelete(applicationRoleModel: ApplicationRoleModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete User Role?',
                message: `Are you sure want to delete ${applicationRoleModel.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.applicationRolesService.delete(applicationRoleModel.id.toString(), this.authService.getCurInsId()).subscribe({
                        next: () => {
                            this.search(0);
                        },
                        error: (value) => {
                            this.snackBar.open(value.error.message, 'Close', {
                                duration: 60000
                              });
                        },
                    });
                }
            }
        })
    }

    sortPage(event): void {
        this.sortOrder = `${event.active},${event.direction}`;
        this.search(this.currentPage);
    }

    navigateToPage(event): void {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        this.search(this.currentPage);
    }

    openUserRolesDialogCreateOrEdit(data?: ApplicationRoleModel) {
        const modalRef = this.matDialog.open(UserRolesNewOrEditComponent, {
            data: data,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(0);
            }
        })
    }

    openUserRolesDialogDetail(data?: ApplicationRoleModel) {
        const modalRef = this.matDialog.open(UserRolesDetailComponent, {
            data: data,
            panelClass: 'mat-dialog-md'
        })

        modalRef.afterClosed().subscribe({
            next: (edit) => {
                if (edit) this.openUserRolesDialogCreateOrEdit(data);
            }
        })
    }
}
