import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FactFindingModel } from '../model/fact-finding.model';
import { TicketModel } from '../model/ticket.model';

@Injectable({
    providedIn: 'root'
})
export class TicketService {

    ticketValue: TicketModel;
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
    ) { }

    save(
        model: TicketModel,
        curIns: string
    ): Observable<HttpResponse<TicketModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<TicketModel>(`/web/ticket`, model, {
            observe: 'response',
            params: httpParams
        });
    }

    saveChat(
        factFindingDTO: FactFindingModel,
        ticketId: string,
        curIns: string
    ): Observable<HttpResponse<FactFindingModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('ticketId', ticketId);
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<FactFindingModel>('/web/ticket/chat', factFindingDTO, {
            observe: 'response',
            params: httpParams
        });
    }

    getAllChat(id: string, curIns: string): Observable<HttpResponse<FactFindingModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('id', id);
        return this.httpClient.get<FactFindingModel[]>('/web/ticket/chat', {
            observe: 'response',
            params: httpParams,
        });
    }

    update(
        model: any,
        curIns: string
    ): Observable<HttpResponse<TicketModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<TicketModel>(`/web/ticket`, model, {
            observe: 'response',
            params: httpParams
        });
    }

    getAll(request: any): Observable<HttpResponse<TicketModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('page', request.page);
        if (request.sort) httpParams = httpParams.set('sort', request.sort);
        if (request.size) httpParams = httpParams.set('size', request.size);
        if (request.curIns) httpParams = httpParams.set('curIns', request.curIns);
        if (request.ticketNumber) httpParams = httpParams.set('ticketNumber', request.ticketNumber);
        if (request.groupName) httpParams = httpParams.set('groupName', request.groupName);
        if (request.categoryName) httpParams = httpParams.set('categoryName', request.categoryName);
        if (request.typeName) httpParams = httpParams.set('typeName', request.typeName);
        if (request.assigneeName) httpParams = httpParams.set('assigneeName', request.assigneeName);
        if (request.departmentName) httpParams = httpParams.set('departmentName', request.departmentName);
        if (request.customerName) httpParams = httpParams.set('customerName', request.customerName);
        if (request.includeDeactivate) httpParams = httpParams.set('includeDeactivate', request.includeDeactivate);
        if (request.vehicleRegNumber) httpParams = httpParams.set('vehicleRegNumber', request.vehicleRegNumber);
        if (request.status && request.status !== 'all') httpParams = httpParams.set('status', request.status);
        if (request.overdue && request.overdue !== '') httpParams = httpParams.set('overdue', request.overdue);
        return this.httpClient.get<TicketModel[]>(`/web/ticket/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getTicketById(id: string, curIns: string): Observable<HttpResponse<TicketModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('id', id);
        return this.httpClient.get<TicketModel>(`/web/ticket`, {
            observe: 'response',
            params: httpParams
        });
    }

    getAttachment(fileName: string ): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        httpParams = httpParams.set('fileName', fileName);
        return this.httpClient.get<any>(`/web/ticket/attachment`, {
            responseType: 'blob' as 'json',
            params: httpParams,
            observe: 'response'
        });
    }

    modificationUpdate(
        model: TicketModel,
        curIns: string
    ): Observable<HttpResponse<TicketModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<TicketModel>(`/web/ticket/modification-update`, model, {
            observe: 'response',
            params: httpParams
      });
    }
}
