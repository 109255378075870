
<div class="d-flex align-items-center justify-content-center">
    <div class="nbt-dashboard-no-padding">
        <div class="d-flex justify-content-center nbt-dashboard-personal-task-container">
            <div class="personal-task-square-box personal-task-assigned" (click)="goToVocListing('ASSIGNED')">
                <h1 class="nbt-dashboard-no-margin">{{assigned ? assigned.count : "-"}}</h1>
                <p class="personal-task-desc">Assigned</p>
            </div>
            <div class="personal-task-square-box personal-task-in-progress" (click)="goToVocListing('IN_PROGRESS')">
                <h1 class="nbt-dashboard-no-margin">{{inProgress ? inProgress.count : "-"}}</h1>
                <p class="personal-task-desc">In Progress</p>
            </div>
            <div class="personal-task-square-box personal-task-response-overdue" (click)="goToVocListing('RESPONSE_OVERDUE')">
                <h1 class="nbt-dashboard-no-margin">{{responseOverdue ? responseOverdue.count : "-"}}</h1>
                <p class="personal-task-desc">Assigned<br> Response Overdue</p>
            </div>
            <div class="personal-task-square-box personal-task-resolution-overdue" (click)="goToVocListing('RESOLUTION_OVERDUE')">
                <h1 class="nbt-dashboard-no-margin">{{resolutionOverdue ? resolutionOverdue.count : "-"}}
                </h1>
                <p class="personal-task-desc">Assigned<br> Resolution Overdue</p>
            </div>
            <div class="personal-task-square-box personal-task-on-hold" (click)="goToVocListing('ON_HOLD')">
                <h1 class="nbt-dashboard-no-margin">{{onHold ? onHold.count : "-"}}</h1>
                <p class="personal-task-desc">On-Hold</p>
            </div>
            <ng-container *ngIf="defaultDepartment">
                <div class="personal-task-square-box personal-task-new" (click)="goToVocListing('NEW')">
                    <h1 class="nbt-dashboard-no-margin">{{new ? new.count : "-"}}</h1>
                    <p class="personal-task-desc">New</p>
                </div>
                <div class="personal-task-square-box personal-task-open" (click)="goToVocListing('OPEN')">
                    <h1 class="nbt-dashboard-no-margin">{{open ? open.count : "-"}}</h1>
                    <p class="personal-task-desc">Open</p>
                </div>
                <div class="personal-task-square-box personal-task-response-overdue" (click)="goToVocListing('DEFAULT_DEPARTMENT_RESPONSE_OVERDUE')">
                    <h1 class="nbt-dashboard-no-margin">{{defaultDepartmentResponseOverdue ? defaultDepartmentResponseOverdue.count : "-"}}</h1>
                    <p class="personal-task-desc">Help-Desk<br> Response Overdue</p>
                </div>
                <div class="personal-task-square-box personal-task-resolution-overdue" (click)="goToVocListing('DEFAULT_DEPARTMENT_RESOLUTION_OVERDUE')">
                    <h1 class="nbt-dashboard-no-margin">{{defaultDepartmentResolutionOverdue ? defaultDepartmentResolutionOverdue.count : "-"}}
                    </h1>
                    <p class="personal-task-desc">Help-Desk<br> Resolution Overdue</p>
                </div>
            </ng-container>
            
            
        </div>
    </div>
    <div class="nbt-dashboard-vertical-divider"></div>
    <div class="nbt-dashboard-no-padding" style="flex-shrink: 0.5;">
        <div class="d-flex justify-content-center nbt-dashboard-personal-task-container">
            <div class="personal-task-square-box personal-task-resolved-today">
                <h1 class="nbt-dashboard-no-margin">{{resolved ? resolved.count : "-"}}</h1>
                <p class="personal-task-desc">Resolved Today</p>
            </div>
            <div class="personal-task-square-box personal-task-closed-today">
                <h1 class="nbt-dashboard-no-margin">{{closed ? closed.count : "-"}}</h1>
                <p class="personal-task-desc">Closed Today</p>
            </div>
            <div class="personal-task-square-box personal-task-reopened-today">
                <h1 class="nbt-dashboard-no-margin">{{reOpen ? reOpen.count : "-"}}</h1>
                <p class="personal-task-desc">Re-opened Today</p>
            </div>
        </div>
    </div>
</div>

