import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { HolidayModel } from 'src/app/model/holiday.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { HolidayService } from 'src/app/services/holiday.service';
import { DialogSimpleComponent } from 'src/app/shared/dialog-simple/dialog-simple.component';
import { HolidayDialogDetailComponent } from '../holiday-dialog-detail/holiday-dialog-detail.component';
import { HolidayDialogNewOrEditComponent } from '../holiday-dialog-new-or-edit/holiday-dialog-new-or-edit.component';
import { CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarView } from 'angular-calendar';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-holiday-listing',
    templateUrl: './holiday-listing.component.html',
    styleUrls: ['./holiday-listing.component.scss']
})
export class HolidayListingComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    windowWidth: number = window.innerWidth;
    displayColumns: string[];
    dataSource: MatTableDataSource<HolidayModel>;
    obs: BehaviorSubject<HolidayModel[]>;
    loading: boolean = false;
    loadingTable: boolean = true;
    readonly CREATE_HOLIDAY = ApplicationPermissionsService.CREATE_HOLIDAY;
    readonly UPDATE_HOLIDAY = ApplicationPermissionsService.UPDATE_HOLIDAY;
    readonly DELETE_HOLIDAY = ApplicationPermissionsService.DELETE_HOLIDAY;

    searchFormGroup: FormGroup;
    institutions: InstitutionModel[];
    HolidayFormGroup: FormGroup;

    pageNumbers: number[];
    numberOfRecords: number;
    countLoad = 0;
    currentPage: number;
    pageSize: number;
    sortOrder: string;
    viewDate: Date = new Date();
    events: CalendarEvent[] = [];
    refreshCalendar: Subject<void> = new Subject();
    clickedColumn: number;
    view: CalendarView = CalendarView.Month;
    inputSearchDate: any;

    constructor(
        private holidayService: HolidayService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private institutionsService: InstitutionsService,
        private authService: AuthService,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private datePipe: DatePipe,
    ) {
        this.dataSource = new MatTableDataSource<HolidayModel>();
        this.dataSource.paginator = this.paginator;

        this.pageNumbers = [];
        this.numberOfRecords = 0;
        this.currentPage = 0;
        this.pageSize = 20;
        this.sortOrder = 'name,asc';
        this.countLoad = 0;
    }

    ngOnInit(): void {
        this.inputSearchDate = this.datePipe.transform(new Date(), 'yyyy-MM', '+800')
        this.displayColumns = [
            'date',
            'name',
            'actions'
        ];
        this.institutionsService.getAll().subscribe({
            next: (value) => (this.institutions = value.body),
        });

        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.currentPage = this.activatedRoute.snapshot.queryParams['page'] ? this.activatedRoute.snapshot.queryParams['page'] : undefined;
            this.sortOrder = this.activatedRoute.snapshot.queryParams['sort'] ? this.activatedRoute.snapshot.queryParams['sort'] : undefined;
            this.pageSize = this.activatedRoute.snapshot.queryParams['pageSize'] ? this.activatedRoute.snapshot.queryParams['pageSize'] : undefined;
        }
    }

    sortPage(event): void {
        this.sortOrder = `${event.active},${event.direction}`;
    }

    navigateToPage(event): void {
        this.currentPage = event.pageIndex;
    }

    openHolidayDialogCreateOrEdit(holidayModel?: HolidayModel) {
        const modalRef = this.matDialog.open(HolidayDialogNewOrEditComponent, {
            data: holidayModel,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.loading = true;
                    setTimeout(() => {
                        this.loading = false;
                    }, 1);
                }
            }
        })
    }

    openHolidayDialogDetail(holidayModel?: HolidayModel) {
        const modalRef = this.matDialog.open(HolidayDialogDetailComponent, {
            data: holidayModel,
            panelClass: 'mat-dialog-md'
        })

        modalRef.afterClosed().subscribe({
            next: (type) => {
                if (type === 'edit') this.openHolidayDialogCreateOrEdit(holidayModel);
                else if (type === 'ok') {
                    this.loading = true;
                    setTimeout(() => {
                        this.loading = false;
                    }, 1);
                }
            }
        })
    }

    changeDate(evt) {
        this.viewDate = new Date(evt.target.value);
    }

    beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
        const firstDateOfMonth = this.datePipe.transform(new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1), 'yyyy-MM-dd', '+800')
        const lastDateOfMonth = this.datePipe.transform(new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0), 'yyyy-MM-dd', '+800')
        this.loadingTable = true;
        this.holidayService.getAll(
            this.authService.getCurInsId(),
            firstDateOfMonth,
            lastDateOfMonth
        ).subscribe({
            next: (value) => {
                this.dataSource.data = value.body.filter(f => {
                    return renderEvent.body.find(r => r.inMonth && this.datePipe.transform(r.date, 'MM-dd', '+800') === this.datePipe.transform(f.date, 'MM-dd', '+800'));
                }).sort((a, b) => a.date > b.date ? 1 : -1);
                renderEvent.body.forEach((day) => {
                    if (day.inMonth) {
                        const dateCalendar = this.datePipe.transform(day.date, 'MM-dd', '+800');
                        const matchDate = value.body.find(d => this.datePipe.transform(d.date, 'MM-dd', '+800') === dateCalendar);
                        if (matchDate) {
                            if (matchDate.recurrent) day.cssClass = 'bg-light-yellow';
                            else day.cssClass = 'bg-light-blue';
                        }
                    }
                })
            }
        })
    }

    dayChoosed(day: any) {
        const selectedDay = this.datePipe.transform(day, 'yyyy-MM-dd', '+800');
        const data = this.dataSource.data.find(d => d.date === selectedDay);
        if (data) {
            this.openHolidayDialogDetail(data);
        } else {
            const holidayModel = new HolidayModel();
            holidayModel.date = selectedDay;
            this.openHolidayDialogCreateOrEdit(holidayModel);
        }
    }

    closeOpenMonthViewDay() {
        this.inputSearchDate = this.datePipe.transform(this.viewDate, 'yyyy-MM', '+800')
    }

    getHolidayMonthApi(from: string, to: string) {
        this.holidayService.getAll(
            this.authService.getCurInsId(),
            from,
            to
        )
    }

    openHolidayDialogDelete(holidayModel?: HolidayModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete Holiday?',
                message: `Are you sure want to delete ${holidayModel.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.holidayService.delete(
                        holidayModel.id.toString(),
                        this.datePipe.transform(holidayModel.date, 'yyyy-MM-dd', '+800'),
                        this.authService.getCurInsId()
                    ).subscribe({
                        next: (value) => {
                            this.loading = true;
                            setTimeout(() => {
                                this.loading = false;
                            }, 1);
                        },
                        error: (error) => {
                            console.error(error)
                        }
                    });
                }
            }
        })
    }

    @HostListener('window:resize')
      onResize() {
          this.windowWidth = window.innerWidth;
    }
}
