import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { BusinessHourModel } from 'src/app/model/business-hours.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';

@Component({
    selector: 'app-business-hours-dialog-detail',
    templateUrl: './business-hours-dialog-detail.component.html',
    styleUrls: ['./business-hours-dialog-detail.component.scss']
})
export class BusinessHoursDialogDetailComponent implements OnInit {

    readonly UPDATE_BUSINESS_HOUR = ApplicationPermissionsService.UPDATE_BUSINESS_HOUR;

    constructor(
        @Inject(MAT_DIALOG_DATA) public businessHourModel: BusinessHourModel,
        public dialogRef: MatDialogRef<BusinessHoursDialogDetailComponent>,
        public authService: AuthService,
    ) { }

    ngOnInit(): void {
    }

    convertTime(time: string) {
        const hour = +time.slice(0, 2);
        if (hour > 12) return `${hour-12}${time.slice(2, 5)} PM`;
        else return `${hour}${time.slice(2, 5)} AM`;
    }
}
