import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationRoleModel } from '../model/application-role.model';
import { BusinessHourModel } from '../model/business-hours.model';

@Injectable({
    providedIn: 'root'
})
export class BusinessHoursService {

    constructor(
        private httpClient: HttpClient
    ) { }

    getAllbyInstitution(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        criteria
    ): Observable<HttpResponse<BusinessHourModel[]>> {
        let httpParams = new HttpParams();
        if (pageNumber) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder) {
            httpParams = httpParams.set('sort', sortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('name', criteria.name);
            }
            if (criteria.institution) {
                httpParams = httpParams.set('institutionId', criteria.institution);
            }
            if (criteria.currentInstitution) {
                httpParams = httpParams.set('curIns', criteria.currentInstitution);
            }
        }

        return this.httpClient.get<BusinessHourModel[]>(`/web/business-hour/all`, { observe: 'response', params: httpParams });
    }

    save(
        model: BusinessHourModel,
        curIns: string
    ): Observable<HttpResponse<BusinessHourModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<BusinessHourModel>(`/web/business-hour`, model, { observe: 'response', params: httpParams });
    }

    public update(
        model: BusinessHourModel,
        curIns: string
    ): Observable<HttpResponse<BusinessHourModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<BusinessHourModel>(`/web/business-hour`, model, { observe: 'response', params: httpParams });
    }

    delete(
        id: string,
        curIns: string
    ): Observable<HttpResponse<BusinessHourModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('id', id);
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.delete<BusinessHourModel[]>('/web/business-hour', { observe: 'response', params: httpParams });
    }

    getAll(curIns: string): Observable<HttpResponse<BusinessHourModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.get<BusinessHourModel[]>(`/web/business-hour/all/no-pagination`, {
          observe: 'response',
          params: httpParams
        });
      }
}
