import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class FilterService {
  public filterInput: {
    ticketNumber: string;
    groupName: string;
    customerName: string;
    departmentName: string;
    categoryName: string;
    vehicleRegNumber: string;
    assigneeName: string;
    typeName: string;
    includeDeactivate: string;
    status: string;
    overdue: string;
  } = {
    ticketNumber: null,
    groupName: null,
    customerName: null,
    departmentName: null,
    categoryName: null,
    vehicleRegNumber: null,
    assigneeName: null,
    typeName: null,
    includeDeactivate: null,
    status: 'all',
    overdue: '',
  };

  public paginationPage: number;

  constructor() {}
}
