<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        View System Config '{{systemConfigurationModel.code}}'
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <div class="nbt-details row">
        <div class="col-md-6">
            <label>Code</label>
            <div class="value" maxlength="100">{{systemConfigurationModel.code || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Institution</label>
            <div class="value" >{{systemConfigurationModel.institution?.name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Description</label>
            <div class="value" maxlength="255">{{systemConfigurationModel.description || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Value</label>
            <div class="value" maxlength="255">{{systemConfigurationModel.value || '-'}}</div>
        </div>
    </div>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Close
    </button>
    <button type="button" *ngIf="authService.hasAuthorityMenu(UPDATE_SYS_CONFIG)" class="nbt-btn-primary ml8" (click)="dialogRef.close('edit')">
        <mat-icon class="mr8">edit</mat-icon>
        Edit
    </button>
</div>