<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        View Department '{{departmentModel.name}}'
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <div class="nbt-details row">
        <div class="col-md-4">
            <label>Name</label>
            <div class="value" maxlength="100">{{departmentModel.name || '-'}}</div>
        </div>
        <div class="col-md-8 label-flex">
            <div>
                <label>Active</label>
            <div class="value">{{departmentModel.active ? 'Active' : 'Not Active'}}</div>
            </div>
            <div class="">
                <label>Branch</label>
                <div class="value">{{departmentModel.isBranch ? 'Branch' : 'Not Branch'}}</div>
            </div>
            <div class="">
                <label>Service Center</label>
                <div class="value">{{departmentModel.isServiceCentre ? 'Service Center' : 'Not Service Center'}}</div>
            </div>
            <div class="">
                <label>Showroom</label>
                <div class="value">{{departmentModel.isShowRoom ? 'Showroom' : 'Not Showroom'}}</div>
            </div>
        </div>
        
        <div class="col-md-12">
            <label>Description</label>
            <div class="value" maxlength="255">{{departmentModel.description || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Email</label>
            <div class="value">{{departmentModel.email || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Parent Department</label>
            <div class="value">{{departmentModel.parentDepartment?.name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>SLA Policy</label>
            <ng-container *ngIf="departmentModel.slaHeaders.length > 0; else elseNoSla">
                <div class="value" *ngFor="let sla of departmentModel.slaHeaders">{{sla.name}}</div>
            </ng-container>
            <ng-template #elseNoSla>
                <div class="value">-</div>
            </ng-template>
        </div>
        <div class="col-md-6">
            <label>Business Hour</label>
            <ng-container *ngIf="departmentModel.businessHourHeaders.length > 0; else elseNoBusinessHour">
                <div class="value" *ngFor="let bu of departmentModel.businessHourHeaders">{{bu.name}}</div>
            </ng-container>
            <ng-template #elseNoBusinessHour>
                <div class="value">-</div>
            </ng-template>
        </div>
        <div class="col-md-6">
            <label>SSI / CSI Version</label>
            <div class="value">{{departmentModel.surveyForm?.name || '-'}}</div>
        </div>
        <br><hr><br>
        <label>Reminders</label>
        <br>
        <br>
        <ng-container *ngIf="departmentModel.reminders.length > 0; else elseNoBusinessHour">
            <ng-container *ngFor="let reminder of departmentModel.reminders">
                <ng-container *ngIf="!reminder.isEscalation">
                    <div class="col-md-3">
                        <label>Priority</label>
                        <div class="value">{{reminder.priority}}</div>
                    </div>
                    <div class="col-md-3">
                        <label>Type</label>
                        <div class="value">{{reminder.type}}</div>
                    </div>
                    <div class="col-md-3">
                        <label>Trigger Time</label>
                        <div class="value">{{mapTriggerTime(reminder.triggerTime, reminder.type)}}</div>
                    </div>
                    <div class="col-md-3">
                        <label>To</label>
                        <ng-container *ngIf="reminder.applicationUsers?.length > 0; else elseNoBusinessHour">
                            <ul class="value">
                                <li *ngFor="let user of reminder.applicationUsers">{{user.username}}</li>
                            </ul>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <br><hr><br>
        <label>Escalations</label>
        <br>
        <br>
        <ng-container *ngIf="departmentModel.reminders.length > 0; else elseNoBusinessHour">
            <ng-container *ngFor="let reminder of departmentModel.reminders">
                <ng-container *ngIf="reminder.isEscalation">
                    <div class="col-md-3">
                        <label>Priority</label>
                        <div class="value">{{reminder.priority}}</div>
                    </div>
                    <div class="col-md-3">
                        <label>Type</label>
                        <div class="value">{{reminder.type}}</div>
                    </div>
                    <div class="col-md-3">
                        <label>Trigger Time</label>
                        <div class="value">{{mapTriggerTime(reminder.triggerTime, reminder.type)}}</div>
                    </div>
                    <div class="col-md-3">
                        <label>To</label>
                        <ng-container *ngIf="reminder.applicationUsers?.length > 0; else elseNoBusinessHour">
                            <ul class="value">
                                <li *ngFor="let user of reminder.applicationUsers">{{user.username}}</li>
                            </ul>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <br><hr><br>
        <label>Autogen Report</label>
        <br>
        <br>
        <ng-container *ngIf="departmentModel.autoGenerationReports.length > 0; else elseNoBusinessHour">
            <ng-container *ngFor="let report of departmentModel.autoGenerationReports">
                <div class="col-md-5">
                    <label>Type</label>
                    <div class="value">{{report.type}}</div>
                </div>
                <div class="col-md-4">
                    <label>Trigger time</label>
                    <div class="value">{{report.triggerTime}}</div>
                </div>
                <div class="col-md-3">
                    <label>To</label>
                    <ng-container *ngIf="report.applicationUsers?.length > 0; else elseNoBusinessHour">
                        <ul class="value">
                            <li *ngFor="let user of report.applicationUsers">{{user.username}}</li>
                        </ul>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
        <br><hr><br>
    </div>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Close
    </button>
    <button type="button" *ngIf="authService.hasAuthorityMenu(UPDATE_DEPARTMENT)" class="nbt-btn-primary ml8" (click)="dialogRef.close('edit')">
        <mat-icon class="mr8">edit</mat-icon>
        Edit
    </button>
</div>