import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { ApplicationUserModel } from '../../model/application-user.model';
import { InstitutionModel } from '../../model/institution.model';

@Directive({
  selector: '[appOrganizationSelected]'
})
export class OrganizationSelectedDirective implements OnChanges {

  @Input() applicationUserFormGroupInput: FormGroup;
  @Input() institutionModelInput: InstitutionModel;

  constructor(private checkbox: MatCheckbox) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const applicationUserFormGroup = changes.applicationUserFormGroupInput.currentValue as FormGroup;
    const institutionModel = changes.institutionModelInput.currentValue as InstitutionModel;
    const applicationUserModel = applicationUserFormGroup.getRawValue() as ApplicationUserModel;
    const configuredInstitutions = applicationUserModel.institutions;

    this.checkbox.writeValue(configuredInstitutions.find(value => value.id === institutionModel.id));
  }
}
