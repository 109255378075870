import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustDialogNewOrEditComponent } from './cust-dialog-new-or-edit/cust-dialog-new-or-edit.component';
import { CustListingComponent } from './cust-listing/cust-listing.component';
import { CustPageComponent } from './cust-page/cust-page.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { SharedModule } from 'src/app/shared/shared.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { CustFormComponent, DialogContentDialog } from './cust-form/cust-form.component';


const routes: Routes = [
    {
        path: 'cust-satisfactory',
        component: CustListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'cust-satisfactory-form',
        component: CustFormComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'cust-satisfactory-new-page',
        component: CustPageComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'cust-satisfactory-dialog',
        component: CustDialogNewOrEditComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DirectivesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    CustDialogNewOrEditComponent,
    CustListingComponent,
    CustPageComponent,
    CustFormComponent,
    DialogContentDialog,
  ]
})
export class CustSatisfactoryModule { }
