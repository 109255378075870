import { Component, OnInit, Input } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Subject, Subscription, interval } from 'rxjs'
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-voc-breakdown-category',
  templateUrl: './voc-breakdown-category.component.html',
  styleUrls: ['./voc-breakdown-category.component.scss']
})
export class VocBreakdownCategoryComponent implements OnInit {

  readonly READ_TICKET_CATEGORY = ApplicationPermissionsService.READ_TICKET_CATEGORY;

  @Input() public startDateSubject: Subject<string>;
  @Input() public departmentIdsSubject: Subject<any>;
  @Input() public defaultDepartment: boolean;
  @Input() public refreshRateSubject: Subject<number>;

  refreshRate: number;
  refreshRateSub: Subscription
  startDate: string;
  departmentIds: any;

  root: am5.Root;
  chart: am5percent.PieChart;
  legend: am5.Legend;
  series: am5percent.PieSeries;

  data = [
    {
      "count": 2,
      "name": "Category 1",
    },
  ];

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.startDateSubject.subscribe(newValue => {
      this.startDate = newValue;
      this.requestData()
    })

    this.departmentIdsSubject.subscribe(newValue => {
      this.departmentIds = newValue;
      this.requestData()
    })

    this.refreshRateSubject.subscribe(newValue => {
      this.refreshRate = newValue;
      if (this.refreshRateSub) {
        this.refreshRateSub.unsubscribe()
      }
      if (this.refreshRate > 0) {
        this.refreshRateSub = interval(this.refreshRate * 1000).subscribe((val) => {
          this.requestData()
        })
      }
    })
  }

  ngAfterViewInit(): void {
    this.initChart();
  }

  initChart() {
    
    this.root = am5.Root.new("chartdiv");
    var responsive = am5themes_Responsive.newEmpty(this.root);

    this.chart = this.root.container.children.push(
      am5percent.PieChart.new(this.root, {
        layout: this.root.horizontalLayout,
        innerRadius: am5.percent(50),
        
      })
    );

    this.series = this.chart.series.push(
      am5percent.PieSeries.new(this.root, {
        name: "Series",
        valueField: "count",
        categoryField: "name",
        alignLabels: false,
        legendLabelText: "{category}",
        legendValueText: "{percent}",
      })
    );

    this.legend = this.chart.children.push(am5.Legend.new(this.root, {
      centerY: am5.p50,
      y: am5.p50,
      centerX: null,
      x: null,
      layout: this.root.verticalLayout ,
      verticalScrollbar: am5.Scrollbar.new(this.root, {
        orientation: "vertical"
      }),
      
    }));
    
    

    let responsiveChart = this.chart
    let responsiveRoot = this.root
    let responsiveLegend = this.legend
    responsive.addRule({
      relevant: am5themes_Responsive.widthL,
      applying: function () {
        responsiveChart.set("layout", responsiveRoot.verticalLayout);
        responsiveLegend.setAll({
          y: null,
          centerY: null,
          x: am5.p50,
          centerX: am5.p50
        });

      },
      removing: function () {
        responsiveChart.set("layout", responsiveRoot.horizontalLayout);
        responsiveLegend.setAll({
          centerY: am5.p50,
          y: am5.p50,
          centerX: null,
          x: null,
        });


      }
    });

    this.root.setThemes([
      am5themes_Animated.new(this.root),
      responsive
    ]);

    this.series.appear(2000, 500);
    
    
    this.series.ticks.template.set("visible", false);
    this.series.labels.template.setAll({
      fontSize: 12,
      tooltipText:"{category}:{value}",
      text: "{category}:{percent}",
      textType: "circular",
      inside: false,
      radius: 10, 
    })

    this.series.slices.template.set('tooltipText', '{category}: {value} ticket(s)');
    

    this.series.data.processor = am5.DataProcessor.new(this.root, {
      numericFields: ["count"]
    });
  }

  updateChart(){
    this.series.data.setAll(this.data);
    this.legend.data.setAll(this.series.dataItems);
  }

  requestData(){
    if (this.startDate && this.departmentIds) {
      if (this.authService.hasAuthorityMenu(this.READ_TICKET_CATEGORY)) {
        this.dashboardService.getVocBreakdownByCategory(this.authService.getCurInsId(), this.startDate, this.departmentIds).subscribe((value)=>{
          this.data = value.body;
          this.updateChart()
        })
      }
    }
    
  }

}
