import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { DepartmentModel } from 'src/app/model/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { ApplicationRoleModel } from 'src/app/model/application-role.model';
import { ApplicationRolesService } from 'src/app/services/application-roles.service';
import { UsersService } from 'src/app/services/users.service';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { ApplicationPositionModel } from 'src/app/model/application-position.model';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { passwordValidator } from 'src/app/shared/validator/password.validator';
import { TOOLTIPS } from 'src/app/shared/constant/tooltips-enum.constant';
import { SystemConfigurationModel } from 'src/app/model/system-configuration.model';
interface Gender {
    value: string;
    description: string;
}

@Component({
    selector: 'app-users-dialog-new-or-edit',
    templateUrl: './users-dialog-new-or-edit.component.html',
    styleUrls: ['./users-dialog-new-or-edit.component.scss']
})
export class UsersDialogNewOrEditComponent implements OnInit {

    applicationUserFormGroup: FormGroup;
    isSaving: boolean = false;
    applicationRoleModels: ApplicationRoleModel[];
    applicationPositionModels: ApplicationPositionModel[];
    departmentModels: DepartmentModel[];
    clearance: SystemConfigurationModel[];
    pageNumbers: number[];
    numberOfRecords: number;
    countLoad = 0;
    currentPage: number;
    pageSize: number;
    sortOrder: string;
    sortOrder2: string;
    dialogType: string = '';
    emailContent: string = TOOLTIPS.emailContent;
    phoneContent: string = TOOLTIPS.phoneContent;
    loading: boolean = true;

    genders: Gender[] = [
        { value: 'M', description: 'Male' },
        { value: 'F', description: 'Female' },
    ];
    constructor(
        @Inject(MAT_DIALOG_DATA) public applicationUserModel: ApplicationUserModel,
        public dialogRef: MatDialogRef<UsersDialogNewOrEditComponent>,
        private snackBar: MatSnackBar,
        private applicationRolesService: ApplicationRolesService,
        private userService: UsersService,
        private systemConfigurationService: SystemConfigurationService,
        private departmentService: DepartmentService,
        private authService: AuthService,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.applicationRoleModels = [];
        this.applicationPositionModels = [];
        this.departmentModels = [];
        this.clearance = [];

        this.pageNumbers = [];
        this.numberOfRecords = 0;
        this.currentPage = 0;
        this.pageSize = 100;
        this.sortOrder = 'institution.name,asc';
        this.sortOrder2 = 'name,asc';
        this.countLoad = 0;

        if (this.applicationUserModel) {
            this.dialogType = 'update';
            this.applicationUserFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.applicationUserModel.id),
                username: this.formBuilder.control({
                    value: this.applicationUserModel.username,
                    disabled: true,
                }),
                password: this.formBuilder.control(this.applicationUserModel.password),
                newPassword: this.formBuilder.control(null),
                repeatPassword: this.formBuilder.control(null),
                code: this.formBuilder.control(this.applicationUserModel.code),
                firstName: this.formBuilder.control(this.applicationUserModel.firstName),
                lastName: this.formBuilder.control(this.applicationUserModel.lastName),
                phone: this.formBuilder.control(this.applicationUserModel.phone,Validators.pattern("^[\+0-9]*$")),
                email: this.formBuilder.control(this.applicationUserModel.email,Validators.email),
                gender: this.formBuilder.control(this.applicationUserModel.gender),
                applicationPosition: this.formBuilder.control(this.applicationUserModel.applicationPosition === null ? null :
                                                (this.applicationUserModel.applicationPosition.parentApplicationPosition === null ?
                                                this.applicationUserModel.applicationPosition :
                                                this.applicationUserModel.applicationPosition.parentApplicationPosition)),
                applicationPositionlv2: this.formBuilder.control(this.applicationUserModel.applicationPosition === null ? null :
                                                        (this.applicationUserModel.applicationPosition.parentApplicationPosition === null ?
                                                                    null : this.applicationUserModel.applicationPosition)),
                clearance: this.formBuilder.control(this.applicationUserModel.clearance, Validators.required),
                departments: this.formBuilder.array(this.applicationUserModel.departments
                    .map(value => this.formBuilder.control(value))),
                active: this.formBuilder.control(this.applicationUserModel.active, Validators.required),
                isChampion: this.formBuilder.control(this.applicationUserModel.isChampion, Validators.required),
                applicationRoles: this.formBuilder.array(this.applicationUserModel.applicationRoles
                    .map(value => this.formBuilder.control(value)), Validators.required)
            }, {
                validators: [
                    passwordValidator()
                ]
            });
        } else {
            this.dialogType = 'create';
            this.applicationUserFormGroup = this.formBuilder.group({
                username: this.formBuilder.control(null, Validators.required),
                password: this.formBuilder.control(null),
                newPassword: this.formBuilder.control(null, Validators.required),
                repeatPassword: this.formBuilder.control(null, Validators.required),
                code: this.formBuilder.control(null),
                firstName: this.formBuilder.control(null),
                lastName: this.formBuilder.control(null),
                phone: this.formBuilder.control(null,Validators.pattern("^[\+0-9]*$")),
                email: this.formBuilder.control(null,Validators.email),
                gender: this.formBuilder.control(null),
                applicationPosition: this.formBuilder.control(null),
                applicationPositionlv2: this.formBuilder.control(null),
                departments: this.formBuilder.array([]),
                active: this.formBuilder.control(true, Validators.required),
                isChampion: this.formBuilder.control(true, Validators.required),
                applicationRoles: this.formBuilder.array([], Validators.required),
                institutions: this.formBuilder.array([]),
                clearance: this.formBuilder.control(null, Validators.required),
            }, {
                validators: [
                    passwordValidator()
                ]
            });
        }

        let name: string = null;
        let institution: string = null;
        this.applicationRolesService.getAllByInstitutionAndChild(this.currentPage, this.pageSize, this.sortOrder, {
            name: name,
            institution: institution,
            sort: this.sortOrder2,
            currentInstitution: this.authService.getCurInsId()
        }).subscribe({
            next: (value) => {
                this.applicationRoleModels = value.body;
                this.deactiveLoading();
            }
        });
        this.userService.getAllApplicationPositions().subscribe({
            next: (value) => (this.applicationPositionModels = value.body),
        });
        this.departmentService.getAllNoPagination().subscribe({
            next: (value) => (this.departmentModels = value.body),
        });
        this.systemConfigurationService.getClearanceEnum().subscribe({
            next: (value) => (this.clearance = value.body),
        });
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    save(): void {
        this.activeLoading();
        this.isSaving = true;
        const applicationUserModel = this.applicationUserFormGroup.getRawValue() as ApplicationUserModel;

        if(this.applicationUserFormGroup.get('applicationPosition').value?.code==='Officer' &&
                    this.applicationUserFormGroup.get('applicationPositionlv2').value) {
            applicationUserModel.applicationPosition = this.applicationUserFormGroup.get('applicationPositionlv2').value;
        }

        if (this.dialogType === 'create') {
            applicationUserModel.password = applicationUserModel.newPassword;
            this.userService.save(applicationUserModel, this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close');
                }
            });
        } else {
            this.userService.update(applicationUserModel, this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close');
                }
            });
        }
    }

    updateApplicationUserApplicationRoles(applicationRoleModel: ApplicationRoleModel): void {
        const applicationRolesFormArray = this.applicationUserFormGroup.get('applicationRoles') as FormArray;
        const indexOfDataToUpdate = applicationRolesFormArray.controls.findIndex(value => {
            return value.value.id === applicationRoleModel.id
        })

        if (indexOfDataToUpdate === -1) {
            applicationRolesFormArray.push(this.formBuilder.control(applicationRoleModel));
        } else {
            applicationRolesFormArray.removeAt(indexOfDataToUpdate);
        }
    }

    updateApplicationUserDepartments(departmentModel: DepartmentModel): void {
        const departmentsFormArray = this.applicationUserFormGroup.get('departments') as FormArray;
        const indexOfDataToUpdate = departmentsFormArray.controls.findIndex(value => {
            return value.value.id === departmentModel.id
        })
        if (indexOfDataToUpdate === -1) {
            departmentsFormArray.push(this.formBuilder.control(departmentModel));
        } else {
            departmentsFormArray.removeAt(indexOfDataToUpdate);
        }
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    compareCodes(object1: any, object2: any) {
        return object1 && object2 && object1 === object2 ;
    }

    checkUser() {
        if (this.applicationUserFormGroup.get('id')) {
            return false;
        } else {
            return true;
        }

    }
}
