<mat-card-content class="mat-card-content-table">
    <table mat-table matSort [dataSource]="statisticDepartmentDataSource" *ngIf="statisticDepartmentDataSource.data">
        <ng-container matColumnDef="departments">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Departments</th>
            <td mat-cell *matCellDef="let element"> {{ element.department || '-'}}</td>
        </ng-container>
        <ng-container matColumnDef="assigned">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="statistic-table-header assigned"><span class="status-color-indicator"></span> Assigned</th>
            <td mat-cell *matCellDef="let element"> {{ element.assigned || '0'}}</td>
        </ng-container>
        <ng-container matColumnDef="inProgress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="statistic-table-header in-progress"><span class="status-color-indicator"></span>In Progress</th>
            <td mat-cell *matCellDef="let element"> {{ element.inProgress || '0'}}</td>
        </ng-container>
        <ng-container matColumnDef="responseOverdue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="statistic-table-header response-overdue"><span class="status-color-indicator"></span>Response Overdue</th>
            <td mat-cell *matCellDef="let element"> {{ element.responseOverdue || '0'}}</td>
        </ng-container>
        <ng-container matColumnDef="resolutionOverdue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="statistic-table-header resolution-overdue"><span class="status-color-indicator"></span>Resolution Overdue</th>
            <td mat-cell *matCellDef="let element"> {{ element.resolutionOverdue || '0'}}</td>
        </ng-container>
        <ng-container matColumnDef="onHold">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="statistic-table-header on-hold"><span class="status-color-indicator"></span>On-Hold</th>
            <td mat-cell *matCellDef="let element"> {{ element.onHold || '0'}}</td>
        </ng-container>
        <ng-container  matColumnDef="new">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="statistic-table-header new"><span class="status-color-indicator"></span>New</th>
            <td mat-cell *matCellDef="let element"> {{ element.new || '0'}}</td>
        </ng-container>
        
        <ng-container  matColumnDef="open">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="statistic-table-header open"><span class="status-color-indicator"></span>Open</th>
            <td mat-cell *matCellDef="let element"> {{ element.open || '0'}}</td>
        </ng-container>
        
        <ng-container matColumnDef="resolved">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="statistic-table-header resolved-today"><span class="status-color-indicator"></span>Resolved</th>
            <td mat-cell *matCellDef="let element"> {{ element.resolved || '0'}}</td>
        </ng-container>
        <ng-container matColumnDef="closed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="statistic-table-header closed-today"><span class="status-color-indicator"></span>Closed</th>
            <td mat-cell *matCellDef="let element"> {{ element.closed || '0'}}</td>
        </ng-container>
        <ng-container  matColumnDef="reOpen">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="statistic-table-header reopened-today"><span class="status-color-indicator"></span>Reopened</th>
            <td mat-cell *matCellDef="let element"> {{ element.reOpen || '0'}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
    </table>
</mat-card-content>