import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartmentsDialogDetailComponent } from './departments-dialog-detail/departments-dialog-detail.component';
import { DepartmentsListingComponent } from './departments-listing/departments-listing.component';
import { DepartmentsDialogNewOrEditComponent } from './departments-dialog-new-or-edit/departments-dialog-new-or-edit.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';


const routes: Routes = [
    {
        path: 'departments',
        component: DepartmentsListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    }
];

@NgModule({
    declarations: [
        DepartmentsDialogDetailComponent,
        DepartmentsListingComponent,
        DepartmentsDialogNewOrEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes),
        FormsModule
    ]
})
export class DepartmentsModule { }
