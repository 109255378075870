<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Issuing SSI Form
        </ng-container>
        <ng-template #elseTemplate>
            Modification Update
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="ssiFormGroup">
        <div class="row">
            <div class="col-md-12 nbt-form">
                <label>Showroom <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select name="dept" formControlName="department" placeholder="Select a Showroom "
                        [compareWith]="compareObjects" (selectionChange)="changeSelectDepartment($event.value.id)">
                        <mat-option *ngFor="let sr of departments" [value]="sr">
                            {{ sr.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form">
                <label>Sales Consultant <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" (ngModelChange)="searchSalesConsultant(salesConsultantControl.value)"
                           [formControl]="salesConsultantControl" [matAutocomplete]="auto" placeholder="Search Sales Consultant"/>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionsScroll)="onScroll()">
                        <mat-option *ngFor="let option of salesConsultantRecords" [value]="option">
                            {{option.username}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="row " formArrayName="vehicle">
                <div class="col-md-6 nbt-form">
                    <label for="">Vehicle Model <span class="required">*</span></label>
                    <mat-form-field appearance="outline">
                        <mat-select name="model" formControlName="model" placeholder="Select a Model "
                            [compareWith]="compareObjects">
                            <mat-option *ngFor="let model of models" [value]="model">
                                {{ model.brand }} {{model.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6 nbt-form">
                    <label for="">Vehicle Number <span class="required">*</span></label>
                    <mat-form-field appearance="outline">
                        <input matInput appAutofocus type="text" formControlName="registrationNo" placeholder="Enter vehicle number" maxlength="200" required />
                        <mat-error *ngIf="ssiFormGroup.get('vehicle.registrationNo').errors?.pattern">Invalid input</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-12 nbt-form-textarea" *ngIf="dialogType === 'update'">
                <label>Justification <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <textarea matInput appAutofocus type="text" formControlName="justification"
                        placeholder="Enter your reason..." rows="5" maxlength="2000">
                    </textarea>
                </mat-form-field>
            </div>
        </div>
    </form>
    <span *ngIf="dialogType === 'create'" class="required">* Required Field For Submission</span>
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8"
        (click)="save()" [disabled]="isSaving || ssiFormGroup.invalid">
        <mat-icon class="mr8">save</mat-icon>
        {{dialogType === 'create' ? 'Confirm and Proceed' : 'Save'}}
    </button>
</div>
