
<div class="nbt-dialog-header"  *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create Ticket Category
        </ng-container>
        <ng-template #elseTemplate>
            Edit Ticket Category - '{{ category.name }}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body"  *ngIf="!loading">
    <form [formGroup]="categoryFormGroup">
        <div class="row">
            <div class="col-md-9 nbt-form">
                <label>Name <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="name" placeholder="Enter name" maxlength="100"/>
                </mat-form-field>
            </div>
            <div class="col-md-3 nbt-form label-flex">
                <mat-checkbox formControlName="active">Active</mat-checkbox>
            </div>
            <div class="col-md-12 nbt-form">
                <label>Description </label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="description" placeholder="Enter description" maxlength="100"/>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form">
                <label>Parent</label>
                <mat-form-field appearance="outline">
                    <mat-select name="parentCategory" formControlName="parentCategory" [compareWith]="compareObjects"
                        [disabled]="checkParent()" placeholder="Select parent category">
                        <mat-option [value]="null">
                            Select parent category
                        </mat-option>
                        <mat-option *ngFor="let grp of parentGroup" [value]="grp">
                            {{ grp.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <span class="required">* Required Field For Submission</span> 
</div>
<div class="nbt-dialog-footer"  *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || categoryFormGroup.invalid"
        (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>
