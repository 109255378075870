import { InstitutionModel } from './institution.model';

export class HolidayModel {
    constructor(
        public id?: string,
        public name?: string,
        public date?: string,
        public recurrent?: boolean,
        public active?: boolean,
        public institution?: InstitutionModel[],
        public createdBy?: string,
        public createdDate?: any,
        public lastModifiedBy?: string,
        public lastModifiedDate?: any
    ) { }
}
