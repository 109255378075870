import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { DepartmentModel } from '../model/department.model';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class DepartmentService {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) { }

    public save(
        model: DepartmentModel,
        curIns: string
    ): Observable<HttpResponse<DepartmentModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<DepartmentModel>(`/web/department`, model, {
            observe: 'response',
            params: httpParams
        });
    }

    public getAll(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        criteria
    ): Observable<HttpResponse<DepartmentModel[]>> {
        let httpParams = new HttpParams();
        if (pageNumber) {
            httpParams = httpParams
                .set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams
                .set('size', `${pageSize}`);
        }
        if (sortOrder) {
            httpParams = httpParams
                .set('sort', sortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('name', criteria.name);
            }
            if (criteria.institution) {
                httpParams = httpParams.set('institutionId', criteria.institution);
            }
            if (criteria.currentInstitution) {
                httpParams = httpParams.set('curIns', criteria.currentInstitution);
            }
        }
        return this.httpClient.get<DepartmentModel[]>(`/web/department/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    public update(
        model: DepartmentModel,
        curIns: string
    ): Observable<HttpResponse<DepartmentModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<DepartmentModel>(`/web/department`, model, {
            observe: 'response',
            params: httpParams
        });
    }

    getDepartmentByUser (curIns: string, isBranch?:boolean, isServiceCentre?: boolean, isShowRoom?: boolean) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        if(isBranch != null && isBranch != undefined)httpParams = httpParams.set('isBranch', isBranch);
        if(isServiceCentre != null && isServiceCentre != undefined)httpParams = httpParams.set('isServiceCentre', isServiceCentre);
        if(isShowRoom != null && isShowRoom != undefined)httpParams = httpParams.set('isShowRoom', isShowRoom);
        return this.httpClient.get<DepartmentModel[]>('/web/department/user', {
            observe: 'response',
            params: httpParams,
        });
    }

    getSurveyDepartments(isBranch?:boolean, isServiceCentre?: boolean, isShowRoom?: boolean) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        if(isBranch != null && isBranch != undefined)httpParams = httpParams.set('isBranch', isBranch);
        if(isServiceCentre != null && isServiceCentre != undefined)httpParams = httpParams.set('isServiceCentre', isServiceCentre);
        if(isShowRoom != null && isShowRoom != undefined)httpParams = httpParams.set('isShowRoom', isShowRoom);
        return this.httpClient.get<DepartmentModel[]>('/web/department/survey', {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllNpsDepartments(
    ){
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        return this.httpClient.get<DepartmentModel[]>('/web/department/nps', {
            observe: 'response',
            params: httpParams,
        });
    }

    getTriggerTimeResolution(): Observable<HttpResponse<any>> {
        return this.httpClient.get<any>('/web/department/trigger-time/resolution');
    }

    getTriggerTimeResponse(): Observable<HttpResponse<any>> {
        return this.httpClient.get<any>('/web/department/trigger-time/response');
    }

    getAllNoPagination (isBranch?:boolean, isServiceCentre?: boolean, isShowRoom?: boolean): Observable<HttpResponse<DepartmentModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        if(isBranch != null && isBranch != undefined)httpParams = httpParams.set('isBranch', isBranch);
        if(isServiceCentre != null && isServiceCentre != undefined)httpParams = httpParams.set('isServiceCentre', isServiceCentre);
        if(isShowRoom != null && isShowRoom != undefined)httpParams = httpParams.set('isShowRoom', isShowRoom);
        return this.httpClient.get<DepartmentModel[]>(`/web/department/all/no-pagination`, {
            observe: 'response',
            params: httpParams,
        });
    }

    isDefaultDepartment(
        curIns: string
    ){
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.get<Boolean>('/web/department/is-default-department', {
            observe: 'response',
            params: httpParams,
        });
    }
}
