import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-voice-of-customer-dialog-preview-image',
    templateUrl: './voice-of-customer-dialog-preview-image.component.html',
    styleUrls: ['./voice-of-customer-dialog-preview-image.component.scss']
})
export class VoiceOfCustomerDialogPreviewImageComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public attachment: any,
        public dialogRef: MatDialogRef<VoiceOfCustomerDialogPreviewImageComponent>,
    ) { }

    ngOnInit(): void {
    }

}
