import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { BusinessHourModel } from 'src/app/model/business-hours.model';
import { InstitutionModel } from 'src/app/model/institution.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { BusinessHoursService } from 'src/app/services/business-hours.service';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { DialogSimpleComponent } from 'src/app/shared/dialog-simple/dialog-simple.component';
import { BusinessHoursDialogDetailComponent } from '../business-hours-dialog-detail/business-hours-dialog-detail.component';
import { BusinessHoursDialogNewOrEditComponent } from '../business-hours-dialog-new-or-edit/business-hours-dialog-new-or-edit.component';

@Component({
    selector: 'app-business-hours-listing',
    templateUrl: './business-hours-listing.component.html',
    styleUrls: ['./business-hours-listing.component.scss']
})
export class BusinessHoursListingComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    readonly CREATE_BUSINESS_HOUR = ApplicationPermissionsService.CREATE_BUSINESS_HOUR;
    readonly UPDATE_BUSINESS_HOUR = ApplicationPermissionsService.UPDATE_BUSINESS_HOUR;
    readonly DELETE_BUSINESS_HOUR = ApplicationPermissionsService.DELETE_BUSINESS_HOUR;

    searchFormGroup: FormGroup;
    pageNumbers: number[];
    numberOfRecords: number;
    countLoad = 0;
    currentPage: number;
    pageSize: number;
    sortOrder: string;
    dataSource: MatTableDataSource<BusinessHourModel>;
    displayColumns: string[];
    institutions: InstitutionModel[];
    obs: BehaviorSubject<BusinessHourModel[]>;

    constructor(
        private formBuilder: FormBuilder,
        private businessHoursServices: BusinessHoursService,
        public authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private institutionsService: InstitutionsService,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<BusinessHourModel>();
        this.dataSource.paginator = this.paginator;
        this.pageNumbers = [];
        this.numberOfRecords = 0;
        this.currentPage = 0;
        this.pageSize = 20;
        this.sortOrder = 'name,asc';
        this.countLoad = 0;

        this.displayColumns = [
            'name',
            'actions'
        ];
        this.institutionsService.getAll().subscribe({
            next: (value) => (this.institutions = value.body),
        });

        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.currentPage = this.activatedRoute.snapshot.queryParams['page']
                ? this.activatedRoute.snapshot.queryParams['page']
                : undefined;
            this.sortOrder = this.activatedRoute.snapshot.queryParams['sort']
                ? this.activatedRoute.snapshot.queryParams['sort']
                : undefined;
            this.pageSize = this.activatedRoute.snapshot.queryParams['pageSize']
                ? this.activatedRoute.snapshot.queryParams['pageSize']
                : undefined;
        }
        this.createForm();
        this.refreshForm();
    }

    private createForm() {
        let name: string;

        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(name ? name : null),
        });
    }

    search(currentPage: number) {

        let name: string;
        let institution: string;

        name = this.searchFormGroup.controls.searchName.value
            ? this.searchFormGroup.controls.searchName.value
            : null;



        this.businessHoursServices
            .getAllbyInstitution(this.currentPage, this.pageSize, this.sortOrder, {
                name: name,
                currentInstitution: this.authService.getCurInsId()
            })
            .subscribe({
                next: (value) => {
                    this.dataSource.data = value.body;
                    this.changeDetectorRef.detectChanges();
                    this.dataSource.paginator = this.paginator;
                    this.obs = this.dataSource.connect();

                    if (currentPage === 0) {
                        this.paginator.firstPage();
                    } else {
                        this.paginator.pageIndex = currentPage;
                    }

                    this.numberOfRecords = Number(value.headers.get('X-Total-Count'));

                },
                error: (value) => {

                    this.snackBar.open(value.error.message, 'Close', {
                        duration: 60000
                    });
                },
            });
    }

    refreshForm() {
        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(null),
        });
        this.search(0);
    }

    openBusinessHoursDialogCreateOrEdit(data?: BusinessHourModel) {
        const modalRef = this.matDialog.open(BusinessHoursDialogNewOrEditComponent, {
            data: data,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(0);
            }
        })
    }

    navigateToPage(event): void { }

    sortPage(event): void {
        this.sortOrder = `${event.active},${event.direction}`;
        this.search(this.currentPage);
    }

    openBusinessHoursDialogDetail(data?: BusinessHourModel) {
        const modalRef = this.matDialog.open(BusinessHoursDialogDetailComponent, {
            data: data,
            panelClass: 'mat-dialog-md'
        })

        modalRef.afterClosed().subscribe({
            next: (edit) => {
                if (edit) this.openBusinessHoursDialogCreateOrEdit(data);
            }
        })
    }

    openBusinessHoursDialogDelete(data: BusinessHourModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete Business Hour?',
                message: `Are you sure want to delete ${data.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.businessHoursServices.delete(data.id.toString(), this.authService.getCurInsId()).subscribe({
                        next: () => {
                            this.search(0);
                        },
                        error: error => {
                            this.search(0);
                            this.snackBar.open(error.error.message, 'Close', {
                                duration: 60000
                            });
                        },
                    });
                }
            }
        })
    }
}
