import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { DepartmentModel } from 'src/app/model/department.model';
import { Model } from 'src/app/model/model.model';
import { SurveyModel } from 'src/app/model/survey.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DepartmentService } from 'src/app/services/department.service';
import { ModelService } from 'src/app/services/model.service';
import { SurveyService } from 'src/app/services/survey.service';
import { UsersService } from 'src/app/services/users.service';
import { REGEXS, SnackBarStatus } from 'src/app/shared/constant';

@Component({
    selector: 'app-cust-dialog-new-or-edit',
    templateUrl: './cust-dialog-new-or-edit.component.html',
    styleUrls: ['./cust-dialog-new-or-edit.component.scss']
})

export class CustDialogNewOrEditComponent implements OnInit, OnDestroy {
    readonly CREATE_CSI = ApplicationPermissionsService.CREATE_CSI;
    readonly snackBarConfig: MatSnackBarConfig = { duration:60000 };
    loading: boolean = true;
    dialogType: string = '';
    isSaving: boolean = false;
    csiFormGroup: FormGroup;
    departments: DepartmentModel[];
    isShowRoom: boolean = true;
    serviceAdvisor: ApplicationUserModel;
    serviceAdvisorRecords: ApplicationUserModel[];
    models: Model[];
    close = SnackBarStatus.CLOSE;
    respond =" has issued a new CSI form : ";
    modifyRespond = " has modified a form : ";

    departmentId: number = null;
    serviceAdvisorControl = new FormControl('', Validators.required);
    totalServiceAdvisor = 0;
    currentTotalServiceAdvisor = 0;
    pageServiceAdvisor = 0;
    sizeServiceAdvisor = 20;

    searchAdvisorSubscription: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SurveyModel,
        public dialogRef: MatDialogRef<CustDialogNewOrEditComponent>,
        private departmentService: DepartmentService,
        private modelService: ModelService,
        private userService: UsersService,
        public authService: AuthService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private surveyService: SurveyService,
    ) { }

    ngOnInit(): void {
        if(this.data) {
            this.dialogType = 'update';
            if(this.data.department) {
                this.changeSelectDepartment(parseInt(this.data.department.id));
            }
            this.serviceAdvisorControl.setValue(this.data.salesConsultant);
            this.csiFormGroup = this.formBuilder.group({
                id: this.data.id,
                department: this.formBuilder.control(this.data.department, Validators.required),
                salesConsultant: this.serviceAdvisorControl,
                vehicle : this.formBuilder.group({
                    model: this.formBuilder.control(this.data.vehicle.model, Validators.required),
                    registrationNo: this.formBuilder.control(this.data.vehicle.registrationNo, [Validators.required,Validators.pattern(REGEXS.VEHICLE)]),
                }),
                justification: this.formBuilder.control(null, Validators.required),
                surveyType: this.formBuilder.control(this.data.surveyType),
            });
        }
        else {
            this.dialogType = 'create';
            this.csiFormGroup = this.formBuilder.group({
                department: this.formBuilder.control(null, Validators.required),
                salesConsultant: this.serviceAdvisorControl,
                vehicle : this.formBuilder.group({
                    model: this.formBuilder.control(null, Validators.required),
                    registrationNo: this.formBuilder.control(null, [Validators.required,Validators.pattern(REGEXS.VEHICLE)]),
                }),
                surveyType: this.formBuilder.control(this.data.surveyType),
            });
        }

        this.modelService.getAllModelNoPagination().subscribe({
            next: (value) => (this.models = value.body),
        });

        this.getDepartmentsWithDefaultDepartmentChecking();
    }

    ngOnDestroy(): void {
        if(this.searchAdvisorSubscription)
            this.searchAdvisorSubscription.unsubscribe();
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    onScroll() {
        if (this.totalServiceAdvisor > this.currentTotalServiceAdvisor) {
            this.pageServiceAdvisor++;
            this.searchServiceAdvisorRequest();
        }
    }

    changeSelectDepartment(departmentId: number){
        this.departmentId = departmentId;
        this.serviceAdvisorRecords = [];
        this.serviceAdvisor = null;
        this.pageServiceAdvisor = 0;
        this.currentTotalServiceAdvisor = 0;
        this.serviceAdvisorControl.reset("");
        this.searchServiceAdvisorRequest();
    }

    searchServiceAdvisor() {
        this.serviceAdvisorRecords = [];
        this.serviceAdvisor = null;
        this.pageServiceAdvisor = 0;
        this.currentTotalServiceAdvisor = 0;
        this.searchServiceAdvisorRequest();
    }

    searchServiceAdvisorRequest() {
        if(this.departmentId!=null) {
            if (this.serviceAdvisorControl.value.id) {
                this.serviceAdvisor = this.serviceAdvisorControl.value;
                return;
            }

            if(this.searchAdvisorSubscription)
                this.searchAdvisorSubscription.unsubscribe();

            this.searchAdvisorSubscription = this.userService
                .searchAllByDepartment(
                    this.pageServiceAdvisor,
                    this.sizeServiceAdvisor,
                    this.serviceAdvisorControl.value,
                    this.authService.getCurInsId(),
                    this.departmentId,
                    { reminderInd: false, defaultDepartmentInd: false },
                    'service advisor')
                .subscribe({
                    next: (value) => {
                        this.totalServiceAdvisor = Number(value.headers.get('X-Total-Count'));
                        this.currentTotalServiceAdvisor = this.currentTotalServiceAdvisor + value.body.length;
                        this.serviceAdvisorRecords.push(...value.body);
                        this.deactiveLoading();
                    },
                    error: (error) => {
                        this.deactiveLoading();
                        this.snackBar.open(error.error.debugMessage, 'Close', {
                            duration: 60000,
                        });
                    },
            });
        }
    }

    displayFn(serviceAdvisor: ApplicationUserModel): string {
        return serviceAdvisor && serviceAdvisor.username ? `${serviceAdvisor.username}` : '';
    }

    save(){
        this.isSaving = true;
        this.activeLoading();
        if(this.dialogType == 'create') {
            this.surveyService.createSurvey(this.csiFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
            next:(response)=>{
                this.isSaving = false;
                let theMessage = this.authService.getUsername() + this.respond + response.body.surveyNumber;
                this.deactiveLoading();
                this.snackBar.open(theMessage, this.close, this.snackBarConfig);
                this.dialogRef.close('ok');
            },
            error: (error) => {
                this.isSaving = false;
                this.deactiveLoading();
                this.snackBar.open(error.error.debugMessage, 'Close', this.snackBarConfig);
            }
            })
        } else if(this.dialogType == 'update') {
            this.surveyService.modificationUpdate(this.csiFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                next:(response)=>{
                    this.isSaving = false;
                    let theMessage = this.authService.getUsername() + this.modifyRespond + response.body.surveyNumber;
                    this.deactiveLoading();
                    this.snackBar.open(theMessage, this.close, this.snackBarConfig);
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.debugMessage, this.close, this.snackBarConfig);
                }
            });
        }
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    back() {
        window.history.back();
    }

    //TODO: REFACTOR FUNCTION
    getDepartmentsWithDefaultDepartmentChecking() {
        this.departmentService.isDefaultDepartment(this.authService.getCurInsId()).subscribe({
            next: (isDefaultDepartment) => {
                if (isDefaultDepartment.body) {
                    this.departmentService.getAllNoPagination(null, true, false).subscribe({
                        next: (res) => {
                            this.departments = res.body;
                        },
                        error: (res) => {
                            this.snackBar.open(
                                res.error.message,
                                'Close',
                                this.snackBarConfig
                            );
                        },
                        complete: () => {
                            this.deactiveLoading();
                        },
                    });
                } else {
                    this.departmentService.getDepartmentByUser(this.authService.getCurInsId(), null, true, false).subscribe({
                            next: (res) => {
                                this.departments = res.body;
                            },
                            error: (res) => {
                                this.snackBar.open(
                                    res.error.message,
                                    'Close',
                                    this.snackBarConfig
                                );
                            },
                            complete: () => {
                                this.deactiveLoading();
                            },
                        });
                }
            },
            error: (err) => {
                this.snackBar.open(
                    err.error.message,
                    'Close',
                    this.snackBarConfig
                );
            },
            complete: () => {
                this.deactiveLoading();
            },
        });
    }
}
