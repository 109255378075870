<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create New Holiday
        </ng-container>
        <ng-template #elseTemplate>
            Edit Holiday '{{holidayModel.name}}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="holidayFormGroup">
        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Name <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="name" placeholder="Enter name" maxlength="100"/>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form label-flex">
                <mat-checkbox formControlName="active">Active</mat-checkbox>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Institution</label>
                <mat-form-field appearance="outline">
                    <mat-select name="institution" formControlName="institution" [compareWith]="compareObjects" 
                        [disabled]="true" placeholder="Select institution">
                        <mat-option [value]="null">
                            Select institution
                        </mat-option>
                        <mat-option *ngFor="let ins of institutions" [value]="ins">
                            {{ ins.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form mat-form-field-wrapper">
                <label>Date <span class="required">*</span></label>
                    <input class="date-picker" formControlName="date"  type="date" placeholder="Select date" />
                    <!-- <mat-form-field appearance="outline"> -->
                    <!-- <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker> -->
                <!-- </mat-form-field> -->
                <!-- <label>Date <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input mdatInput [matDatepicker]="picker" formControlName="date" placeholder="Select date"/>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field> -->
            </div>
            <div class="col-md-6 nbt-form label-flex">
                <mat-checkbox  formControlName="recurrent" [(ngModel)]="recurrent"> Recurring Holiday </mat-checkbox>
            </div>
        </div>
    </form>
    <span class="required">* Required Field For Submission</span> 
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || holidayFormGroup.invalid"
        (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>