import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { InstitutionModel } from 'src/app/model/institution.model';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { REGEXS } from 'src/app/shared/constant/regex.constant';
import { TOOLTIPS } from 'src/app/shared/constant/tooltips-enum.constant';

@Component({
    selector: 'app-institutions-dialog-new-or-edit',
    templateUrl: './institutions-dialog-new-or-edit.component.html',
    styleUrls: ['./institutions-dialog-new-or-edit.component.scss']
})
export class InstitutionsDialogNewOrEditComponent implements OnInit {

    dialogType: string = '';
    isSaving: boolean = false;
    institutionFormGroup: FormGroup;
    parentInstitution: InstitutionModel[] = [];
    emailContent: string = TOOLTIPS.emailContent;
    phoneContent: string = TOOLTIPS.phoneContent;
    enablePlaceholder: string = '';
	loading: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public institutionModel: InstitutionModel,
        public dialogRef: MatDialogRef<InstitutionsDialogNewOrEditComponent>,
        private institutionService: InstitutionsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private institutionsService: InstitutionsService,
        private snackBar: MatSnackBar,
        
    ) { 
        this.enablePlaceholder = '0359262362'
    }

    ngOnInit(): void {
        if (this.institutionModel) {
            this.dialogType = 'update';
            this.institutionFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.institutionModel.id),
                name: this.formBuilder.control(this.institutionModel.name,Validators.required),
                shortName: this.formBuilder.control(this.institutionModel.shortName),
                parentInstitution: this.formBuilder.control(this.institutionModel.parentInstitution, Validators.required),
                businessRegNo: this.formBuilder.control(this.institutionModel.businessRegNo),
                industry: this.formBuilder.control(this.institutionModel.industry),
                address: this.formBuilder.control(this.institutionModel.address),
                phone: this.formBuilder.control(this.institutionModel.phone,Validators.pattern(REGEXS.PHONE_FILTER)),
                fax: this.formBuilder.control(this.institutionModel.fax,Validators.pattern(REGEXS.PHONE_FILTER)),
                email: this.formBuilder.control(this.institutionModel.email,Validators.email),
                website: this.formBuilder.control(this.institutionModel.website),
                createdBy: this.formBuilder.control(this.institutionModel.createdBy),
                createdDate: this.formBuilder.control(this.institutionModel.createdDate),
                lastModifiedBy: this.formBuilder.control(this.institutionModel.lastModifiedBy),
                lastModifiedDate: this.formBuilder.control(this.institutionModel.lastModifiedDate),
                active: this.formBuilder.control(this.institutionModel.active)
            });
        } else {
            this.dialogType = 'create';
            this.institutionFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null,Validators.required),
                active: this.formBuilder.control(true),
                shortName: this.formBuilder.control(null),
                parentInstitution: this.formBuilder.control(null,Validators.required),
                businessRegNo: this.formBuilder.control(null),
                industry: this.formBuilder.control(null),
                address: this.formBuilder.control(null),
                phone: this.formBuilder.control(null,Validators.pattern(REGEXS.PHONE_FILTER)),
                fax: this.formBuilder.control(null, Validators.pattern(REGEXS.PHONE_FILTER)),
                email: this.formBuilder.control(null, Validators.email),
                website: this.formBuilder.control(null),
                createdBy: this.formBuilder.control(null),
                createdDate: this.formBuilder.control(null)
            });
            this.institutionFormGroup.markAllAsTouched();
        }

        this.institutionsService.getAll().subscribe({
            next: (value) => {
                this.parentInstitution = value.body;
                this.deactiveLoading();
            }
        });
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    save(): void {
        this.isSaving = true;
        this.activeLoading();
        if (this.dialogType === 'create') {
            this.institutionService.save(this.institutionFormGroup.getRawValue()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                    });
                }
            });
        } else {
            this.institutionsService.update(this.institutionFormGroup.getRawValue()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                    });
                }
            });
        }
    }


    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    checkInstitution() {
        if (this.institutionFormGroup.get('id')) {
            return true;
        } else {
            return false;
        }
    }

}
