import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomerRecordsListingComponent } from './customer-records-listing/customer-records-listing.component';
import { CustomerRecordsDialogNewOrEditComponent } from './customer-records-dialog-new-or-edit/customer-records-dialog-new-or-edit.component';
import { CustomerRecordsDetailComponent } from './customer-records-detail/customer-records-detail.component';
import { CustomerRecordsMysteryShopperDetailComponent } from './customer-records-mystery-shopper-detail/customer-records-mystery-shopper-detail.component';
import { CustomerRecordsMysteryShopperDetailDialogSubmitComponent } from './customer-records-mystery-shopper-detail-dialog-submit/customer-records-mystery-shopper-detail-dialog-submit.component';
import { CustomerRecordsDialogNewOrEditVehiclesComponent } from './customer-records-dialog-new-or-edit-vehicles/customer-records-dialog-new-or-edit-vehicles.component';
import { CustomerRecordsDialogAddActivityComponent } from './customer-records-dialog-add-activity/customer-records-dialog-add-activity.component';
import { CustomerRecordsDialogTransferOwnershipComponent } from './customer-records-dialog-transfer-ownership/customer-records-dialog-transfer-ownership.component';
import { CustomerRecordsDetailActivityComponent } from './customer-records-detail-activity/customer-records-detail-activity.component';

const routes: Routes = [
    {
        path: '',
        component: CustomerRecordsListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'detail',
        component: CustomerRecordsDetailComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'mystery-shopper-detail',
        component: CustomerRecordsMysteryShopperDetailComponent,
    }
];

@NgModule({
    declarations: [
        CustomerRecordsListingComponent,
        CustomerRecordsDialogNewOrEditComponent,
        CustomerRecordsDetailComponent,
        CustomerRecordsDialogNewOrEditVehiclesComponent,
        CustomerRecordsDialogAddActivityComponent,
        CustomerRecordsDialogTransferOwnershipComponent,
        CustomerRecordsDetailActivityComponent,
        CustomerRecordsMysteryShopperDetailComponent,
        CustomerRecordsMysteryShopperDetailDialogSubmitComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes)
    ]
})
export class CustomerRecordsModule { }
