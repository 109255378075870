import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthenticatedGuard } from "src/app/auth/authenticated.guard";
import { DirectivesModule } from "src/app/directives/directives.module";
import { SharedModule } from "src/app/shared/shared.module";
import { VOCReportsComponent } from "./report-main.component";

const routes: Routes = [
    {
        path: 'voc-report',
        component: VOCReportsComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    }
];

@NgModule({
    declarations:[
        VOCReportsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes)
    ]
})
export class VOCReportsModule{}