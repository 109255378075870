<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        VOC Information Modification
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="ticketFormGroup">
        <div class="row">
            <div class="col-md-12 nbt-form">
                <label>VOC Title <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" autoAo formControlName="title" placeholder="Enter VOC Title">
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form">
                <label>Group <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select name="group" [compareWith]="compareObjects" formControlName="group" placeholder="Select Group"
                        (selectionChange)="changeSelectGroup($event)">
                        <mat-option [value]="null">
                            Select Group
                        </mat-option>
                        <mat-option *ngFor="let group of groups" [value]="group">
                            {{ group.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form">
                <label>Category <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select name="category" formControlName="category" placeholder="Select Category"
                        (selectionChange)="changeSelectCategory($event)" [compareWith]="compareObjects">
                        <mat-option [value]="null">
                            Select Category
                        </mat-option>
                        <mat-option *ngFor="let category of categories" [value]="category">
                            {{ category.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form">
                <label>Type <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select name="type" formControlName="type" placeholder="Select Type" [compareWith]="compareObjects">
                        <mat-option [value]="null">
                            Select Type
                        </mat-option>
                        <mat-option *ngFor="let type of types" [value]="type">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>VOC Vehicle Model</label>
                <mat-form-field appearance="outline">
                    <mat-select name="vehicle" formControlName="model" placeholder="Select model"
                        [compareWith]="compareObjects" >
                        <mat-option [value]="null">
                            Select model
                        </mat-option>
                        <mat-option *ngFor="let model of models" [value]="model">
                            {{ model.brand }} {{ model.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>VOC Vehicle Number</label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="registrationNo"
                        placeholder="Enter Vehicle Number" >
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form-textarea">
                <label>Details <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <textarea matInput type="text" formControlName="detail"
                        placeholder="Enter description" rows="5" maxlength="2000" #myInput>
                    </textarea>
                    <span>{{myInput.value.length}} / 2000</span>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form">
                <label>Incident Branch <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select name="type" formControlName="incidentBranch" placeholder="Select Incident Branch"
                        [compareWith]="compareObjects">
                        <mat-option [value]="null">
                            Select incident branch
                        </mat-option>
                        <mat-option *ngFor="let branch of incidentBranchs" [value]="branch">
                            {{ branch.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Channel <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select name="channel" formControlName="channel" placeholder="Select Channel" (selectionChange)="changeOption($event)">
                        <mat-option [value]="null">
                            Select Channel
                        </mat-option>
                        <mat-option *ngFor="let channel of sysConfigChannel" [value]="channel">
                            {{ channel }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Delivery / Last Service Date</label>
                <input class="date-picker" formControlName="serviceDeliveryDate" type="date"
                    placeholder="Select purchase date" />
            </div>
            <ng-container *ngIf="channelShow">
                <div class="col-md-12 nbt-form">
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="channelOther"
                            placeholder="Enter channel" max="50">
                    </mat-form-field>
                </div>
            </ng-container>
            
            <div class="col-md-12 nbt-form-textarea">
                <label>Justification <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <textarea matInput appAutofocus type="text" formControlName="justification"
                        placeholder="Enter your reason..." rows="5" maxlength="2000">
                    </textarea>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || ticketFormGroup.invalid"
        (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>
