import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoiceOfCustomerModule } from './voice-of-customer/voice-of-customer.module';
import { VOCReportsModule } from './voc-reports/voc-reports.module';
import { SalesSatisfactoryModule } from './sales-satisfactory/sales-satisfactory.module';
import { CustSatisfactoryModule } from './cust-satisfactory/cust-satisfactory.module';
import { NetPromoterModule } from './net-promoter/net-promoter.module';



@NgModule({
  imports: [
    CommonModule,
    VoiceOfCustomerModule,
    VOCReportsModule,
    SalesSatisfactoryModule,
    CustSatisfactoryModule,
    NetPromoterModule
  ],
  declarations: [
  ],
  
})
export class OperationsModule { }
