import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SurveyReportService {
    constructor(private httpClient: HttpClient) {}

    generateSSIScoringReport(request: SingleDepartmentParams) {
        const params = new HttpParams({ fromObject: { ...request } });
        return this.httpClient.get(`/web/report/ssi/raw-data/scoring-report`, {
            observe: 'response',
            params,
            responseType: 'blob',
        });
    }

    generateCSIScoringReport(request: SingleDepartmentParams) {
        const params = new HttpParams({ fromObject: { ...request } });
        return this.httpClient.get(`/web/report/csi/raw-data/scoring-report`, {
            observe: 'response',
            params,
            responseType: 'blob',
        });
    }

    generateSSIAverageAnswerReport(request: SingleDepartmentParams) {
        const params = new HttpParams({ fromObject: { ...request } });
        return this.httpClient.get(
            `/web/report/ssi/raw-data/average-answer-report`,
            {
                observe: 'response',
                params,
                responseType: 'blob',
            }
        );
    }

    generateCSIAverageAnswerReport(request: SingleDepartmentParams) {
        const params = new HttpParams({ fromObject: { ...request } });
        return this.httpClient.get(
            `/web/report/csi/raw-data/average-answer-report`,
            {
                observe: 'response',
                params,
                responseType: 'blob',
            }
        );
    }

    generateSSIResponseRateReport(request: MultipleDepartmentParams) {
        const params = new HttpParams({ fromObject: { ...request } });
        return this.httpClient.get(
            `/web/report/ssi/raw-data/response-rate-report`,
            {
                observe: 'response',
                params,
                responseType: 'blob',
            }
        );
    }

    generateCSIResponseRateReport(request: MultipleDepartmentParams) {
        const params = new HttpParams({ fromObject: { ...request } });
        return this.httpClient.get(
            `/web/report/csi/raw-data/response-rate-report`,
            {
                observe: 'response',
                params,
                responseType: 'blob',
            }
        );
    }
}

interface SingleDepartmentParams {
    curIns: number;
    departmentId: number;
    surveyFormId: number;
    startDate: string;
    endDate: string;
}

interface MultipleDepartmentParams {
    curIns: number;
    departmentIds: number[];
    startDate: string;
    endDate: string;
}
