<div class="container-fluid nbt-container">
    <div class="nbt-header">
        <div class="nbt-header-title">Customer Records</div>
        <div class="nbt-btn-right">
            <button class="nbt-btn-primary" appScreenPermission [permissionsInput]="[CREATE_CUSTOMER]"
                (click)="openCustomerDialogCreate()">
                <mat-icon class="mr8">add</mat-icon>
                Add Customer Record
            </button>
        </div>
    </div>
    <br>

    <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px;">
        <form [formGroup]="searchFormGroup">
            <div class="row">
                <div class="col-md-2 nbt-form">
                    <label>Search Customer by</label>
                    <mat-form-field appearance="outline">
                        <mat-select name="searchByType" formControlName="searchByType" placeholder="Select search by "
                                    [compareWith]="compareObjects">
                            <mat-option [value]="null">
                                Select search by
                            </mat-option>
                            <mat-option *ngFor="let custSearchBy of customerSearchBy" [value]="custSearchBy.value">
                                {{custSearchBy.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-8 nbt-form">
                    <label>Search Parameter</label>
                    <mat-form-field appearance="outline">
                        <input matInput appAutofocus formControlName="searchParameter" placeholder="Enter parameter">
                    </mat-form-field>
                </div>
                <div class="col-md-2 d-flex align-items-center justify-content-center" style="padding-bottom: 1.34375em;">
                    <button class=" nbt-btn-primary" (click)="search(0)">
                        <mat-icon class="mr8">search</mat-icon>
                        Search</button>
                </div>
                <div class="col-md-10 d-flex align-items-center nbt-form flex-row" *ngIf="isShowMysteryShopperCheckbox">
                    <mat-checkbox formControlName="isMysteryShopper" (change)="setMysteryShopper($event)">Mystery Shopper</mat-checkbox>
                </div>
            </div>
        </form>
    </mat-card-content>


    <br>
    <div><strong>{{numberOfRecords}}</strong> result<span *ngIf="numberOfRecords > 1">s</span></div>
    <br>
    <mat-card-content class="mat-card-content-table">
        <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="dataSource" *ngIf="dataSource.data" aria-label="table">
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.firstName">
                        {{ element.salutation}} {{ element.firstName}}
                    </ng-container>
                    <ng-template #elseTemplate>
                        -
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="isBuyer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Buyer
                </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.isBuyer" class="nbt-status status-eco-blue">
                        Yes
                    </div>
                    <div *ngIf="!element.isBuyer" class="nbt-status status-mid-grey">
                        No
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="ic">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>IC/Passport Number</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.ic || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.email || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="mainPhone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone (Main)</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.mainPhone || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_CUSTOMER)" (click)="openCustomerDialogEdit(element)" matTooltip="Edit">edit</mat-icon>
                    <mat-icon (click)="openCustomerDialogDetail(element)" matTooltip="View">remove_red_eye</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
        </table>
    </mat-card-content>
    <mat-paginator (page)="navigateToPage($event)" [length]="numberOfRecords" [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
    </mat-paginator>
</div>
