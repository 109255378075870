<mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px;" >
    <div class="loading-indicator" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!loading">
        <div class="nbt-header">
            <div class="col-md-12 nbt-details row">
                <div class="col-md-6">
                    <div class="mystery-shopper-header-title">Mystery Shopper Assesment - {{ mysteryShopperModel.department.name }}</div>
                </div>
                <div class="col-md-6" style="text-align:right; display:inline-grid">
                    <b>Overall Score : {{ mysteryShopperModel.score || '0' }} </b>
                    <i style="font-size: 10pt;">{{  mysteryShopperModel.mysteryShopperForm.name.toLowerCase().split("_").join(" ") }} </i>
                </div>
            </div>
        </div>
        <hr/> <br>

        <div class="mystery-shopper-survey-form">
            <mat-accordion multi>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Details Description
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <br>
                    <form [formGroup]="detailsFormGroup">
                        <div class="col-xl-12 nbt-details row">
                            <div class="col-xl-3">
                                <label><b>Phone</b></label>
                                <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px; display:flex;">
                                    <div class="row left-list-detail">
                                        <div class="nbt-form">
                                            <label><span class="required">*</span> Reception Staff Interacted With :</label>
                                            <mat-form-field appearance="outline">
                                                <mat-select name="phoneReceptionStaffName" formControlName="phoneReceptionStaff" placeholder="Select Reception Staff"
                                                            [compareWith]="compareObjects" [disabled]="msIsReceived">
                                                    <mat-option *ngFor="let prs of phoneReceptionStaffList" [value]="prs">
                                                        {{ prs.username }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="nbt-form">
                                            <label><span class="required">*</span> Sales Consultant Interacted With :</label>
                                            <mat-form-field appearance="outline">
                                                <mat-select name="phoneSalesConsultantStaffName" formControlName="phoneSalesConsultantStaff" placeholder="Select Sales Consultant Staff"
                                                            [compareWith]="compareObjects" [disabled]="msIsReceived">
                                                    <mat-option *ngFor="let psc of phoneSalesConsultantStaffList" [value]="psc">
                                                        {{ psc.username }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="nbt-form">
                                            <label><span class="required">*</span> Date Time :</label>
                                            <input class="date-picker" formControlName="phoneDatetimeString" type="datetime-local" placeholder="Select datetime" [readonly]="msIsReceived"/>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </div>
                            <div class="col-xl-3">
                                <label><b>Digital</b></label>
                                <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px; display:flex;">
                                    <div class="row left-list-detail">
                                        <div class="nbt-form">
                                            <label><span class="required">*</span> Sales Consultant Staff Interacted With :</label>
                                            <mat-form-field appearance="outline">
                                                <input matInput type="text" (ngModelChange)="searchDigitalSalesConsultant(digitalSalesConsultantControl.value)"
                                                       [formControl]="digitalSalesConsultantControl" [matAutocomplete]="auto" placeholder="Search Digital Sales Consultant" [readonly]="msIsReceived"/>
                                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionsScroll)="onScrollDigitalSalesConsultant()">
                                                    <mat-option *ngFor="let option of digitalSalesConsultantRecords" [value]="option">
                                                        {{option.username}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="nbt-form">
                                            <label>Instagram / Facebook Account :</label>
                                            <mat-form-field appearance="outline">
                                                <input matInput type="text" formControlName="digitalSocialMedia" placeholder="IG/Fb Account" [readonly]="msIsReceived">
                                            </mat-form-field>
                                        </div>
                                        <div class="nbt-form">
                                            <label><span class="required">*</span> Date Time :</label>
                                            <input class="date-picker" formControlName="digitalDatetimeString" type="datetime-local" placeholder="Select datetime" [readonly]="msIsReceived"/>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </div>
                            <div class="col-xl-3">
                                <label><b>Showroom</b></label>
                                <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px; display:flex;">
                                    <div class="row left-list-detail">
                                        <div class="nbt-form">
                                            <label><span class="required">*</span> Sales Consultant Staff Interacted With :</label>
                                            <mat-form-field appearance="outline">
                                                <input matInput type="text" (ngModelChange)="searchShowroomSalesConsultant(showroomSalesConsultantControl.value)" [readonly]="msIsReceived"
                                                       [formControl]="showroomSalesConsultantControl" [matAutocomplete]="auto2" placeholder="Search Showroom Sales Consultant"/>
                                                <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="displayFn" (optionsScroll)="onScrollShowroomSalesConsultant()">
                                                    <mat-option *ngFor="let option of showroomSalesConsultantRecords" [value]="option">
                                                        {{option.username}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="nbt-form">
                                            <label><span class="required">*</span> Vehicle Requested for Test Drive :</label>
                                            <mat-form-field appearance="outline">
                                                <mat-select name="modelRequested" formControlName="modelRequested" placeholder="Select Requested Model "
                                                            [compareWith]="compareObjects" [disabled]="msIsReceived">
                                                    <mat-option *ngFor="let model of modelsRequested" [value]="model">
                                                        {{ model.brand }} {{model.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="nbt-form">
                                            <label><span class="required">*</span> Vehicle Offered as Alternative :</label>
                                            <mat-form-field appearance="outline">
                                                <mat-select name="modelOffered" formControlName="modelOffered" placeholder="Select Offered Model "
                                                            [compareWith]="compareObjects" [disabled]="msIsReceived">
                                                    <mat-option *ngFor="let model of modelsOffered" [value]="model">
                                                        {{ model.brand }} {{model.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="nbt-form">
                                            <label><span class="required">*</span> Date Time :</label>
                                            <input class="date-picker" formControlName="showroomDatetimeString" type="datetime-local" placeholder="Select datetime" [readonly]="msIsReceived"/>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </div>
                            <div class="col-xl-3">
                                <label><b>Mystery Shopper Detail</b></label>
                                <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px; display:flex;">
                                    <div class="row left-list-detail">
                                        <div class="nbt-form">
                                            <label>Name :</label>
                                            <label>{{ mysteryShopperModel.customer.firstName }}</label>
                                        </div>
                                        <div class="nbt-form">
                                            <br>
                                            <label>Phone Number :</label>
                                            <label>{{ mysteryShopperModel.customer.mainPhone }}</label>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </div>
                        </div>
                    </form>
                </mat-expansion-panel>
                <br>
                <div *ngIf="appointmentPhoneSectionFormGroup">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="col-md-12 row">
                                    <div class="col-md-10">
                                        Appointment Phone Section
                                    </div>
                                    <div class="col-md-2">
                                        Score : {{ appointmentPhoneSectionFormGroup.controls.score.value || '0' }}
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <form [formGroup]="appointmentPhoneSectionFormGroup">
                            <div class="col-md-12 nbt-details" >
                                <div class="row" formArrayName="mysteryShopperDetails">
                                    <div *ngFor="let details of formArrayAppointmentPhoneSection() ; let i = index" [formGroupName]="i">
                                        <ng-container [ngTemplateOutlet]="questionForm1"
                                                      [ngTemplateOutletContext]="{details:details}">
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <ng-container [ngTemplateOutlet]="remark1"
                                          [ngTemplateOutletContext]="{formGroup:appointmentPhoneSectionFormGroup}">
                            </ng-container>
                            <hr/>
                            <div class="row">
                                <div class="attachment">
                                    <input id="appointmentPhoneSectionFileUpload" hidden class="attachment-section"  type="file" (change)="onFileSelected($event, 'APPOINTMENT_PHONE')" multiple [accept]="acceptedMimeTypes" placeholder="Browse..." #fileInput>
                                    <button  type="button" class="nbt-btn-secondary" data-dismiss="modal"
                                             (click)="triggerFileUpload('appointmentPhoneSectionFileUpload')"
                                             [disabled]="msIsReceived">
                                        Add File
                                    </button>
                                    <label><b>&ensp;Attachments</b></label>
                                    <mat-icon class="material-symbols-text" [matTooltip]="attContent" matTooltipPosition="below">
                                        info
                                    </mat-icon>
                                </div>
                                <div *ngIf="appointmentPhoneSectionAttachmentUrls.length > 0" class="preview-img-section">
                                    <div class="img-box" *ngFor="let img of appointmentPhoneSectionAttachmentUrls; let i = index">
                                        <ng-container [ngTemplateOutlet]="attachmentPreview"
                                                      [ngTemplateOutletContext]="{img:img}">
                                        </ng-container>
                                        <span class="dlt-btn">
                                        <mat-icon matTooltip="Click to download" class="mr8" (click)="downloadFile(img)">download</mat-icon>
                                        <mat-icon matTooltip="Click to remove" class="mr8"
                                                  *ngIf="!msIsReceived"
                                                  (click)="removeImage(i, img, 'APPOINTMENT_PHONE', appointmentPhoneSectionFormGroup.value.id)">
                                            delete
                                        </mat-icon>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>
                    <br>
                </div>
                <div *ngIf="appointmentDigitalSectionFormGroup">
                    <mat-expansion-panel>
                       <mat-expansion-panel-header>
                           <mat-panel-title>
                               <div class="col-md-12 row">
                                   <div class="col-md-10">
                                       Appointment Digital Section
                                   </div>
                                   <div class="col-md-2">
                                       Score : {{ appointmentDigitalSectionFormGroup.controls.score.value || '0' }}
                                   </div>
                               </div>
                           </mat-panel-title>
                       </mat-expansion-panel-header>
                       <br>
                       <form [formGroup]="appointmentDigitalSectionFormGroup">
                           <div class="col-md-12 nbt-details">
                               <div class="row" formArrayName="mysteryShopperDetails">
                                   <div *ngFor="let details of formArrayAppointmentDigitalSection() ; let i = index" [formGroupName]="i">
                                       <ng-container [ngTemplateOutlet]="questionForm1"
                                                     [ngTemplateOutletContext]="{details:details}">
                                       </ng-container>
                                   </div>
                               </div>
                           </div>
                           <ng-container [ngTemplateOutlet]="remark1"
                                         [ngTemplateOutletContext]="{formGroup:appointmentDigitalSectionFormGroup}">
                           </ng-container>
                           <hr/>
                           <div class="row">
                               <div class="attachment">
                                   <input id="appointmentDigitalSectionFileUpload" hidden class="attachment-section"  type="file" (change)="onFileSelected($event, 'APPOINTMENT_DIGITAL')" multiple [accept]="acceptedMimeTypes" placeholder="Browse..." #fileInput>
                                   <button  type="button" class="nbt-btn-secondary" data-dismiss="modal"
                                            (click)="triggerFileUpload('appointmentDigitalSectionFileUpload')"
                                            [disabled]="msIsReceived">
                                       Add File
                                   </button>
                                   <label><b>&ensp;Attachments</b></label>
                                   <mat-icon class="material-symbols-text" [matTooltip]="attContent" matTooltipPosition="below">
                                       info
                                   </mat-icon>
                               </div>
                               <div *ngIf="appointmentDigitalSectionAttachmentUrls.length > 0" class="preview-img-section">
                                   <div class="img-box" *ngFor="let img of appointmentDigitalSectionAttachmentUrls; let i = index">
                                       <ng-container [ngTemplateOutlet]="attachmentPreview"
                                                     [ngTemplateOutletContext]="{img:img}">
                                       </ng-container>
                                       <span class="dlt-btn">
                                        <mat-icon matTooltip="Click to download" class="mr8" (click)="downloadFile(img)">download</mat-icon>
                                        <mat-icon matTooltip="Click to remove" class="mr8"
                                                  *ngIf="!msIsReceived"
                                                  (click)="removeImage(i, img, 'APPOINTMENT_DIGITAL', appointmentDigitalSectionFormGroup.value.id)">
                                            delete
                                        </mat-icon>
                                    </span>
                                   </div>
                               </div>
                           </div>
                       </form>
                   </mat-expansion-panel>
                    <br>
                </div>
                <div *ngIf="welcomeSectionFormGroup">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="col-md-12 row">
                                    <div class="col-md-10">
                                        Welcome Section
                                    </div>
                                    <div class="col-md-2">
                                        Score : {{ welcomeSectionFormGroup.controls.score.value || '0' }}
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <form [formGroup]="welcomeSectionFormGroup">
                            <div class="col-md-12 nbt-details" >
                                <div class="row" formArrayName="mysteryShopperDetails">
                                    <div *ngFor="let details of formArrayWelcomeSection() ; let i = index" [formGroupName]="i">
                                        <ng-container [ngTemplateOutlet]="questionForm1"
                                                      [ngTemplateOutletContext]="{details:details}">
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <ng-container [ngTemplateOutlet]="remark1"
                                          [ngTemplateOutletContext]="{formGroup:welcomeSectionFormGroup}">
                            </ng-container>
                            <hr/>
                            <div class="row">
                                <div class="attachment">
                                    <input id="welcomeSectionFileUpload" hidden class="attachment-section"  type="file" (change)="onFileSelected($event, 'WELCOME')" multiple [accept]="acceptedMimeTypes" placeholder="Browse..." #fileInput>
                                    <button  type="button" class="nbt-btn-secondary" data-dismiss="modal"
                                             (click)="triggerFileUpload('welcomeSectionFileUpload')"
                                             [disabled]="msIsReceived">
                                        Add File
                                    </button>
                                    <label><b>&ensp;Attachments</b></label>
                                    <mat-icon class="material-symbols-text" [matTooltip]="attContent" matTooltipPosition="below">
                                        info
                                    </mat-icon>
                                </div>
                                <div *ngIf="welcomeSectionAttachmentUrls.length > 0" class="preview-img-section">
                                    <div class="img-box" *ngFor="let img of welcomeSectionAttachmentUrls; let i = index">
                                        <ng-container [ngTemplateOutlet]="attachmentPreview"
                                                      [ngTemplateOutletContext]="{img:img}">
                                        </ng-container>
                                        <span class="dlt-btn">
                                        <mat-icon matTooltip="Click to download" class="mr8" (click)="downloadFile(img)">download</mat-icon>
                                        <mat-icon matTooltip="Click to remove" class="mr8"
                                                  *ngIf="!msIsReceived"
                                                  (click)="removeImage(i, img, 'WELCOME', welcomeSectionFormGroup.value.id)">
                                            delete
                                        </mat-icon>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>
                    <br>
                </div>
                <div *ngIf="consultationSectionFormGroup">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="col-md-12 row">
                                    <div class="col-md-10">
                                        Consultation Section
                                    </div>
                                    <div class="col-md-2">
                                        Score : {{ consultationSectionFormGroup.controls.score.value || '0' }}
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <form [formGroup]="consultationSectionFormGroup">
                            <div class="col-md-12 nbt-details" >
                                <div class="row" formArrayName="mysteryShopperDetails">
                                    <div *ngFor="let details of formArrayConsultationSection() ; let i = index" [formGroupName]="i">
                                        <ng-container [ngTemplateOutlet]="questionForm1"
                                                      [ngTemplateOutletContext]="{details:details}">
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <ng-container [ngTemplateOutlet]="remark1"
                                          [ngTemplateOutletContext]="{formGroup:consultationSectionFormGroup}">
                            </ng-container>
                            <hr/>
                            <div class="row">
                                <div class="attachment">
                                    <input id="consultationSectionFileUpload" hidden class="attachment-section"  type="file" (change)="onFileSelected($event, 'CONSULTATION')" multiple [accept]="acceptedMimeTypes" placeholder="Browse..." #fileInput>
                                    <button  type="button" class="nbt-btn-secondary" data-dismiss="modal"
                                             (click)="triggerFileUpload('consultationSectionFileUpload')"
                                             [disabled]="msIsReceived">
                                        Add File
                                    </button>
                                    <label><b>&ensp;Attachments</b></label>
                                    <mat-icon class="material-symbols-text" [matTooltip]="attContent" matTooltipPosition="below">
                                        info
                                    </mat-icon>
                                </div>
                                <div *ngIf="consultationSectionAttachmentUrls.length > 0" class="preview-img-section">
                                    <div class="img-box" *ngFor="let img of consultationSectionAttachmentUrls; let i = index">
                                        <ng-container [ngTemplateOutlet]="attachmentPreview"
                                                      [ngTemplateOutletContext]="{img:img}">
                                        </ng-container>
                                        <span class="dlt-btn">
                                        <mat-icon matTooltip="Click to download" class="mr8" (click)="downloadFile(img)">download</mat-icon>
                                        <mat-icon matTooltip="Click to remove" class="mr8"
                                                  *ngIf="!msIsReceived"
                                                  (click)="removeImage(i, img, 'CONSULTATION', consultationSectionFormGroup.value.id)">
                                            delete
                                        </mat-icon>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>
                    <br>
                </div>
                <div *ngIf="presentationSectionFormGroup">
                    <mat-expansion-panel >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="col-md-12 row">
                                    <div class="col-md-10">
                                        Presentation Section
                                    </div>
                                    <div class="col-md-2">
                                        Score : {{ presentationSectionFormGroup.controls.score.value || '0' }}
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <form [formGroup]="presentationSectionFormGroup">
                            <div class="col-md-12 nbt-details" >
                                <div class="row" formArrayName="mysteryShopperDetails">
                                    <div *ngFor="let details of formArrayPresentationSection() ; let i = index" [formGroupName]="i">
                                        <ng-container [ngTemplateOutlet]="questionForm1"
                                                      [ngTemplateOutletContext]="{details:details}">
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <ng-container [ngTemplateOutlet]="remark1"
                                          [ngTemplateOutletContext]="{formGroup:presentationSectionFormGroup}">
                            </ng-container>
                            <hr/>
                            <div class="row">
                                <div class="attachment">
                                    <input id="presentationSectionFileUpload" hidden class="attachment-section"  type="file" (change)="onFileSelected($event, 'PRESENTATION')" multiple [accept]="acceptedMimeTypes" placeholder="Browse..." #fileInput>
                                    <button  type="button" class="nbt-btn-secondary" data-dismiss="modal"
                                             (click)="triggerFileUpload('presentationSectionFileUpload')"
                                             [disabled]="msIsReceived">
                                        Add File
                                    </button>
                                    <label><b>&ensp;Attachments</b></label>
                                    <mat-icon class="material-symbols-text" [matTooltip]="attContent" matTooltipPosition="below">
                                        info
                                    </mat-icon>
                                </div>
                                <div *ngIf="presentationSectionAttachmentUrls.length > 0" class="preview-img-section">
                                    <div class="img-box" *ngFor="let img of presentationSectionAttachmentUrls; let i = index">
                                        <ng-container [ngTemplateOutlet]="attachmentPreview"
                                                      [ngTemplateOutletContext]="{img:img}">
                                        </ng-container>
                                        <span class="dlt-btn">
                                        <mat-icon matTooltip="Click to download" class="mr8" (click)="downloadFile(img)">download</mat-icon>
                                        <mat-icon matTooltip="Click to remove" class="mr8"
                                                  *ngIf="!msIsReceived"
                                                  (click)="removeImage(i, img, 'PRESENTATION', presentationSectionFormGroup.value.id)">
                                            delete
                                        </mat-icon>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>
                    <br>
                </div>
                <div *ngIf="demonstrationSectionFormGroup">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="col-md-12 row">
                                    <div class="col-md-10">
                                        Demonstration (Test Drive) Section
                                    </div>
                                    <div class="col-md-2">
                                        Score : {{ demonstrationSectionFormGroup.controls.score.value || '0' }}
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <form [formGroup]="demonstrationSectionFormGroup">
                            <div class="col-md-12 nbt-details" >
                                <div class="row" formArrayName="mysteryShopperDetails">
                                    <div *ngFor="let details of formArrayDemonstrationSection() ; let i = index" [formGroupName]="i">
                                        <ng-container [ngTemplateOutlet]="questionForm1"
                                                      [ngTemplateOutletContext]="{details:details}">
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 nbt-details">
                                <span class="required">*Please Note : The Test Drive and Appointment should be prompted by the Sales Consultant NOT by the Mystery Shopper</span>
                            </div>
                            <br>
                            <ng-container [ngTemplateOutlet]="remark1"
                                          [ngTemplateOutletContext]="{formGroup:demonstrationSectionFormGroup}">
                            </ng-container>
                            <hr/>
                            <div class="row">
                                <div class="attachment">
                                    <input id="demonstrationSectionFileUpload" hidden class="attachment-section"  type="file" (change)="onFileSelected($event, 'DEMONSTRATION')" multiple [accept]="acceptedMimeTypes" placeholder="Browse..." #fileInput>
                                    <button  type="button" class="nbt-btn-secondary" data-dismiss="modal"
                                             (click)="triggerFileUpload('demonstrationSectionFileUpload')"
                                             [disabled]="msIsReceived">
                                        Add File
                                    </button>
                                    <label><b>&ensp;Attachments</b></label>
                                    <mat-icon class="material-symbols-text" [matTooltip]="attContent" matTooltipPosition="below">
                                        info
                                    </mat-icon>
                                </div>
                                <div *ngIf="demonstrationSectionAttachmentUrls.length > 0" class="preview-img-section">
                                    <div class="img-box" *ngFor="let img of demonstrationSectionAttachmentUrls; let i = index">
                                        <ng-container [ngTemplateOutlet]="attachmentPreview"
                                                      [ngTemplateOutletContext]="{img:img}">
                                        </ng-container>
                                        <span class="dlt-btn">
                                        <mat-icon matTooltip="Click to download" class="mr8" (click)="downloadFile(img)">download</mat-icon>
                                        <mat-icon matTooltip="Click to remove" class="mr8"
                                                  *ngIf="!msIsReceived"
                                                  (click)="removeImage(i, img, 'DEMONSTRATION', demonstrationSectionFormGroup.value.id)">
                                            delete
                                        </mat-icon>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>
                    <br>
                </div>
                <div *ngIf="communicationSectionFormGroup">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <ng-container *ngIf="mysteryShopperModel.mysteryShopperForm.name.toUpperCase().includes('VERSION_2'); else communicationSectionForm2">
                                    <div class="col-md-12 row">
                                        <div class="col-md-10">
                                            Communication (Whole Process) Section
                                        </div>
                                        <div class="col-md-2">
                                            Score : {{ communicationSectionFormGroup.controls.score.value || '0' }}
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #communicationSectionForm2>
                                    <div class="col-md-12 row">
                                        <div class="col-md-10">
                                            Communication (After Showroom Visit) Section
                                        </div>
                                        <div class="col-md-2">
                                            Score : {{ communicationSectionFormGroup.controls.score.value || '0' }}
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <form [formGroup]="communicationSectionFormGroup">
                            <div class="col-md-12 nbt-details" >
                                <div class="row" formArrayName="mysteryShopperDetails">
                                    <div *ngFor="let details of formArrayCommunicationSection() ; let i = index" [formGroupName]="i">
                                        <ng-container [ngTemplateOutlet]="questionForm1"
                                                      [ngTemplateOutletContext]="{details:details}">
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <ng-container [ngTemplateOutlet]="remark1"
                                          [ngTemplateOutletContext]="{formGroup:communicationSectionFormGroup}">
                            </ng-container>
                            <hr/>
                            <div class="row">
                                <div class="attachment">
                                    <input id="communicationSectionFileUpload" hidden class="attachment-section"  type="file" (change)="onFileSelected($event, 'COMMUNICATION')" multiple [accept]="acceptedMimeTypes" placeholder="Browse..." #fileInput>
                                    <button  type="button" class="nbt-btn-secondary" data-dismiss="modal"
                                             (click)="triggerFileUpload('communicationSectionFileUpload')"
                                             [disabled]="msIsReceived">
                                        Add File
                                    </button>
                                    <label><b>&ensp;Attachments</b></label>
                                    <mat-icon class="material-symbols-text" [matTooltip]="attContent" matTooltipPosition="below">
                                        info
                                    </mat-icon>
                                </div>
                                <div *ngIf="communicationSectionAttachmentUrls.length > 0" class="preview-img-section">
                                    <div class="img-box" *ngFor="let img of communicationSectionAttachmentUrls; let i = index">
                                        <ng-container [ngTemplateOutlet]="attachmentPreview"
                                                      [ngTemplateOutletContext]="{img:img}">
                                        </ng-container>
                                        <span class="dlt-btn">
                                        <mat-icon matTooltip="Click to download" class="mr8" (click)="downloadFile(img)">download</mat-icon>
                                        <mat-icon matTooltip="Click to remove" class="mr8"
                                                  *ngIf="!msIsReceived"
                                                  (click)="removeImage(i, img, 'COMMUNICATION', communicationSectionFormGroup.value.id)">
                                            delete
                                        </mat-icon>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>
                    <br>
                </div>
                <div *ngIf="agreementAndPotentialPurchaseSectionFormGroup">
                    <mat-expansion-panel>
                    <mat-expansion-panel-header>
                       <mat-panel-title>
                           <div class="col-md-12 row">
                               <div class="col-md-10">
                                   Agreement & Potential Purchase Section
                               </div>
                               <div class="col-md-2">
                                   Score : {{ agreementAndPotentialPurchaseSectionFormGroup.controls.score.value || '0' }}
                               </div>
                           </div>
                       </mat-panel-title>
                    </mat-expansion-panel-header>
                    <br>
                    <form [formGroup]="agreementAndPotentialPurchaseSectionFormGroup">
                       <div class="col-md-12 nbt-details" >
                           <div class="row" formArrayName="mysteryShopperDetails">
                               <div *ngFor="let details of formArrayAgreementAndPotentialPurchaseSection() ; let i = index" [formGroupName]="i">
                                   <ng-container [ngTemplateOutlet]="questionForm1"
                                                 [ngTemplateOutletContext]="{details:details}">
                                   </ng-container>
                               </div>
                           </div>
                       </div>
                       <ng-container [ngTemplateOutlet]="remark1"
                                     [ngTemplateOutletContext]="{formGroup:agreementAndPotentialPurchaseSectionFormGroup}">
                       </ng-container>
                    </form>
                    </mat-expansion-panel>
                    <br>
                </div>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <div *ngIf="mysteryShopperModel.mysteryShopperForm.name.toUpperCase().includes('VERSION_2'); else msImprovementSectionForm2">
                            <mat-panel-title>
                                Mystery Shopper View and Suggestions Section
                            </mat-panel-title>
                        </div>
                        <ng-template #msImprovementSectionForm2>
                            <mat-panel-title>
                                Mystery Shopper Views and Proposals for Improvement Section
                            </mat-panel-title>
                        </ng-template>
                    </mat-expansion-panel-header>
                    <br>
                    <div class="col-md-12 nbt-form-textarea">
                        <mat-form-field appearance="outline">
                                <textarea matInput appAutofocus type="text" [formControl]="remarkControl"
                                          [readonly]="msIsReceived" required
                                          placeholder="Enter remark" maxlength="2000" rows="10" #myInput >
                                </textarea>
                            <span>{{myInput.value.length}} / 2000</span>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <br>
            </mat-accordion>
        </div>
        <br>
        <span class="required"><i>* Before submit please fill all the required field</i></span>
        <br>
        <div class="nbt-dialog-footer">
            <ng-container *ngIf="isShowCancelButton">
                <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="back()">
                    <mat-icon class="mr8">cancel</mat-icon>
                    Cancel
                </button>
            </ng-container>
            <button type="button" class="nbt-btn-primary ml8" (click)="saveMsDetail(false)"
                    [disabled]="msIsReceived">
                <mat-icon class="mr8">save</mat-icon>
                Save
            </button>
            <button type="button" class="nbt-btn-primary ml8" (click)="saveMsDetail(true)"
                    [disabled]="msIsReceived || detailsFormGroup.invalid || appointmentPhoneSectionFormGroup.invalid
                                    || appointmentDigitalSectionFormGroup.invalid || welcomeSectionFormGroup.invalid
                                    || consultationSectionFormGroup.invalid || demonstrationSectionFormGroup.invalid
                                    || communicationSectionFormGroup.invalid || agreementAndPotentialPurchaseSectionFormGroup.invalid
                                    || presentationSectionFormGroup.invalid
                                    || !this.remarkControl.value">
                <mat-icon class="mr8">navigate_next</mat-icon>
                Submit
            </button>
        </div>
    </div>

    <ng-template #questionForm1 let-details="details" >
        <div class="row">
            <div class="col-md-8 question-align">
                <span><strong>Q{{details.value.mysteryShopperFormQuestion.seq}} : {{questionChecking(details.value.question,0)}}</strong>
                <br *ngIf="questionChecking(details.value.question,1)!==''">
                <i>Q{{details.value.mysteryShopperFormQuestion.seq}} : {{questionChecking(details.value.question,1)}}</i></span>
            </div>
            <div class="col-md-4 ">
                <div *ngIf="details.value.mysteryShopperFormQuestion.isAlternative; else answerFormNotAlternative">
                    <mat-radio-group aria-labelledby="example-radio-group-label" [formControl]="details.get('mysteryShopperFormAnswer')">
                        <mat-radio-button *ngFor="let ans of details.value.answers"
                                          [value]="ans" name="radio_survey_{{details.value.id}}"
                                          aria-label="Select an option"
                                          [checked]="details.value.mysteryShopperFormAnswer!== null && ans.id === details.value.mysteryShopperFormAnswer.id"
                                          [disabled]="msIsReceived">
                            <mat-radio-label>
                                <img [src]="ans.answerURL" class="img-size"/>
                            </mat-radio-label>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <ng-template #answerFormNotAlternative>
                    <mat-radio-group aria-labelledby="example-radio-group-label" [formControl]="details.get('mysteryShopperFormAnswer')" required>
                        <mat-radio-button *ngFor="let ans of details.value.answers"
                                          [value]="ans" name="radio_survey_{{details.value.id}}"
                                          aria-label="Select an option"
                                          [checked]="details.value.mysteryShopperFormAnswer!== null && ans.id === details.value.mysteryShopperFormAnswer.id"
                                          [disabled]="msIsReceived">
                            <mat-radio-label>
                                <img [src]="ans.answerURL" class="img-size"/>
                            </mat-radio-label>
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-template>
            </div>
        </div>
        <hr/>
    </ng-template>

    <ng-template #remark1 let-formGroup="formGroup">
        <div class="col-md-12 nbt-form-textarea">
            <br>
            <label><b>Please provide any further comments you may have</b> <span class="required">*</span></label>
            <br>
            <mat-form-field appearance="outline">
                <textarea matInput appAutofocus type="text" [formControl]="formGroup.get('remark')" [readonly]="msIsReceived"
                        placeholder="Enter remark" maxlength="2000" rows="4" #myInput>
                </textarea>
                <span>{{myInput.value.length}} / 2000</span>
            </mat-form-field>
        </div>
    </ng-template>

    <ng-template #attachmentPreview let-img="img">
        <img matTooltip="Click to preview" *ngIf="img.type === 'image'" [src]="img.url" alt="profile-img_{{i}}" (click)="previewImage(img)"/>
        <a matTooltip="Click to open" *ngIf="img.type === 'application'" [href]="img.url" target="_blank" rel="noopener noreferrer">
            <mat-icon class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">picture_as_pdf</mat-icon>
        </a>
        <mat-icon *ngIf="img.type === 'video'" class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">video_collection</mat-icon>
        <mat-icon *ngIf="img.type === 'audio'" class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">audio_file</mat-icon>
        <div class="att-name">{{img.name}} </div>
    </ng-template>
</mat-card-content>


