<div
    class="container-fluid nbt-container"
    appScreenPermission
    [permissionsInput]="[READ_REPORT]"
>
    <div class="loading-indicator" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>

    <div class="nbt-header">
        <div class="nbt-header-title">Voice Of Customer Reports</div>
        <div class="nbt-btn-right">
            <button
                class="nbt-btn-primary"
                (click)="generate()"
                [disabled]="disableGenerateButton()"
            >
                Generate
            </button>
        </div>
    </div>
</div>
<br />
<mat-card-content
    class="mat-card-content-table"
    style="background: #e7e7e7; overflow: hidden; padding: 20px"
>
    <form>
        <div class="row">
            <div class="nbt-form col-lg4 col-md-6">
                <label>Report Type</label>
                <mat-form-field appearance="outline">
                    <mat-select
                        name="report_type"
                        (selectionChange)="displayParams(selectedReportType)"
                        [(ngModel)]="selectedReportType"
                        placeholder="Select report type"
                        style="
                            background-color: white;
                            height: 100%;
                            padding-top: 14px;
                        "
                    >
                        <mat-option [value]="null">
                            Select report type
                        </mat-option>
                        <mat-option
                            *ngFor="let type of reportType"
                            [value]="type"
                        >
                            {{ type }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div
                class="nbt-form col-lg4 col-md-6"
                *ngIf="useDepartmentIdsParam || useDepartmentIdParam"
            >
                <label>Start Date</label>
                <mat-form-field appearance="outline">
                    <input
                        id="start_date_field"
                        name="start_date"
                        type="date"
                        matInput
                        [(ngModel)]="startDate"
                        style="
                            background-color: white;
                            height: 100%;
                            padding-top: 14px;
                        "
                    />
                </mat-form-field>
            </div>
            <div
                class="nbt-form col-lg4 col-md-6"
                *ngIf="useDepartmentIdsParam || useDepartmentIdParam"
            >
                <label>End Date</label>
                <mat-form-field appearance="outline">
                    <input
                        id="end_date_field"
                        name="end_date"
                        type="date"
                        matInput
                        [(ngModel)]="endDate"
                        style="
                            background-color: white;
                            height: 100%;
                            padding-top: 14px;
                        "
                    />
                </mat-form-field>
            </div>
            <div
                class="nbt-form col-lg4 col-md-6"
                *ngIf="useDepartmentIdsParam"
            >
                <label>Department</label>
                <mat-form-field appearance="outline">
                    <mat-select
                        name="department"
                        [(ngModel)]="departmentIds"
                        multiple
                        placeholder="Select Department"
                        style="
                            background-color: white;
                            height: 100%;
                            padding-top: 14px;
                        "
                        #departmentSelect
                    >
                        <mat-select-trigger
                            *ngIf="
                                departmentIds?.length > 0 &&
                                departmentIds?.length < departments.length
                            "
                        >
                            {{departmentIds?.[0] || ''}}
                            <span *ngIf="departmentIds?.length > 1">
                                (+{{ departmentIds?.length - 1 }}
                                {{
                                    departmentIds?.length === 2
                                        ? "other"
                                        : "others"
                                }})
                            </span>
                        </mat-select-trigger>
                        <mat-select-trigger
                            *ngIf="
                                departmentIds?.length === departments?.length
                            "
                            >All Departments</mat-select-trigger
                        >
                        <div class="nbt-select-all">
                            <mat-checkbox
                                (change)="toggleDepartmentAllSelection()"
                                #selectAllCheckbox
                                >Select All</mat-checkbox
                            >
                        </div>
                        <mat-option
                            #option
                            *ngFor="let dept of departments"
                            [value]="dept.name"
                            (click)="handleOption(option.selected)"
                        >
                            {{ dept.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="nbt-form col-lg4 col-md-6" *ngIf="useDepartmentIdParam">
                <label>Department</label>
                <mat-form-field appearance="outline">
                    <mat-select
                        name="department"
                        [(ngModel)]="departmentId"
                        placeholder="Select Department"
                        style="
                            background-color: white;
                            height: 100%;
                            padding-top: 14px;
                        "
                    >
                        <mat-option
                            *ngFor="let department of departments"
                            [value]="department.id"
                        >
                            {{ department.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="nbt-form col-lg4 col-md-6" *ngIf="useDepartmentIdParam">
                <label>Survey Form</label>
                <mat-form-field appearance="outline">
                    <mat-select
                        name="Survey Form"
                        [(ngModel)]="surveyFormId"
                        placeholder="Select Survey Form"
                        style="
                            background-color: white;
                            height: 100%;
                            padding-top: 14px;
                        "
                    >
                        <mat-option
                            *ngFor="let surveyForm of surveyForms"
                            [value]="surveyForm.id"
                        >
                            {{ surveyForm.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="nbt-form col-lg4 col-md-6" *ngIf="useTicketNumberParam">
                <label>Ticket Number</label>
                <mat-form-field appearance="outline">
                    <input
                        id="ticket_id"
                        name="ticket_id"
                        type="text"
                        matInput
                        [(ngModel)]="ticketNumber"
                        placeholder="Input Ticket Number"
                        style="
                            background-color: white;
                            height: 100%;
                            padding-top: 14px;
                        "
                    />
                </mat-form-field>
            </div>
            <div *ngIf="useMonthYearParam">
                <div class="nbt-form col-lg4 col-md-6">
                    <label>Mystery Shopper Survey Form</label>
                    <mat-form-field appearance="outline">
                        <mat-select
                            name="Mystery Shopper Survey Form"
                            [(ngModel)]="mysteryShopperSurveyFormId"
                            placeholder="Select Mystery Shopper Survey Form"
                            style="
                            background-color: white;
                            height: 100%;
                            padding-top: 14px;
                        "
                        >
                            <mat-option
                                *ngFor="let mysteryShopperSurveyForm of mysteryShopperSurveyForms"
                                [value]="mysteryShopperSurveyForm.id"
                            >
                                {{ mysteryShopperSurveyForm.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div class="nbt-form col-lg4 col-md-6" >
                        <label>Month</label>
                        <mat-form-field appearance="outline">
                            <mat-select
                                name="month"
                                [(ngModel)]="month"
                                placeholder="Select Month"
                                style="background-color: white; height: 100%; padding-top: 14px;">
                                <mat-option *ngFor="let month of months" [value]="month.value">
                                    {{ month.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="nbt-form col-lg4 col-md-6" >
                        <label>Year</label>
                        <mat-form-field appearance="outline">
                            <input
                                id="year"
                                name="year"
                                type="text"
                                matInput
                                [(ngModel)]="year"
                                placeholder="Input Year"
                                style="
                                    background-color: white;
                                    height: 100%;
                                    padding-top: 14px;
                                "
                            />
                    </mat-form-field>
                    </div>
                </div>
            </div>

        </div>
    </form>
</mat-card-content>
