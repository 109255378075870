import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { SlaModel } from 'src/app/model/sla.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';

@Component({
    selector: 'app-sla-dialog-detail',
    templateUrl: './sla-dialog-detail.component.html',
    styleUrls: ['./sla-dialog-detail.component.scss']
})
export class SlaDialogDetailComponent implements OnInit {

    readonly UPDATE_SLA = ApplicationPermissionsService.UPDATE_SLA;

    constructor(
        @Inject(MAT_DIALOG_DATA) public slaModel: any,
        public dialogRef: MatDialogRef<SlaDialogDetailComponent>,
        public authService: AuthService,
    ) { }

    ngOnInit(): void {
    }

    bgColorPriorityDot(index: number): string {
        switch (index) {
            case 0: return 'red';
            case 1: return 'yellow';
            case 2: return 'blue';
            case 3: return 'green';
        }
    }
}
