<div class="container-fluid nbt-container" (keydown)="handleKeyBoard($event)">
    <div class="loading-indicator" *ngIf="isExporting">
      <mat-spinner></mat-spinner>
    </div>
    <div class="nbt-header">
        <div class="nbt-header-title">VOC Listing</div>
        <div class="nbt-btn-right">
            <button class="nbt-btn-primary" appScreenPermission [permissionsInput]="[CREATE_TICKET]" (click)="openVOCNew()">
                <mat-icon class="mr8">add</mat-icon>
                Create VOC
            </button>
        </div>
    </div>
    <br>
    <mat-card-content class="mat-card-content-table" style="background: #E7E7E7; overflow: hidden; padding: 20px;">
        <form [formGroup]="searchFormGroup">
            <div class="row">
                <div class="nbt-form col-lg-4 col-md-6">
                    <label>Ticket Number</label>
                    <mat-form-field appearance="outline">
                        <input matInput id="ticketNumber" formControlName="ticketNumber" placeholder="Search ticket number" style="background-color: white;"/>
                    </mat-form-field>
                </div>
                <div class="nbt-form col-lg-4 col-md-6">
                    <label>VOC Group</label>
                    <mat-form-field appearance="outline">
                        <input matInput id="groupName" formControlName="groupName" placeholder="Search group name" style="background-color: white;"/>
                    </mat-form-field>
                </div>
                <div class="nbt-form col-lg-4 col-md-6">
                    <label>Customer Name</label>
                    <mat-form-field appearance="outline">
                        <input matInput id="customerName" formControlName="customerName" placeholder="Search customer name" style="background-color: white;"/>
                    </mat-form-field>
                </div>
                <div class="nbt-form col-lg-4 col-md-6">
                    <label>Department</label>
                    <mat-form-field appearance="outline">
                        <input matInput id="departmentName" formControlName="departmentName" placeholder="Search department name" style="background-color: white;"/>
                    </mat-form-field>
                </div>
                <div class="nbt-form col-lg-4 col-md-6">
                    <label>VOC Category</label>
                    <mat-form-field appearance="outline">
                        <input matInput id="categoryName" formControlName="categoryName" placeholder="Search category name" style="background-color: white;"/>
                    </mat-form-field>
                </div>
                <div class="nbt-form col-lg-4 col-md-6">
                    <label>Vehicle Registration Number</label>
                    <mat-form-field appearance="outline">
                        <input matInput id="username" formControlName="vehicleRegNumber" placeholder="Search vehicle registration number" style="background-color: white;"/>
                    </mat-form-field>
                </div>
                <div class="nbt-form col-lg-4 col-md-6">
                    <label>Consignee Username</label>
                    <mat-form-field appearance="outline">
                        <input matInput id="assigneeName" formControlName="assigneeName" placeholder="Search consignee name" style="background-color: white;"/>
                    </mat-form-field>
                </div>
                <div class="nbt-form col-lg-4 col-md-6">
                    <label>VOC Type</label>
                    <mat-form-field appearance="outline">
                        <input matInput id="typeName" formControlName="typeName" placeholder="Search type name" style="background-color: white;"/>
                    </mat-form-field>
                </div>
                <div class="nbt-form col-lg-4 col-md-6 justify-content-center checkbox-label">
                    <mat-checkbox formControlName="includeDeactivate">Include deactivated data</mat-checkbox>
                </div>
                <div class="nbt-form col-lg-4 col-md-6">
                    <label>VOC Status</label>
                    <mat-form-field appearance="outline">
                        <mat-select name="status" formControlName="status" placeholder="Select status" style="background-color: white; height: 100%; padding-top: 14px;" >
                            <mat-option [value]="null">
                                Select status
                            </mat-option>
                            <mat-option [value]="'all'">All</mat-option>
                            <mat-option *ngFor="let status of actionEnums" [value]="status.value">
                                {{status.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="nbt-form col-lg-4 col-md-6">
                    <label>Overdue</label>
                    <mat-form-field appearance="outline">
                        <mat-select name="status" formControlName="overdue" placeholder="Select overdue" style="background-color: white; height: 100%; padding-top: 14px;" >
                            <mat-option [value]="null">
                                Select overdue
                            </mat-option>
                            <mat-option *ngFor="let overdue of overdueList" [value]="overdue.value">
                                {{overdue.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="nbt-form col-lg-12" style="display: flex; flex-direction: row; justify-content: flex-end;">
                  <!-- This hidden button is a hacky solution to simulate enter key -->
                    <button type="submit" (click)="search()" hidden></button>
                    <button class="nbt-btn-secondary mr8" appScreenPermission [permissionsInput]="[READ_REPORT_TICKET_LISTING,READ_REPORT]" (click)="exportReport()" [disabled]="isExporting">
                        Export
                    </button>
                    <button class="nbt-btn-secondary mr8" (click)="refreshForm()">
                        Clear
                    </button>
                    <button class="nbt-btn-primary" (click)="resetPagination(); search();">
                        Search
                    </button>
                </div>
            </div>
        </form>
    </mat-card-content>
    <br>
    <div [ngStyle]="{'display': !loading ? 'none' : ''}" class="nbt-empty-result">
        <div class="nbt-data-loading"></div>
    </div>
    <div [ngStyle]="{'display': loading ? 'none' : ''}">
        <div><strong>{{numberOfRecords}}</strong> result<span *ngIf="numberOfRecords > 1">s</span></div>
        <br>
        <mat-card-content class="mat-card-content-table">
            <table mat-table matSort  (matSortChange)="sortPage($event)" [dataSource]="dataSource" *ngIf="dataSource.data" aria-label="table-voc-listing">
                <ng-container matColumnDef="ticketNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        VOC Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.ticketNumber || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Type
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.type?.name || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Title
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.title || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="channel">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Channel
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.channel || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="customer.firstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Customer Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.customer?.firstName || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="createdDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Created Datetime
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.createdDate | date: 'dd/MM/yyyy hh:mm a' || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="lastModifiedDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Last Updated Datetime
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.lastModifiedDate | date: 'dd/MM/yyyy hh:mm a' || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="incidentBranch.name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Incident Branch
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.incidentBranch?.name || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="vehicle.registrationNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        VOC Vehicle Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.vehicle?.registrationNo || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="ticketDatetime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        VOC Datetime
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.ticketDatetime | date: 'dd/MM/yyyy hh:mm a' || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="priority">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Priority
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.priority || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="department.name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Assigned Department
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.department?.name || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="assignee.username">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Consignee Username
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.assignee?.username || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="defaultDepartmentResponseOverdueDatetime" mat-header-date-cell>
                    <th mat-header-cell [hidden]="hiddenDefaultDepartment" *matHeaderCellDef>
                        Help-Desk Response Overdue Datetime
                    </th>
                    <td mat-cell [hidden]="hiddenDefaultDepartment" *matCellDef="let element" >
                        {{ element?.defaultDepartmentResponseOverdueDatetime | date: 'dd/MM/yyyy hh:mm a' || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="defaultDepartmentResponseLeadTime">
                    <th mat-header-cell [hidden]="hiddenDefaultDepartment" *matHeaderCellDef>
                        Help-Desk Response LeadTime (DD|HH|MM)
                    </th>
                    <td mat-cell [hidden]="hiddenDefaultDepartment" *matCellDef="let element" >
                        {{ element?.defaultDepartmentResponseLeadTime || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="defaultDepartmentResponseOverdue">
                    <th mat-header-cell [hidden]="hiddenDefaultDepartment" *matHeaderCellDef>
                        Help-Desk Response Comply
                    </th>
                    <td mat-cell [hidden]="hiddenDefaultDepartment" *matCellDef="let element" >
                        {{ element.defaultDepartmentResponseOverdue&&element?.defaultDepartmentResponseLeadTime?'F':element?.defaultDepartmentResponseLeadTime?'T':'-' || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="defaultDepartmentResolutionOverdueDatetime">
                    <th mat-header-cell [hidden]="hiddenDefaultDepartment" *matHeaderCellDef>
                        Help-Desk Resolution Overdue Datetime
                    </th>
                    <td mat-cell [hidden]="hiddenDefaultDepartment" *matCellDef="let element" >
                        {{ element?.defaultDepartmentResolutionOverdueDatetime | date: 'dd/MM/yyyy hh:mm a' || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="defaultDepartmentResolutionLeadTime">
                    <th mat-header-cell [hidden]="hiddenDefaultDepartment" *matHeaderCellDef>
                        Help-Desk Resolution LeadTime (DD|HH|MM)
                    </th>
                    <td mat-cell [hidden]="hiddenDefaultDepartment" *matCellDef="let element" >
                        {{ element?.defaultDepartmentResolutionLeadTime || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="defaultDepartmentResolutionOverdue">
                    <th mat-header-cell [hidden]="hiddenDefaultDepartment" *matHeaderCellDef>
                        Help-Desk Resolution Comply
                    </th>
                    <td mat-cell [hidden]="hiddenDefaultDepartment" *matCellDef="let element" >
                        {{ element?.defaultDepartmentResolutionOverdue&&element?.defaultDepartmentResolutionLeadTime?'F':element?.defaultDepartmentResolutionLeadTime?'T':'-' || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="departmentResponseOverdueDatetime">
                    <th mat-header-cell *matHeaderCellDef>
                        Assigned Response Overdue Datetime
                    </th>
                    <td mat-cell *matCellDef="let element" >
                        {{ element?.departmentResponseOverdueDatetime | date: 'dd/MM/yyyy hh:mm a' || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="departmentResponseOverdueLeadTime">
                    <th mat-header-cell *matHeaderCellDef>
                        Assigned Response LeadTime (DD|HH|MM)
                    </th>
                    <td mat-cell *matCellDef="let element" >
                        {{ element?.departmentResponseOverdueLeadTime || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="departmentResponseOverdue">
                    <th mat-header-cell *matHeaderCellDef>
                        Assigned Response Comply
                    </th>
                    <td mat-cell *matCellDef="let element" >
                        {{ element?.departmentResponseOverdue&&element?.departmentResponseOverdueLeadTime?'F':element?.departmentResponseOverdueLeadTime?'T':'-' || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="departmentResolutionOverdueDatetime">
                    <th mat-header-cell *matHeaderCellDef>
                        Assigned Resolution Overdue Datetime
                    </th>
                    <td mat-cell *matCellDef="let element" >
                        {{ element?.departmentResolutionOverdueDatetime | date: 'dd/MM/yyyy hh:mm a' || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="departmentResolutionOverdueLeadTime">
                    <th mat-header-cell *matHeaderCellDef>
                        Assigned Resolution LeadTime (DD|HH|MM)
                    </th>
                    <td mat-cell *matCellDef="let element" >
                        {{ element?.departmentResolutionOverdueLeadTime || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="departmentResolutionOverdue">
                    <th mat-header-cell *matHeaderCellDef>
                        Assigned Resolution Comply
                    </th>
                    <td mat-cell *matCellDef="let element" >
                        {{ element?.departmentResolutionOverdue&&element?.departmentResolutionOverdueLeadTime?'F':element?.departmentResolutionOverdueLeadTime?'T':'-'  || '-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="status" [stickyEnd]="windowWidth < 768 ? false : true">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Status
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="nbt-status" [ngClass]="getStatusColor(element.status)">
                            {{ statusValue(element.status) }}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions" [stickyEnd]="windowWidth < 768 ? false : true">
                    <th mat-header-cell *matHeaderCellDef style="color: white;">Actions</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_TICKET)" (click)="openVOCDetail(element)" matTooltip="View">remove_red_eye</mat-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
            </table>
        </mat-card-content>
        <mat-paginator (page)="navigateToPage($event)" [length]="numberOfRecords" [pageSize]="pageSize" [pageIndex]="filterService.paginationPage"
            [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
        </mat-paginator>
    </div>
</div>
