import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { PinEntryComponent } from './pin-entry/pin-entry.component';
import { ResetPasswordApplicationUserComponent } from './reset-password-email/reset-password-email.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'pin-entry',
    component: PinEntryComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordApplicationUserComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
