<div class="loading-indicator" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf="!loading">
    <div class="nbt-dialog-header">
        <div class="nbt-dialog-title" style="text-align:center;">
            <p><br><b><span>Once you have submitted, you </span>
                <span class="required"><b>will be not able</b></span>
                <span> to amend your answer. Are you sure you would like to submit ?</span>
                </b>
            </p>
        </div>
    </div>
    <div class="nbt-dialog-body">
        <div class="nbt-details row" style="justify-content:center">
            <div class="col-md-4">
                <button type="button" class="nbt-btn-primary" (click)="save()">
                    <mat-icon class="mr8">navigate_next</mat-icon>
                    Submit
                </button>
            </div>
            <div class="col-md-4">
                <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
                    <mat-icon class="mr8">clear</mat-icon>
                    Cancel
                </button>
            </div>


        </div>
    </div>
</div>

