import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { CategoryModel } from 'src/app/model/category.model';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-categories-dialog-new-or-edit',
  templateUrl: './categories-dialog-new-or-edit.component.html',
  styleUrls: ['./categories-dialog-new-or-edit.component.scss']
})
export class CategoriesDialogNewOrEditComponent implements OnInit {

  dialogType: string = '';
  categoryFormGroup: FormGroup;
  isSaving: boolean = false;
  parentGroup: CategoryModel[];
  loading: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public category: CategoryModel,
    public dialogRef: MatDialogRef<CategoriesDialogNewOrEditComponent>,
    private formBuilder : FormBuilder,
    private categoryService: CategoryService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    if(this.category?.id){
      this.dialogType = 'update';
      this.categoryFormGroup = this.formBuilder.group({
        id: this.formBuilder.control(this.category.id),
        name: this.formBuilder.control(this.category.name, Validators.required),
        description: this.formBuilder.control(this.category.description),
        active: this.formBuilder.control(this.category.active),
        parentCategory: this.formBuilder.control(this.category.parentCategory),
        path: this.formBuilder.control(this.category.path),
      })
    }else{
      this.dialogType = 'create';
      this.categoryFormGroup = this.formBuilder.group({
        name: this.formBuilder.control(null, Validators.required),
        description: this.formBuilder.control(null),
        active: this.formBuilder.control(true),
        parentCategory: this.formBuilder.control(this.category?.parentCategory),
      })
    }

    this.categoryService.getAllCategories(null,null,null,true).subscribe({
      next: response =>{
        this.parentGroup = response.body.filter( cat => cat.path.split('|').length === 1);
        this.deactiveLoading();
      }
    });
  }

  activeLoading(){
    this.loading = true;
  }

  deactiveLoading(){
      this.loading = false;
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id === object2.id;
  }

  checkParent() {
    if (this.categoryFormGroup.get('id') && this.categoryFormGroup.get('path')) {
        return true;
    } else {
        return false;
    }
  }

  save(): void {
    this.isSaving = true;
    this.activeLoading();
    if (this.dialogType === 'create') {
        this.categoryService.save(this.categoryFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
            next: () => {
                this.isSaving = false;
                this.deactiveLoading();
                this.dialogRef.close('ok');
            },
            error: error => {
                this.isSaving = false;
                this.deactiveLoading();
                this.snackBar.open(error.error.message, 'Close', {
                  duration: 60000
                });
            }
        });
    } else {
        this.categoryService.update(this.categoryFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
            next: () => {
                this.isSaving = false;
                this.deactiveLoading();
                this.dialogRef.close('ok');
            },
            error: error => {
                this.isSaving = false;
                this.deactiveLoading();
                this.snackBar.open(error.error.message, 'Close', {
                  duration: 60000
                });
            }
        });
    }
  }
}

