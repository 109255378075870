import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CategoryModel } from '../model/category.model';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {


    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) { }

    getAllCategories(
        name: string,
        id: number,
        sort?: string,
        getAll?: boolean
    ): Observable<HttpResponse<CategoryModel[]>> {
        let httpParams = new HttpParams();
        if(name)httpParams = httpParams.set('name', name);
        if(id)httpParams = httpParams.set('parentId', id);
        if (getAll)httpParams = httpParams.set('getAll', getAll);
        if (sort)httpParams = httpParams.set('sort', sort);

        httpParams = httpParams.set('curIns',  this.authService.getCurInsId());
        return this.httpClient.get<CategoryModel[]>(`/web/category/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    save(
        model: CategoryModel,
        curIns: string
    ): Observable<HttpResponse<CategoryModel>>{
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<CategoryModel>(`/web/category`, model, { observe: 'response', params: httpParams })
    }

    update(
        model: CategoryModel,
        curIns: string
    ): Observable<HttpResponse<CategoryModel>>{
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<CategoryModel>(`/web/category`, model, { observe: 'response', params: httpParams })
    }

    getCategoryById(id: number, active?: boolean): Observable<HttpResponse<CategoryModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        httpParams = httpParams.set('id', id);
        if (active !== null && active !== undefined) {
          httpParams = httpParams.set('active', active);
        }

        return this.httpClient.get<CategoryModel>('/web/category', {
            observe: 'response',
            params: httpParams
        });
    }

    getAllByParentIdNoPagination(id: number, getAll?: boolean): Observable<HttpResponse<CategoryModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        if(id){
            httpParams = httpParams.set('parentId', id);
        }
        if (getAll)httpParams = httpParams.set('getAll', getAll);
        return this.httpClient.get<CategoryModel[]>('/web/category/all-by-parent-id/no-pagination', {
            observe: 'response',
            params: httpParams
        })
    }
}
