import { Property, propertyNameCompare } from '../util';

export const ACTION_ENUM: Property[] = [
    {
        value: 'NEW',
        name: 'New',
    },
    {
        value: 'ASSIGNED',
        name: 'Assigned',
    },
    {
        value: 'IN_PROGRESS',
        name: 'In Progress',
    },
    {
        value: 'RESOLVED',
        name: 'Resolved',
    },
    {
        value: 'CLOSED',
        name: 'Closed',
    },
    {
        value: 'ON_HOLD',
        name: 'On-Hold',
    },
    {
        value: 'CANCEL',
        name: 'Cancel',
    },
    {
        value: 'RE_OPEN',
        name: 'Re-Open',
    },
    {
        value: 'RESUME',
        name: 'Resume',
    },
    {
        value: 'OPEN',
        name: 'Open',
    },
    {
        value: 'RESCHEDULED',
        name: 'Rescheduled',
    },
    {
        value: 'COMPLETED',
        name: 'Completed',
    },
    {
        value: 'DECLINED',
        name: 'Declined',
    },
].sort(propertyNameCompare);
