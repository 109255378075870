<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create New Business Hour
        </ng-container>
        <ng-template #elseTemplate>
            Edit Business Hour '{{businessHourModel.name}}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="businessHourFormGroup">
        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Name <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="name" placeholder="Enter name" maxlength="100" required/>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form-textarea">
                <label>Description</label>
                <mat-form-field appearance="outline">
                    <textarea matInput appAutofocus type="text" formControlName="description" 
                    placeholder="Enter description" maxlength="255"></textarea>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Institution</label>
                <mat-form-field appearance="outline">
                    <mat-select name="institution" formControlName="institution" [compareWith]="compareObjects"
                        [disabled]="checkInstitution()" placeholder="Select institution">
                        <mat-option [value]="null">
                            Select institution
                        </mat-option>
                        <mat-option *ngFor="let ins of institutions" [value]="ins">
                            {{ ins.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form">
                <label>Select working days</label>
                <br>
                <div class="day-picker-wrap">
                    <div (click)="selectDays('SUNDAY', 1)" [ngClass]="isSelectedDay('SUNDAY') ? 'day-picker-business-hour-active' : 'day-picker-business-hour'">Sun</div>
                    <div (click)="selectDays('MONDAY', 2)" [ngClass]="isSelectedDay('MONDAY') ? 'day-picker-business-hour-active' : 'day-picker-business-hour'">Mon</div>
                    <div (click)="selectDays('TUESDAY', 3)" [ngClass]="isSelectedDay('TUESDAY') ? 'day-picker-business-hour-active' : 'day-picker-business-hour'">Tue</div>
                    <div (click)="selectDays('WEDNESDAY', 4)" [ngClass]="isSelectedDay('WEDNESDAY') ? 'day-picker-business-hour-active' : 'day-picker-business-hour'">Wed</div>
                    <div (click)="selectDays('THURSDAY', 5)" [ngClass]="isSelectedDay('THURSDAY') ? 'day-picker-business-hour-active' : 'day-picker-business-hour'">Thu</div>
                    <div (click)="selectDays('FRIDAY', 6)" [ngClass]="isSelectedDay('FRIDAY') ? 'day-picker-business-hour-active' : 'day-picker-business-hour'">Fri</div>
                    <div (click)="selectDays('SATURDAY', 7)" [ngClass]="isSelectedDay('SATURDAY') ? 'day-picker-business-hour-active' : 'day-picker-business-hour'">Sat</div>
                </div>
            </div>
            <div class="col-md-12 nbt-form" *ngIf="formArrayBusinessHourDetails().length > 0">
                <br>
                <br>
                <label>Enter working hours</label>
                <br>
                <div class="col-md-3">
                    <ng-container *ngIf="formArrayBusinessHourDetails().length > 1 && windowWidth < 768">
                        <button type="button" class="nbt-btn-secondary" (click)="copyAllTime()">
                            <mat-icon class="mr8">content_copy</mat-icon>
                            Copy All
                        </button>
                    </ng-container>
                </div>
                <br>
                <div class="row" style="align-items: center" formArrayName="businessHourDetails">
                    <ng-container *ngFor="let det of formArrayBusinessHourDetails(); let i = index" [formGroupName]="i">
                        <div class="col-md-2">{{det.value?.day}}</div>
                        <div class="col-md-3 nbt-form" style="height: 50px;">
                            <mat-form-field appearance="outline">
                                <input matInput type="time" formControlName="startTime"/>
                            </mat-form-field>
                        </div>
                        <div class="col-md-1">to</div>
                        <div class="col-md-3 nbt-form" style="height: 50px;">
                            <mat-form-field appearance="outline">
                                <input matInput type="time" formControlName="endTime"/>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <ng-container *ngIf="i === 0 && formArrayBusinessHourDetails().length > 1 && windowWidth >= 768">
                                <button type="button" class="nbt-btn-secondary" (click)="copyAllTime()">
                                    <mat-icon class="mr8">content_copy</mat-icon>
                                    Copy All
                                </button>
                            </ng-container>
                        </div>
                        <br>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
    <span class="required">* Required Field For Submission</span> 
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || businessHourFormGroup.invalid"
        (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>