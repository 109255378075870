import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { DepartmentModel } from 'src/app/model/department.model';
import { Model } from 'src/app/model/model.model';
import { REGEXS } from 'src/app/shared/constant/regex.constant';
import { SurveyFormQuesModel } from 'src/app/model/survey-form-question.model';
import { SurveyModel } from 'src/app/model/survey.model';
import { UpdateSurveyModel } from 'src/app/model/update-survey.model';
import { DepartmentService } from 'src/app/services/department.service';
import { ModelService } from 'src/app/services/model.service';
import { SurveyService } from 'src/app/services/survey.service';
import { CSI_FORM_CONTENT } from 'src/app/shared/constant/satisfactory-form.constant';
import { DatePipe } from '@angular/common';
import { IMAGE_ENUM, TOOLTIPS } from 'src/app/shared/constant';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { CustDialogNewOrEditComponent } from '../cust-dialog-new-or-edit/cust-dialog-new-or-edit.component';

@Component({
    selector: 'app-cust-form',
    templateUrl: './cust-form.component.html',
    styleUrls: ['./cust-form.component.scss']
})

export class CustFormComponent implements OnInit {
    readonly snackBarConfig: MatSnackBarConfig = { duration: 60000 };
    readonly MODIFY_CSI = ApplicationPermissionsService.MODIFY_CSI;
    formContent: any;
    formContentM: any;
    loading: boolean = false;
    csiFormGroup: FormGroup;
    currentDate :Date;
    localCurrentDate: string;
    serviceCentres: DepartmentModel[];
    surveyForm : SurveyFormQuesModel[];
    models: Model[];
    surveyHeaderId: any;
    surveyModel: SurveyModel;
    dialogType : string ='';
    updateSurveyModel : UpdateSurveyModel;
    imageEnum = IMAGE_ENUM;
    na: string = "../assets/survey/na.png";
    respond =" has been successfully responded.";
    close = "Close";
    isSaving: boolean = false;
    phoneContent: string = TOOLTIPS.phoneContent;
    brandToyota: string = "TOYOTA";
    brandLexus: string = "LEXUS";

    constructor(
        private formBuilder: FormBuilder,
        private modelService: ModelService,
        private route: ActivatedRoute,
        private surveyService: SurveyService,
        private departmentService: DepartmentService,
        private authService:AuthService,
        private snackBar : MatSnackBar,
        private datePipe: DatePipe,
        public dialog: MatDialog,

    ) {
        this.currentDate = new Date();
        this.localCurrentDate = new Date(this.currentDate.getTime() - (this.currentDate.getTimezoneOffset() * 60000)).toISOString();

    }

    ngOnInit(): void {
        this.activeLoading();
        this.getSurveyFormDetail();
        this.csiFormGroup = this.formBuilder.group({
        vehicle : this.formBuilder.group({
            model: this.formBuilder.control(null, Validators.required),
            registrationNo: this.formBuilder.control(null, Validators.required),
        }),
        serviceCenter: this.formBuilder.control(null),
        serviceDatetimeString: this.formBuilder.control(null),
        remark: this.formBuilder.control(null),
        surveyDetails: this.formBuilder.array([]),
        phoneNumber : this.formBuilder.control(null, [Validators.required, Validators.pattern(REGEXS.PHONE_FILTER)]),
        });
        this.modelService.getAllModelNoPagination().subscribe({
            next: (value) => {
                this.models = value.body;
            }
        });

        this.getDepartmentsWithDefaultDepartmentChecking();
    }

    getSurveyFormDetail(){
        this.route.queryParams.subscribe({
            next: param =>{
                if(param.id){
                    this.dialogType = 'read';
                    this.surveyHeaderId = param.id;
                    this.surveyService.getOneSurvey(this.surveyHeaderId).subscribe({
                        next: value => {
                            this.surveyModel = (value.body);
                            this.checkLanguage(this.surveyModel.surveyForm?.brand);
                            if(this.surveyModel.isReceive){
                            this.csiFormGroup = this.formBuilder.group({
                                id: this.surveyHeaderId,
                                vehicle : this.formBuilder.group({
                                model: this.formBuilder.control(this.surveyModel.vehicle?.model),
                                registrationNo: this.formBuilder.control(this.surveyModel.vehicle?.registrationNo),
                                }),
                                serviceCenter: this.formBuilder.control(this.surveyModel.department.name),
                                serviceDatetimeString: this.formBuilder.control(this.checkDate(this.surveyModel.serviceDatetime)),
                                remark: this.formBuilder.control(this.surveyModel.remark),
                                phoneNumber : this.formBuilder.control(this.surveyModel.phoneNumber, [Validators.required, Validators.pattern(REGEXS.PHONE_FILTER)]),
                                surveyDetails: this.formBuilder.array(value.body.surveyDetails.map(detail =>this.formBuilder.group({
                                    id: detail.id,
                                    isReceive: this.surveyModel.isReceive,
                                    question: detail.surveyFormQuestion.question,
                                    surveyFormQuestion: detail.surveyFormQuestion,
                                    surveyFormAnswer: detail.surveyFormAnswer==null ? null : this.formBuilder.array(detail.surveyFormAnswer.map(ans => this.formBuilder.group({
                                        id: ans.id,
                                        answerImage: ans.answerImage,
                                        score: ans.score
                                    }))),
                                    answerURL: this.processImage(detail.surveyFormAnswer==null ? undefined : detail.surveyFormAnswer[0].answerImage),
                                    answers: this.formBuilder.array(detail.surveyFormQuestion.surveyFormAnswers.map(ans => this.formBuilder.group({
                                        id: ans.id,
                                        answerImage: ans.answerImage,
                                        score: ans.score
                                    }))),
                                }))),
                            });
                            this.csiFormGroup.disable();
                            }else{
                            this.dialogType = 'create';
                            this.csiFormGroup = this.formBuilder.group({
                                id: this.surveyHeaderId,
                                vehicle : this.formBuilder.group({
                                model: this.formBuilder.control(null),
                                registrationNo: this.formBuilder.control(null),
                                }),
                                serviceCenter: this.formBuilder.control(null),
                                serviceDatetimeString: this.formBuilder.control(this.localCurrentDate.substring(0, 16), [Validators.required]),
                                remark: this.formBuilder.control(null),
                                phoneNumber: this.formBuilder.control(null, Validators.pattern(REGEXS.PHONE_FILTER)),
                                surveyDetails: this.formBuilder.array(value.body.surveyDetails.map(detail =>this.formBuilder.group({
                                    id: detail.id,
                                    question: detail.surveyFormQuestion.question,
                                    surveyFormQuestion: detail.surveyFormQuestion,
                                    surveyFormAnswer: null,
                                    answers: this.formBuilder.array(detail.surveyFormQuestion.surveyFormAnswers.map(ans => this.formBuilder.group({
                                        id: ans.id,
                                        answerImage: ans.answerImage,
                                        answerURL: this.processImage(ans.answerImage),
                                        score: ans.score
                                    }))),
                                    remark: detail.remark,
                                }))),
                            });
                            if(!this.surveyModel.isActive) {
                                this.csiFormGroup.disable();
                            }
                            }
                            this.deactiveLoading();
                        }
                    });
                }
            }
        })
    }

    checkDate(inputDate: string):string{
        if(inputDate !== null && inputDate !== undefined){
            return this.datePipe.transform(new Date(this.surveyModel.serviceDatetime),"yyyy-MM-dd'T'HH:mm")
        }
        return "";
    }

    checkLanguage(brand: string){
        switch(brand){
            case this.brandToyota:
                this.formContent = CSI_FORM_CONTENT.english_toyota;
                this.formContentM = CSI_FORM_CONTENT.malay_toyota;
                break;
            case this.brandLexus:
                this.formContent = CSI_FORM_CONTENT.english_lexus;
                this.formContentM = CSI_FORM_CONTENT.malay_lexus;
                break;
            default:
                this.formContent = CSI_FORM_CONTENT.english_toyota;
                this.formContentM = CSI_FORM_CONTENT.malay_toyota;
        }
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    formArraySurvey() {
        const array = (this.csiFormGroup.get('surveyDetails') as FormArray).controls;
        return array;
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    back() {
        window.history.back();
    }

    questionChecking(question: String, langInd: number){
        return question.split('|')[langInd];
    }

    saveSurvey():void{
        this.activeLoading();
        this.updateSurveyModel = this.csiFormGroup.getRawValue();
        this.updateSurveyModel.serviceDatetimeString = this.updateSurveyModel.serviceDatetimeString  + ':00+0800';
        this.surveyService.updateSurvey(this.updateSurveyModel, this.authService.getCurInsId()).subscribe({
            next: (response) => {
                this.openDialog();
            },
            error: error => {
                let theMessage  = '';
                this.deactiveLoading();
                error.error.subErrors?.forEach(subError => {
                    theMessage += subError.message + ' \n ';
                });
                if(theMessage){
                    this.snackBar.open(theMessage, this.close, this.snackBarConfig);
                }else{
                    this.snackBar.open(error?.error?.message, this.close, this.snackBarConfig);
                }
            }
        });
    }

    processImage(imageName: any){
        let image;
        if(imageName != undefined){
            image = this.imageEnum.find((image) => image.name === imageName).value;
        }
        if(image===undefined){
        image = this.na;
        }
        return image;
    }

    checkRadio(event:boolean, i:number){
        const array = (this.csiFormGroup.get('surveyDetails') as FormArray);

        if(event){
            array.controls[i].patchValue({ surveyFormAnswer:null });
            array.controls[i].disable();
        }else{
            array.controls[i].enable();
        }
    }

    openDialog() {
        const dialogRef = this.dialog.open(DialogContentDialog);

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
            this.deactiveLoading();
            this.back();
        });
    }

    //TODO: REFACTOR FUNCTION
    getDepartmentsWithDefaultDepartmentChecking() {
        this.departmentService.isDefaultDepartment(this.authService.getCurInsId()).subscribe({
            next: (isDefaultDepartment) => {
                if (isDefaultDepartment.body) {
                    this.departmentService.getAllNoPagination(null, true, false).subscribe({
                        next: (res) => {
                            this.serviceCentres = res.body;
                        },
                        error: (res) => {
                            this.snackBar.open(
                                res.error.message,
                                'Close',
                                this.snackBarConfig
                            );
                        },
                    });
                } else {
                    this.departmentService.getDepartmentByUser(this.authService.getCurInsId(), null, true, false).subscribe({
                            next: (res) => {
                                this.serviceCentres = res.body;
                            },
                            error: (res) => {
                                this.snackBar.open(
                                    res.error.message,
                                    'Close',
                                    this.snackBarConfig
                                );
                            },
                        });
                }
            },
            error: (err) => {
                this.snackBar.open(
                    err.error.message,
                    'Close',
                    this.snackBarConfig
                );
            },
        });
    }

    isNASize(answer: string) {
        if(!answer)
            return false;
        return answer.includes("na.png");
    }

    modification() {
        const modalRef = this.dialog.open(CustDialogNewOrEditComponent, {
            data: this.surveyModel,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.ngOnInit();
            }
        })
    }
}

@Component({
    selector: 'dialog-content-dialog',
    templateUrl: './dialog-content-dialog.html',
})
export class DialogContentDialog {}

