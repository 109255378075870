<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        View Holiday '{{holidayModel.name}}'
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <div class="nbt-details row">
        <div class="col-md-6">
            <label>Name</label>
            <div class="value" maxlength="100">{{holidayModel.name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Active</label>
            <div class="value">{{holidayModel.active ? 'Active' : 'Not Active'}}</div>
        </div>
        <div class="col-md-6">
            <label>Institution</label>
            <div class="value">{{holidayModel.institution?.name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Date</label>
            <div class="value">{{holidayModel.date || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Recurrent</label>
            <div class="value">{{holidayModel.recurrent ? 'Yes' : 'No'}}</div>
        </div>
    </div>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Close
    </button>
    <button type="button" *ngIf="authService.hasAuthorityMenu(DELETE_HOLIDAY)" class="nbt-btn-primary ml8" (click)="openHolidayDialogDelete(holidayModel)">
        <mat-icon class="mr8">delete</mat-icon>
        Delete
    </button>
    <button type="button" *ngIf="authService.hasAuthorityMenu(UPDATE_HOLIDAY)" class="nbt-btn-primary ml8" (click)="dialogRef.close('edit')">
        <mat-icon class="mr8">edit</mat-icon>
        Edit
    </button>
</div>