import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/auth/auth.service';
import { NpsService } from 'src/app/services/nps.service';
import { CustomerRecordsService } from 'src/app/services/customer-records.service';
import { NpsModel } from 'src/app/model/nps.model';
import { CustomerRecordModel, CustomerRecordActivityModel } from 'src/app/model/customer-records.model';
import { TimeZone, SnackBarStatus, ACTION_ENUM } from 'src/app/shared/constant';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-nps-call-in-dialog',
  templateUrl: './nps-call-in-dialog.component.html',
  styleUrls: ['./nps-call-in-dialog.component.scss']
})

export class NpsCallInDialogComponent implements OnInit {

    windowWidth: number = window.innerWidth;
    readonly snackBarConfig: MatSnackBarConfig = { duration:60000 };
    isSaving:boolean = false;
    loading: boolean = false;
    archivedLoading: boolean = false;
    npsCallInFormGroup: FormGroup;
    currentDate : Date;
    localCurrentDate: string;
    callDatetimeString: string;
    customerRecordActivityModel: CustomerRecordActivityModel;
    close = SnackBarStatus.CLOSE;
    actionEnums: any[] = ACTION_ENUM;

    constructor(
        @Inject(MAT_DIALOG_DATA) public npsModel: NpsModel,
        public dialogRef: MatDialogRef<NpsCallInDialogComponent>,
        private formBuilder : FormBuilder,
        private snackBar: MatSnackBar,
        private datePipe: DatePipe,
        private authService: AuthService,
        private npsService: NpsService,
        private customerRecordsService: CustomerRecordsService,
    ) {
        this.currentDate = new Date();
        this.localCurrentDate = new Date(this.currentDate.getTime() - (this.currentDate.getTimezoneOffset() * 60000)).toISOString();
        this.callDatetimeString = this.localCurrentDate.substring(0, 16);
    }

    ngOnInit(): void {
        this.activeLoading();
        this.getActivityRecord();
        this.npsCallInFormGroup = this.formBuilder.group({
            id: this.formBuilder.control(null),
            status: this.formBuilder.control(null),
            callDatetimeString: this.formBuilder.control(null, [Validators.required]),
            callDetail: this.formBuilder.control(null, [Validators.required]),
        })
    }

    getActivityRecord() {
        this.customerRecordsService.getOneActivity(this.npsModel.activity.id,
                                                    this.npsModel.activity.customer.id,
                                                    this.authService.getCurInsId()).subscribe({
            next: value => {
                this.customerRecordActivityModel = value.body;
                //Calling customer record here because when call activity not returning all customer data
                this.getCustomerRecord();
            },
            error: error => {
                this.deactiveLoading();
                this.snackBar.open(error.message, this.close, this.snackBarConfig);
            }
        });
    }

    getCustomerRecord() {
        this.customerRecordsService.getOne(this.customerRecordActivityModel.customer.id, this.authService.getCurInsId()).subscribe({
            next: value => {
                this.customerRecordActivityModel.customer = value.body;
                this.defineNpsCallInFormGroup();
                this.deactiveLoading();
            },
            error: error => {
                this.deactiveLoading();
                this.snackBar.open(error.message, this.close, this.snackBarConfig);
            }
        });
    }

    defineNpsCallInFormGroup() {
        if(this.npsModel.callDatetime!=null || this.npsModel.callDatetime!=undefined) {
            this.callDatetimeString = this.datePipe.transform(new Date(this.npsModel.callDatetime),"yyyy-MM-dd'T'HH:mm");
        }

        this.npsCallInFormGroup = this.formBuilder.group({
            id: this.formBuilder.control(this.npsModel.id),
            status: this.formBuilder.control(this.npsModel.status),
            callDatetimeString: this.formBuilder.control(this.callDatetimeString, [Validators.required]),
            callDetail: this.formBuilder.control(this.npsModel.callDetail, [Validators.required]),
        })
    }

    save(status: String) {
        this.isSaving = true;
        this.activeLoading();
        const npsValue = this.npsCallInFormGroup.getRawValue();
        npsValue.status = status;
        npsValue.callDatetimeString = npsValue.callDatetimeString + ':00' + TimeZone.BRUNEI_TIMEZONE;
        this.npsService.updateNPS(npsValue, this.authService.getCurInsId()).subscribe({
            next: response => {
                this.isSaving = false;
                this.deactiveLoading();
                this.npsModel = response.body;
                let theMessage = '';
                theMessage = this.npsModel.npsNumber + ' has been successfully updated. Status is ' + this.npsModel.status;
                this.snackBar.open(theMessage, 'Close', this.snackBarConfig);
                this.dialogRef.close();
            },
            error: error => {
                this.isSaving = false;
                this.deactiveLoading();
                this.snackBar.open(error.message, this.close, this.snackBarConfig);
            }
        })
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;

    }
    activeArchivedLoading(){
        this.archivedLoading = true;
    }

    deactiveArchivedLoading(){
        this.archivedLoading = false;
    }

    statusValue(value: string): string {
        return this.actionEnums.find(status => status.value === value)?.name;
    }

    getStatusColor(value: string) {
        if (value === 'OPEN') {
          return 'status-color-new';
        }
        if (value === 'RESCHEDULED') {
          return 'status-color-rescheduled';
        }
        if (value === 'COMPLETED') {
          return 'status-color-completed';
        }
        if (value === 'DECLINED') {
          return 'status-color-declined';
        }
    }

    @HostListener('window:resize')
      onResize() {
          this.windowWidth = window.innerWidth;
    }
}
