/* eslint-disable no-underscore-dangle */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanelHeader } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { DepartmentModel } from 'src/app/model/department.model';
import { NpsModel } from 'src/app/model/nps.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DepartmentService } from 'src/app/services/department.service';
import { NpsService } from 'src/app/services/nps.service';
import { UsersService } from 'src/app/services/users.service';
import { ACTION_ENUM } from 'src/app/shared/constant';
import { NpsCallInDialogDetailComponent } from '../nps-call-in-dialog-detail/nps-call-in-dialog-detail.component';
import { NpsCallInDialogComponent } from '../nps-call-in-dialog/nps-call-in-dialog.component';

@Component({
    selector: 'app-nps-listing',
    templateUrl: './nps-listing.component.html',
    styleUrls: ['./nps-listing.component.scss'],
})
export class NpsListingComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;

    readonly snackBarConfig: MatSnackBarConfig = { duration: 60000 };
    readonly CREATE_NPS = ApplicationPermissionsService.CREATE_NPS;
    readonly UPDATE_NPS = ApplicationPermissionsService.UPDATE_NPS;
    readonly READ_FULL_NPS = ApplicationPermissionsService.READ_FULL_NPS;
    readonly READ_INDIVIDUAL_NPS =
        ApplicationPermissionsService.READ_INDIVIDUAL_NPS;

    // Columns constant
    readonly NUMBER = 'number';
    readonly NPS_NUMBER = 'npsNumber';
    readonly CUSTOMER_MAIN_PHONE = 'activity.customer.mainPhone';
    readonly REGISTRATION_NO = 'activity.vehicle.registrationNo';
    readonly ACTUAL_DATE = 'activity.actualDate';
    readonly BRANCH = 'activity.department.name';
    readonly STATUS = 'status';
    readonly ACTIONS = 'actions';

    // Table type constant
    readonly SALES_BUYER = TableType.SALES_BUYER;
    readonly SALES_NON_BUYER = TableType.SALES_NON_BUYER;
    readonly AFTER_SALES = TableType.AFTER_SALES;

    readonly ASC = 'ASC';
    readonly DESC = 'DESC';

    loading = false;
    archivedLoading = false;

    //Table
    obs: BehaviorSubject<NpsModel[]>;

    acBuyerRecords = 0;
    acBuyerCurrentPage = 0;
    acBuyerPageSize = 20;
    acBuyerDataSource: MatTableDataSource<NpsModel> =
        new MatTableDataSource<NpsModel>();

    arBuyerRecords = 0;
    arBuyerCurrentPage = 0;
    arBuyerPageSize = 20;
    arBuyerDataSource: MatTableDataSource<NpsModel> =
        new MatTableDataSource<NpsModel>();

    acNonBuyerRecords = 0;
    acNonBuyerCurrentPage = 0;
    acNonBuyerPageSize = 20;
    acNonBuyerDataSource: MatTableDataSource<NpsModel> =
        new MatTableDataSource<NpsModel>();

    arNonBuyerRecords = 0;
    arNonBuyerCurrentPage = 0;
    arNonBuyerPageSize = 20;
    arNonBuyerDataSource: MatTableDataSource<NpsModel> =
        new MatTableDataSource<NpsModel>();

    acSalesRecords = 0;
    acSalesCurrentPage = 0;
    acSalesPageSize = 20;
    acSalesDataSource: MatTableDataSource<NpsModel> =
        new MatTableDataSource<NpsModel>();

    arSalesRecords = 0;
    arSalesCurrentPage = 0;
    arSalesPageSize = 20;
    arSalesDataSource: MatTableDataSource<NpsModel> =
        new MatTableDataSource<NpsModel>();

    sortOrder = 'createdDate,desc';
    currentNpsOrder = this.DESC;

    tableColumns: string[] = [
        this.NUMBER,
        this.NPS_NUMBER,
        this.CUSTOMER_MAIN_PHONE,
        this.REGISTRATION_NO,
        this.ACTUAL_DATE,
        this.BRANCH,
        this.STATUS,
        this.ACTIONS,
    ];

    departments: DepartmentModel[];
    promoters: ApplicationUserModel[];
    currentBranchId: number = null;
    currentPromoterId: number = null;
    actionEnums: any[] = ACTION_ENUM;
    status = '';

    isExpandAfterSales = false;
    isExpandSalesNonBuyer = false;
    isExpandSalesBuyer = true;

    constructor(
        private authService: AuthService,
        private snackBar: MatSnackBar,
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private departmentService: DepartmentService,
        private userService: UsersService,
        private npsService: NpsService
    ) {}

    ngOnInit(): void {
        this.currentBranchId = null;
        this.currentPromoterId = null;
        this.activeLoading();
        this.activeArchivedLoading();


        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.sortOrder = this.activatedRoute.snapshot.queryParams['sort'] ? this.activatedRoute.snapshot.queryParams['sort']: undefined;
            this.acBuyerCurrentPage = this.activatedRoute.snapshot.queryParams['page'] ? this.activatedRoute.snapshot.queryParams['page']: undefined;
            this.acBuyerPageSize = this.activatedRoute.snapshot.queryParams['pageSize'] ? this.activatedRoute.snapshot.queryParams['pageSize']: undefined;

            this.arBuyerCurrentPage  = this.activatedRoute.snapshot.queryParams['page'] ? this.activatedRoute.snapshot.queryParams['page']: undefined;
            this.arBuyerPageSize  = this.activatedRoute.snapshot.queryParams['pageSize'] ? this.activatedRoute.snapshot.queryParams['pageSize']: undefined;

            this.acNonBuyerCurrentPage  = this.activatedRoute.snapshot.queryParams['page'] ? this.activatedRoute.snapshot.queryParams['page']: undefined;
            this.acNonBuyerPageSize  = this.activatedRoute.snapshot.queryParams['pageSize'] ? this.activatedRoute.snapshot.queryParams['pageSize']: undefined;

            this.arNonBuyerCurrentPage  = this.activatedRoute.snapshot.queryParams['page'] ? this.activatedRoute.snapshot.queryParams['page']: undefined;
            this.arNonBuyerPageSize  = this.activatedRoute.snapshot.queryParams['pageSize'] ? this.activatedRoute.snapshot.queryParams['pageSize']: undefined;

            this.acSalesCurrentPage  = this.activatedRoute.snapshot.queryParams['page'] ? this.activatedRoute.snapshot.queryParams['page']: undefined;
            this.acSalesPageSize  = this.activatedRoute.snapshot.queryParams['pageSize'] ? this.activatedRoute.snapshot.queryParams['pageSize']: undefined;

            this.arSalesCurrentPage  = this.activatedRoute.snapshot.queryParams['page'] ? this.activatedRoute.snapshot.queryParams['page']: undefined;
            this.arSalesPageSize  = this.activatedRoute.snapshot.queryParams['pageSize'] ? this.activatedRoute.snapshot.queryParams['pageSize']: undefined;
        }

        this.departmentService.getAllNpsDepartments().subscribe({
            next: (value) => {
                this.departments = value.body;
            },
        });

        //GET ACTIVE BUYER RECORD
        this.getNpsRecord(
            this.acBuyerCurrentPage,
            this.acBuyerPageSize,
            this.sortOrder,
            this.SALES_BUYER,
            this.currentNpsOrder,
            null,
            null,
            true
        );
        this.getNpsRecord(
            this.arBuyerCurrentPage,
            this.arBuyerPageSize,
            this.sortOrder,
            this.SALES_BUYER,
            this.currentNpsOrder,
            null,
            null,
            false
        );

        //GET ACTIVE NON-BUYER RECORD
        this.getNpsRecord(
            this.acNonBuyerCurrentPage,
            this.acNonBuyerPageSize,
            this.sortOrder,
            this.SALES_NON_BUYER,
            this.currentNpsOrder,
            null,
            null,
            true
        );
        this.getNpsRecord(
            this.arNonBuyerCurrentPage,
            this.arNonBuyerPageSize,
            this.sortOrder,
            this.SALES_NON_BUYER,
            this.currentNpsOrder,
            null,
            null,
            false
        );

        //GET ACTIVE SALES RECORD
        this.getNpsRecord(
            this.acSalesCurrentPage,
            this.acSalesPageSize,
            this.sortOrder,
            this.AFTER_SALES,
            this.currentNpsOrder,
            null,
            null,
            true
        );
        this.getNpsRecord(
            this.arSalesCurrentPage,
            this.arSalesPageSize,
            this.sortOrder,
            this.AFTER_SALES,
            this.currentNpsOrder,
            null,
            null,
            false
        );

        this.deactiveArchivedLoading();
        this.deactiveLoading();
    }

    getNpsRecord(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        type: string,
        npsOrder: string,
        branchId: number,
        promoterId: number,
        active: boolean
    ) {
        this.npsService
            .getAllNpsRecord(
                pageNumber,
                pageSize,
                sortOrder,
                type,
                npsOrder,
                branchId,
                promoterId,
                active
            )
            .subscribe({
                next: (value) => {
                    if (active === true) {
                        if (type === 'SALES_BUYER') {
                            this.acBuyerDataSource.data = value.body;
                            this.acBuyerRecords = Number(
                                value.headers.get('X-Total-Count')
                            );
                            this.deactiveLoading();
                        } else if (type === 'SALES_NON_BUYER') {
                            this.acNonBuyerDataSource.data = value.body;
                            this.acNonBuyerRecords = Number(
                                value.headers.get('X-Total-Count')
                            );
                            this.deactiveLoading();
                        } else {
                            this.acSalesDataSource.data = value.body;
                            this.acSalesRecords = Number(
                                value.headers.get('X-Total-Count')
                            );
                            this.deactiveLoading();
                        }
                    }
                    if (active === false) {
                        if (type === 'SALES_BUYER') {
                            this.arBuyerDataSource.data = value.body;
                            this.arBuyerRecords = Number(
                                value.headers.get('X-Total-Count')
                            );
                            this.deactiveArchivedLoading();
                        } else if (type === 'SALES_NON_BUYER') {
                            this.arNonBuyerDataSource.data = value.body;
                            this.arNonBuyerRecords = Number(
                                value.headers.get('X-Total-Count')
                            );
                            this.deactiveArchivedLoading();
                        } else {
                            this.arSalesDataSource.data = value.body;
                            this.arSalesRecords = Number(
                                value.headers.get('X-Total-Count')
                            );
                            this.deactiveArchivedLoading();
                        }
                    }
                },
            });
    }

    reload() {
        this.ngOnInit();
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }
    activeArchivedLoading() {
        this.archivedLoading = true;
    }

    deactiveArchivedLoading() {
        this.archivedLoading = false;
    }

    sortActiveSalesBuyer(event: { active: string; direction: string }) {
        if (event.active === this.NPS_NUMBER) {
            this.currentNpsOrder = event.direction.toUpperCase();
            this.sortOrder = `createdDate,${event.direction}`;
        } else {
            this.sortOrder = `${event.active},${event.direction}`;
        }

        this.getNpsRecord(
            this.acBuyerCurrentPage,
            this.acBuyerPageSize,
            this.sortOrder,
            TableType.SALES_BUYER,
            this.currentNpsOrder,
            this.currentBranchId,
            this.currentPromoterId,
            true
        );
    }

    sortArchivedSalesBuyer(event: { active: string; direction: string }) {
        if (event.active === this.NPS_NUMBER) {
            this.currentNpsOrder = event.direction.toUpperCase();
            this.sortOrder = `createdDate,${event.direction}`;
        } else {
            this.sortOrder = `${event.active},${event.direction}`;
        }

        this.getNpsRecord(
            this.arBuyerCurrentPage,
            this.arBuyerPageSize,
            this.sortOrder,
            TableType.SALES_BUYER,
            this.currentNpsOrder,
            this.currentBranchId,
            this.currentPromoterId,
            false
        );
    }

    sortActiveSalesNonBuyer(event: { active: string; direction: string }) {
        if (event.active === this.NPS_NUMBER) {
            this.currentNpsOrder = event.direction.toUpperCase();
            this.sortOrder = `createdDate,${event.direction}`;
        } else {
            this.sortOrder = `${event.active},${event.direction}`;
        }

        this.getNpsRecord(
            this.acNonBuyerCurrentPage,
            this.acNonBuyerPageSize,
            this.sortOrder,
            TableType.SALES_NON_BUYER,
            this.currentNpsOrder,
            this.currentBranchId,
            this.currentPromoterId,
            true
        );
    }

    sortArchivedSalesNonBuyer(event: { active: string; direction: string }) {
        if (event.active === this.NPS_NUMBER) {
            this.currentNpsOrder = event.direction.toUpperCase();
            this.sortOrder = `createdDate,${event.direction}`;
        } else {
            this.sortOrder = `${event.active},${event.direction}`;
        }

        this.getNpsRecord(
            this.arNonBuyerCurrentPage,
            this.arNonBuyerPageSize,
            this.sortOrder,
            TableType.SALES_NON_BUYER,
            this.currentNpsOrder,
            this.currentBranchId,
            this.currentPromoterId,
            false
        );
    }

    sortActiveAfterSales(event: { active: string; direction: string }) {
        if (event.active === this.NPS_NUMBER) {
            this.currentNpsOrder = event.direction.toUpperCase();
            this.sortOrder = `createdDate,${event.direction}`;
        } else {
            this.sortOrder = `${event.active},${event.direction}`;
        }

        this.getNpsRecord(
            this.acSalesCurrentPage,
            this.acSalesPageSize,
            this.sortOrder,
            TableType.AFTER_SALES,
            this.currentNpsOrder,
            this.currentBranchId,
            this.currentPromoterId,
            true
        );
    }

    sortArchivedAfterSales(event: { active: string; direction: string }) {
        if (event.active === this.NPS_NUMBER) {
            this.currentNpsOrder = event.direction.toUpperCase();
            this.sortOrder = `createdDate,${event.direction}`;
        } else {
            this.sortOrder = `${event.active},${event.direction}`;
        }

        this.getNpsRecord(
            this.arSalesCurrentPage,
            this.arSalesPageSize,
            this.sortOrder,
            TableType.AFTER_SALES,
            this.currentNpsOrder,
            this.currentBranchId,
            this.currentPromoterId,
            false
        );
    }

    navigateToPageAcBuyer(event): void {
        this.acBuyerCurrentPage = event.pageIndex;
        this.acBuyerPageSize = event.pageSize;
        this.getNpsRecord(
            event.pageIndex,
            event.pageSize,
            this.sortOrder,
            this.SALES_BUYER,
            this.ASC,
            this.currentBranchId,
            this.currentPromoterId,
            true
        );
    }

    navigateToPageArBuyer(event): void {
        this.arBuyerCurrentPage = event.pageIndex;
        this.arBuyerPageSize = event.pageSize;
        this.getNpsRecord(
            event.pageIndex,
            event.pageSize,
            this.sortOrder,
            this.SALES_BUYER,
            this.ASC,
            this.currentBranchId,
            this.currentPromoterId,
            false
        );
    }

    navigateToPageAcNonBuyer(event): void {
        this.acNonBuyerCurrentPage = event.pageIndex;
        this.acNonBuyerPageSize = event.pageSize;
        this.getNpsRecord(
            event.pageIndex,
            event.pageSize,
            this.sortOrder,
            this.SALES_NON_BUYER,
            this.ASC,
            this.currentBranchId,
            this.currentPromoterId,
            true
        );
    }

    navigateToPageArNonBuyer(event): void {
        this.arNonBuyerCurrentPage = event.pageIndex;
        this.arNonBuyerPageSize = event.pageSize;
        this.getNpsRecord(
            event.pageIndex,
            event.pageSize,
            this.sortOrder,
            this.SALES_NON_BUYER,
            this.ASC,
            this.currentBranchId,
            this.currentPromoterId,
            false
        );
    }

    navigateToPageAcSales(event): void {
        this.acSalesCurrentPage = event.pageIndex;
        this.acSalesPageSize = event.pageSize;
        this.getNpsRecord(
            event.pageIndex,
            event.pageSize,
            this.sortOrder,
            this.AFTER_SALES,
            this.ASC,
            this.currentBranchId,
            this.currentPromoterId,
            true
        );
    }

    navigateToPageArSales(event): void {
        this.arSalesCurrentPage = event.pageIndex;
        this.arSalesPageSize = event.pageSize;
        this.getNpsRecord(
            event.pageIndex,
            event.pageSize,
            this.sortOrder,
            this.AFTER_SALES,
            this.ASC,
            this.currentBranchId,
            this.currentPromoterId,
            false
        );
    }

    openNewCallIn() {
        const modalRef = this.matDialog.open(NpsCallInDialogComponent, {
            data: '',
            panelClass: 'mat-dialog-lg',
        });

        modalRef.afterClosed().subscribe({
            next: () => {
                this.reload();
            },
        });
    }

    openNewNPS() {
        this.npsService.npsValue = null;
        this.router.navigate(['/operations/nps-dialog']);
    }

    selectDepartment(branch: { id: number }, el: MatExpansionPanelHeader) {
        if (!el._isExpanded()) {
            this.ngOnInit();
            return;
        }
        this.currentBranchId = branch.id;
        this.activeLoading();
        this.activeArchivedLoading();
        this.userService.getAllNPSPromotersByDepartment(branch.id).subscribe({
            next: (value) => {
                this.promoters = value.body;
            },
        });
        //GET ACTIVE BUYER RECORD
        this.getNpsRecord(
            this.acBuyerCurrentPage,
            this.acBuyerPageSize,
            this.sortOrder,
            this.SALES_BUYER,
            this.ASC,
            branch.id,
            null,
            true
        );
        this.getNpsRecord(
            this.arBuyerCurrentPage,
            this.arBuyerPageSize,
            this.sortOrder,
            this.SALES_BUYER,
            this.ASC,
            branch.id,
            null,
            false
        );

        //GET ACTIVE NON-BUYER RECORD
        this.getNpsRecord(
            this.acNonBuyerCurrentPage,
            this.acNonBuyerPageSize,
            this.sortOrder,
            this.SALES_NON_BUYER,
            this.ASC,
            branch.id,
            null,
            true
        );
        this.getNpsRecord(
            this.arNonBuyerCurrentPage,
            this.arNonBuyerPageSize,
            this.sortOrder,
            this.SALES_NON_BUYER,
            this.ASC,
            branch.id,
            null,
            false
        );

        //GET ACTIVE SALES RECORD
        this.getNpsRecord(
            this.acSalesCurrentPage,
            this.acSalesPageSize,
            this.sortOrder,
            this.AFTER_SALES,
            this.ASC,
            branch.id,
            null,
            true
        );
        this.getNpsRecord(
            this.arSalesCurrentPage,
            this.arSalesPageSize,
            this.sortOrder,
            this.AFTER_SALES,
            this.ASC,
            branch.id,
            null,
            false
        );

        this.deactiveLoading();
        this.deactiveArchivedLoading();
    }

    filterList(promoter: { id: number }, branch: { id: number }) {
        this.currentBranchId = branch.id;
        this.currentPromoterId = promoter.id;
        this.activeLoading();
        this.activeArchivedLoading();
        //GET ACTIVE BUYER RECORD
        this.getNpsRecord(
            this.acBuyerCurrentPage,
            this.acBuyerPageSize,
            this.sortOrder,
            this.SALES_BUYER,
            this.ASC,
            branch.id,
            promoter.id,
            true
        );
        this.getNpsRecord(
            this.arBuyerCurrentPage,
            this.arBuyerPageSize,
            this.sortOrder,
            this.SALES_BUYER,
            this.ASC,
            branch.id,
            promoter.id,
            false
        );

        //GET ACTIVE NON-BUYER RECORD
        this.getNpsRecord(
            this.acNonBuyerCurrentPage,
            this.acNonBuyerPageSize,
            this.sortOrder,
            this.SALES_NON_BUYER,
            this.ASC,
            branch.id,
            promoter.id,
            true
        );
        this.getNpsRecord(
            this.arNonBuyerCurrentPage,
            this.arNonBuyerPageSize,
            this.sortOrder,
            this.SALES_NON_BUYER,
            this.ASC,
            branch.id,
            promoter.id,
            false
        );

        //GET ACTIVE SALES RECORD
        this.getNpsRecord(
            this.acSalesCurrentPage,
            this.acSalesPageSize,
            this.sortOrder,
            this.AFTER_SALES,
            this.ASC,
            branch.id,
            promoter.id,
            true
        );
        this.getNpsRecord(
            this.arSalesCurrentPage,
            this.arSalesPageSize,
            this.sortOrder,
            this.AFTER_SALES,
            this.ASC,
            branch.id,
            promoter.id,
            false
        );

        this.deactiveLoading();
        this.deactiveArchivedLoading();
    }

    getStatusColor(value: string) {
        if (value === 'OPEN') {
            return 'status-color-new';
        }
        if (value === 'RESCHEDULED') {
            return 'status-color-rescheduled';
        }
        if (value === 'COMPLETED') {
            return 'status-color-completed';
        }
        if (value === 'DECLINED') {
            return 'status-color-declined';
        }
    }

    statusValue(value: string): string {
        return this.actionEnums.find((status) => status.value === value).name;
    }

    openNPSDialogDetail(data?: NpsModel) {
        const modalRef = this.matDialog.open(NpsCallInDialogDetailComponent, {
            data,
            panelClass: 'mat-dialog-md',
        });

        modalRef.afterClosed().subscribe();
    }

    openNPSEditDialog(data?: NpsModel) {
        const modalRef = this.matDialog.open(NpsCallInDialogComponent, {
            data,
            panelClass: 'mat-dialog-lg',
        });

        modalRef.afterClosed().subscribe({
            next: () => {
                this.reload();
            },
        });
    }
}
enum TableType {
    SALES_BUYER = 'SALES_BUYER',
    SALES_NON_BUYER = 'SALES_NON_BUYER',
    AFTER_SALES = 'AFTER_SALES',
}
