import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { InstitutionModel } from 'src/app/model/institution.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { InstitutionsDialogNewOrEditComponent } from '../institutions-dialog-new-or-edit/institutions-dialog-new-or-edit.component';
import { InstitutionsDialogDetailComponent } from '../institutions-dialog-detail/institutions-dialog-detail.component';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-institutions-listing',
    templateUrl: './institutions-listing.component.html',
    styleUrls: ['./institutions-listing.component.scss']
})
export class InstitutionsListingComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns: string[];
    dataSource: MatTableDataSource<InstitutionModel>;
    obs: BehaviorSubject<InstitutionModel[]>;

    readonly CREATE_INSTITUTION = ApplicationPermissionsService.CREATE_INSTITUTION;
    readonly UPDATE_INSTITUTION = ApplicationPermissionsService.UPDATE_INSTITUTION;

    searchFormGroup: FormGroup;
    institutions: InstitutionModel[];
    InstitutionFormGroup: FormGroup;

    pageNumbers: number[];
    numberOfRecords: number;
    countLoad = 0;
    currentPage: number;
    pageSize: number;
    sortOrder: string;

    constructor(
        private institutionsService: InstitutionsService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar,
        public authService: AuthService,
    ) {
        this.dataSource = new MatTableDataSource<InstitutionModel>();
        this.dataSource.paginator = this.paginator;

        this.pageNumbers = [];
        this.numberOfRecords = 0;
        this.currentPage = 0;
        this.pageSize = 20;
        this.sortOrder = 'name,asc';
        this.countLoad = 0;
    }

    ngOnInit(): void {
        this.displayColumns = [
            'name',
            'shortName',
            'parentInstitution',
            'businessRegNo',
            'active',
            'actions'
        ];

        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.currentPage = this.activatedRoute.snapshot.queryParams['page']
                ? this.activatedRoute.snapshot.queryParams['page']
                : undefined;
            this.sortOrder = this.activatedRoute.snapshot.queryParams['sort']
                ? this.activatedRoute.snapshot.queryParams['sort']
                : undefined;
            this.pageSize = this.activatedRoute.snapshot.queryParams['pageSize']
                ? this.activatedRoute.snapshot.queryParams['pageSize']
                : undefined;
        }
        this.createForm();
        this.refreshForm();
    }

    private createForm() {
        let name: string;
        let createdDate: string;
        let updatedDate: string;

        if (this.activatedRoute.snapshot.queryParams['page']) {
            name = this.activatedRoute.snapshot.queryParams['name']
                ? this.activatedRoute.snapshot.queryParams['name']
                : null;
            createdDate = this.activatedRoute.snapshot.queryParams['createdDate']
                ? this.activatedRoute.snapshot.queryParams['createdDate']
                : null;
            updatedDate = this.activatedRoute.snapshot.queryParams['updatedDate']
                ? this.activatedRoute.snapshot.queryParams['updatedDate']
                : null;
        }

        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(name ? name : null),
            searchCreatedDate: this.formBuilder.control(
                createdDate ? createdDate : null
            ),
            searchUpdatedDate: this.formBuilder.control(
                updatedDate ? updatedDate : null
            ),
        });
    }

    search(currentPage: number) {
        let name: String;
        let createdDate: string;
        let updatedDate: string;

        name = this.searchFormGroup.controls.searchName.value
            ? this.searchFormGroup.controls.searchName.value
            : null;

        createdDate = this.searchFormGroup.controls.searchCreatedDate.value
            ? this.getTimeString(
                this.searchFormGroup.controls.searchCreatedDate.value
            )
            : null;

        updatedDate = this.searchFormGroup.controls.searchUpdatedDate.value
            ? this.getTimeString(
                this.searchFormGroup.controls.searchUpdatedDate.value
            )
            : null;

        this.institutionsService
            .getAllSearch(this.currentPage, this.pageSize, this.sortOrder, {
                name: name,
                createdDate: createdDate ? createdDate : null,
                updatedDate: updatedDate ? updatedDate : null,
            })
            .subscribe({
                next: (value) => {
                    this.dataSource.data = value.body;
                    this.changeDetectorRef.detectChanges();
                    this.dataSource.paginator = this.paginator;
                    this.obs = this.dataSource.connect();

                    if (currentPage === 0) {
                        this.paginator.firstPage();
                    } else {
                        this.paginator.pageIndex = currentPage;
                    }

                    this.numberOfRecords = Number(value.headers.get('X-Total-Count'));
                },
                error: (value) => {
                    this.snackBar.open(value.error.message, 'Close', {
                        duration: 60000
                    });
                },
            });
    }

    refreshForm() {
        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(null),
            searchCreatedDate: this.formBuilder.control(null),
            searchUpdatedDate: this.formBuilder.control(null),
        });
        this.search(0);
    }

    getTimeString(dateToChange: Date): string {
        let newDate: Date = new Date(dateToChange.getTime() + 8 * 60 * 60 * 1000);
        return newDate.toISOString();
    }

    ngOnDestroy(): void {
        if (this.dataSource) {
            this.dataSource.disconnect();
        }
    }

    create(): void {
        this.router
            .navigate(['create'], {
                relativeTo: this.activatedRoute,
            })
            .then();
    }

    edit(institutionModel: InstitutionModel): void {
        this.router
            .navigate(['edit'], {
                relativeTo: this.activatedRoute,
                state: {
                    institutionModel,
                },
            })
            .then();
    }

    sortPage(event): void {
        this.sortOrder = `${event.active},${event.direction}`;
        this.search(this.currentPage);
    }

    navigateToPage(event): void {
        this.currentPage = event.pageIndex;
        this.search(this.currentPage);
    }

    openInstitutionDialogCreateOrEdit(institutionModel?: InstitutionModel) {
        const modalRef = this.matDialog.open(InstitutionsDialogNewOrEditComponent, {
            data: institutionModel,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
            if (yes) this.search(0);
        }})
    }

    openInstitutionDialogDetail(institutionModel?: InstitutionModel) {
        const modalRef = this.matDialog.open(InstitutionsDialogDetailComponent, {
            data: institutionModel,
            panelClass: 'mat-dialog-md'
        })

        modalRef.afterClosed().subscribe({
            next: (edit) => {
                if (edit) this.openInstitutionDialogCreateOrEdit(institutionModel);
            }
        })
    }
}
