<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title" *ngIf="data?.action === 'ON_HOLD'">
        On-Hold VOC
    </div>
    <div class="nbt-dialog-title" *ngIf="data?.action === 'CANCEL'">
        Cancel VOC
    </div>
    <div class="nbt-dialog-title" *ngIf="data?.action === 'RE_OPEN'">
        Re-Open VOC
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <div class="row" *ngIf="data?.action === 'ON_HOLD'">
        <div class="col-md-12 nbt-form-textarea">
            <label>On-Hold Reason <span class="required">*</span></label>
            <mat-form-field appearance="outline">
                <textarea matInput appAutofocus [(ngModel)]="onHoldReason" placeholder="Enter your reasons..." maxlength="2000"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="data?.action === 'CANCEL'">
        <div class="col-md-12 nbt-form-textarea">
            <label>Cancel Reason <span class="required">*</span></label>
            <mat-form-field appearance="outline">
                <textarea matInput appAutofocus [(ngModel)]="cancelReason" placeholder="Enter your reasons..." maxlength="2000"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="data?.action === 'OPEN'">
        <div class="col-md-12 nbt-form-textarea">
            <label>Open Reason <span class="required">*</span></label>
            <mat-form-field appearance="outline">
                <textarea matInput appAutofocus [(ngModel)]="openReason" placeholder="Enter your reasons..." maxlength="2000"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="data?.action === 'RE_OPEN'">
        <div class="col-md-12 nbt-form">
            <label>Department <span class="required">*</span></label>
            <mat-form-field appearance="outline">
                <mat-select name="type" [(ngModel)]="reOpenDepartment"
                (selectionChange)="changeSelectDepartment($event)" placeholder="Select department">
                    <mat-option *ngFor="let regDept of registeredDepts " [value]="regDept">
                        {{ regDept.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-12 nbt-form">
            <label>Consignee <span class="required">*</span></label>
            <mat-form-field appearance="outline">
                <mat-select name="type" [(ngModel)]="reOpenAssignee" placeholder="Select assignee">
                    <mat-option *ngFor="let assignee of assignees" [value]="assignee">
                        {{ assignee.username }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-12 nbt-form" style="padding-bottom: 1.34375em;">
            <label>Priority <span class="required">*</span></label>
            <mat-radio-group [(ngModel)]="reOpenPriority">
                <mat-radio-button value="HIGH" style="margin-right: 20px;">HIGH</mat-radio-button>
                <mat-radio-button value="LOW">LOW</mat-radio-button>
              </mat-radio-group>
        </div>
        <div class="col-md-12 nbt-form-textarea">
            <label>Re-open Reason <span class="required">*</span></label>
            <mat-form-field appearance="outline">
                <textarea matInput appAutofocus [(ngModel)]="reOpenReason" placeholder="Enter your reasons..." maxlength="2000"></textarea>
            </mat-form-field>
        </div>
        <br>
        <div *ngIf="(assigneesManager.length > 0 || assigneesHod.length>0) && reOpenAssignee">
            <label style="color:darkgray">Please take note, a VOC ticket assignment email will be CCed to following :</label>
            <div *ngIf="this.reOpenPriority==='HIGH'">
                <div *ngFor="let hodApplicationUser of assigneesHod ; let i = index">
                    <label style="color:darkgray">
                        {{ i+1 }}. {{hodApplicationUser.applicationPosition.code }} - {{ hodApplicationUser.firstName }} {{ hodApplicationUser.lastName }} ({{ hodApplicationUser.email }})
                    </label>
                </div>
                <div *ngFor="let managerApplicationUser of assigneesManager ; let i = index">
                    <label style="color:darkgray">
                        {{ i+1+assigneesHod.length }}. {{managerApplicationUser.applicationPosition.code }} - {{ managerApplicationUser.firstName }} {{ managerApplicationUser.lastName }} ({{ managerApplicationUser.email }})
                    </label>
                </div>
            </div>
            <div *ngIf="this.reOpenPriority==='LOW'">
                <div *ngFor="let managerApplicationUser of assigneesManager ; let i = index">
                    <label style="color:darkgray">
                        {{ i+1 }}. {{managerApplicationUser.applicationPosition.code }} - {{ managerApplicationUser.firstName }} {{ managerApplicationUser.lastName }} ({{ managerApplicationUser.email }})
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="assigneesManager.length===0 && assigneesHod.length===0 && reOpenAssignee">
            <label style="color:darkgray">
                Please take note, VOC ticket assignment email will not be CCed to any HOD or managers as the role is not configured for this department.
            </label>
        </div>
        <br>
    </div>
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button *ngIf="data?.action === 'ON_HOLD'" type="button" class="nbt-btn-primary ml8" (click)="submitConfirmation()" [disabled]="!onHoldReason">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
    <button *ngIf="data?.action === 'CANCEL'" type="button" class="nbt-btn-primary ml8" (click)="submitConfirmation()" [disabled]="!cancelReason">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
    <button *ngIf="data?.action === 'OPEN'" type="button" class="nbt-btn-primary ml8" (click)="submitConfirmation()" [disabled]="!openReason">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
    <button *ngIf="data?.action === 'RE_OPEN'" type="button" class="nbt-btn-primary ml8" (click)="submitConfirmation()" [disabled]="!reOpenDepartment || !reOpenAssignee || !reOpenReason">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>
