import { ApplicationUserModel } from './application-user.model';
import { DepartmentModel } from './department.model';
import { InstitutionModel } from './institution.model';
import { VehicleModelModel } from './vehicle-dialog.model';

export class CustomerRecordModel {
    constructor(
        public id?: string,
        public salutation?: string,
        public firstName?: string,
        public lastName?: string,
        public ic?: string,
        public email?: string,
        public isBuyer?: boolean,
        public isMysteryShopper? : boolean,
        public mainPhone?: string,
        public homePhone?: string,
        public officePhone?: string,
        public remarks?: string,
        public vehicles?: CustomerRecordVehiclesModel[],
        public vehicle?: CustomerRecordVehiclesModel,
        public profilePicture?: string,
        public registeredDepartment?: DepartmentModel,
        public departments?: DepartmentModel[],
        public mysteryShopperStartDate?: string,
        public mysteryShopperEndDate?: string,
        public mysteryShopperDepartmentList?: DepartmentModel[],
    ) {}
}

export class CustomerRecordVehiclesModel {
    constructor(
        public id?: string,
        public purchaseDate?: string,
        public lastServiceDate?: string,
        public registrationNo?: string,
        public remarks?: string,
        public model?: VehicleModelModel,
        public institution?: InstitutionModel,
        public customer?: CustomerRecordModel
    ) {}
}

export class CustomerRecordActivityModel {
    constructor(
        public id?: string,
        public type?: string,
        public details?: string,
        public actualDate?: any,
        public isNps?: boolean,
        public model?: VehicleModelModel,
        public vehicle?: CustomerRecordVehiclesModel,
        public department?: DepartmentModel,
        public applicationUser?: ApplicationUserModel,
        public customer?: CustomerRecordModel,
        public registrationNo?: string
    ) {}
}
