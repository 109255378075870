<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        Preview Attachment
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <img [src]="attachment?.url" alt="profile-img" style="width: 100%;"/>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">check</mat-icon>
        Okay
    </button>
</div>