<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create New User Role
        </ng-container>
        <ng-template #elseTemplate>
            Edit User Role '{{applicationRoleModel.name}}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="applicationRoleFormGroup">
        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Name <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="name" placeholder="Enter name" maxlength="100"/>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form label-flex">
                <mat-checkbox formControlName="active">Active</mat-checkbox>
            </div>
            <div class="col-md-12 nbt-form">
                <label>Institution</label>
                <mat-form-field appearance="outline">
                    <mat-select name="institution" formControlName="institution" [compareWith]="compareObjects" [disabled]="checkInstitution()" placeholder="Select institution">
                        <mat-option [value]="null">
                            Select institution
                        </mat-option>
                        <mat-option *ngFor="let ins of institutions" [value]="ins">
                            {{ins.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form">
                <label>Permissions</label>
                <mat-tree class="bg-transparent" [dataSource]="dataSource" [treeControl]="treeControl" style="overflow: scroll;">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding="1">
                        <mat-checkbox appApplicationPermissionSelected
                            [applicationRoleFormGroupInput]="applicationRoleFormGroup"
                            [applicationPermissionModelNameInput]="node.name"
                            (click)="updateApplicationRoleApplicationPermissions(node.name)">
                            {{ node.name }}
                        </mat-checkbox>
                    </mat-tree-node>

                    <mat-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
                            </mat-icon>
                        </button>
                        {{ node.name }}
                    </mat-tree-node>
                </mat-tree>
            </div>
        </div>
    </form>
    <span class="required">* Required Field For Submission</span> 
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || applicationRoleFormGroup.invalid"
        (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>