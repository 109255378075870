import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';

@Component({
    selector: 'app-institutions-dialog-detail',
    templateUrl: './institutions-dialog-detail.component.html',
    styleUrls: ['./institutions-dialog-detail.component.scss']
})
export class InstitutionsDialogDetailComponent implements OnInit {

    readonly UPDATE_INSTITUTION = ApplicationPermissionsService.UPDATE_INSTITUTION;

    constructor(
        @Inject(MAT_DIALOG_DATA) public institutionModel: InstitutionModel,
        public dialogRef: MatDialogRef<InstitutionsDialogDetailComponent>,
        public authService: AuthService,
    ) { }

    ngOnInit(): void {
    }

}
