import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { SystemConfigurationModel } from '../model/system-configuration.model';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class SystemConfigurationService {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) { }

    public save(
        model: SystemConfigurationModel,
        curIns: string
    ): Observable<HttpResponse<SystemConfigurationModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<SystemConfigurationModel>(
            `/web/system-config`,
            model,
            {
                observe: 'response',
                params: httpParams
            }
        );
    }

    public getAll(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        criteria
    ): Observable<HttpResponse<SystemConfigurationModel[]>> {
        let httpParams = new HttpParams();

        if (pageNumber) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder) {
            httpParams = httpParams.set('sort', sortOrder);
        }

        if (criteria) {
            if (criteria.code) {
                httpParams = httpParams.set('code', criteria.code);
            }
            if (criteria.institution) {
                httpParams = httpParams.set('institutionId', criteria.institution);
            }
            if (criteria.currentInstitution) {
                httpParams = httpParams.set('curIns', criteria.currentInstitution);
            }
        }

        return this.httpClient.get<SystemConfigurationModel[]>(
            `/web/system-config/all`,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }


    getSystemConfigurationValueByCode(code: string, curIns: number): Observable<HttpResponse<SystemConfigurationModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('code', code);
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.get<SystemConfigurationModel[]>(
        `/web/system-config/code`,
        {
            observe: 'response',
            params: httpParams,
        }
        );
    }

    getSystemConfigurationActivityByCode(): Observable<HttpResponse<SystemConfigurationModel[]>> {
            return this.httpClient.get<SystemConfigurationModel[]>(
            `/web/system-config/activity-type-enum`,
            {
                observe: 'response',
            }
        );
    }

    getAutoGenerationReportTypeEnum(): Observable<HttpResponse<SystemConfigurationModel[]>> {
        return this.httpClient.get<SystemConfigurationModel[]>(
        `/web/system-config/auto-generation-report-type-enum`,
            {
                observe: 'response',
            }
        );
    }

    getAutoGenerationReportTriggerEnum(): Observable<HttpResponse<SystemConfigurationModel[]>> {
        return this.httpClient.get<SystemConfigurationModel[]>(
        `/web/system-config/auto-generation-report-trigger-enum`,
            {
                observe: 'response',
            }
        );
    }


    public update(
        model: SystemConfigurationModel,
        curIns: string
    ): Observable<HttpResponse<SystemConfigurationModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<SystemConfigurationModel>(
            `/web/system-config`,
            model,
            {
                observe: 'response',
                params: httpParams
            }
        );
    }

    getSystemConfigByCode(code: string):Observable<HttpResponse<string[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        httpParams = httpParams.set('code', code);
        return this.httpClient.get<string[]>('/web/system-config/code', {
            observe: 'response',
            params: httpParams
        });
    }

    public getClearanceEnum(): Observable<
        HttpResponse<SystemConfigurationModel[]>
    > {
        return this.httpClient.get<SystemConfigurationModel[]>(
            `/web/system-config/clearance-enum`,
            {
                observe: 'response',
            }
        );
    }
}
