
<div class="container-fluid nbt-container">
    <div class="nbt-header">
        <div class="nbt-header-title">Ticket Category</div>
    </div>
    <br>
    <form [formGroup]="searchFormGroup" (ngSubmit)="search()">
        <div class="nbt-form nbt-search-bar">
            <mat-form-field appearance="outline">
                <input matInput id="name" formControlName="searchName" placeholder="Search name" />
                <mat-icon class="mr8 close" (click)="refreshForm()" *ngIf="searchFormGroup?.value?.searchName?.length > 0">close</mat-icon>
                <mat-icon class="mr8 search" (click)="search()">search</mat-icon>
            </mat-form-field>
        </div>
    </form>
    <br>
    <mat-card-content class="mat-card-style mat-card-content-table" *ngIf="windowWidth >= 1280" style="overflow:hidden !important">
        <div class="col-md-4" style="padding:20px;">
            <div class="nbt-header">
                <label class="nbt-header-title-category">Ticket Group</label>
                <div class="nbt-btn-right">
                    <button type="button" class="nbt-btn-fourth" (click)="openGroupCreateOrEdit()"> 
                        <mat-icon class="mr8">add</mat-icon>
                        New Group
                    </button>
                </div>
            </div>
            <div class="table-style">
                <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="dataSourceGroup">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header >
                            Name
                        </th>
                        <td mat-cell *matCellDef="let group">
                            <div [class]="isHighlighted(group,'group')" (click)="clickGroup(group)">
                                {{ group.name }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_CATEGORY)" (click)="openGroupCreateOrEdit(element)" matTooltip="Edit">edit</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                    
                </table>
            </div>
        </div>
        <div class="col-md-4 " style="padding:20px;">
            <div class="nbt-header">
                <label class="nbt-header-title-category">Ticket Category</label>
                <div class="nbt-btn-right">
                    <button type="button" class="nbt-btn-fourth" (click)="openCategoryCreateOrEdit()"> 
                        <mat-icon class="mr8">add</mat-icon>
                        New Category
                    </button>
                </div>
            </div>
            <div class="table-style">
                <table mat-table matSort (matSortChange)="sortPageCategory($event)"  [dataSource]="dataSourceCategory">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let category">
                            <div [class]="isHighlighted(category,'category')" (click)="clickCategory(category)">
                                {{ category.name }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_CATEGORY)" (click)="openCategoryCreateOrEdit(element)" matTooltip="Edit">edit</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>
            </div>
        </div>
        <div class="col-md-4" style="padding:20px;">
            <div class="nbt-header">
                <label class="nbt-header-title-category">Ticket Type</label>
                <div class="nbt-btn-right">
                    <button type="button" class="nbt-btn-fourth" (click)="openTypeCreateOrEdit()"> 
                        <mat-icon class="mr8">add</mat-icon>
                        New Type
                    </button>
                </div>
            </div>
            <div class="table-style">
                <table mat-table matSort (matSortChange)="sortPageType($event)" [dataSource]="dataSourceType">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let type">
                            <div [class]="isHighlighted(type,'type')" (click)="clickType(type)">
                                {{ type.name }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_CATEGORY)" (click)="openTypeCreateOrEdit(element)" matTooltip="Edit">edit</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>
            </div>
        </div>
    </mat-card-content>
    <mat-card-content class="mat-card-content-table" *ngIf="windowWidth < 1280">
        <div class="" style="padding:20px;">
            <div class="nbt-header">
                <label class="nbt-header-title-category">Ticket Group</label>
                <div class="nbt-btn-right">
                    <button type="button" class="nbt-btn-fourth" (click)="openGroupCreateOrEdit()"> 
                        <mat-icon class="mr8">add</mat-icon>
                        New Group
                    </button>
                </div>
            </div>
            <div class="table-mobile-style">
                <table mat-table matSort  [dataSource]="dataSourceGroup">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header >
                            Name
                        </th>
                        <td mat-cell *matCellDef="let group">
                            <div (click)="clickGroup(group)">
                                {{ group.name }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_CATEGORY)" (click)="openGroupCreateOrEdit(element)" matTooltip="Edit">edit</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>
            </div>
        </div>
        <div class="" style="padding:20px;">
            <div class="nbt-header">
                <label class="nbt-header-title-category">Ticket Category</label>
                <div class="nbt-btn-right">
                    <button type="button" class="nbt-btn-fourth" (click)="openCategoryCreateOrEdit()"> 
                        <mat-icon class="mr8">add</mat-icon>
                        New Category
                    </button>
                </div>
            </div>
            <div class="table-mobile-style">
                <table mat-table matSort  [dataSource]="dataSourceCategory">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let category">
                            <div (click)="clickCategory(category)">
                                {{ category.name }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_CATEGORY)" (click)="openCategoryCreateOrEdit(element)" matTooltip="Edit">edit</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>
            </div>
            <div class="" style="padding:20px;">
                <div class="nbt-header">
                    <label class="nbt-header-title-category">Ticket Type</label>
                    <div class="nbt-btn-right">
                        <button type="button" class="nbt-btn-fourth" (click)="openTypeCreateOrEdit()"> 
                            <mat-icon class="mr8">add</mat-icon>
                            New Type
                        </button>
                    </div>
                </div>
            </div>
            <div class="table-mobile-style">
                <table mat-table matSort  [dataSource]="dataSourceType">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let type">
                            <ng-container>
                                {{ type.name }}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_CATEGORY)" (click)="openTypeCreateOrEdit(element)" matTooltip="Edit">edit</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>
            </div>
        </div>
    </mat-card-content>
</div>
    