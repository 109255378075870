import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { HolidayModel } from '../model/holiday.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HolidayService {
    constructor(private httpClient: HttpClient) { }

    public save(
        model: HolidayModel,
        curIns: string
    ): Observable<HttpResponse<HolidayModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<HolidayModel>(`/web/holiday`, model, {
            observe: 'response',
            params: httpParams
        });
    }

    public getAll(currentInstitution: number, from: string, to: string): Observable<HttpResponse<HolidayModel[]>> {
        let httpParams = new HttpParams();

        httpParams = httpParams.set('curIns', currentInstitution);
        httpParams = httpParams.set('from', from);
        httpParams = httpParams.set('to', to);

        return this.httpClient.get<HolidayModel[]>(`/web/holiday/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    public update(
        model: HolidayModel,
        curIns: string
    ): Observable<HttpResponse<HolidayModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<HolidayModel>(`/web/holiday`, model, {
            observe: 'response',
            params: httpParams
        });
    }

    public delete(
        id: string,
        inputDate: string,
        curIns: string
    ): Observable<HttpResponse<HolidayModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('id', id);
        httpParams = httpParams.set('inputDate', inputDate);
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.delete<HolidayModel[]>(
            '/web/holiday',
            {
                observe: 'response',
                params: httpParams
            }
        );
    }


}
