export const CSI_FORM_CONTENT = {
    english_toyota: {
        header:"AFTER-SALES SERVICE SURVEY",
        valueCust:"Dear Valued Customers",
        tq:"Thank you for sending your Toyota to our Service Centre.Recalling your recent service experience at our Service Center, we are keen to know what your experience was with respect to the areas listed below. We would therefore be more grateful if you could kindly respond to this questionnaire to enable to further improve our services to you.",
        tq2: "Thank you",
        company:"NBT(BRUNEI) SDN BHD ",
        feedback:"Please give us your feedback on your service.",
        tick:"Kindly tick one",
        good:"Very Good",
        poor:"Poor",
        notApplicable:"Not Applicable",
        compliments:"Any other compliments, suggestion or complaints",
    },
    malay_toyota: {
        header:"PENDAPAT BERKAITAN SERVIS SELEPAS JUALAN",
        valueCust:"Kepada Pelanggan Yang Dihargai",
        tq:"Terima kasih kerana menghantar Toyota awda ke Pusat Servis kami. Mengingati pengalaman servis lalu awda di Pusat Service kami, bagaimana penilaian pengalaman awda tentang servis kami bagi perkara-perkara berikut. Kami sangat berbesar hati jika awda dapat menjawab pertanyaan ini bagi mutu perkhidmatan kami untuk awda.",
        tq2: "Terima Kasih",
        company:"NBT(BRUNEI) SDN BHD ",
        feedback:"Sila berikan kami pendapat awda mengenai servis kami",
        tick:"Tandakan yang sesuai",
        good:"Cermelang",
        poor:"lemah",
        notApplicable:"Tidak Berkenaan",
        compliments:"Lain-lain pujian, cadangan dan aduan",
    },
    english_lexus: {
        header:"AFTER-SALES SERVICE SURVEY",
        valueCust:"Dear Valued Customers",
        tq:"Thank you for sending your Lexus to our Service Centre.Recalling your recent service experience at our Service Center, we are keen to know what your experience was with respect to the areas listed below. We would therefore be more grateful if you could kindly respond to this questionnaire to enable to further improve our services to you.",
        tq2: "Thank you",
        company:"NBT(BRUNEI) SDN BHD ",
        feedback:"Please give us your feedback on your service.",
        tick:"Kindly tick one",
        good:"Very Good",
        poor:"Poor",
        notApplicable:"Not Applicable",
        compliments:"Any other compliments, suggestion or complaints",
    },
    malay_lexus: {
        header:"PENDAPAT BERKAITAN SERVIS SELEPAS JUALAN",
        valueCust:"Kepada Pelanggan Yang Dihargai",
        tq:"Terima kasih kerana menghantar Lexus awda ke Pusat Servis kami. Mengingati pengalaman servis lalu awda di Pusat Service kami, bagaimana penilaian pengalaman awda tentang servis kami bagi perkara-perkara berikut. Kami sangat berbesar hati jika awda dapat menjawab pertanyaan ini bagi mutu perkhidmatan kami untuk awda.",
        tq2: "Terima Kasih",
        company:"NBT(BRUNEI) SDN BHD ",
        feedback:"Sila berikan kami pendapat awda mengenai servis kami",
        tick:"Tandakan yang sesuai",
        good:"Cermelang",
        poor:"lemah",
        notApplicable:"Tidak Berkenaan",
        compliments:"Lain-lain pujian, cadangan dan aduan",
    },
}

export const SSI_FORM_CONTENT = {
    english_toyota:{
        intro:"Toyota Customer Service Quality Survey",
        tq:"Thank you for choosing NBT.",
        tel:" Tel: 2427488",
        dept:"2022 - NBT Brunei. Customer relations Department.",
        email:" Email: customercare@nbt-brunei.com",
        address:"P.O.. Box 3, Bandar Seri Begawan, BS8670, Negara Brunei Darussalam",
        by:"Powered by Think Axis Solutions - Brunei"

    },
    malay_toyota:{
        intro:"Kaji Selidik Kualiti Perkhidmatan Pelanggan Toyota",
        tq:"Terima kasih kerana memilih NBT.",
        tel:" Tel: 2427488",
        dept:"2022 - NBT Brunei. Customer relations Department.",
        email:" Email: customercare@nbt-brunei.com",
        address:"P.O.. Box 3, Bandar Seri Begawan, BS8670, Negara Brunei Darussalam",
        by:"Powered by Think Axis Solutions - Brunei"

    },
    english_lexus:{
        intro:"Lexus Customer Service Quality Survey",
        tq:"Thank you for choosing NBT.",
        tel:" Tel: 2427488",
        dept:"2022 - NBT Brunei. Customer relations Department.",
        email:" Email: customercare@nbt-brunei.com",
        address:"P.O.. Box 3, Bandar Seri Begawan, BS8670, Negara Brunei Darussalam",
        by:"Powered by Think Axis Solutions - Brunei"

    },
    malay_lexus:{
        intro:"Kaji Selidik Kualiti Perkhidmatan Pelanggan Lexus",
        tq:"Terima kasih kerana memilih NBT.",
        tel:" Tel: 2427488",
        dept:"2022 - NBT Brunei. Customer relations Department.",
        email:" Email: customercare@nbt-brunei.com",
        address:"P.O.. Box 3, Bandar Seri Begawan, BS8670, Negara Brunei Darussalam",
        by:"Powered by Think Axis Solutions - Brunei"

    },
}