import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NpsListingComponent } from './nps-listing/nps-listing.component';
import { NpsCallInDialogComponent } from './nps-call-in-dialog/nps-call-in-dialog.component';
import { NpsCallInDialogDetailComponent } from './nps-call-in-dialog-detail/nps-call-in-dialog-detail.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { NpsPageNewComponent } from './nps-page-new/nps-page-new.component';


const routes: Routes = [
    {
        path: 'nps-listing',
        component: NpsListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'nps-dialog',
        component: NpsPageNewComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'nps-call-in-dialog',
        component: NpsCallInDialogComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'nps-call-in-dialog-detail',
        component: NpsCallInDialogDetailComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
];


@NgModule({
    declarations: [
        NpsListingComponent,
        NpsCallInDialogComponent,
        NpsCallInDialogDetailComponent,
        NpsPageNewComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes)
    ]
})
export class NetPromoterModule { }
