import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Authority, AuthService } from 'src/app/auth/auth.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { DepartmentModel } from 'src/app/model/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { BusinessHoursService } from 'src/app/services/business-hours.service';
import { BusinessHourModel } from 'src/app/model/business-hours.model';
import { UsersService } from 'src/app/services/users.service';
import { SlaModel } from 'src/app/model/sla.model';
import { SlaService } from 'src/app/services/sla.service';
import { TOOLTIPS } from 'src/app/shared/constant/tooltips-enum.constant';
import { SurveyService } from 'src/app/services/survey.service';
import { SurveyFormModel } from 'src/app/model/survey-form.model';
import { SurveyType } from 'src/app/shared/constant';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { SystemConfigurationModel, SYS_CONFIG } from 'src/app/model/system-configuration.model';

@Component({
    selector: 'app-departments-dialog-new-or-edit',
    templateUrl: './departments-dialog-new-or-edit.component.html',
    styleUrls: ['./departments-dialog-new-or-edit.component.scss']
})
export class DepartmentsDialogNewOrEditComponent implements OnInit {

    dialogType: string = '';
    departmentFormGroup: FormGroup;
    isSaving: boolean = false;
    institutions: InstitutionModel[] = [];
    selectedValue: string;
    parentDepartment: DepartmentModel[] = [];
    versions: DepartmentModel[] = [];
    businessHoursList: BusinessHourModel[] = [];
    selectedBusinessHourHeaders: BusinessHourModel;
    triggerTimeResolutions = [];
    triggerTimeResponses = [];
    users: any[] = [];
    agents: any[] = [];
    surveyForms: SurveyFormModel[] = [];
    surveyType:SurveyFormModel;
    slaHeaders: SlaModel[] = [];
    emailContent: string = TOOLTIPS.emailContent;
    loading: boolean = true;
    csi = SurveyType.CSI;
    ssi = SurveyType.SSI;
    reportType : SystemConfigurationModel[];
    reportTriggerType : SystemConfigurationModel[] = new Array();
    reportTriggerTypeMysteryShopper : SystemConfigurationModel[] = new Array();

    constructor(
        @Inject(MAT_DIALOG_DATA) public departmentModel: DepartmentModel,
        public dialogRef: MatDialogRef<DepartmentsDialogNewOrEditComponent>,
        private departmentService: DepartmentService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private institutionsService: InstitutionsService,
        private snackBar: MatSnackBar,
        private businessHoursServices: BusinessHoursService,
        private userService: UsersService,
        private slaService: SlaService,
        private surveyService: SurveyService,
        private sysConfigService: SystemConfigurationService,
    ) { }

    ngOnInit(): void {
        if (this.departmentModel) {
            this.dialogType = 'update';
            this.departmentFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.departmentModel.id),
                name: this.formBuilder.control(this.departmentModel.name, Validators.required),
                description: this.formBuilder.control(this.departmentModel.description),
                email: this.formBuilder.control(this.departmentModel.email, Validators.required),
                active: this.formBuilder.control(this.departmentModel.active),
                isBranch: this.formBuilder.control(this.departmentModel.isBranch),
                isServiceCentre:this.formBuilder.control(this.departmentModel.isServiceCentre),
                isShowRoom: this.formBuilder.control(this.departmentModel.isShowRoom),
                slaHeader: this.formBuilder.control(this.departmentModel.slaHeaders.length > 0 ? this.departmentModel.slaHeaders[0] : null),
                parentDepartment: this.formBuilder.control(this.departmentModel.parentDepartment),
                institution: this.formBuilder.control(this.departmentModel.institution),
                lastModifiedBy: this.formBuilder.control(this.departmentModel.lastModifiedBy),
                lastModifiedDate: this.formBuilder.control(this.departmentModel.lastModifiedDate),
                businessHourHeaders: this.formBuilder.control(this.departmentModel.businessHourHeaders),
                surveyForm: this.formBuilder.control(this.departmentModel.surveyForm),
                businessHourHeader: this.formBuilder.control(this.departmentModel.businessHourHeaders.length > 0 ? this.departmentModel.businessHourHeaders[0] : null),
                reminders: this.formBuilder.array(this.departmentModel.reminders.map(reminder => this.formBuilder.group({
                    id: reminder.id,
                    priority: reminder.priority,
                    isEscalation: reminder.isEscalation,
                    type: reminder.type,
                    triggerTime: reminder.triggerTime,
                    applicationUsers: [reminder.applicationUsers.map(user => user.id)]
                }))),
                autoGenerationReports: this.formBuilder.array(this.departmentModel.autoGenerationReports.map(report => this.formBuilder.group({
                    id: report.id,
                    type:[report.type, Validators.required],
                    triggerTime: [report.triggerTime, Validators.required],
                    applicationUsers: [report.applicationUsers.map(user => user.id)]
                }))),
            });

            this.userService.getAllByDepartment(this.authService.getCurIns().id, +this.departmentModel.id).subscribe({
                next: value => {
                    this.users = value.body;
                }
            });

            this.userService.getAllByDepartment(this.authService.getCurIns().id, +this.departmentModel.id, { reminderInd: false, defaultDepartmentInd: true }).subscribe({
                next: value => {
                    this.agents = value.body;
                }
            });

            if(this.departmentModel.isShowRoom){
                this.surveyService.getAllSurveysNoPagination(this.ssi).subscribe({
                    next: (value) => (this.surveyForms = value.body),
                });
            }else if(this.departmentModel.isServiceCentre){
                this.surveyService.getAllSurveysNoPagination(this.csi).subscribe({
                    next: (value) => (this.surveyForms = value.body),
                });
            }else{

            }

        } else {
            this.dialogType = 'create';
            this.departmentFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null, Validators.required),
                description: this.formBuilder.control(null),
                email: this.formBuilder.control(null, Validators.required),
                active: this.formBuilder.control(true),
                isBranch: this.formBuilder.control(false),
                isServiceCentre:this.formBuilder.control(false),
                isShowRoom: this.formBuilder.control(false),
                parentDepartment: this.formBuilder.control(null),
                slaHeader: this.formBuilder.control(null),
                institution: this.formBuilder.control(this.authService.getCurIns(), Validators.required),
                createdBy: this.formBuilder.control(null),
                createdDate: this.formBuilder.control(null),
                businessHourHeaders: this.formBuilder.control([]),
                businessHourHeader: this.formBuilder.control(null),
                reminders: this.formBuilder.array([]),
                autoGenerationReports: this.formBuilder.array([]),
                surveyForm: this.formBuilder.control(null),
            });
            this.departmentFormGroup.markAllAsTouched();
        }

        this.slaService.getAllNoPagination({curIns: this.authService.getCurInsId()}).subscribe({
            next: (value) => (this.slaHeaders = value.body),
        });

        this.institutionsService.getAll().subscribe({
            next: (value) => (this.institutions = value.body),
        });
        this.departmentService.getAllNoPagination().subscribe({
            next: (value) => (this.parentDepartment = value.body),
        });
        this.businessHoursServices.getAll(this.authService.getCurIns().id).subscribe({
            next: value => {
                this.businessHoursList = value.body;
                if (this.dialogType === 'update') {
                    if (this.departmentModel.businessHourHeaders.length > 0)
                        this.selectedBusinessHourHeaders = this.businessHoursList.find(bu => this.departmentModel.businessHourHeaders.find(de => de.id === bu.id));
                }
            }
        });
        this.departmentService.getTriggerTimeResolution().subscribe({
            next: value => {
                this.triggerTimeResolutions = [];
                if (value) {
                    for (let i in value) {
                        this.triggerTimeResolutions.push(value[i]);
                    }
                }
            }
        });
        this.departmentService.getTriggerTimeResponse().subscribe({
            next: value => {
                this.triggerTimeResponses = [];
                for (let i in value) {
                    this.triggerTimeResponses.push(value[i]);
                }
                this.deactiveLoading();
            }
        });

        this.sysConfigService.getAutoGenerationReportTypeEnum().subscribe({
            next: (value) => {
                this.reportType = value.body;
                this.reportType = this.reportType.filter(
                    (obj) =>
                    obj.code === 'CSI_SCORING_REPORT'||
                    obj.code === 'SSI_SCORING_REPORT'||
                    obj.code === 'CSI_AVERAGE_ANSWER_REPORT'||
                    obj.code === 'SSI_AVERAGE_ANSWER_REPORT'||
                    obj.code === 'CSI_RESPONSE_RATE_REPORT'||
                    obj.code === 'SSI_RESPONSE_RATE_REPORT'||
                    obj.code === 'NPS_FULL_LISTING_REPORT' ||
                    obj.code === 'MYSTERY_SHOPPER_REPORT'
                );
            },
        });

        this.sysConfigService.getAutoGenerationReportTriggerEnum().subscribe({
            next: (value) => {
                this.reportTriggerType = value.body;
                var monthlyReportTriggerType = value.body.find(el => el.code === 'MONTHLY');
                this.reportTriggerTypeMysteryShopper.push(monthlyReportTriggerType);
            },
            error: (error) => {
                this.snackBar.open(error.error.message, 'Close', {
                    duration: 60000,
                });
            },
        });
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    checkDepartment() {
        if (this.departmentFormGroup.get('id')) {
            return true;
        } else {
            return false;
        }
    }

    checkInstitution() {
        return true;
    }


    save(): void {
        this.isSaving = true;
        this.activeLoading();
        const departmentModel = this.departmentFormGroup.getRawValue() as DepartmentModel;
        departmentModel.businessHourHeaders = [];
        if (departmentModel.businessHourHeader) departmentModel.businessHourHeaders.push(departmentModel.businessHourHeader);
        delete departmentModel.businessHourHeader;
        departmentModel.slaHeaders = [];
        if (departmentModel.slaHeader) departmentModel.slaHeaders.push(departmentModel.slaHeader);
        delete departmentModel.slaHeader;
        departmentModel.reminders.forEach(reminder => {
            var result: any = [];
            reminder.applicationUsers.forEach(user => {
                if(this.users.find(e => e.id===user)){
                    result.push({ id: user } );
                }
            });
            reminder.applicationUsers = result;
        })
        departmentModel.autoGenerationReports.forEach(report => {
            var result: any = [];
            report.applicationUsers.forEach(user => {
                if(this.agents.find(e => e.id===user)){
                    result.push({ id: user } );
                }
            });
            report.applicationUsers = result;
        })

        if (this.dialogType === 'create') {
            this.departmentService.save(departmentModel, this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                    });
                }
            })
        } else {
            this.departmentService.update(departmentModel, this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                    });
                }
            })
        }
    }

    formArrayReminders() {
        return (this.departmentFormGroup.get('reminders') as FormArray).controls;
    }

    formArrayAutogenReport() {
        return (this.departmentFormGroup.get('autoGenerationReports') as FormArray).controls;
    }

    addReminderEscalation(isEscalation: boolean) {
        const reminders = this.departmentFormGroup.get('reminders') as FormArray;
        const reminder = this.formBuilder.group({
            isEscalation: isEscalation,
            type: 'RESOLUTION',
            triggerTime: '0|0|0',
            priority: 'LOW',
            applicationUsers: []
        })
        reminders.push(reminder)
    }

    addAutogenReport() {
        const autogenReports = this.departmentFormGroup.get('autoGenerationReports') as FormArray;
        const autoReport = this.formBuilder.group({
            type: '',
            triggerTime: '',
            applicationUsers: []
        })
        autogenReports.push(autoReport)
    }

    removeReminder(i: number) {
        const reminders = this.departmentFormGroup.get('reminders') as FormArray;
        reminders.controls.splice(i, 1)
    }

    removeAutogenReport(i: number) {
        const reminders = this.departmentFormGroup.get('autoGenerationReports') as FormArray;
        reminders.controls.splice(i, 1)
    }

    isServiceChecked(event) {
        if (event) {
            this.departmentFormGroup.get('isServiceCentre').setValue(event.checked);
            this.departmentFormGroup.get('isShowRoom').setValue(false);
            this.getSurvey(this.csi);
        }
    }

    isShowroomChecked(event) {
        if (event) {
            this.departmentFormGroup.get('isShowRoom').setValue(event.checked);
            this.departmentFormGroup.get('isServiceCentre').setValue(false);
            this.getSurvey(this.ssi);
        }
    }

    getSurvey(event: string){
        this.surveyService.getAllSurveysNoPagination(event).subscribe({
           next: (value) => (this.surveyForms = value.body),
        });
    }

     reportTypeSelectionChange(event, i, autoGenerationReports) {
        if (event.value == 'MYSTERY_SHOPPER_REPORT') {
            const autoGenerationReportList = this.departmentFormGroup.value.autoGenerationReports;
            autoGenerationReportList[i].triggerTime = 'MONTHLY';
            this.departmentFormGroup.get('autoGenerationReports').setValue(autoGenerationReportList);
        }
    }
}


