import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormGroup } from '@angular/forms';
import { ApplicationRoleModel } from 'src/app/model/application-role.model';
import { ApplicationUserModel } from 'src/app/model/application-user.model';

@Directive({
  selector: '[appApplicationRoleSelected]'
})
export class ApplicationRoleSelectedDirective implements OnChanges {

  @Input() applicationUserFormGroupInput: FormGroup;
  @Input() applicationRoleModelInput: ApplicationRoleModel;

  constructor(private checkbox: MatCheckbox) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const applicationUserFormGroup = changes.applicationUserFormGroupInput.currentValue as FormGroup;
    const applicationRoleModel = changes.applicationRoleModelInput.currentValue as ApplicationRoleModel;
    const applicationUserModel = applicationUserFormGroup.getRawValue() as ApplicationUserModel;
    const configuredApplicationRoles = applicationUserModel.applicationRoles;

    this.checkbox.writeValue(configuredApplicationRoles.find(value => value.id === applicationRoleModel.id));
  }
}
