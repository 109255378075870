import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';

@Component({
    selector: 'app-user-roles-detail',
    templateUrl: './user-roles-detail.component.html',
    styleUrls: ['./user-roles-detail.component.scss']
})
export class UserRolesDetailComponent implements OnInit {

    categoryPermissionList: any[] = [];
    readonly UPDATE_APPLICATION_ROLE = ApplicationPermissionsService.UPDATE_APPLICATION_ROLE;

    constructor(
        @Inject(MAT_DIALOG_DATA) public applicationUserRoleModel: any,
        public dialogRef: MatDialogRef<UserRolesDetailComponent>,
        public authService: AuthService,
    ) { }

    ngOnInit(): void {
        if (this.applicationUserRoleModel.applicationPermissions.length > 0) {
            const permissions = this.applicationUserRoleModel.applicationPermissions;
        }
    }

}
