import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HolidayListingComponent } from './holiday-listing/holiday-listing.component';
import { HolidayDialogDetailComponent } from './holiday-dialog-detail/holiday-dialog-detail.component';
import { HolidayDialogNewOrEditComponent } from './holiday-dialog-new-or-edit/holiday-dialog-new-or-edit.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CalendarCommonModule, CalendarMonthModule } from 'angular-calendar';


const routes: Routes = [
    {
        path: 'holiday',
        component: HolidayListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    }
];

@NgModule({
    declarations: [
        HolidayListingComponent,
        HolidayDialogDetailComponent,
        HolidayDialogNewOrEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes),
        CalendarCommonModule,
        CalendarMonthModule,
    ]
})
export class HolidayModule { }
