import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { DepartmentModel } from '../model/department.model';
import { Observable } from 'rxjs';
import { DashboardPersonalTicketModel } from '../model/dashboard-personal-ticket.model';
import { ActionEnum } from '../components/home/home.component';
import { StatisticsDepartmentModel } from '../model/statistics-department.model';
import { VocBreakdownCategoryModel } from '../model/voc-breakdown-category.model';
import { VocSlaComplianceDepartmentModel } from '../model/voc-sla-compliance-department.model';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(private httpClient: HttpClient) { }

    public getPersonalTicketCountByStatus(
        curIns: string,
        status: ActionEnum
    ): Observable<HttpResponse<DashboardPersonalTicketModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('status', status);
        return this.httpClient.get<DashboardPersonalTicketModel>(`/web/dashboard/personal-ticket`, {
            observe: 'response',
            params: httpParams
        });
    }

    public getDefaultDepartmentPersonalTicketCountByStatus(
        curIns: string,
        status: ActionEnum
    ): Observable<HttpResponse<DashboardPersonalTicketModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('status', status);
        return this.httpClient.get<DashboardPersonalTicketModel>(`/web/dashboard/default-department/personal-ticket`, {
            observe: 'response',
            params: httpParams
        });
    }

    public getStatisticsDepartment(
        curIns: string,
        startDate: string,
        departmentIds: any
    ): Observable<HttpResponse<StatisticsDepartmentModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('startDate', startDate);
        httpParams = httpParams.set('departmentIds', departmentIds);
        return this.httpClient.get<StatisticsDepartmentModel[]>(`/web/dashboard/statistic-department`, {
            observe: 'response',
            params: httpParams ,
            
        });
    }

    public getStatisticsDefaultDepartment(
        curIns: string,
        startDate: string,
        departmentIds: any
    ): Observable<HttpResponse<StatisticsDepartmentModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('startDate', startDate);
        httpParams = httpParams.set('departmentIds', departmentIds);
        return this.httpClient.get<StatisticsDepartmentModel[]>(`/web/dashboard/default-department/statistic-department` ,{
            observe: 'response',
            params: httpParams
        });
    }


    public getVocBreakdownByCategory(
        curIns: string,
        startDate: string,
        departmentIds: any
    ): Observable<HttpResponse<VocBreakdownCategoryModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('startDate', startDate);
        httpParams = httpParams.set('departmentIds', departmentIds);
        return this.httpClient.get<VocBreakdownCategoryModel[]>(`/web/dashboard/breakdown-category` ,{
            observe: 'response',
            params: httpParams
        });
    }

    public getVocSlaComplianceByDepartment(
        curIns: string,
        startDate: string,
        departmentIds: any
    ): Observable<HttpResponse<VocSlaComplianceDepartmentModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('startDate', startDate);
        httpParams = httpParams.set('departmentIds', departmentIds);
        return this.httpClient.get<VocSlaComplianceDepartmentModel[]>(`/web/dashboard/compliance` ,{
            observe: 'response',
            params: httpParams
        });
    }

    public getVocSlaComplianceByDefaultDepartment(
        curIns: string,
        startDate: string,
        departmentIds: any
    ): Observable<HttpResponse<VocSlaComplianceDepartmentModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('startDate', startDate);
        httpParams = httpParams.set('departmentIds', departmentIds);
        return this.httpClient.get<VocSlaComplianceDepartmentModel[]>(`/web/dashboard/default-department/compliance` ,{
            observe: 'response',
            params: httpParams
        });
    }
    
}
