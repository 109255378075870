<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        Add New Activity
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="activityFormGroup">
        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Activity Type <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select name="applicationPosition" formControlName="type">
                        <mat-option *ngFor="let type of sysConfig" [value]="type.code">
                            {{ type.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Actual Activity Date <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput [matDatepicker]="picker" [(ngModel)]="currentDate" formControlName="actualDate" placeholder="Select actual activity date"/>
                    <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form-textarea">
                <label>Details <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <textarea matInput appAutofocus type="text" formControlName="details"
                        placeholder="Enter details" rows="5" maxlength="2000" #myInput>
                    </textarea>
                    <span> {{myInput.value.length}} / 2000</span>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Branch <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="department" placeholder="Select branch">
                        <mat-option *ngFor="let department of departments" [value]="department" (click)="selectDepartment(department)">
                            {{ department.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Vehicle Model</label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="model" placeholder="Select vehicle model" (selectionChange) = "modelSelectionChange($event)">
                        <mat-option *ngFor="let model of models" [value]="model">
                            {{model.brand}} {{model.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Sales Consultant <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="applicationUser" placeholder="Select agent">
                        <mat-option *ngFor="let user of users" [value]="user">
                            {{user.username}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label for="">Vehicle Number</label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="registrationNo" placeholder="Enter vehicle number" maxlength="20"/>
                    <mat-error *ngIf="activityFormGroup.get('registrationNo').errors?.pattern">Invalid input</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
        <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || activityFormGroup.invalid" (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>
