import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { MysteryShopperFormModel } from '../model/mystery-shopper-form.model';

@Injectable({
    providedIn: 'root',
})
export class MysteryShopperReportService {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {}

    generateMysteryShopperReport(request: MsReportParams) {
        const params = new HttpParams({ fromObject: { ...request } });
        return this.httpClient.get(`/web/report/mystery-shopper`, {
            observe: 'response',
            params,
            responseType: 'blob',
        });
    }

    getAllMysteryShopperFormVersion():Observable<HttpResponse<MysteryShopperFormModel[]>>{
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());

        return this.httpClient.get<MysteryShopperFormModel[]>(`/web/report/mystery-shopper-form-version`, {
            observe: 'response',
            params: httpParams,
        });
    }

}

interface MsReportParams {
    curIns: number;
    month: string;
    year: string;
    mysteryShopperSurveyFormId: number;
}
