<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        View Activity
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <div class="nbt-details row">
        <div class="col-md-6">
            <label>Type</label>
            <div class="value">{{activity.type || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Actual Activity Date</label>
            <div class="value">{{(activity.actualDate | date: 'd MMMM yyyy') || '-'}}</div>
        </div>
        <div class="col-md-12">
            <label>Details</label>
            <div class="value">{{activity.details || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Branch</label>
            <div class="value">{{activity.department?.name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Vehicle Model</label>
            <div class="value">{{activity.model?.brand || '-'}} {{activity.model?.name || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Sales Consultant</label>
            <div class="value">{{activity.applicationUser?.username || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Registration No.</label>
            <div class="value">{{activity.vehicle?.registrationNo || '-'}}</div>
        </div>
    </div>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Close
    </button>
</div>
