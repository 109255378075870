import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthorizationInterceptor } from './shared/interceptor/authorization-interceptor.service';
import { ErrorInterceptor } from './shared/interceptor/error.interceptor';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ComponentsModule } from './components/components.module';
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './login/login.module';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {MatSliderModule} from '@angular/material/slider';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ComponentsModule,
        SharedModule,
        LoginModule,
        MatSliderModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                floatLabel: 'never'
            }
        },
        { 
            provide: MAT_DATE_LOCALE, 
            useValue: 'en-GB' 
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
