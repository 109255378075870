import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { HolidayModel } from 'src/app/model/holiday.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { HolidayService } from 'src/app/services/holiday.service';
import { DialogSimpleComponent } from 'src/app/shared/dialog-simple/dialog-simple.component';

@Component({
    selector: 'app-holiday-dialog-detail',
    templateUrl: './holiday-dialog-detail.component.html',
    styleUrls: ['./holiday-dialog-detail.component.scss']
})
export class HolidayDialogDetailComponent {

    readonly UPDATE_HOLIDAY = ApplicationPermissionsService.UPDATE_HOLIDAY;
    readonly DELETE_HOLIDAY = ApplicationPermissionsService.DELETE_HOLIDAY;

    constructor(
        @Inject(MAT_DIALOG_DATA) public holidayModel: any,
        public dialogRef: MatDialogRef<HolidayDialogDetailComponent>,
        public authService: AuthService,
        private matDialog: MatDialog,
        private holidayService: HolidayService,
        private snackBar: MatSnackBar,
        private datePipe: DatePipe,
    ) { }

    openHolidayDialogDelete(holidayModel?: HolidayModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete Holiday?',
                message: `Are you sure want to delete ${holidayModel.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.holidayService.delete(
                        holidayModel.id.toString(),
                        this.datePipe.transform(holidayModel.date, 'yyyy-MM-dd', '+800'),
                        this.authService.getCurInsId()
                    ).subscribe({
                        next: () => {
                            this.dialogRef.close('ok');
                        },
                        error: (value) => {
                            if (value.status === 200) this.dialogRef.close('ok');
                            else this.snackBar.open(value.error.message, 'Close');
                        },
                    });
                }
            }
        })
    }

}
