import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CUSTOMERSEARCHBY } from 'src/app/shared/constant/customer-searchBy-enum.constant';
import { AuthService } from 'src/app/auth/auth.service';
import { CustomerRecordModel } from 'src/app/model/customer-records.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { CustomerRecordsService } from 'src/app/services/customer-records.service';
import { CustomerRecordsDialogNewOrEditComponent } from '../customer-records-dialog-new-or-edit/customer-records-dialog-new-or-edit.component';

@Component({
    selector: 'app-customer-records-listing',
    templateUrl: './customer-records-listing.component.html',
    styleUrls: ['./customer-records-listing.component.scss']
})
export class CustomerRecordsListingComponent implements OnInit {

    readonly CREATE_CUSTOMER = ApplicationPermissionsService.CREATE_CUSTOMER;
    readonly UPDATE_CUSTOMER = ApplicationPermissionsService.UPDATE_CUSTOMER;
    pageSize: number = 20;
    sortOrder: string = 'firstName,desc';
    currentPage: number = 0;
    searchFormGroup: FormGroup;
    numberOfRecords: number = 0;
    dataSource: MatTableDataSource<CustomerRecordModel> = new MatTableDataSource<CustomerRecordModel>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayColumns: string[] = [
        'firstName',
        'isBuyer',
        'ic',
        'email',
        'mainPhone',
        'actions'
    ];
    loading:boolean = false;
    customerSearchBy = CUSTOMERSEARCHBY;
    isMysteryShopper: boolean = false;
    isShowMysteryShopperCheckbox: boolean = false;

    constructor(
        private matDialog: MatDialog,
        public activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        public authService: AuthService,
        private customerRecordsService: CustomerRecordsService,
        private snackBar: MatSnackBar,
        private router: Router,
    ) { }

    ngOnInit(): void {
        if (this.authService.getAuthorities().some(e => e.authority.includes('READ_MYSTERY_SHOPPER') || e.authority.includes('CREATE_MYSTERY_SHOPPER'))) {
            this.isShowMysteryShopperCheckbox = true;
        }

        this.currentPage = this.activatedRoute?.snapshot?.queryParams['page'] ? this.activatedRoute?.snapshot?.queryParams['page'] : 0;
        this.sortOrder = this.activatedRoute?.snapshot?.queryParams['sort'] ? this.activatedRoute?.snapshot?.queryParams['sort'] : 'firstName,asc';
        this.pageSize = this.activatedRoute?.snapshot?.queryParams['pageSize'] ? this.activatedRoute?.snapshot?.queryParams['pageSize'] : 20;
        this.searchFormGroup = this.formBuilder.group({
            searchByType: this.formBuilder.control(null),
            searchParameter: this.formBuilder.control(null),
            isMysteryShopper: this.formBuilder.control(false),
        });
        this.refreshForm();
        this.dataSource.paginator = this.paginator;
    }

    openCustomerDialogEdit(customerRecordModel?: CustomerRecordModel) {
        this.customerRecordsService.getOne(customerRecordModel.id, this.authService.getCurInsId()).subscribe({
            next: (value) => {
                customerRecordModel = value.body;
                const modalRef = this.matDialog.open(CustomerRecordsDialogNewOrEditComponent, {
                    data: customerRecordModel,
                    panelClass: 'mat-dialog-lg'
                })

                modalRef.afterClosed().subscribe({
                    next: () => {
                        this.search(0);
                    },
                    error: () => {
                        this.search(0)
                    }
                })
            },
            error: (value) => {
                this.snackBar.open(value.error.message, 'Close', {
                    duration: 60000
                });
            },
        })
    }

    openCustomerDialogCreate(customerRecordModel?: CustomerRecordModel) {
        const modalRef = this.matDialog.open(CustomerRecordsDialogNewOrEditComponent, {
            data: customerRecordModel,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: () => {
                this.search(0);
            },
            error: () => {
                this.search(0)
            }
        })
    }

    refreshForm() {
        this.searchFormGroup = this.formBuilder.group({
            searchByType: this.formBuilder.control(null),
            searchParameter: this.formBuilder.control(null),
            isMysteryShopper: this.formBuilder.control(false),
        });
        this.search(0);
        this.activeLoading();
    }

    search(currentPage: number) {
        this.activeLoading();
        let ic: string = null;
        let phone: string = null;
        let vehicleModel: string = null;
        let vehicleNumber: string = null;
        let registeredDepartment: string = null;
        let firstName: string = null;

        if ('ic' === this.searchFormGroup.get('searchByType').value) {
            ic = this.searchFormGroup.get('searchParameter').value;
        } else if ('phone' === this.searchFormGroup.get('searchByType').value) {
            phone = this.searchFormGroup.get('searchParameter').value;
        } else if ('vehicleModel' === this.searchFormGroup.get('searchByType').value) {
            vehicleModel = this.searchFormGroup.get('searchParameter').value;
        } else if ('vehicleNumber' === this.searchFormGroup.get('searchByType').value) {
            vehicleNumber = this.searchFormGroup.get('searchParameter').value;
        } else if ('registeredDepartment' === this.searchFormGroup.get('searchByType').value) {
            registeredDepartment = this.searchFormGroup.get('searchParameter').value;
        } else {
            firstName = this.searchFormGroup.get('searchParameter').value;
        }

        const requestGet = {
            ic: ic,
            phone: phone,
            vehicleModel: vehicleModel,
            vehicleNumber: vehicleNumber,
            registeredDepartment: registeredDepartment,
            firstName: firstName,
            isMysteryShopper: this.isMysteryShopper,
            currentPage: currentPage,
            pageSize: this.pageSize,
            sortOrder: this.sortOrder,
            currentInstitution: this.authService.getCurInsId()
        }
        this.customerRecordsService.getAll(requestGet).subscribe({
            next: (value) => {
                this.dataSource.data = value.body;
                this.numberOfRecords = +value.headers.get('X-Total-Count');
            },
            error: (value) => {
                this.snackBar.open(value.error.message, 'Close', {
                    duration: 60000
                  });
            },
        })
        this.deactiveLoading();
    }

    sortPage(event): void {
        this.sortOrder = `${event.active},${event.direction}`;
        this.search(this.currentPage);
    }

    navigateToPage(event): void {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        this.search(this.currentPage);
    }

    openCustomerDialogDetail(customerRecordModel?: CustomerRecordModel) {
        this.router.navigate(['/customer-record/detail'], {
            queryParams: { id: customerRecordModel.id }
        })
    }

    setMysteryShopper(value){
        if(value){
            this.isMysteryShopper = true;
        }
        if(!value.checked) {
            this.isMysteryShopper = false;
        }
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }
}
