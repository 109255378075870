import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerRecordActivityModel } from 'src/app/model/customer-records.model';

@Component({
    selector: 'app-customer-records-detail-activity',
    templateUrl: './customer-records-detail-activity.component.html',
    styleUrls: ['./customer-records-detail-activity.component.scss']
})
export class CustomerRecordsDetailActivityComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public activity: CustomerRecordActivityModel,
        public dialogRef: MatDialogRef<CustomerRecordsDetailActivityComponent>,
    ) { }
}