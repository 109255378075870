import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { ApplicationPermissionModel } from 'src/app/model/application-permission.model';
import { ApplicationRoleModel } from 'src/app/model/application-role.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { ApplicationRolesService } from 'src/app/services/application-roles.service';

interface ApplicationPermissionNode {
    name: string;
    children: ApplicationPermissionNode[];
}

interface ApplicationPermissionFlatNode {
    name: string;
    level: number;
    expandable: boolean;
}

@Component({
    selector: 'app-user-roles-new-or-edit',
    templateUrl: './user-roles-new-or-edit.component.html',
    styleUrls: ['./user-roles-new-or-edit.component.scss']
})
export class UserRolesNewOrEditComponent implements OnInit {

    dialogType: string = '';
    applicationRoleFormGroup: FormGroup;
    isSaving: boolean = false;
    treeControl: FlatTreeControl<ApplicationPermissionFlatNode>;
    treeFlattener: MatTreeFlattener<ApplicationPermissionNode, ApplicationPermissionFlatNode>;
    dataSource: MatTreeFlatDataSource<ApplicationPermissionNode, ApplicationPermissionFlatNode>;
    hasChild = (_: number, node: ApplicationPermissionFlatNode) =>
        node.expandable;
    applicationPermissionModelByName: Map<string, ApplicationPermissionModel>;
    institutions: InstitutionModel[] = [];
    loading: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public applicationRoleModel: ApplicationRoleModel,
        public dialogRef: MatDialogRef<UserRolesNewOrEditComponent>,
        private formBuilder: FormBuilder,
        private applicationRolesService: ApplicationRolesService,
        private router: Router,
        private snackBar: MatSnackBar,
        private applicationPermissionsService: ApplicationPermissionsService,
        private institutionsService: InstitutionsService,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        if (this.applicationRoleModel) {
            this.dialogType = 'update';
            this.applicationRoleFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.applicationRoleModel.id),
                name: this.formBuilder.control(this.applicationRoleModel.name, Validators.required),
                institution: this.formBuilder.control(this.applicationRoleModel.institution, Validators.required),
                active: this.formBuilder.control(this.applicationRoleModel.active),
                applicationPermissions: this.formBuilder.array(this.applicationRoleModel.applicationPermissions.map(value => this.formBuilder.group({
                    id: value.id,
                    name: value.name,
                    code: value.code
                })))
            });
        } else {
            this.dialogType = 'create';
            this.applicationRoleFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null, Validators.required),
                institution: this.formBuilder.control(this.authService.getCurIns(), Validators.required),
                active: this.formBuilder.control(true),
                applicationPermissions: this.formBuilder.array([])
            });
        }

        this.applicationPermissionsService.getAll(this.applicationRoleFormGroup.getRawValue().id).subscribe({
            next: (httpResponse) => {
                this.dataSource.data = UserRolesNewOrEditComponent.constructApplicationPermissionNode(httpResponse.body);
                httpResponse.body.forEach((applicationPermissionModel) => {
                    this.applicationPermissionModelByName.set(
                        applicationPermissionModel.name,
                        applicationPermissionModel
                    );
                });
                this.deactiveLoading();
            },
        });
        this.institutionsService.getAll().subscribe({
            next: (value) => (this.institutions = value.body),
        });

        this.treeControl = new FlatTreeControl<ApplicationPermissionFlatNode>(
            (dataNode) => dataNode.level,
            (dataNode) => dataNode.expandable
        );
        this.treeFlattener = new MatTreeFlattener(
            (node, level) => {
                return {
                    expandable: node.children && node.children.length > 0,
                    name: node.name,
                    level: level,
                };
            },
            (node) => node.level,
            (node) => node.expandable,
            (node) => node.children
        );
        this.dataSource = new MatTreeFlatDataSource<
            ApplicationPermissionNode,
            ApplicationPermissionFlatNode
        >(this.treeControl, this.treeFlattener);
        this.applicationPermissionModelByName = new Map<
            string,
            ApplicationPermissionModel
        >();
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    save(): void {
        this.isSaving = true;
        this.activeLoading();
        if (this.dialogType === 'create') {
            this.applicationRolesService.save(this.applicationRoleFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                    });
                }
            });
        } else {
            this.applicationRolesService.update(this.applicationRoleFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                    });
                }
            });
        }

    }

    private static constructApplicationPermissionNode(
        applicationPermissionModels: ApplicationPermissionModel[]
    ): ApplicationPermissionNode[] {
        const applicationPermissionNodesByType = new Map<
            string,
            ApplicationPermissionNode
        >();
        for (const applicationPermissionModel of applicationPermissionModels) {
            const category = applicationPermissionModel.category;
            if (applicationPermissionNodesByType.has(category)) {
                const applicationPermissionNode =
                    applicationPermissionNodesByType.get(category);
                applicationPermissionNode.children.push({
                    name: applicationPermissionModel.name,
                    children: [],
                });
            } else {
                applicationPermissionNodesByType.set(
                    applicationPermissionModel.category,
                    {
                        name: applicationPermissionModel.category,
                        children: [
                            {
                                name: applicationPermissionModel.name,
                                children: [],
                            },
                        ],
                    }
                );
            }
        }

        return Array.from(applicationPermissionNodesByType.values());
    }

    updateApplicationRoleApplicationPermissions(
        applicationPermissionName: string
    ): void {
        const applicationPermissionsFormArray = this.applicationRoleFormGroup.get(
            'applicationPermissions'
        ) as FormArray;
        const indexOfDataToUpdate =
            applicationPermissionsFormArray.controls.findIndex((value) => {
                const applicationPermissionFormGroup = value as FormGroup;
                return (
                    applicationPermissionFormGroup.get('name').value ===
                    applicationPermissionName
                );
            });

        if (indexOfDataToUpdate === -1) {
            const applicationPermissionModel =
                this.applicationPermissionModelByName.get(applicationPermissionName);
            applicationPermissionsFormArray.push(
                this.formBuilder.group({
                    id: this.formBuilder.control(applicationPermissionModel.id),
                    name: this.formBuilder.control(applicationPermissionModel.name),
                    code: this.formBuilder.control(applicationPermissionModel.code),
                    category: this.formBuilder.control(
                        applicationPermissionModel.category
                    ),
                })
            );
        } else {
            applicationPermissionsFormArray.removeAt(indexOfDataToUpdate);
        }
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    checkInstitution() {
        return true;
    }
}
