<div class="nbt-header" *ngIf="!loading">
    <div class="container-fluid nbt-container">
        <div class="nbt-header" *ngIf="!loading">
            <div class="d-flex">
                <div class="nbt-header-title">
                    Issuing CSI Form
                </div>
            </div>
        </div>
        <br>
        <br>
        <mat-card-content *ngIf="!loading" class="mat-card-content-table" style="overflow: unset; padding: 25px;">
            <ng-container>
                <form [formGroup]="csiFormGroup">
                    <div class="row form-title">
                        Please confirm generation of CSI
                    </div>
                    <br>
                    <br>
                    <div class="row ">
                        <div class="col-md-12 nbt-form">
                            <label for="">Service Centre <span class="required">*</span></label>
                            <mat-form-field appearance="outline">
                                <mat-select name="department" formControlName="department" placeholder="Select a Service Centre "
                                    [compareWith]="compareObjects" (selectionChange)="changeSelectDepartment($event)">
                                    <mat-option *ngFor="let dept of departments" [value]="dept" >
                                        {{ dept.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-12 nbt-form">
                            <label for="">Service Advisor <span class="required">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput type="text" (ngModelChange)="searchServiceAdvisor(serviceAdvisorControl.value)"
                                       [formControl]="serviceAdvisorControl" [matAutocomplete]="auto" placeholder="Search Service Advisor"/>
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionsScroll)="onScroll()">
                                    <mat-option *ngFor="let option of serviceAdvisorRecords" [value]="option">
                                        {{option.username}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row " formArrayName="vehicle">
                        <div class="col-md-6 nbt-form">
                            <label for="">Vehicle Model <span class="required">*</span></label>
                            <mat-form-field appearance="outline">
                                <mat-select name="model" formControlName="model" placeholder="Select a Model "
                                    [compareWith]="compareObjects">
                                    <mat-option *ngFor="let model of models" [value]="model">
                                        {{ model.brand }} {{model.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 nbt-form">
                            <label for="">Vehicle Number <span class="required">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput appAutofocus type="text" formControlName="registrationNo" placeholder="Enter vehicle number" maxlength="20" required />
                                <mat-error *ngIf="csiFormGroup.get('vehicle.registrationNo').errors?.pattern">Invalid input</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <span class="required">* Required Field For Submission</span>
                <div class="nbt-dialog-footer" *ngIf="!loading">
                    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="back()">
                        <mat-icon class="mr8">clear</mat-icon>
                        Cancel
                    </button>
                    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || csiFormGroup.invalid" (click)="save()">
                        <mat-icon class="mr8">save</mat-icon>
                        Confirm and Proceed
                    </button>
                </div>
            </ng-container>
        </mat-card-content>
    </div>
</div>
