<div class="container-fluid h-100 d-flex justify-content-center align-items-center">
  <mat-card class="w-50 h-50"
            [formGroup]="pinCodeFormGroup">
    <mat-card-header class="d-flex justify-content-center">
      <mat-card-title>
        <img mat-card-image
             src="assets/logo-white.png"
             alt="Mandrill Base Project">
        <h2 class="text-center">Sign in</h2>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>PIN Code</mat-label>
          <input matInput
                 appAutofocus
                 type="password"
                 formControlName="pinCode"
                 placeholder="Enter PIN code"/>
          <mat-error>Username is required</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="invalidCredentials"
           class="d-flex align-items-center text-danger">
        <mat-icon class="me-2">error_outline</mat-icon>
        PIN code is incorrect
      </div>
      <div *ngIf="loginError"
           class="d-flex align-items-center text-danger">
        <mat-icon class="me-2">error_outline</mat-icon>
        Unable to login at the moment. Please contact the administrator for assistance.
      </div>
    </mat-card-content>

    <mat-card-actions>
      <div class="d-flex justify-content-end">
        <button color="primary"
                mat-raised-button
                [disabled]="pinCodeFormGroup.invalid"
                (click)="login()">
          LOGIN
        </button>
        <button mat-raised-button
                (click)="cancel()">
          CANCEL
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
