<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create New Department
        </ng-container>
        <ng-template #elseTemplate>
            Edit Department '{{departmentModel.name}}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="departmentFormGroup">
        <mat-tab-group>
            <mat-tab label="Department Info">
                <br>
                <br>
                <div class="row">
                    <div class="col-md-6 nbt-form">
                        <label>Name <span class="required">*</span></label>
                        <mat-form-field appearance="outline">
                            <input matInput appAutofocus type="text" formControlName="name" placeholder="Enter name" maxlength="100"/>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 nbt-form label-flex">
                        <div class="">
                            <mat-checkbox formControlName="active">Active</mat-checkbox>
                        </div>
                        <div class=" ">
                            <mat-checkbox formControlName="isBranch">Branch</mat-checkbox>
                        </div>
                        <div class="">
                            <mat-checkbox formControlName="isServiceCentre"  (change)="isServiceChecked($event)">Service Center</mat-checkbox>
                        </div>
                        <div class="">
                            <mat-checkbox formControlName="isShowRoom"  (change)="isShowroomChecked($event)">Showroom</mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-12 nbt-form-textarea">
                        <label>Description</label>
                        <mat-form-field appearance="outline">
                            <textarea matInput appAutofocus type="text" formControlName="description"
                                placeholder="Enter description" rows="5" maxlength="255" ></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 nbt-form">
                        <label>Email <span class="required">*</span></label>
                        <mat-form-field appearance="outline">
                            <input matInput appAutofocus type="text" formControlName="email" placeholder="Enter email" maxlength="100"/>
                            <mat-error *ngIf="departmentFormGroup.get('email').errors?.email">Invalid input</mat-error>
                            <mat-icon class="material-symbols-text" [matTooltip]="emailContent" matTooltipPosition="above">
                        info
                    </mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 nbt-form">
                        <label>Parent Department</label>
                        <mat-form-field appearance="outline">
                            <mat-select name="parentDepartment" formControlName="parentDepartment"
                                [compareWith]="compareObjects" [disabled]="checkDepartment()"
                                placeholder="Select parent department">
                                <mat-option [value]="null">
                                    Select parent department
                                </mat-option>
                                <mat-option *ngFor="let dep of parentDepartment" [value]="dep">
                                    {{ dep.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 nbt-form">
                        <label>Business Hours</label>
                        <mat-form-field appearance="outline">
                            <mat-select name="businessHourHeader" [compareWith]="compareObjects"
                                formControlName="businessHourHeader" placeholder="Select business hour">
                                <mat-option [value]="null">
                                    Select business hour
                                </mat-option>
                                <mat-option *ngFor="let bus of businessHoursList" [value]="bus">
                                    {{ bus.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 nbt-form">
                        <label>SLA Policy</label>
                        <mat-form-field appearance="outline">
                            <mat-select name="slaHeader" [compareWith]="compareObjects"
                            formControlName="slaHeader" placeholder="Select SLA Policy">
                            <mat-option [value]="null">
                                Select SLA Policy
                            </mat-option>
                            <mat-option *ngFor="let sla of slaHeaders" [value]="sla">
                                {{ sla.name }}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 nbt-form">
                        <label>SSI / CSI Version</label>
                        <mat-form-field appearance="outline">
                            <mat-select name="surveyForm" [compareWith]="compareObjects"
                            formControlName="surveyForm" placeholder="Select a Survey Form" >
                            <mat-option *ngFor="let form of surveyForms" [value]="form">
                                {{ form.name }}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <span class="required">* Required Field For Submission</span>
            </mat-tab>
            <mat-tab label="Reminders" *ngIf="dialogType === 'update'">
                <br>
                <br>
                <div class="row" formArrayName="reminders">
                    <ng-container *ngFor="let rem of formArrayReminders(); let i = index" [formGroupName]="i">
                        <div *ngIf="!rem.get('isEscalation').value" class="col-md-12 nbt-form">
                            <div class="row form-array-department">
                                <div class="nbt-btn-right" (click)="removeReminder(i)">
                                    <mat-icon>delete</mat-icon>
                                </div>
                                <br>
                                <br>
                                <div class="col-md-2 nbt-form" style="margin-top: 15px;">For</div>
                                <div class="col-md-4 nbt-form">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="priority">
                                            <mat-option value="HIGH">HIGH</mat-option>
                                            <mat-option value="LOW">LOW</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 nbt-form" style="margin-top: 15px;">priority.</div>
                                <div class="col-md-2 nbt-form" style="margin-top: 15px;">When</div>
                                <div class="col-md-4 nbt-form">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="type">
                                            <mat-option value="RESOLUTION">Resolution time</mat-option>
                                            <mat-option value="RESPONSE">Response time</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2 nbt-form" style="margin-top: 15px;">approaches in</div>
                                <div class="col-md-4 nbt-form">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="triggerTime">
                                            <ng-container *ngIf="rem.get('type').value === 'RESOLUTION'; else elseTypeResponse">
                                                <mat-option *ngFor="let res of triggerTimeResolutions" [value]="res.triggerTime">{{res.message}}</mat-option>
                                            </ng-container>
                                            <ng-template #elseTypeResponse>
                                                <mat-option *ngFor="let res of triggerTimeResponses" [value]="res.triggerTime">{{res.message}}</mat-option>
                                            </ng-template>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2 nbt-form" style="margin-top: 15px;">send reminder to</div>
                                <div class="col-md-10 nbt-form">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="applicationUsers" multiple required>
                                            <mat-option *ngFor="let user of users" [value]="user.id">{{user.username}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <br>
                        </div>
                    </ng-container>
                </div>
                <br>
                <button class="nbt-btn-secondary" (click)="addReminderEscalation(false)">Add Reminder</button>
            </mat-tab>
            <mat-tab label="Escalations" *ngIf="dialogType === 'update'">
                <br>
                <br>
                <div class="row" formArrayName="reminders">
                    <ng-container *ngFor="let rem of formArrayReminders(); let i = index"[formGroupName]="i">
                        <div *ngIf="rem.get('isEscalation').value" class="col-md-12 nbt-form">
                            <div class="row form-array-department">
                                <div class="nbt-btn-right" (click)="removeReminder(i)">
                                    <mat-icon>delete</mat-icon>
                                </div>
                                <br>
                                <br>
                                <div class="col-md-2 nbt-form" style="margin-top: 15px;">For</div>
                                <div class="col-md-4 nbt-form">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="priority">
                                            <mat-option value="HIGH">HIGH</mat-option>
                                            <mat-option value="LOW">LOW</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 nbt-form" style="margin-top: 15px;">priority.</div>
                                <div class="col-md-2 nbt-form" style="margin-top: 15px;">When</div>
                                <div class="col-md-4 nbt-form">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="type">
                                            <mat-option value="RESOLUTION">Resolution time</mat-option>
                                            <mat-option value="RESPONSE">Response time</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 nbt-form" style="margin-top: 15px;">is not met, escalate</div>
                                <div class="col-md-3 nbt-form">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="triggerTime">
                                            <ng-container *ngIf="rem.get('type').value === 'RESOLUTION'; else elseTypeResponse">
                                                <mat-option *ngFor="let res of triggerTimeResolutions" [value]="res.triggerTime">{{res.message}}</mat-option>
                                            </ng-container>
                                            <ng-template #elseTypeResponse>
                                                <mat-option *ngFor="let res of triggerTimeResponses" [value]="res.triggerTime">{{res.message}}</mat-option>
                                            </ng-template>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2 nbt-form" style="margin-top: 15px;">to</div>
                                <div class="col-md-10 nbt-form">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="applicationUsers" multiple required>
                                            <mat-option *ngFor="let user of users" [value]="user.id">{{user.username}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <br>
                        </div>
                    </ng-container>
                </div>
                <br>
                <button class="nbt-btn-secondary" (click)="addReminderEscalation(true)">Add Escalation</button>
            </mat-tab>
            <mat-tab label="Autogen-Report" *ngIf="dialogType === 'update'">
                <br>
                <br>
                <div class="row" formArrayName="autoGenerationReports">
                    <ng-container *ngFor="let rpt of formArrayAutogenReport(); let i = index"[formGroupName]="i">
                        <div class="col-md-12 nbt-form">
                            <div class="row form-array-department">
                                <div class="nbt-btn-right" (click)="removeAutogenReport(i)">
                                    <mat-icon>delete</mat-icon>
                                </div>
                                <br>
                                <br>
                                <div class="col-md-2 nbt-form" style="margin-top: 15px;">For</div>
                                <div class="col-md-4 nbt-form">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="type" placeholder="Select report type"
                                            style="
                                                height: 100%;
                                                padding-top: 14px;
                                            " required (selectionChange)="reportTypeSelectionChange($event, i)">
                                            <mat-option *ngFor="let type of reportType" [value]="type.code">
                                                {{ type.value }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 nbt-form" style="margin-top: 15px;">report.</div>
                                <div class="col-md-2 nbt-form" style="margin-top: 15px;">on every</div>
                                <div class="col-md-4 nbt-form">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="triggerTime" placeholder="Select trigger time"
                                            style="
                                                height: 100%;
                                                padding-top: 14px;
                                            " required [disabled]="this.departmentFormGroup.get('autoGenerationReports').value[i].type==='MYSTERY_SHOPPER_REPORT'">
                                            <mat-option *ngFor="let trigger of reportTriggerType" [value]="trigger.code">
                                                {{ trigger.value }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 nbt-form" style="margin-top: 15px;"></div>
                                <div class="col-md-2 nbt-form" style="margin-top: 15px;">send report to</div>
                                <div class="col-md-10 nbt-form">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="applicationUsers" multiple required>
                                            <mat-option *ngFor="let user of agents" [value]="user.id">{{user.username}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <br>
                        </div>
                    </ng-container>
                </div>
                <br>
                <button class="nbt-btn-secondary" (click)="addAutogenReport()">New Automation</button>
            </mat-tab>
        </mat-tab-group>
    </form>
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || departmentFormGroup.invalid"
        (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>
