import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersListingComponent } from './users-listing/users-listing.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UsersDialogNewOrEditComponent } from './users-dialog-new-or-edit/users-dialog-new-or-edit.component';
import { UsersDialogDetailComponent } from './users-dialog-detail/users-dialog-detail.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { UsersDialogResetPasswordComponent } from './users-dialog-reset-password/users-dialog-reset-password.component';
import { DirectivesModule } from 'src/app/directives/directives.module';

const routes: Routes = [
    {
        path: 'users',
        component: UsersListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    }
];

@NgModule({
    declarations: [
        UsersListingComponent,
        UsersDialogNewOrEditComponent,
        UsersDialogDetailComponent,
        UsersDialogResetPasswordComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes)
    ]
})
export class UsersModule { }
