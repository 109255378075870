import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DepartmentModel } from 'src/app/model/department.model';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Subject } from 'rxjs'

@Component({
  selector: 'app-dashboard-action',
  templateUrl: './dashboard-action.component.html',
  styleUrls: ['./dashboard-action.component.scss']
})
export class DashboardActionComponent implements OnInit {

  @Input() departmentModelsSubject: Subject<DepartmentModel[]>;
  @Input() startDateSubject: Subject<string>;
  @Input() departmentIdsSubject: Subject<DepartmentModel[]>;
  @Input() defaultDepartment: boolean;

  departmentModels : DepartmentModel[] = []

  @ViewChild('departmentSelect') departmentSelect: MatSelect;
  departmentAllSelected = true
  departmentFormControl = new FormControl('');
  departmentDateFormControl = new FormControl(new Date());
  departmentMultiSelected : any[] = [];

  constructor(
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.departmentModelsSubject.subscribe(newValue => {
      this.departmentModels = newValue;
      for(var model of this.departmentModels){
        this.departmentMultiSelected.push(model.id);
      }
        this.departmentIdsSubject.next(this.departmentMultiSelected);
    });
  }

  ngAfterContentInit(): void {
    this.sendStartDateSubject()
  }

  toggleDepartmentAllSelection() {
      this.departmentSelect.options.forEach((item: MatOption) => this.departmentAllSelected ? item.select() : item.deselect())
      this.departmentIdsSubject.next(this.departmentMultiSelected)
  }

  toggleDepartmentSingleSelection() {
      let newStatus = true;
      this.departmentSelect.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus = false;
        }
      });
      this.departmentAllSelected = newStatus;
      this.departmentIdsSubject.next(this.departmentMultiSelected)
  }

  dateChange(){
    this.sendStartDateSubject()  
  }

  sendStartDateSubject(){
    this.startDateSubject.next(this.datePipe.transform(this.departmentDateFormControl.value , 'yyyy-MM-dd', '+800'))
  }
  
  today = new Date(); 

}
