<div class="container-fluid nbt-container">
    <div class="nbt-header" *ngIf="!loading && windowWidth > 700">
        <div class="d-flex">
            <div class="nbt-status" [ngClass]="getStatusColor(status)" style="margin-right: 15px;" *ngIf="status !== ''">
                {{ statusValue(status) }}
            </div>
            <div class="nbt-header-title" *ngIf="status === ''">
                Create New VOC
            </div>
            <div class="nbt-header-title" *ngIf="status !== ''">
                {{ticketModel?.ticketNumber}}
                <div class="mt8">{{ticketModel?.title}}</div>
                <hr>
                <div class="mt8">Created On: {{ticketModel?.createdDate | date:dateFilter}}</div>
                <div *ngIf="ticketModel?.department!== null" class="mt8">{{ticketModel?.defaultDepartment?.name }} Due By: {{ ticketModel?.defaultDepartmentOverdueDatetime | date:dateFilter}}</div>
                <div *ngIf="ticketModel?.department!== null" class="mt8">{{ticketModel?.department?.name }} Due By: {{ticketModel?.departmentOverdueDatetime | date:dateFilter}}</div>
            </div>
        </div>
        <div class="nbt-btn-right back-btn">
            <button  type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="previousState()" style="background: white;">
                <mat-icon class="mr8">arrow_back</mat-icon>
                Back
            </button>
        </div>
    </div>
    <div class="nbt-header" *ngIf="!loading && windowWidth < 701">
        <ng-container *ngIf="status !== ''">
            <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 20px;">
                <div class="nbt-status" [ngClass]="getStatusColor(status)" style="margin-right: 15px;" >
                    {{ statusValue(status) }}
                </div>
                <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="previousState()" style="background: white; font-size: 14px; padding: 0 12px;">
                    <mat-icon class="mr8" style="font-size: 22px; margin: 0 2px -1px 0 !important;">arrow_back</mat-icon>
                    Back
                </button>
            </div>
            <div class="d-flex">
                <div class="nbt-header-title" *ngIf="status === ''">
                    Create New VOC
                </div>
                <div class="nbt-header-title" *ngIf="status !== ''">
                    {{ticketModel?.ticketNumber}}
                    <div class="mt8">{{ticketModel?.title}}</div>
                    <hr>
                    <div class="mt8">Created On: {{ticketModel?.createdDate | date:dateFilter}}</div>
                    <div *ngIf="ticketModel?.department!== null" class="mt8">{{ticketModel?.defaultDepartment?.name }} Due By: {{ ticketModel?.defaultDepartmentOverdueDatetime | date:dateFilter}}</div>
                    <div *ngIf="ticketModel?.department!== null" class="mt8">{{ticketModel?.department?.name }} Due By: {{ticketModel?.departmentOverdueDatetime | date:dateFilter}}</div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="status === ''">
            <div class="d-flex justify-content-between align-items-center">
                <div class="nbt-header-title">
                    Create New VOC
                </div>
                <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="previousState()" style="background: white; font-size: 14px; padding: 0 12px;">
                    <mat-icon class="mr8" style="font-size: 22px; margin: 0 2px -1px 0 !important;">arrow_back</mat-icon>
                    Back
                </button>
            </div>
        </ng-container>
    </div>
    <br>
    <div *ngIf="loading" class="nbt-empty-result">
        <div class="nbt-data-loading"></div>
    </div>
    <mat-card-content *ngIf="!loading" class="mat-card-content-table" style="overflow: unset; padding: 25px;">
        <!-- page indicator -->
        <ng-container class="page-indicator" *ngIf="status !== 'NEW' && status !== 'OPEN' && status !== 'ON_HOLD' && status !== 'IN_PROGRESS' && status !== 'RESOLVED' && status !== 'CLOSED' && status !== 'RE_OPEN'">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6 page-indicator-outline" >
                    <div  [ngClass]="state === 1 ? 'custInfoCircle' : 'vocCircle'">1</div>
                    <hr>
                    <div  [ngClass]="state === 2 ? 'custInfoCircle' : 'vocCircle'">2</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-3 text-center marginBtm-20">
                    <div>Customer Information</div>
                </div>
                <div class="col-md-3"></div>
                <div class="col-md-3">
                    <div>VOC Information</div>
                </div>
            </div>
        </ng-container>
        <mat-tab-group [(selectedIndex)]="vocTabIndex">
            <mat-tab label="VOC">
                <br>
                <mat-accordion multi>
                    <!-- Customer Information -->
                    <mat-expansion-panel [expanded]="state === 1">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Customer Information
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <ng-container *ngIf="isCreate">
                            <mat-card>
                            <form [formGroup]="customerFormGroup">
                                <div class="row" *ngIf="state === 1">
                                    <div class="col-md-4 nbt-form">
                                        <label>Search Customer by</label>
                                        <mat-form-field appearance="outline">
                                            <mat-select name="searchByType" formControlName="searchByType" placeholder="Select search by "
                                                [compareWith]="compareObjects">
                                                <mat-option [value]="null">
                                                    Select search by
                                                </mat-option>
                                                <mat-option *ngFor="let srhBy of searchBy" [value]="srhBy.value">
                                                    {{srhBy.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6 nbt-form">
                                        <label>Search Parameter</label>
                                        <mat-form-field appearance="outline">
                                            <input matInput appAutofocus formControlName="searchParameter" placeholder="Enter parameter"
                                            (ngModelChange)="toggleEnableSearchCustomer($event)">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-2 d-flex align-items-center justify-content-center">
                                        <button class=" nbt-btn-primary" (click)="searchCust()" [disabled]="disableSearchCustomer">
                                            <mat-icon class="mr8">search</mat-icon>
                                        Search</button>
                                    </div>
                                    <br>
                                    <br>
                                </div>
                                <ng-container *ngIf="customerSearchResultShow">
                                    <ng-container *ngIf="customerSearchResult.length > 0; else noCustomerSearchResult">
                                        <label>Result</label>
                                        <div class="search-result-customer-form">
                                            <div *ngFor="let customer of customerSearchResult" (click)="selectCustomer(customer)" class="mb8">
                                                {{customer.firstName}} {{customer.lastName}} - {{customer.ic}} - {{customer.mainPhone}} <span *ngIf="customer.vehicle">- {{customer.vehicle.registrationNo}}</span>
                                            </div>
                                            <div *ngIf="totalCustomers > currentTotalCustomers" (click)="loadMoreCustomers()" class="load-more">
                                                Load More
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #noCustomerSearchResult>
                                        <label style="margin-bottom: 21.5px;">No Result</label>
                                    </ng-template>
                                </ng-container>
                            </form>
                            </mat-card>
                            <br>
                            <br>
                        </ng-container>
                        <form [formGroup]="customerFormGroup">

                            <div class="row">
                                <div class="col-md-4 nbt-form">
                                    <label>Salutation</label>
                                    <mat-form-field appearance="outline">
                                        <mat-select name="salutation" formControlName="salutation" placeholder="Select salutation"
                                            [compareWith]="compareObjects" >
                                            <mat-option [value]="null">
                                                Select salutation
                                            </mat-option>
                                            <mat-option *ngFor="let sal of sysConfigSal" [value]="sal">
                                                {{sal}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-8 nbt-form">
                                    <label>Full Name <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <input matInput appAutofocus type="text" formControlName="firstName" placeholder="Enter first name"
                                            maxlength="200" required />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 nbt-form">
                                    <label>Identity Card Number/Passport <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <input matInput appAutofocus type="text" formControlName="ic"
                                            placeholder="Enter identity card number/passport" maxlength="50" />
                                        <mat-error *ngIf="customerFormGroup?.get('ic')?.errors?.pattern"></mat-error>
                                        <mat-icon class="material-symbols-text" [matTooltip]="icContent" matTooltipPosition="above">
                                            info
                                        </mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 nbt-form">
                                    <label>Email <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <input matInput appAutofocus type="text" formControlName="email" placeholder="Enter email"
                                            maxlength="100" />
                                        <mat-error *ngIf="customerFormGroup?.get('email').errors?.email">Invalid input</mat-error>
                                        <mat-icon class="material-symbols-text" [matTooltip]="emailContent" matTooltipPosition="above">
                                            info
                                        </mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 nbt-form">
                                    <label>Contact Number (Main) <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <input matInput appAutofocus type="text" formControlName="mainPhone"
                                            placeholder="Enter contact number (main)" maxlength="20" />
                                        <mat-error *ngIf="customerFormGroup?.get('mainPhone').errors?.pattern">Invalid input</mat-error>
                                        <mat-icon class="material-symbols-text" [matTooltip]="phoneContent" matTooltipPosition="above">
                                            info
                                        </mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 nbt-form">
                                    <label>Contact Number (Home)</label>
                                    <mat-form-field appearance="outline">
                                        <input matInput appAutofocus type="text" formControlName="homePhone"
                                            placeholder="Enter contact number (home)" maxlength="20" />
                                        <mat-error *ngIf="customerFormGroup?.get('homePhone').errors?.pattern">Invalid input</mat-error>
                                        <mat-icon class="material-symbols-text" [matTooltip]="phoneContent" matTooltipPosition="above">
                                            info
                                        </mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 nbt-form" >
                                    <label>Contact Number (Office)</label>
                                    <mat-form-field appearance="outline">
                                        <input matInput appAutofocus type="text" formControlName="officePhone"
                                            placeholder="Enter contact number (office)" maxlength="20" />
                                        <mat-error *ngIf="customerFormGroup?.get('officePhone').errors?.pattern">Invalid input</mat-error>
                                        <mat-icon class="material-symbols-text" [matTooltip]="phoneContent" matTooltipPosition="above">
                                            info
                                        </mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 nbt-form" *ngIf="state === 1">
                                    <label>Registered Branch <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <mat-select matInput appAutofocus  name="type" formControlName="registeredDepartment" placeholder="Select Registered Branch"
                                            [compareWith]="compareObjects">
                                            <mat-option [value]="null">
                                                Select Registered Branch
                                            </mat-option>
                                            <mat-option *ngFor="let branch of registeredBranchs" [value]="branch">
                                                {{ branch.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 nbt-form">
                                    <label>Customer Vehicle Model</label>
                                    <mat-form-field appearance="outline">
                                        <mat-select name="model" formControlName="model" placeholder="Select model"
                                            [compareWith]="compareObjects" >
                                            <mat-option [value]="null">
                                                Select model
                                            </mat-option>
                                            <mat-option *ngFor="let model of models" [value]="model">
                                                {{ model.brand }} {{ model.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 nbt-form" >
                                    <label>Customer Vehicle Number</label>
                                    <mat-form-field appearance="outline">
                                        <input matInput appAutofocus type="text" formControlName="registrationNo"
                                            placeholder="Enter Vehicle Number"  >
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 d-flex align-items-center nbt-form flex-row">
                                    <mat-checkbox formControlName="isBuyer" (change)="checkBuyer($event.checked)">Buyer</mat-checkbox>
                                </div>
                            </div>
                            <span class="required" *ngIf="state === 1">* Required Field For Submission</span>
                        </form>
                    </mat-expansion-panel>
                    <br>

                    <!-- VOC Information -->
                    <!-- <mat-expansion-panel *ngIf="state === 2" [expanded]="state === 2 && status === ''"> -->
                    <mat-expansion-panel *ngIf="state === 2" [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                VOC Information
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <form [formGroup]="ticketFormGroup">
                            <div *ngIf="state === 2">
                                <div class="row">
                                    <div class="col-md-12 nbt-form">
                                        <label>VOC Title <span class="required">*</span></label>
                                        <mat-form-field appearance="outline">
                                            <input matInput appAutofocus type="text" autoAo formControlName="title" placeholder="Enter VOC Title">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-12 nbt-form">
                                        <label>Group <span class="required">*</span></label>
                                        <mat-form-field appearance="outline">
                                            <mat-select name="group" [compareWith]="compareObjects" formControlName="group" placeholder="Select Group"
                                                (selectionChange)="changeSelectGroup($event)">
                                                <mat-option [value]="null">
                                                    Select Group
                                                </mat-option>
                                                <mat-option *ngFor="let group of groups" [value]="group">
                                                    {{ group.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-12 nbt-form">
                                        <label>Category <span class="required">*</span></label>
                                        <mat-form-field appearance="outline">
                                            <mat-select name="category" formControlName="category" placeholder="Select Category"
                                                (selectionChange)="changeSelectCategory($event)" [compareWith]="compareObjects">
                                                <mat-option [value]="null">
                                                    Select Category
                                                </mat-option>
                                                <mat-option *ngFor="let category of categories" [value]="category">
                                                    {{ category.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-12 nbt-form">
                                        <label>Type <span class="required">*</span></label>
                                        <mat-form-field appearance="outline">
                                            <mat-select name="type" formControlName="type" placeholder="Select Type" [compareWith]="compareObjects">
                                                <mat-option [value]="null">
                                                    Select Type
                                                </mat-option>
                                                <mat-option *ngFor="let type of types" [value]="type">
                                                    {{ type.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6 nbt-form">
                                        <label>VOC Vehicle Model</label>
                                        <mat-form-field appearance="outline">
                                            <mat-select name="vehicle" formControlName="model" placeholder="Select model"
                                                [compareWith]="compareObjects" >
                                                <mat-option [value]="null">
                                                    Select model
                                                </mat-option>
                                                <mat-option *ngFor="let model of models" [value]="model">
                                                    {{ model.brand }} {{ model.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6 nbt-form">
                                        <label>VOC Vehicle Number</label>
                                        <mat-form-field appearance="outline">
                                            <input matInput type="text" formControlName="registrationNo"
                                                placeholder="Enter Vehicle Number" >
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-12 nbt-form-textarea">
                                        <label>Details <span class="required">*</span></label>
                                        <mat-form-field appearance="outline">
                                            <textarea matInput type="text" formControlName="detail"
                                                placeholder="Enter description" rows="5" maxlength="2000" #myInput>
                                            </textarea>
                                            <span>{{myInput.value.length}} / 2000</span>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-12 nbt-form">
                                        <label>Incident Branch <span class="required">*</span></label>
                                        <mat-form-field appearance="outline">
                                            <mat-select name="type" formControlName="incidentBranch" placeholder="Select Incident Branch"
                                                [compareWith]="compareObjects">
                                                <mat-option [value]="null">
                                                    Select incident branch
                                                </mat-option>
                                                <mat-option *ngFor="let branch of incidentBranchs" [value]="branch">
                                                    {{ branch.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4 nbt-form">
                                        <label>Channel <span class="required">*</span></label>
                                        <mat-form-field appearance="outline">
                                            <mat-select name="channel" formControlName="channel" placeholder="Select Channel" (selectionChange)="changeOption($event)">
                                                <mat-option [value]="null">
                                                    Select Channel
                                                </mat-option>
                                                <mat-option *ngFor="let channel of sysConfigChannel" [value]="channel">
                                                    {{ channel }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4 nbt-form mat-form-field-wrapper">
                                        <label>Delivery / Last Service Date</label>
                                        <input class="date-picker" formControlName="serviceDeliveryDate" type="date"
                                            placeholder="Select purchase date" />
                                    </div>
                                    <div class="col-md-4 nbt-form mat-form-field-wrapper">
                                        <label>VOC Date/Time <span class="required">*</span></label>
                                        <input class="date-picker" formControlName="ticketDatetimeString" type="datetime-local"
                                            placeholder="Select last service date" />
                                    </div>
                                    <ng-container *ngIf="channelShow">
                                        <div class="col-md-12 nbt-form">
                                            <mat-form-field appearance="outline">
                                                <input matInput type="text" formControlName="channelOther"
                                                    placeholder="Enter channel" max="50">
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </form>
                        <div class="nbt-btn-right" *ngIf="status === 'NEW' || status === 'OPEN'">
                             <button appScreenPermission [permissionsInput]="[MODIFY_TICKET]" type="button" class="nbt-btn-primary ml8" (click)="modification()">
                                <mat-icon class="mr8">save</mat-icon>
                                Modification
                            </button>
                        </div>
                    </mat-expansion-panel>
                    <br>

                    <!-- Customer Documents -->
                    <mat-expansion-panel *ngIf="state === 2" [expanded]="status === '' || status === 'NEW'">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Customer Documents

                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <div class="row">
                            <div class="col-md-12 nbt-form">
                                <div class="attachment">Attachments
                                    <mat-icon class="material-symbols-text" [matTooltip]="attContent" matTooltipPosition="below">
                                        info
                                    </mat-icon>
                                </div>
                                <br>
                                <div *ngIf="status === '' || status === 'NEW'">
                                    <input id="customerFileUpload" hidden class="attachment-voc"  type="file" (change)="onFileSelected($event, 'customer')" multiple [accept]="acceptedMimeTypes" placeholder="Browse..." #fileInput>
                                    <button  type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="triggerFileUpload('customerFileUpload')">
                                        Add File
                                    </button>
                                </div>
                                <div *ngIf="customerAttachmentUrls.length > 0" class="preview-img-voc">
                                    <div class="img-box" *ngFor="let img of customerAttachmentUrls; let i = index">
                                        <img matTooltip="Click to preview" *ngIf="img.type === 'image'" [src]="img.url" alt="profile-img_{{i}}" (click)="previewImage(img)"/>
                                        <a matTooltip="Click to open" *ngIf="img.type === 'application'" [href]="img.url" target="_blank" rel="noopener noreferrer">
                                            <mat-icon class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">picture_as_pdf</mat-icon>
                                        </a>
                                        <mat-icon *ngIf="img.type === 'video'" class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">video_collection</mat-icon>
                                        <mat-icon *ngIf="img.type === 'audio'" class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">audio_file</mat-icon>
                                        <div class="att-name">{{img.name}} </div>
                                        <span class="dlt-btn">
                                            <mat-icon matTooltip="Click to remove" *ngIf="img.id === null" class="mr8" (click)="removeImage(i,'customer')">delete</mat-icon>
                                            <mat-icon matTooltip="Click to download" class="mr8" (click)="downloadFile(img)">download</mat-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <br>

                    <!-- VOC Verification -->
                    <mat-expansion-panel *ngIf="status !== ''" [expanded]="status === 'NEW' || status === 'OPEN'">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                VOC Verification
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <form [formGroup]="vocVerificationFormGroup">
                            <div class="row">
                                <div class="col-md-4 nbt-form" style="padding-bottom: 1.34375em;">
                                    <label>Date/Time Of Calling <span class="required">*</span></label>
                                    <input class="date-picker" formControlName="verificationCallDatetimeString" type="datetime-local"
                                        placeholder="Select date time calling"  />
                                </div>
                                <div class="col-md-8 nbt-form"></div>
                                <div class="col-md-12 nbt-form-textarea">
                                    <label>Details <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <textarea matInput appAutofocus type="text" formControlName="verificationDetail"
                                            placeholder="Enter verification detail" rows="5" maxlength="2000" #myInput>
                                        </textarea>
                                        <span>{{myInput.value.length}} / 2000</span>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12 nbt-form" style="padding-bottom: 1.34375em;">
                                    <label>Priority <span class="required">*</span></label>
                                    <mat-radio-group formControlName="priority">
                                        <mat-radio-button value="HIGH" style="margin-right: 20px;">HIGH</mat-radio-button>
                                        <mat-radio-button value="LOW">LOW</mat-radio-button>
                                      </mat-radio-group>
                                </div>
                                <div class="col-md-12 nbt-form" style="padding-bottom: 1.34375em;">
                                    <label>Classification <span class="required">*</span></label>
                                    <mat-radio-group formControlName="classification">
                                        <mat-radio-button value="Product" style="margin-right: 20px;">Product</mat-radio-button>
                                        <mat-radio-button value="Customer Handling" style="margin-right: 20px;">Customer Handling</mat-radio-button>
                                        <mat-radio-button value="Service">Service</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="col-md-12 nbt-form">
                                    <label>Department <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <mat-select name="type" formControlName="department"
                                        (selectionChange)="changeSelectDepartment($event)"
                                        [compareWith]="compareObjects" placeholder="Select department">
                                        <mat-option [value]="null">
                                            Select department
                                        </mat-option>
                                            <mat-option *ngFor="let assignDept of assignDepts" [value]="assignDept">
                                                {{ assignDept.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>
                    <br>

                    <!-- VOC Assignment -->
                    <mat-expansion-panel *ngIf="status !== ''" [expanded]="status === 'NEW' || status === 'OPEN' || status === 'ASSIGNED'">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                VOC Assignment
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <form [formGroup]="vocVerificationAssigneeFormGroup">
                            <div class="row">
                                <div class="col-md-12 nbt-form">
                                    <label>Consignee <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <mat-select name="type" formControlName="assignee"
                                                    (selectionChange)="changeSelectAssignee()"
                                                    [compareWith]="compareObjects" placeholder="Select assignee">
                                            <mat-option [value]="null">
                                                Select assignee
                                            </mat-option>
                                            <mat-option *ngFor="let assignee of assignees" [value]="assignee">
                                                {{ assignee.username }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="(assigneesManager.length > 0 || assigneesHod.length>0) && isShowCc">
                                    <label style="color:darkgray">Please take note, a VOC ticket assignment email will be CCed to following :</label>
                                    <div *ngIf="this.vocVerificationFormGroup.get('priority').value==='HIGH'">
                                        <div *ngFor="let hodApplicationUser of assigneesHod ; let i = index">
                                            <label style="color:darkgray">
                                                {{ i+1 }}. {{hodApplicationUser.applicationPosition.code }} - {{ hodApplicationUser.firstName }} {{ hodApplicationUser.lastName }} ({{ hodApplicationUser.email }})
                                            </label>
                                        </div>
                                        <div *ngFor="let managerApplicationUser of assigneesManager ; let i = index">
                                            <label style="color:darkgray">
                                                {{ i+1+assigneesHod.length }}. {{managerApplicationUser.applicationPosition.code }} - {{ managerApplicationUser.firstName }} {{ managerApplicationUser.lastName }} ({{ managerApplicationUser.email }})
                                            </label>
                                        </div>
                                    </div>
                                    <div *ngIf="this.vocVerificationFormGroup.get('priority').value==='LOW'">
                                        <div *ngFor="let managerApplicationUser of assigneesManager ; let i = index">
                                            <label style="color:darkgray">
                                                {{ i+1 }}. {{managerApplicationUser.applicationPosition.code }} - {{ managerApplicationUser.firstName }} {{ managerApplicationUser.lastName }} ({{ managerApplicationUser.email }})
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="assigneesManager.length===0 && assigneesHod.length ===0 && isShowCc">
                                    <label style="color:darkgray">
                                        Please take note, VOC ticket assignment email will not be CCed to any HOD or managers as the role is not configured for this department.
                                    </label>
                                </div>
                                <br><br><br>
                                <div *ngIf="status === 'ASSIGNED' || status === 'IN_PROGRESS'" class="col-md-12 nbt-form" style="align-items: center;">
                                    <button class="nbt-btn-primary" style="width: fit-content;" (click)="confirmation('OPEN')">
                                        Return to CR
                                    </button>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>
                    <br>

                    <!-- VOC Follow-Up -->
                    <mat-expansion-panel *ngIf="status === 'IN_PROGRESS' || status === 'CANCEL' || status === 'ON_HOLD' || status === 'RESOLVED' || status === 'RE_OPEN' || status === 'CLOSED'" [expanded]="status === 'IN_PROGRESS'">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                VOC Follow-Up
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <form [formGroup]="vocFollowUpFormGroup">
                            <div class="row">
                                <div class="col-md-12 nbt-form" style="padding-bottom: 1.34375em;" *ngIf="status === 'IN_PROGRESS'">
                                    <label>Action <span class="required">*</span></label>
                                    <mat-radio-group formControlName="action">
                                        <mat-radio-button value="IN_PROGRESS" style="margin-right: 20px;">Update Fact-Finding</mat-radio-button>
                                        <mat-radio-button value="RESOLVED">Resolve VOC</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="col-md-12 nbt-form-textarea" *ngIf="status === 'IN_PROGRESS' || status === 'RESOLVED' || status === 'RE_OPEN' || status === 'CLOSED'">
                                    <label *ngIf="vocFollowUpFormGroup?.get('action')?.value === 'IN_PROGRESS'">Fact-Finding <span class="required">*</span></label>
                                    <label *ngIf="vocFollowUpFormGroup?.get('action')?.value === 'RESOLVED' || vocFollowUpFormGroup?.get('action')?.value === 'RE_OPEN'  || status === 'RESOLVED' || status === 'RE_OPEN' || status === 'CLOSED'">Resolution <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <textarea matInput type="text" formControlName="detail"
                                            placeholder="Enter your remarks" rows="5" maxlength="2000" #myInput>
                                        </textarea>
                                        <span>{{myInput.value.length}} / 2000</span>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12 nbt-form-textarea" *ngIf="status === 'CANCEL'">
                                    <label>Cancel <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <textarea matInput appAutofocus type="text" formControlName="cancelReason"
                                            placeholder="Enter cancel detail" rows="5" maxlength="2000">
                                        </textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12 nbt-form-textarea" *ngIf="status === 'ON_HOLD'">
                                    <label>On-Hold Status <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <input matInput appAutofocus type="text" formControlName="onHoldStatus" placeholder="Enter status">
                                    </mat-form-field>
                                    <label>On-Hold Reason <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <textarea matInput appAutofocus type="text" formControlName="onHoldReason"
                                            placeholder="Enter on-hold detail" rows="5" maxlength="2000">
                                        </textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12 nbt-form" style="margin-bottom: 21.5px;">
                                    <div class="attachment">Attachments <span class="required" *ngIf="vocFollowUpFormGroup.get('action')?.value === 'RESOLVED'"> *</span>
                                        <mat-icon class="material-symbols-text" [matTooltip]="attContent" matTooltipPosition="below">
                                            info
                                        </mat-icon>
                                    </div>
                                    <br>
                                    <ng-container *ngIf="vocFollowUpFormGroup?.get('action')?.value === 'IN_PROGRESS'">
                                        <div *ngIf="status === 'IN_PROGRESS'">
                                            <input id="factFindingFileUpload" hidden class="attachment-voc" type="file" (change)="onFileSelected($event, 'factFinding')" multiple [accept]="acceptedMimeTypes" placeholder="Browse...">
                                            <button  type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="triggerFileUpload('factFindingFileUpload')">
                                                Add File
                                            </button>
                                        </div>
                                        <div *ngIf="factFindingAttachmentUrls.length > 0" class="preview-img-voc">
                                            <div class="img-box" *ngFor="let img of factFindingAttachmentUrls; let i = index">
                                                <img matTooltip="Click to preview" *ngIf="img.type === 'image'" [src]="img.url" alt="profile-img_{{i}}" (click)="previewImage(img)"/>
                                                <a matTooltip="Click to open" *ngIf="img.type === 'application'" [href]="img.url" target="_blank" rel="noopener noreferrer">
                                                    <mat-icon class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">picture_as_pdf</mat-icon>
                                                </a>
                                                <mat-icon *ngIf="img.type === 'video'" class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">video_collection</mat-icon>
                                                <mat-icon *ngIf="img.type === 'audio'" class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">audio_file</mat-icon>
                                                <div class="att-name">{{img.name}}</div>
                                                <span class="dlt-btn">
                                                    <mat-icon matTooltip="Click to remove" class="mr8" (click)="removeImage(i, 'factFinding')">delete</mat-icon>
                                                    <mat-icon matTooltip="Click to download" class="mr8" (click)="downloadFile(img)">download</mat-icon>
                                                </span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="vocFollowUpFormGroup.get('action')?.value === 'RESOLVED' || status === 'CLOSED' || status === 'RESOLVED' || status === 'RE_OPEN'">
                                        <div *ngIf="status === 'IN_PROGRESS'">
                                            <input id="resolvedFileUpload" hidden  class="attachment-voc" type="file" (change)="onFileSelected($event, 'resolve')" multiple [accept]="acceptedMimeTypes" placeholder="Browse...">
                                            <button  type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="triggerFileUpload('resolvedFileUpload')">
                                                Add File
                                            </button>
                                        </div>
                                        <div *ngIf="resolveAttachmentUrls.length > 0" class="preview-img-voc">
                                            <div class="img-box" *ngFor="let img of resolveAttachmentUrls; let i = index">
                                                <img matTooltip="Click to preview" *ngIf="img.type === 'image'" [src]="img.url" alt="profile-img_{{i}}" (click)="previewImage(img)"/>
                                                <a matTooltip="Click to open" *ngIf="img.type === 'application'" [href]="img.url" target="_blank" rel="noopener noreferrer">
                                                    <mat-icon class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">picture_as_pdf</mat-icon>
                                                </a>
                                                <mat-icon *ngIf="img.type === 'video'" class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">video_collection</mat-icon>
                                                <mat-icon *ngIf="img.type === 'audio'" class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">audio_file</mat-icon>
                                                <div class="att-name">{{img.name}}</div>
                                                <span class="dlt-btn">
                                                    <mat-icon matTooltip="Click to remove"  *ngIf="status === 'ASSIGNED' || status === 'IN_PROGRESS'" class="mr8" (click)="removeImage(i, 'resolve')">delete</mat-icon>
                                                    <mat-icon matTooltip="Click to download" class="mr8" (click)="downloadFile(img)">download</mat-icon>
                                                </span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <mat-card-content class="mat-card-content-table" style="padding: 0;" *ngIf="ticketModel?.factFindings?.length > 0">
                                    <table class="customer-record-table" aria-label="table-customer-records">
                                        <thead>
                                            <tr>
                                                <th><div style="padding-left: 16px;">Fact-Finding</div></th>
                                                <th><div>Created Date</div></th>
                                                <th><div>Created By</div></th>
                                                <th><div>Attachments</div></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let finding of ticketModel?.factFindings">
                                                <td><div style="padding-left: 16px;">{{finding.detail}}</div></td>
                                                <td><div>{{(finding.createdDate | date: 'd MMMM yyyy') || '-'}}</div></td>
                                                <td><div>{{finding.createdBy}}</div></td>
                                                <td>
                                                    <div *ngFor="let att of finding.factFindingAttachments" style="cursor: pointer; flex-direction: column;">
                                                        <img matTooltip="Click to preview" (click)="previewImage(att)" *ngIf="att.type === 'image'" [src]="att.url" alt="profile-att_{{i}}" style="height: 150px; width: 200px; object-fit: cover;"/>
                                                        <a matTooltip="Click to open" *ngIf="att.type === 'application'" [href]="att.url" target="_blank" rel="noopener noreferrer">
                                                            <mat-icon class="pdf-icon" style="font-size: 60px; color: var(--dark-blue-color);">picture_as_pdf</mat-icon>
                                                        </a>
                                                        <mat-icon matTooltip="Click to download" *ngIf="att.type === 'audio'" (click)="downloadFile(att)" class="pdf-icon" style="height: 60px; font-size: 60px; color: var(--dark-blue-color);">audio_file</mat-icon>
                                                        <mat-icon matTooltip="Click to download" *ngIf="att.type === 'video'" (click)="downloadFile(att)" class="pdf-icon" style="height: 60px; font-size: 60px; color: var(--dark-blue-color);">video_collection</mat-icon>
                                                        <div class="att-name">{{att.name}}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </mat-card-content>
                            </div>
                        </form>
                    </mat-expansion-panel>
                    <br>

                    <!-- VOC Review -->
                    <mat-expansion-panel *ngIf="status === 'RESOLVED' || status === 'CLOSED' || status === 'RE_OPEN'" [expanded]="status === 'RESOLVED'">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                VOC Review
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <form [formGroup]="vocReviewFormGroup">
                            <div class="row">
                                <div class="col-md-4 nbt-form" style="padding-bottom: 1.34375em;">
                                    <label>Date/Time Of Calling<span class="required">*</span></label>
                                    <input class="date-picker" formControlName="reviewDatetimeString" type="datetime-local"
                                        placeholder="Select date time calling"/>
                                </div>
                                <div class="col-md-8 nbt-form"></div>
                                <div class="col-md-12 nbt-form-textarea">
                                    <label>Post Follow-up Call <span class="required">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <textarea matInput appAutofocus type="text" formControlName="reviewDetail"
                                            placeholder="Enter your remark" rows="5" maxlength="2000">
                                        </textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>
                    <br>
                </mat-accordion>
                <div class="nbt-btn-right">
                    <button type="button" *ngIf="state === 1" class="nbt-btn-secondary mr8"  (click)="refreshForm()">
                        <mat-icon class="mr8">clear_all</mat-icon>
                        Clear
                    </button>
                    <button  type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="back()">
                        <mat-icon class="mr8">clear</mat-icon>
                        Cancel
                    </button>
                    <button *ngIf="showStartWorkBtn" class="nbt-btn-primary ml8" style="background-color: #1DAA5F; border-color: #1DAA5F" (click)="changeStatusAction('IN_PROGRESS')">
                        <mat-icon class="mr8">play_arrow</mat-icon>
                        Start Work
                    </button>
                    <button *ngIf="state === 1" type="button" class="nbt-btn-primary ml8" (click)="changeState()" [disabled]="customerFormGroup.invalid">
                        <mat-icon class="mr8">navigate_next</mat-icon>
                        Next
                    </button>
                    <ng-container *ngIf="state === 2 && status !== 'CANCEL' && status !== 'RESOLVED' && status !== 'RE_OPEN' && status !== 'CLOSED'">
                        <button *ngIf="status === ''" type="button" class="nbt-btn-primary ml8" (click)="saveVOC()" [disabled]="ticketFormGroup.invalid">
                            <mat-icon class="mr8">send</mat-icon>
                            Submit
                        </button>
                        <button *ngIf="status === 'NEW' || status === 'OPEN' || status === 'ASSIGNED'" type="button" class="nbt-btn-primary ml8" (click)="saveVOC()" [disabled]="vocVerificationFormGroup.invalid || vocVerificationAssigneeFormGroup.invalid">
                            <mat-icon class="mr8">send</mat-icon>
                            Submit
                        </button>
                        <button *ngIf="status === 'IN_PROGRESS'" type="button" class="nbt-btn-primary ml8" (click)="saveVOC()" [disabled]="vocFollowUpFormGroup.invalid || (vocFollowUpFormGroup?.get('action')?.value === 'RESOLVED' && resolveAttachmentUrls?.length === 0)">
                            <mat-icon class="mr8">send</mat-icon>
                            Submit
                        </button>
                    </ng-container>
                    <button *ngIf="status === 'RESOLVED'" type="button" class="nbt-btn-primary ml8" [disabled]="vocReviewFormGroup.invalid" (click)="confirmation('RE_OPEN')">
                        <mat-icon class="mr8">send</mat-icon>
                        Submit and Reopen
                    </button>
                    <button *ngIf="status === 'RESOLVED'" type="button" class="nbt-btn-primary ml8" [disabled]="vocReviewFormGroup.invalid" (click)="changeStatusAction('CLOSED')">
                        <mat-icon class="mr8">send</mat-icon>
                        Submit and Close
                    </button>
                    <div *ngIf="authService.hasAuthorityMenu(ON_HOLD_TICKET) || authService.hasAuthorityMenu(CANCEL_TICKET)" class="relative" id="moreVocDropdown">
                        <button *ngIf="status === 'ASSIGNED' || status === 'NEW' || status === 'OPEN' || status === 'IN_PROGRESS' || status === 'ON_HOLD'" type="button" class="nbt-btn-primary ml8" (click)="openMore()">
                            <mat-icon class="mr8">more_horiz</mat-icon>
                            More
                            <mat-icon class="ml8">arrow_drop_down</mat-icon>
                        </button>
                        <div *ngIf="isOpenMore" class="more-droprown-voc">
                            <div *ngIf="authService.hasAuthorityMenu(ON_HOLD_TICKET) && ( status === 'ASSIGNED' || status === 'NEW' || status === 'OPEN' || status === 'IN_PROGRESS' )" (click)="confirmation('ON_HOLD')">
                                <mat-icon class="mr8">back_hand</mat-icon>
                                On-Hold
                            </div>
                            <div *ngIf="status === 'ON_HOLD'" (click)="changeStatusAction('RESUME')">
                                <mat-icon class="mr8">play_circle_fill</mat-icon>
                                Resume
                            </div>
                            <div *ngIf="authService.hasAuthorityMenu(CANCEL_TICKET)" (click)="confirmation('CANCEL')">
                                <mat-icon class="mr8" style="transform: rotate(45deg);">add_circle</mat-icon>
                                Cancel VOC
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="WorkLog" *ngIf="status === 'NEW' || status === 'OPEN' || status === 'ON_HOLD' || status === 'ASSIGNED' || status === 'IN_PROGRESS' || status === 'RESOLVED' || status === 'CLOSED' || status === 'RE_OPEN' || status === 'CANCEL'">
                <br>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Work Log
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <br>
                    <form [formGroup]="vocFollowUpFormGroup">
                        <mat-card-content class="mat-card-content-table">
                            <table class="customer-record-table" aria-label="table-customer-records">
                                <thead>
                                    <tr>
                                        <th><div style="padding-left: 16px;">Work Logs</div></th>
                                        <th><div style="padding-left: 16px;">Created Date</div></th>
                                        <th><div style="padding-left: 16px;">Created By</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let worklog of ticketModel?.workLogs">
                                        <td><div style="padding-left: 16px;">{{worklog.detail}}</div></td>
                                        <td><div style="padding-left: 16px;">{{(worklog.createdDate | date: 'dd/MM/yyyy hh:mm a ') || '-'}}</div></td>
                                        <td><div style="padding-left: 16px;">{{worklog.createdBy}}</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-card-content>
                    </form>
                </mat-expansion-panel>
            </mat-tab>
            <mat-tab label="Chat" *ngIf="status === 'NEW' || status === 'OPEN' || status === 'ON_HOLD' || status === 'ASSIGNED' || status === 'IN_PROGRESS' || status === 'RESOLVED' || status === 'CLOSED' || status === 'RE_OPEN' || status === 'CANCEL'">
                <br>
                <div>
                    <br>
                    <form [formGroup]="vocChatFormGroup">
                        <div class="row">
                            <div class="col-md-12 nbt-form-textarea">
                                <label>Message </label>
                                <mat-form-field appearance="outline">
                                    <textarea matInput type="text" formControlName="detail"
                                        placeholder="Enter description" rows="5" maxlength="2000" #myChatInput>
                                    </textarea>
                                    <span>{{myChatInput.value.length}} / 2000</span>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="nbt-dialog-footer-chat-submitButton">
                            <button class="nbt-btn-primary" (click)="createChat()">
                                Submit
                            </button>
                        </div>
                    </form>
                    <br>
                    <mat-card-content class="mat-card-content-table">
                        <table class="customer-record-table" aria-label="table-customer-records">
                            <thead>
                                <tr>
                                    <th><div>Chat</div></th>
                                    <th><div>Created Date</div></th>
                                    <th><div>Created By</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let chat of chats">
                                    <td><div>{{chat.detail}}</div></td>
                                    <td><div>{{(chat.createdDate | date: 'dd/MM/yyyy hh:mm a ') || '-'}}</div></td>
                                    <td><div>{{chat.createdBy}}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</div>
