import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { SystemConfigurationModel } from 'src/app/model/system-configuration.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { SystemConfigDialogDetailComponent } from '../system-config-dialog-detail/system-config-dialog-detail.component';
import { SystemConfigDialogNewOrEditComponent } from '../system-config-dialog-new-or-edit/system-config-dialog-new-or-edit.component';

@Component({
    selector: 'app-system-config-listing',
    templateUrl: './system-config-listing.component.html',
    styleUrls: ['./system-config-listing.component.scss']
})
export class SystemConfigListingComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns: string[];
    dataSource: MatTableDataSource<SystemConfigurationModel>;
    obs: BehaviorSubject<SystemConfigurationModel[]>;

    readonly CREATE_SYS_CONFIG = ApplicationPermissionsService.CREATE_SYS_CONFIG;
    readonly UPDATE_SYS_CONFIG = ApplicationPermissionsService.UPDATE_SYS_CONFIG;

    searchFormGroup: FormGroup;
    institutions: InstitutionModel[];
    DepartmentFormGroup: FormGroup;

    numberOfRecords: number;
    pageNumbers: number[];
    countLoad = 0;
    currentPage: number;
    pageSize: number;
    sortOrder: string;

    constructor(
        private systemConfigurationService: SystemConfigurationService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private institutionsService: InstitutionsService,
        public authService: AuthService,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar
    ) {
        this.dataSource = new MatTableDataSource<SystemConfigurationModel>();
        this.dataSource.paginator = this.paginator;

        this.pageNumbers = [];
        this.numberOfRecords = 0;
        this.currentPage = 0;
        this.pageSize = 20;
        this.sortOrder = 'code,asc';
        this.countLoad = 0;
    }

    ngOnInit(): void {
        this.displayColumns = ['code', 'description', 'institution', 'active', 'actions'];

        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.currentPage = this.activatedRoute.snapshot.queryParams['page']
                ? this.activatedRoute.snapshot.queryParams['page']
                : undefined;
            this.sortOrder = this.activatedRoute.snapshot.queryParams['sort']
                ? this.activatedRoute.snapshot.queryParams['sort']
                : undefined;
            this.pageSize = this.activatedRoute.snapshot.queryParams['pageSize']
                ? this.activatedRoute.snapshot.queryParams['pageSize']
                : undefined;
        }
        this.createForm();
        this.refreshForm();
    }

    private createForm() {
        let code: string;
        let institution: string;
        let createdDate: string;
        let updatedDate: string;

        if (this.activatedRoute.snapshot.queryParams['page']) {
            code = this.activatedRoute.snapshot.queryParams['code']
                ? this.activatedRoute.snapshot.queryParams['code']
                : null;
            institution = this.activatedRoute.snapshot.queryParams['institutions']
                ? this.activatedRoute.snapshot.queryParams['institutions']
                : null;
            createdDate = this.activatedRoute.snapshot.queryParams['createdDate']
                ? this.activatedRoute.snapshot.queryParams['createdDate']
                : null;
            updatedDate = this.activatedRoute.snapshot.queryParams['updatedDate']
                ? this.activatedRoute.snapshot.queryParams['updatedDate']
                : null;
        }

        this.searchFormGroup = this.formBuilder.group({
            searchCode: this.formBuilder.control(code ? code : null),
            searchInstitution: this.formBuilder.control(
                institution ? institution : null
            ),
            searchCreatedDate: this.formBuilder.control(
                createdDate ? createdDate : null
            ),
            searchUpdatedDate: this.formBuilder.control(
                updatedDate ? updatedDate : null
            ),
        });
    }

    search(currentPage: number) {
        let code: string;
        let institution: string;
        let createdDate: string;
        let updatedDate: string;

        code = this.searchFormGroup.controls.searchCode.value
            ? this.searchFormGroup.controls.searchCode.value
            : null;

        institution = this.searchFormGroup.controls.searchInstitution.value
            ? this.searchFormGroup.controls.searchInstitution.value
            : null;

        createdDate = this.searchFormGroup.controls.searchCreatedDate.value
            ? this.getTimeString(
                this.searchFormGroup.controls.searchCreatedDate.value
            )
            : null;

        updatedDate = this.searchFormGroup.controls.searchUpdatedDate.value
            ? this.getTimeString(
                this.searchFormGroup.controls.searchUpdatedDate.value
            )
            : null;
        this.systemConfigurationService.getAll(
            this.currentPage,
            this.pageSize,
            this.sortOrder,
            {
                code: code,
                institution: institution,
                createdDate: createdDate ? createdDate : null,
                updatedDate: updatedDate ? updatedDate : null,
                currentInstitution: this.authService.getCurInsId()
            }
        ).subscribe({
            next: (value) => {
                this.dataSource.data = value.body;
                this.changeDetectorRef.detectChanges();
                this.dataSource.paginator = this.paginator;
                this.obs = this.dataSource.connect();

                if (currentPage === 0) {
                    this.paginator.firstPage();
                } else {
                    this.paginator.pageIndex = currentPage;
                }

                this.numberOfRecords = Number(value.headers.get('X-Total-Count'));
            },
            error: (value) => {
                this.snackBar.open(value.error.message, 'Close', {
                    duration: 60000
                  });
            },
        });
    }

    refreshForm() {
        this.searchFormGroup = this.formBuilder.group({
            searchCode: this.formBuilder.control(null),
            searchInstitution: this.formBuilder.control(null),
            searchCreatedDate: this.formBuilder.control(null),
            searchUpdatedDate: this.formBuilder.control(null),
        });
        this.search(0);
    }

    getTimeString(dateToChange: Date): string {
        let newDate: Date = new Date(dateToChange.getTime() + 8 * 60 * 60 * 1000);
        return newDate.toISOString();
    }

    ngOnDestroy(): void {
        if (this.dataSource) {
            this.dataSource.disconnect();
        }
    }

    create(): void {
        this.router
            .navigate(['create'], {
                relativeTo: this.activatedRoute,
            })
            .then();
    }

    edit(systemConfigurationModel: SystemConfigurationModel): void {
        this.router
            .navigate(['edit'], {
                relativeTo: this.activatedRoute,
                state: {
                    systemConfigurationModel,
                },
            })
            .then();
    }

    sortPage(event): void {
        this.sortOrder = `${event.active},${event.direction}`;
    }

    navigateToPage(event): void { }

    openSystemConfigDialogCreateOrEdit(systemConfigurationModel?: SystemConfigurationModel) {
        const modalRef = this.matDialog.open(SystemConfigDialogNewOrEditComponent, {
            data: systemConfigurationModel,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(0);
            }
        })
    }

    openSystemConfigDialogDetail(systemConfigurationModel?: SystemConfigurationModel) {
        const modalRef = this.matDialog.open(SystemConfigDialogDetailComponent, {
            data: systemConfigurationModel,
            panelClass: 'mat-dialog-md'
        })

        modalRef.afterClosed().subscribe({
            next: (edit) => {
                if (edit) this.openSystemConfigDialogCreateOrEdit(systemConfigurationModel);
            }
        })
    }
}
