import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRolesListingComponent } from './user-roles-listing/user-roles-listing.component';
import { UserRolesNewOrEditComponent } from './user-roles-new-or-edit/user-roles-new-or-edit.component';
import { UserRolesDetailComponent } from './user-roles-detail/user-roles-detail.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';

const routes: Routes = [
    {
        path: 'user-roles',
        component: UserRolesListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    }
];

@NgModule({
    declarations: [
        UserRolesListingComponent,
        UserRolesNewOrEditComponent,
        UserRolesDetailComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes)
    ]
})
export class UserRolesModule { }
