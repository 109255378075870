import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SlaModel } from '../model/sla.model';

@Injectable({
    providedIn: 'root',
})
export class SlaService {
    constructor(private httpClient: HttpClient) {}

    save(model: SlaModel, curIns: string): Observable<HttpResponse<SlaModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<SlaModel>(`/web/sla`, model, {
            observe: 'response',
            params: httpParams,
        });
    }

    getAll(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        criteria
    ): Observable<HttpResponse<SlaModel[]>> {
        let httpParams = new HttpParams();
        if (pageNumber) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder) {
            httpParams = httpParams.set('sort', sortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('name', criteria.name);
            }
            if (criteria.institution) {
                httpParams = httpParams.set(
                    'institutionId',
                    criteria.institution
                );
            }
            if (criteria.currentInstitution) {
                httpParams = httpParams.set(
                    'curIns',
                    criteria.currentInstitution
                );
            }
        }
        return this.httpClient.get<SlaModel[]>(`/web/sla/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllNoPagination(
        request: BasicRequest
    ): Observable<HttpResponse<SlaModel[]>> {
        const params = new HttpParams({ fromObject: { ...request } });
        return this.httpClient.get<SlaModel[]>(`/web/sla/all/no-pagination`, {
            observe: 'response',
            params,
        });
    }

    getSlaPriorityEnum() {
        return this.httpClient.get<any[]>('/sla/priority-enum');
    }

    update(
        model: SlaModel,
        curIns: string
    ): Observable<HttpResponse<SlaModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<SlaModel>(`/web/sla`, model, {
            observe: 'response',
            params: httpParams,
        });
    }

    delete(id: string, curIns: string): Observable<HttpResponse<SlaModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('id', id);
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.delete<SlaModel[]>('/web/sla', {
            observe: 'response',
            params: httpParams,
        });
    }
}
interface BasicRequest {
    curIns: number;
}
