import { ChangeDetectorRef, Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { CategoryModel } from 'src/app/model/category.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { CategoryService } from 'src/app/services/category.service';
import { CategoriesDialogNewOrEditComponent } from '../categories-dialog-new-or-edit/categories-dialog-new-or-edit.component';
import { GroupsDialogNewOrEditComponent } from '../groups-dialog-new-or-edit/groups-dialog-new-or-edit.component';
import { TypesDialogNewOrEditComponent } from '../types-dialog-new-or-edit/types-dialog-new-or-edit.component';


@Component({
  selector: 'app-categories-listing',
  templateUrl: './categories-listing.component.html',
  styleUrls: ['./categories-listing.component.scss']
})
export class CategoriesListingComponent implements OnInit {

  readonly CREATE_CATEGORY = ApplicationPermissionsService.CREATE_CATEGORY;
  readonly UPDATE_CATEGORY = ApplicationPermissionsService.UPDATE_CATEGORY;

  windowWidth: number = window.innerWidth;
  dataSourceGroup: MatTableDataSource<CategoryModel> = new MatTableDataSource<CategoryModel>();
  dataSourceCategory: MatTableDataSource<CategoryModel> = new MatTableDataSource<CategoryModel>();
  dataSourceType: MatTableDataSource<CategoryModel> = new MatTableDataSource<CategoryModel>();

  obs: BehaviorSubject<CategoryModel[]>;
  dataSource: MatTableDataSource<CategoryModel>;
  displayColumns: string[];

  searchFormGroup: FormGroup;
  groups: CategoryModel[];
  categories: CategoryModel[];
  types: CategoryModel[];
  currentPage: number;
  sortOrder: string;
  tempGroup: CategoryModel;
  tempCategory: CategoryModel;
  tempType: CategoryModel;
  selectedIndex: number;

  constructor(
    private formBuilder: FormBuilder,
    private categoryService: CategoryService,
    public  authService: AuthService,
    private matDialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
  ) {
    this.dataSource = new MatTableDataSource<CategoryModel>();
    this.currentPage = 0;
    this.sortOrder = 'asc';
   }

  ngOnInit(): void {
    this.displayColumns = [
      'name',
      'actions'
    ];
    this.searchFormGroup = this.formBuilder.group({
        searchName: this.formBuilder.control(null)
    });
    this.refreshForm();

  }
  search(){
    this.categoryService.getAllCategories(this.searchFormGroup.get('searchName').value,null,this.sortOrder,true).subscribe({
      next: response =>{
        this.dataSourceGroup.data = response.body.filter( cat => cat.path.split('|').length === 1);
        this.dataSourceCategory.data = response.body.filter(cat => cat.path.split('|').length === 2);
        this.dataSourceType.data = response.body.filter(cat => cat.path.split('|').length === 3);
      }
    })
  }

  sortPage(event): void {
    this.sortOrder = `${event.direction}`;
    this.refreshForm();
  }

  clickGroup(group: CategoryModel){
    this.tempGroup=group;
    this.categoryService.getAllCategories(null,group.id,this.sortOrder).subscribe({
      next: response => {
        this.dataSourceCategory.data = response.body;
        for(const category of this.dataSourceCategory.data){
          category.parentCategory = group;
        }
        this.dataSourceType.data = null;
      }
    })
  }

  sortPageCategory(event): void {
    this.sortOrder = `${event.direction}`;
    this.clickGroup(this.tempGroup);
  }

  clickCategory(category: CategoryModel){
    this.tempCategory = category;
    this.categoryService.getAllCategories(null,category.id,this.sortOrder).subscribe({
      next: response => {
        this.dataSourceType.data = response.body;
        for(const type of this.dataSourceType.data){
          type.parentCategory = category;
        }
      }
    })
  }

  clickType(type: CategoryModel){
    this.tempType = type;
  }

  sortPageType(event): void {
    this.sortOrder = `${event.direction}`;
    this.clickCategory(this.tempCategory);
  }

  refreshForm() {
    this.searchFormGroup = this.formBuilder.group({
        searchName: this.formBuilder.control(null),
    });
    this.categoryService.getAllCategories(this.searchFormGroup.get('searchName').value, null,this.sortOrder).subscribe({
      next: response =>{
        this.dataSourceGroup.data = response.body.filter( cat => cat.path.split('|').length === 1);
        this.dataSourceCategory.data = [];
        this.dataSourceType.data = [];
      }
    })
  }

  openGroupCreateOrEdit(data?: CategoryModel){
    const modalRef = this.matDialog.open(GroupsDialogNewOrEditComponent, {
      data: data,
      panelClass: 'mat-dialog-lg'
    })

    modalRef.afterClosed().subscribe({
        next: (yes) => {
            if (yes) this.refreshForm();
        }
    })
  }

  isHighlighted(data: CategoryModel, type: string){
    if(type==='group'){
      if(this.tempGroup===data){
        return 'highlight';
      }else{
        return '';
      }
    }else if(type==='category'){
      if(this.tempCategory===data){
        return 'highlight';
      }else{
        return '';
      }
    }else if(type==='type'){
      if(this.tempType===data){
        return 'highlight';
      }else{
        return '';
      }
    }else{
      return '';
    }
    
  }

  openCategoryCreateOrEdit(data?: CategoryModel){
    if(!data) data = {id:null,name:null,institution:null,path:null,description:null,active:true,parentCategory:this.tempGroup};
    const modalRef = this.matDialog.open(CategoriesDialogNewOrEditComponent, {
      data: data,
      panelClass: 'mat-dialog-lg'
    })

    modalRef.afterClosed().subscribe({
        next: (yes) => {
            if (yes) this.refreshForm();
        }
    })
  }

  openTypeCreateOrEdit(data?: CategoryModel){
    if(!data) data = {id:null,name:null,institution:null,path:null,description:null,active:true,parentCategory:this.tempCategory};
    const modalRef = this.matDialog.open(TypesDialogNewOrEditComponent, {
      data: data,
      panelClass: 'mat-dialog-lg'
    })

    modalRef.afterClosed().subscribe({
        next: (yes) => {
            if (yes) this.refreshForm();
        }
    })
  }

  @HostListener('window:resize')
    onResize() {
        this.windowWidth = window.innerWidth;
  }

}

