import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/auth/auth.service';
import { DashboardPersonalTicketModel } from 'src/app/model/dashboard-personal-ticket.model';
import { DepartmentModel } from 'src/app/model/department.model';
import { StatisticsDepartmentDatasource } from 'src/app/model/statistics-department.datasource';
import { StatisticsDepartmentModel } from 'src/app/model/statistics-department.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DepartmentService } from 'src/app/services/department.service';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { Subject } from 'rxjs'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  readonly READ_PERSONAL_TICKET = ApplicationPermissionsService.READ_PERSONAL_TICKET;
  readonly READ_PERSONAL_TICKET_DEFAULT_DEPARTMENT = ApplicationPermissionsService.READ_PERSONAL_TICKET_DEFAULT_DEPARTMENT;
  readonly READ_TICKET_DEPARTMENT_STATISTIC = ApplicationPermissionsService.READ_TICKET_DEPARTMENT_STATISTIC;
  readonly READ_TICKET_DEFAULT_DEPARTMENT_STATISTIC = ApplicationPermissionsService.READ_TICKET_DEFAULT_DEPARTMENT_STATISTIC;
  readonly READ_DEPARTMENT = ApplicationPermissionsService.READ_DEPARTMENT;
  readonly READ_TICKET_CATEGORY = ApplicationPermissionsService.READ_TICKET_CATEGORY;
  readonly READ_TICKET_SLA_COMPLIANCE_DEPARTMENT = ApplicationPermissionsService.READ_TICKET_SLA_COMPLIANCE_DEPARTMENT;
  readonly READ_TICKET_SLA_COMPLIANCE_DEFAULT_DEPARTMENT = ApplicationPermissionsService.READ_TICKET_SLA_COMPLIANCE_DEFAULT_DEPARTMENT;

  departmentModelsSubject: Subject<DepartmentModel[]> = new Subject();
  defaultDepartmentModelsSubject: Subject<DepartmentModel[]> = new Subject();
  
  statisticDepartmentStartDateSubject: Subject<string> = new Subject();
  statisticDepartmentDepartmentIdsSubject: Subject<any> = new Subject();
  
  statisticDefaultDepartmentStartDateSubject: Subject<string> = new Subject();
  statisticDefaultDepartmentDepartmentIdsSubject: Subject<any> = new Subject();

  categoryDepartmentStartDateSubject: Subject<string> = new Subject();
  categoryDepartmentDepartmentIdsSubject: Subject<any> = new Subject();

  complianceDepartmentStartDateSubject: Subject<string> = new Subject();
  complianceDepartmentDepartmentIdsSubject: Subject<any> = new Subject();
  
  complianceDefaultDepartmentStartDateSubject: Subject<string> = new Subject();
  complianceDefaultDepartmentDepartmentIdsSubject: Subject<any> = new Subject();

  refreshRateSubject: Subject<number> = new Subject();
  refreshRateLookup = [0,30,35,45,50]
  refreshRate: number;

  constructor(
    private departmentService: DepartmentService,
    private institutionsService: InstitutionsService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    if(!this.authService.getCurIns()){
      this.institutionsService.getAll().subscribe({
        next: (value) => {
            this.authService.setCurIns(value.body[0]);
            this.initDepartment()
          },
      });
    }else{
      this.initDepartment();
    }
  }

  initDepartment(){
    this.refreshRateSubject.next( this.refreshRateLookup[0] )
    if (this.authService.hasAuthorityMenu([this.READ_TICKET_DEPARTMENT_STATISTIC, this.READ_TICKET_CATEGORY, this.READ_TICKET_SLA_COMPLIANCE_DEPARTMENT])) {
      this.requestUserDepartment();
    }

    if (this.authService.hasAuthorityMenu(this.READ_DEPARTMENT) && this.authService.hasAuthorityMenu([this.READ_TICKET_DEFAULT_DEPARTMENT_STATISTIC, this.READ_TICKET_SLA_COMPLIANCE_DEFAULT_DEPARTMENT])) {
      this.requestDefaultDepartment();
    }
  }

  requestUserDepartment(){
    this.departmentService.getDepartmentByUser(this.authService.getCurInsId()).subscribe((value) => this.departmentModelsSubject.next(value.body));
  }

  requestDefaultDepartment() {
    this.departmentService.getAllNoPagination().subscribe((value) => this.defaultDepartmentModelsSubject.next(value.body));
  }

  refreshRateChange(event){
    this.refreshRateSubject.next( this.refreshRateLookup[event.value] || 0 )
  }

  updateRefreshText(event){
    this.refreshRate = this.refreshRateLookup[event.value];
  }

}

export enum ActionEnum {
  ASSIGNED = "ASSIGNED",
  IN_PROGRESS = "IN_PROGRESS",
  RESPONSE_OVERDUE = "RESPONSE_OVERDUE",
  RESOLUTION_OVERDUE = "RESOLUTION_OVERDUE",
  DEFAULT_DEPARTMENT_RESPONSE_OVERDUE = "DEFAULT_DEPARTMENT_RESPONSE_OVERDUE",
  DEFAULT_DEPARTMENT_RESOLUTION_OVERDUE = "DEFAULT_DEPARTMENT_RESOLUTION_OVERDUE",
  ON_HOLD = "ON_HOLD",
  NEW = "NEW",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  RE_OPEN = "RE_OPEN",
  RESOLVED = "RESOLVED",
  CANCEL = "CANCEL",
  RESUME = "RESUME",
}

