import { Property, propertyNameCompare } from '../util';

export const CUSTOMERSEARCHBY: Property[] = [
     {
        value: 'ic',
        name: 'Identification No.',
    },
    {
        value: 'phone',
        name: 'Contact No.',
    },
    {
        value: 'firstName',
        name: 'Name',
    },
    {
        value: 'vehicleNumber',
        name: 'Vehicle Number',
    },
    {
        value: 'vehicleModel',
        name: 'Vehicle Model',
    },
    {
        value: 'registeredDepartment',
        name: 'Registered Department',
    },
].sort(propertyNameCompare);
