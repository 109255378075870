import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { CustomerRecordVehiclesModel } from 'src/app/model/customer-records.model';
import { Model } from 'src/app/model/model.model';
import { ModelService } from 'src/app/services/model.service';
import { VehicleModelService } from 'src/app/services/vehicle-model.service';

@Component({
    selector: 'app-customer-records-dialog-new-or-edit-vehicles',
    templateUrl: './customer-records-dialog-new-or-edit-vehicles.component.html',
    styleUrls: ['./customer-records-dialog-new-or-edit-vehicles.component.scss']
})
export class CustomerRecordsDialogNewOrEditVehiclesComponent implements OnInit {

    customerRecordVehiclesFormGroup: FormGroup;
    dialogType: string = '';
    isSaving: boolean = false;
    models: Model[] = [];
    loading: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public customerRecordVehiclesModel: CustomerRecordVehiclesModel,
        public dialogRef: MatDialogRef<CustomerRecordsDialogNewOrEditVehiclesComponent>,
        private formBuilder: FormBuilder,
        private vehicleModelService: VehicleModelService,
        private modelService: ModelService,
        public authService: AuthService,
        private snackBar: MatSnackBar,
        private datePipe: DatePipe,
    ) { }

    ngOnInit(): void {
        if (this.customerRecordVehiclesModel) {
            this.dialogType = 'update';
            this.customerRecordVehiclesFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.customerRecordVehiclesModel.id),
                model: this.formBuilder.control(null, Validators.required),
                registrationNo: this.formBuilder.control(this.customerRecordVehiclesModel.registrationNo, Validators.required),
                purchaseDate: this.formBuilder.control(this.customerRecordVehiclesModel.purchaseDate),
                lastServiceDate: this.formBuilder.control(this.customerRecordVehiclesModel.lastServiceDate)
            })
        } else {
            this.dialogType = 'create';
            this.customerRecordVehiclesFormGroup = this.formBuilder.group({
                model: this.formBuilder.control(null, Validators.required),
                registrationNo: this.formBuilder.control(null, Validators.required),
                purchaseDate: this.formBuilder.control(null),
                lastServiceDate: this.formBuilder.control(null),
            })
        }

        this.modelService.getAllModelNoPagination().subscribe({
            next: value => {
                this.models = value.body;
                if (this.dialogType === 'update') {
                    const model = this.models.find(model => model.id === this.customerRecordVehiclesModel.model.id);
                    this.customerRecordVehiclesFormGroup.get('model').setValue(model)
                }
                this.deactiveLoading();

            }
        })
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    save() {
        this.isSaving = true;
        this.activeLoading();
        const data = this.customerRecordVehiclesFormGroup.getRawValue();
        data.purchaseDate = this.datePipe.transform(data.purchaseDate, 'yyyy-MM-dd', '+800');
        data.lastServiceDate = this.datePipe.transform(data.lastServiceDate, 'yyyy-MM-dd', '+800');
        if (this.dialogType === 'create') {
            this.vehicleModelService.createVehicle(data, this.authService.getCurInsId()).subscribe({
                next: value => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close({
                        data: value.body,
                        type: this.dialogType
                    });
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                      });
                }
            })
        } else {
            this.vehicleModelService.updateVehicle(data, this.authService.getCurInsId()).subscribe({
                next: value => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close({
                        data: value.body,
                        type: this.dialogType
                    });
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                      });
                }
            })
        }
    }

}
