import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { DepartmentModel } from 'src/app/model/department.model';
import { Model } from 'src/app/model/model.model';
import { SystemConfigurationModel, SYS_CONFIG } from 'src/app/model/system-configuration.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DepartmentService } from 'src/app/services/department.service';
import { ModelService } from 'src/app/services/model.service';
import { SurveyService } from 'src/app/services/survey.service';
import { UsersService } from 'src/app/services/users.service';
import { REGEXS, SnackBarStatus } from 'src/app/shared/constant';

@Component({
    selector: 'app-cust-page',
    templateUrl: './cust-page.component.html',
    styleUrls: ['./cust-page.component.scss']
})

export class CustPageComponent implements OnInit, OnDestroy {
    readonly CREATE_SSI = ApplicationPermissionsService.CREATE_CSI;
    readonly snackBarConfig: MatSnackBarConfig = { duration:60000 };
    loading:boolean = false;
    sysConfigBrand = SYS_CONFIG.BRAND;
    sysConfig: SystemConfigurationModel[];
    departments: DepartmentModel[];
    csiFormGroup : FormGroup;
    isBranch: DepartmentModel;
    serviceAdvisor: ApplicationUserModel;
    serviceAdvisorRecords: ApplicationUserModel[];
    models: Model[];
    isSaving:boolean = false;
    respond =" has issued a new CSI form : ";
    close = SnackBarStatus.CLOSE;

    departmentId: number = null;
    serviceAdvisorControl = new FormControl('', Validators.required);
    totalServiceAdvisor = 0;
    currentTotalServiceAdvisor = 0;
    pageServiceAdvisor = 0;
    sizeServiceAdvisor = 20;

    searchAdvisorSubscription: Subscription;

    constructor(
        private modelService : ModelService,
        private departmentService: DepartmentService,
        private userService: UsersService,
        public authService: AuthService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private surveyService: SurveyService,

    ) {}

    ngOnInit(): void {
        this.csiFormGroup = this.formBuilder.group({
            department: this.formBuilder.control(null, Validators.required),
             salesConsultant: this.serviceAdvisorControl,
            vehicle : this.formBuilder.group({
                model: this.formBuilder.control(null, Validators.required),
                registrationNo: this.formBuilder.control(null,[Validators.required,Validators.pattern(REGEXS.VEHICLE)]),
            })
        });

        this.modelService.getAllModelNoPagination().subscribe({
            next: (value) => (this.models = value.body),
        });

        this.getDepartmentsWithDefaultDepartmentChecking();
    }

    ngOnDestroy(): void {
        if(this.searchAdvisorSubscription)
            this.searchAdvisorSubscription.unsubscribe();
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    back() {
        window.history.back();
    }

    onScroll() {
        if (this.totalServiceAdvisor > this.currentTotalServiceAdvisor) {
            this.pageServiceAdvisor++;
            this.searchServiceAdvisorRequest();
        }
    }

    changeSelectDepartment(event: any){
        this.departmentId = event.value.id;
        this.serviceAdvisorRecords = [];
        this.serviceAdvisor = null;
        this.pageServiceAdvisor = 0;
        this.currentTotalServiceAdvisor = 0;
        this.serviceAdvisorControl.reset("");
        this.searchServiceAdvisorRequest();
    }

    searchServiceAdvisor() {
        this.serviceAdvisorRecords = [];
        this.serviceAdvisor = null;
        this.pageServiceAdvisor = 0;
        this.currentTotalServiceAdvisor = 0;
        this.searchServiceAdvisorRequest();
    }

    searchServiceAdvisorRequest() {
        if(this.departmentId!=null) {
            if (this.serviceAdvisorControl.value.id) {
                this.serviceAdvisor = this.serviceAdvisorControl.value;
                return;
            }

            if(this.searchAdvisorSubscription)
                this.searchAdvisorSubscription.unsubscribe();

            this.searchAdvisorSubscription = this.userService
                .searchAllByDepartment(
                    this.pageServiceAdvisor,
                    this.sizeServiceAdvisor,
                    this.serviceAdvisorControl.value,
                    this.authService.getCurInsId(),
                    this.departmentId,
                    { reminderInd: false, defaultDepartmentInd: false },
                    'service advisor')
                .subscribe({
                    next: (value) => {
                        this.totalServiceAdvisor = Number(value.headers.get('X-Total-Count'));
                        this.currentTotalServiceAdvisor = this.currentTotalServiceAdvisor + value.body.length;
                        this.serviceAdvisorRecords.push(...value.body);
                        this.deactiveLoading();
                    },
                    error: (error) => {
                        this.deactiveLoading();
                        this.snackBar.open(error.error.message, 'Close', {
                            duration: 60000,
                        });
                    },
            });
        }
    }

    displayFn(serviceAdvisor: ApplicationUserModel): string {
        return serviceAdvisor && serviceAdvisor.username ? `${serviceAdvisor.username}` : '';
    }

    save(){
        this.isSaving = true;
        this.activeLoading();
        this.surveyService.createSurvey(this.csiFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
        next:(response)=>{
            this.isSaving = false;
            this.deactiveLoading();
            let theMessage = this.authService.getUsername() + this.respond + response.body.surveyNumber;
            this.snackBar.open(theMessage, this.close, this.snackBarConfig);
            this.back();
        },
        error: (error) => {
            this.isSaving = false;
            this.deactiveLoading();
            this.snackBar.open(error.error.message, this.close, this.snackBarConfig);
        }
        })
    }

    //TODO: REFACTOR FUNCTION
    getDepartmentsWithDefaultDepartmentChecking() {
        this.departmentService.isDefaultDepartment(this.authService.getCurInsId()).subscribe({
            next: (isDefaultDepartment) => {
                if (isDefaultDepartment.body) {
                    this.departmentService.getAllNoPagination(null, true, false).subscribe({
                        next: (res) => {
                            this.departments = res.body;
                        },
                        error: (res) => {
                            this.snackBar.open(
                                res.error.message,
                                'Close',
                                this.snackBarConfig
                            );
                        },
                    });
                } else {
                    this.departmentService.getDepartmentByUser(this.authService.getCurInsId(), null, true, false).subscribe({
                            next: (res) => {
                                this.departments = res.body;
                            },
                            error: (res) => {
                                this.snackBar.open(
                                    res.error.message,
                                    'Close',
                                    this.snackBarConfig
                                );
                            },
                        });
                }
            },
            error: (err) => {
                this.snackBar.open(
                    err.error.message,
                    'Close',
                    this.snackBarConfig
                );
            },
        });
    }

}
