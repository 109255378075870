import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CustomerRecordModel,
    CustomerRecordVehiclesModel,
} from '../model/customer-records.model';

@Injectable({
    providedIn: 'root',
})
export class CustomerRecordsService {
    constructor(private httpClient: HttpClient) {}

    create(
        model: CustomerRecordModel,
        curIns: string
    ): Observable<HttpResponse<CustomerRecordModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<CustomerRecordModel>(
            `/web/customer`,
            model,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }

    getAll(request: any): Observable<HttpResponse<CustomerRecordModel[]>> {
        let httpParams = new HttpParams();
        if (request.ic) httpParams = httpParams.set('ic', request.ic);
        if (request.phone) httpParams = httpParams.set('phone', request.phone);
        if (request.vehicleModel) httpParams = httpParams.set('vehicleModel', request.vehicleModel);
        if (request.vehicleNumber) httpParams = httpParams.set('vehicleNumber', request.vehicleNumber);
        if (request.registeredDepartment) httpParams = httpParams.set('registeredDepartment', request.registeredDepartment);
        if (request.firstName) httpParams = httpParams.set('firstName', request.firstName);
        if (request.isMysteryShopper) httpParams = httpParams.set('isMysteryShopper', request.isMysteryShopper);
        if (request.currentInstitution) httpParams = httpParams.set('curIns', request.currentInstitution);
        if (request.currentPage) httpParams = httpParams.set('page', request.currentPage);
        if (request.pageSize) httpParams = httpParams.set('size', request.pageSize);
        if (request.sortOrder) httpParams = httpParams.set('sort', request.sortOrder);

        return this.httpClient.get<CustomerRecordModel[]>(`/web/customer/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getOne(
        id: string,
        curIns: number
    ): Observable<HttpResponse<CustomerRecordModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('id', id);
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.get<CustomerRecordModel>(`/web/customer`, {
            observe: 'response',
            params: httpParams,
        });
    }

    update(
        model: CustomerRecordModel,
        curIns: string
    ): Observable<HttpResponse<CustomerRecordModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<CustomerRecordModel>(`/web/customer`, model, {
            observe: 'response',
            params: httpParams
        });
    }

    getAllCustomersNoPagination(request: any): Observable<HttpResponse<CustomerRecordModel[]>> {

        let httpParams = new HttpParams();
        if (request.name) httpParams = httpParams.set('firstName', request.name);
        if (request.currentInstitution) httpParams = httpParams.set('curIns', request.currentInstitution);
        if (request.ic) httpParams = httpParams.set('ic', request.ic);
        if (request.phone) httpParams = httpParams.set('phone', request.phone);
        if (request.vehicleNumber) httpParams = httpParams.set('vehicleNumber', request.vehicleNumber);

        return this.httpClient.get<CustomerRecordModel[]>(`/web/customer/all/no-pagination`, {
            observe: 'response',
            params: httpParams,
        });
    }


    getAllNoPagination(curIns: string): Observable<HttpResponse<CustomerRecordModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.get<CustomerRecordModel[]>(`/web/customer/all/no-pagination`, {
            observe: 'response',
            params: httpParams,
        });
    }

    searchCustomers(request: {
        page?: number;
        size?: number;
        name?: string;
        currentInstitution: number;
        ic?: string;
        phone?: string;
        vehicleNumber?: string;
    }): Observable<HttpResponse<CustomerRecordModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', request.currentInstitution);
        if (request.page) {
            httpParams = httpParams.set('page', request.page);
        }
        if (request.size) {
            httpParams = httpParams.set('size', request.size);
        }
        if (request.name) {
            httpParams = httpParams.set('firstName', request.name);
        }
        if (request.ic) {
            httpParams = httpParams.set('ic', request.ic);
        }
        if (request.phone) {
            httpParams = httpParams.set('phone', request.phone);
        }
        if (request.vehicleNumber) {
            httpParams = httpParams.set('vehicleNumber', request.vehicleNumber);
        }

        return this.httpClient.get<CustomerRecordModel[]>(
            `/web/customer/all/search`,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }

    chageOwnership(
        id: string,
        customerId: string,
        curIns: string
    ): Observable<HttpResponse<CustomerRecordVehiclesModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('id', id);
        httpParams = httpParams.set('customerId', customerId);
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<CustomerRecordVehiclesModel>(
            `/web/vehicle/ownership`,
            null,
            {
                observe: 'response',
                params: httpParams,
            }
        );
    }

    uploadPicture(
        curIns: string,
        customerId: string,
        image: File
    ): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('image', image);
        return this.httpClient.put<any>(
            `/web/customer/picture?curIns=${curIns}&id=${customerId}`,
            formData
        );
    }

    getPicture(curIns: string, name: string): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        httpParams = httpParams.set('name', name);
        return this.httpClient.get<any>(`/web/customer/picture`, {
            responseType: 'blob' as 'json',
            params: httpParams,
            observe: 'response',
        });
    }

    createActivity(curIns: string, model: any): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.post<any>(`/web/activity`, model, {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllActivities(request: any): Observable<any> {
        let httpParams = new HttpParams();
        if (request.page) {
            httpParams = httpParams.set('page', request.page);
        }
        if (request.size) {
            httpParams = httpParams.set('size', request.size);
        }
        if (request.sort) {
            httpParams = httpParams.set('sort', request.sort);
        }
        if (request.curIns) {
            httpParams = httpParams.set('curIns', request.curIns);
        }
        if (request.customerId) {
            httpParams = httpParams.set('customerId', request.customerId);
        }
        return this.httpClient.get<any>('/web/activity/all', {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllTickets(request: any): Observable<any> {
        let httpParams = new HttpParams();
        if (request.page) {
            httpParams = httpParams.set('page', request.page);
        }
        if (request.size) {
            httpParams = httpParams.set('size', request.size);
        }
        if (request.sort) {
            httpParams = httpParams.set('sort', request.sort);
        }
        if (request.curIns) {
            httpParams = httpParams.set('curIns', request.curIns);
        }
        if (request.customerId) {
            httpParams = httpParams.set('customerId', request.customerId);
        }
        return this.httpClient.get<any>('/web/ticket/customer/all', {
            observe: 'response',
            params: httpParams,
        });
    }

    getOneActivity(id: string, customerId: string, curIns: number): Observable<HttpResponse<CustomerRecordModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('id', id);
        httpParams = httpParams.set('customerId', customerId);
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.get<CustomerRecordModel>(`/web/activity`, {
            observe: 'response',
            params: httpParams,
        });
    }
}
