<div class="container-fluid nbt-container">
    <!-- <div *ngIf="loading" class="nbt-empty-result">
        <div class="nbt-data-loading"></div>
    </div> -->
    <div class="nbt-header">
        <div class="nbt-header-title">Dashboard</div>
        <div class="nbt-header-action" style="min-height: 75px;">
            <div class="nbt-slider-container">
                <div class="d-flex justify-content-between">
                    <div class="nbt-refresh-rate-text nbt-refresh-rate-bold">Data Refresh Rate</div>
                    <div class="nbt-refresh-rate-text">{{refreshRate ? refreshRate + " seconds" : "None"}}</div>
                </div>
                <div class="d-flex justify-content-center" style="height: 5vh;">
                    <mat-slider class="nbt-refresh-rate-slider" (input)="updateRefreshText($event)"
                        (change)="refreshRateChange($event)" tickInterval="1" step="1" min="0" max="4"
                        aria-label="units"></mat-slider>
                </div>


            </div>

        </div>

    </div>

    <br>
    <div appScreenPermission [permissionsInput]="[READ_PERSONAL_TICKET]">
        <div class="nbt-dashboard-section">
            <div class="nbt-dashboard-section-title">
                <div class="section-title-text">Personal Ticket (VOC) Dashboard</div>
            </div>
            <hr>
            <div class="nbt-dashboard-section-body">
                <app-personal-task [defaultDepartment]="false" [refreshRateSubject]="refreshRateSubject">
                </app-personal-task>
            </div>
        </div>
        <br>
    </div>

    <div class="nbt-dashboard-section nbt-mb20" appScreenPermission
            [permissionsInput]="[READ_PERSONAL_TICKET_DEFAULT_DEPARTMENT]">
            <div class="nbt-dashboard-section-title">
                <div class="section-title-text">Help-Desk Personal Ticket (VOC) Dashboard</div>
            </div>
            <hr>
            <div class="nbt-dashboard-section-body">
                <app-personal-task [defaultDepartment]="true" [refreshRateSubject]="refreshRateSubject">
                </app-personal-task>
            </div>
        <br>
    </div>

    <div appScreenPermission [permissionsInput]="[READ_TICKET_DEPARTMENT_STATISTIC]">
        <div class="nbt-dashboard-section">
            <div class="nbt-dashboard-section-title">
                <div class="section-title-text">Ticket (VOC) Status by Department</div>
                <div class="section-title-action">
                    <app-dashboard-action [startDateSubject]="statisticDepartmentStartDateSubject" [departmentIdsSubject]="statisticDepartmentDepartmentIdsSubject"  [departmentModelsSubject]="departmentModelsSubject"></app-dashboard-action>
                </div>
            </div>
            <hr>
            <app-statistic-department [defaultDepartment]="false"
                [startDateSubject]="statisticDepartmentStartDateSubject"
                [departmentIdsSubject]="statisticDepartmentDepartmentIdsSubject"
                [refreshRateSubject]="refreshRateSubject"></app-statistic-department>
        </div>

        <br>
    </div>

    <div appScreenPermission [permissionsInput]="[READ_TICKET_DEFAULT_DEPARTMENT_STATISTIC]">
        <div class="nbt-dashboard-section">
            <div class="nbt-dashboard-section-title">
                <div class="section-title-text">Help-Desk Ticket (VOC) Status by Department</div>
                <div class="section-title-action">
                    <app-dashboard-action [startDateSubject]="statisticDefaultDepartmentStartDateSubject" [departmentIdsSubject]="statisticDefaultDepartmentDepartmentIdsSubject"  [departmentModelsSubject]="defaultDepartmentModelsSubject"></app-dashboard-action>
                </div>
            </div>
            <hr>
            <app-statistic-department [defaultDepartment]="true"
                [startDateSubject]="statisticDefaultDepartmentStartDateSubject"
                [departmentIdsSubject]="statisticDefaultDepartmentDepartmentIdsSubject"
                [refreshRateSubject]="refreshRateSubject"></app-statistic-department>
        </div>
        <br>
    </div>

    <div appScreenPermission [permissionsInput]="[READ_TICKET_CATEGORY]">
        <div class="nbt-dashboard-section">
            <div class="nbt-dashboard-section-title">
                <div class="section-title-text">Ticket (VOC) Grouping Statistic by Department</div>
                <div class="section-title-action">
                    <app-dashboard-action [startDateSubject]="categoryDepartmentStartDateSubject" [departmentIdsSubject]="categoryDepartmentDepartmentIdsSubject"  [departmentModelsSubject]="departmentModelsSubject"></app-dashboard-action>
                </div>
            </div>
            <hr>
            <app-voc-breakdown-category [defaultDepartment]="true"
            [startDateSubject]="categoryDepartmentStartDateSubject"
            [departmentIdsSubject]="categoryDepartmentDepartmentIdsSubject"
            [refreshRateSubject]="refreshRateSubject"></app-voc-breakdown-category>
        </div>
        <br>
    </div>

    <div appScreenPermission [permissionsInput]="[READ_TICKET_SLA_COMPLIANCE_DEPARTMENT]">
        <div class="nbt-dashboard-section">
            <div class="nbt-dashboard-section-title">
                <div class="section-title-text">Ticket (VOC) Status Compliance Statistic by Department</div>
                <div class="section-title-action">
                    <app-dashboard-action [startDateSubject]="complianceDepartmentStartDateSubject" [departmentIdsSubject]="complianceDepartmentDepartmentIdsSubject"  [departmentModelsSubject]="departmentModelsSubject"></app-dashboard-action>
                </div>
            </div>
            <hr>
            <app-voc-sla-compliance-department [defaultDepartment]="false"
            [startDateSubject]="complianceDepartmentStartDateSubject"
            [departmentIdsSubject]="complianceDepartmentDepartmentIdsSubject"
            [refreshRateSubject]="refreshRateSubject"></app-voc-sla-compliance-department>
        </div>
        <br>
    </div>

    <div appScreenPermission [permissionsInput]="[READ_TICKET_SLA_COMPLIANCE_DEFAULT_DEPARTMENT]">
        <div class="nbt-dashboard-section">
            <div class="nbt-dashboard-section-title">
                <div class="section-title-text">Help-Desk Ticket (VOC) Status Compliance Statistic by Department </div>
                <div class="section-title-action">
                    <app-dashboard-action [defaultDepartment]="true" [startDateSubject]="complianceDefaultDepartmentStartDateSubject" [departmentIdsSubject]="complianceDefaultDepartmentDepartmentIdsSubject"  [departmentModelsSubject]="defaultDepartmentModelsSubject"></app-dashboard-action>
                </div>
            </div>
            <hr>
            <app-voc-sla-compliance-department [defaultDepartment]="true"
            [startDateSubject]="complianceDefaultDepartmentStartDateSubject"
            [departmentIdsSubject]="complianceDefaultDepartmentDepartmentIdsSubject"
            [refreshRateSubject]="refreshRateSubject"></app-voc-sla-compliance-department>
        </div>
        <br>
    </div>
</div>