import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemConfigListingComponent } from './system-config-listing/system-config-listing.component';
import { SystemConfigDialogDetailComponent } from './system-config-dialog-detail/system-config-dialog-detail.component';
import { SystemConfigDialogNewOrEditComponent } from './system-config-dialog-new-or-edit/system-config-dialog-new-or-edit.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';

const routes: Routes = [
    {
        path: 'system-config',
        component: SystemConfigListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    }
];

@NgModule({
    declarations: [
        SystemConfigListingComponent,
        SystemConfigDialogDetailComponent,
        SystemConfigDialogNewOrEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes)
    ]
})
export class SystemConfigModule { }
