<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        View User '{{applicationUserModel.username}}'
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <div class="nbt-details row">
        <div class="col-md-6">
            <label>Username</label>
            <div class="value" maxlength="100">{{applicationUserModel.username || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Staff code</label>
            <div class="value" maxlength="100">{{applicationUserModel.code || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Clearance</label>
            <div class="value" maxlength="100">{{applicationUserModel.clearanceValue || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>First Name</label>
            <div class="value" maxlength="100">{{applicationUserModel.firstName || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Last Name</label>
            <div class="value" maxlength="100">{{applicationUserModel.lastName || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Phone Number</label>
            <div class="value" maxlength="20">{{applicationUserModel.phone || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Email</label>
            <div class="value" maxlength="100">{{applicationUserModel.email || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Gender</label>
            <div class="value">{{(applicationUserModel.gender?applicationUserModel.gender === 'M' ? 'Male' : 'Female' : '-')}}</div>
        </div>
        <div class="col-md-6">
            <label>Position</label>
            <div class="value">{{applicationUserModel.applicationPosition?.code || '-'}}</div>
        </div>
        <ng-template #elseNoBusinessHour>
            <div class="value">-</div>
        </ng-template>
        <div class="col-md-6">
            <label>Roles</label>
            <ng-container *ngIf="applicationUserModel.applicationRoles.length > 0; else elseNoBusinessHour">
                <ng-container *ngFor="let role of applicationUserModel.applicationRoles">
                        <div>
                            <div class="value">{{role.institution.name}} - {{role.name}}</div>
                        </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="col-md-6">
            <label>Departments</label>
            <ng-container *ngIf="applicationUserModel.departments.length > 0; else elseNoBusinessHour">
                <ng-container *ngFor="let department of applicationUserModel.departments">
                        <div>
                            <div class="value">{{department.name}}</div>
                        </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Close
    </button>
    <button type="button" *ngIf="authService.hasAuthorityMenu(UPDATE_APPLICATION_USER)" class="nbt-btn-primary ml8" (click)="dialogRef.close('edit')">
        <mat-icon class="mr8">edit</mat-icon>
        Edit
    </button>
</div>