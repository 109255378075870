import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ActionEnum } from 'src/app/components/home/home.component';
import { CategoryModel } from 'src/app/model/category.model';
import { TicketModel } from 'src/app/model/ticket.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DepartmentService } from 'src/app/services/department.service';
import { FilterService } from 'src/app/services/search-filter.service';
import { TicketReportService } from 'src/app/services/ticket-report.service';
import { TicketService } from 'src/app/services/ticket.service';
import { ACTION_ENUM } from 'src/app/shared/constant/action-enum.constant';
import { DEFAULT_DEPARTMENT_OVERDUE_ENUM } from 'src/app/shared/constant/default-department-overdue-enum.constant';
import { OVERDUE_ENUM } from 'src/app/shared/constant/overdue-enum.constant';

@Component({
  selector: 'app-voice-of-customer-listing',
  templateUrl: './voice-of-customer-listing.component.html',
  styleUrls: ['./voice-of-customer-listing.component.scss'],
})
export class VoiceOfCustomerListingComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @HostListener('document:keydown', ['$event']) handleKeyBoard = this.keyBoard;

  displayColumns: string[] = [
    'ticketNumber',
    'type',
    'title',
    'channel',
    'customer.firstName',
    'createdDate',
    'lastModifiedDate',
    'incidentBranch.name',
    'vehicle.registrationNo',
    'ticketDatetime',
    'priority',
    'department.name',
    'assignee.username',
    'defaultDepartmentResponseOverdueDatetime',
    'defaultDepartmentResponseLeadTime',
    'defaultDepartmentResponseOverdue',
    'defaultDepartmentResolutionOverdueDatetime',
    'defaultDepartmentResolutionLeadTime',
    'defaultDepartmentResolutionOverdue',
    'departmentResponseOverdueDatetime',
    'departmentResponseOverdueLeadTime',
    'departmentResponseOverdue',
    'departmentResolutionOverdueDatetime',
    'departmentResolutionOverdueLeadTime',
    'departmentResolutionOverdue',
    'status',
    'actions',
  ];
  dataSource: MatTableDataSource<TicketModel>;

  readonly CREATE_TICKET = ApplicationPermissionsService.CREATE_TICKET;
  readonly UPDATE_TICKET = ApplicationPermissionsService.UPDATE_TICKET;
  readonly READ_REPORT = ApplicationPermissionsService.READ_REPORT;
  readonly READ_REPORT_TICKET_LISTING =
    ApplicationPermissionsService.READ_REPORT_TICKET_LISTING;

  readonly snackBarConfig: MatSnackBarConfig = { duration: 60000 };

  windowWidth: number = window.innerWidth;
  numberOfRecords: number;
  pageSize = 10;
  currentPage = 0;
  sortOrder: string;
  obs: BehaviorSubject<TicketModel[]>;
  category: CategoryModel[];
  searchFormGroup: FormGroup;
  actionEnums: any[] = ACTION_ENUM;
  overdueEnums: any[] = OVERDUE_ENUM;
  defaultDepartmentOverdueEnums: any[] = DEFAULT_DEPARTMENT_OVERDUE_ENUM;
  overdueList: any[];
  loading = true;
  isExporting = false;
  hiddenDefaultDepartment = true;
  searchFormData: any;
  pageIndex: any;

  constructor(
    private formBuilder: FormBuilder,
    private ticketService: TicketService,
    private departmentService: DepartmentService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private ticketReportService: TicketReportService,
    public filterService: FilterService
  ) {
    this.dataSource = new MatTableDataSource<TicketModel>();
  }

  ngOnInit(): void {
    ///remove completed, declined, rescheduled from enum here, don't know is it used on other module or not,
    ///on BE side it doesn't used
    this.actionEnums = this.actionEnums.filter(e => e.value !== 'COMPLETED' && e.value !== 'DECLINED' && e.value !== 'RESCHEDULED')
    this.activatedRoute.queryParams.subscribe({
      next: (response) => {
        if (response.status) {
          switch (ActionEnum[response.status]) {
            case ActionEnum.ASSIGNED:
              this.preloadForm(
                ActionEnum.ASSIGNED,
                null,
                this.authService.getUsername(),
                response.fromTicketDetails
              );
              break;
            case ActionEnum.IN_PROGRESS:
              this.preloadForm(
                ActionEnum.IN_PROGRESS,
                null,
                this.authService.getUsername(),
                response.fromTicketDetails
              );
              break;
            case ActionEnum.RESPONSE_OVERDUE:
              this.preloadForm(
                null,
                ActionEnum.RESPONSE_OVERDUE,
                this.authService.getUsername(),
                response.fromTicketDetails
              );
              break;
            case ActionEnum.RESOLUTION_OVERDUE:
              this.preloadForm(
                null,
                ActionEnum.RESOLUTION_OVERDUE,
                this.authService.getUsername(),
                response.fromTicketDetails
              );
              break;
            case ActionEnum.DEFAULT_DEPARTMENT_RESPONSE_OVERDUE:
              this.preloadForm(
                null,
                ActionEnum.DEFAULT_DEPARTMENT_RESPONSE_OVERDUE,
                null,
                response.fromTicketDetails
              );
              break;
            case ActionEnum.DEFAULT_DEPARTMENT_RESOLUTION_OVERDUE:
              this.preloadForm(
                null,
                ActionEnum.DEFAULT_DEPARTMENT_RESOLUTION_OVERDUE,
                null,
                response.fromTicketDetails
              );
              break;
            case ActionEnum.ON_HOLD:
              this.preloadForm(
                ActionEnum.ON_HOLD,
                null,
                this.authService.getUsername(),
                response.fromTicketDetails
              );
              break;
            case ActionEnum.NEW:
              this.preloadForm(ActionEnum.NEW, null, null, response.fromTicketDetails);
              break;
            case ActionEnum.OPEN:
              this.preloadForm(ActionEnum.OPEN, null, null, response.fromTicketDetails);
              break;
            default:
              this.refreshForm();
              break;
          }
        } else if (response.ticketNumber) {
          this.searchFormGroup = this.formBuilder.group({
            ticketNumber: this.formBuilder.control(response.ticketNumber),
            status: this.formBuilder.control('all'),
            groupName: this.formBuilder.control(null),
            categoryName: this.formBuilder.control(null),
            typeName: this.formBuilder.control(null),
            departmentName: this.formBuilder.control(null),
            assigneeName: this.formBuilder.control(null),
            customerName: this.formBuilder.control(null),
            vehicleRegNumber: this.formBuilder.control(null),
            includeDeactivate: this.formBuilder.control(null),
            overdue: this.formBuilder.control(null),
          });
          this.search();
        } else if (response.fromTicketDetails) {
          this.preloadForm(null, null, null, response.fromTicketDetails);
        } else {
          if (this.activatedRoute.snapshot.queryParams.page) {
            this.currentPage = this.activatedRoute.snapshot.queryParams.page
              ? this.activatedRoute.snapshot.queryParams.page
              : undefined;
            this.sortOrder = this.activatedRoute.snapshot.queryParams.sort
              ? this.activatedRoute.snapshot.queryParams.sort
              : undefined;
            this.pageSize = this.activatedRoute.snapshot.queryParams.pageSize
              ? this.activatedRoute.snapshot.queryParams.pageSize
              : undefined;
          }

          this.refreshForm();
        }
      },
    });
  }

  exportReport() {
    this.isExporting = true;
    this.snackBar.open('Generating File', 'Close', this.snackBarConfig);
    const advanceSearch = this.searchFormGroup.getRawValue();
    this.ticketReportService
      .exportVOC({
        page: this.currentPage,
        curIns: this.authService.getCurInsId(),
        sort: this.sortOrder,
        size: this.pageSize,
        ...advanceSearch,
      })
      .subscribe({
        next: (res) => {
          const url = window.URL.createObjectURL(res.body);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = res.headers.get('content-disposition').split('=')[1];
          a.click();
          this.snackBar.open('File Downloaded', 'Close', this.snackBarConfig);
          this.isExporting = false;
        },
        error: async (res) => {
          this.snackBar.open(
            JSON.parse(await res.error.text()).message,
            'Close',
            this.snackBarConfig
          );
          this.isExporting = false;
        },
      });
  }

  search() {
    this.loading = true;
    const advanceSearch = this.searchFormGroup.getRawValue();

    this.searchFormData = this.searchFormGroup.value;
    this.filterService.filterInput.ticketNumber = this.searchFormData.ticketNumber;
    this.filterService.filterInput.groupName = this.searchFormData.groupName;
    this.filterService.filterInput.customerName = this.searchFormData.customerName;
    this.filterService.filterInput.departmentName = this.searchFormData.departmentName;
    this.filterService.filterInput.categoryName = this.searchFormData.categoryName;
    this.filterService.filterInput.vehicleRegNumber = this.searchFormData.vehicleRegNumber;
    this.filterService.filterInput.assigneeName = this.searchFormData.assigneeName;
    this.filterService.filterInput.typeName = this.searchFormData.typeName;
    this.filterService.filterInput.includeDeactivate = this.searchFormData.includeDeactivate;
    this.filterService.filterInput.status = this.searchFormData.status;
    this.filterService.filterInput.overdue = this.searchFormData.overdue;

    this.ticketService
      .getAll({
        page: this.filterService.paginationPage,
        curIns: this.authService.getCurInsId(),
        sort: this.sortOrder,
        size: this.pageSize,
        ...advanceSearch,
      })
      .subscribe({
        next: (value) => {
          this.dataSource.data = value.body;
          this.numberOfRecords = Number(value.headers.get('X-Total-Count'));
          this.loading = false;
        },
        error: (value) => {
          this.snackBar.open(value.error.message, 'Close');
          this.loading = false;
        },
      });
  }

  resetPagination() {
    this.filterService.paginationPage = 0;
  }

  preloadForm(action: string, overdue: string, userName: string, fromTicketDetails: boolean) {
    if (fromTicketDetails) {
      this.searchFormGroup = this.formBuilder.group({
        ticketNumber: this.formBuilder.control(this.filterService.filterInput.ticketNumber || null),
        groupName: this.formBuilder.control(this.filterService.filterInput.groupName || null),
        categoryName: this.formBuilder.control(this.filterService.filterInput.categoryName || null),
        typeName: this.formBuilder.control(this.filterService.filterInput.typeName || null),
        departmentName: this.formBuilder.control(this.filterService.filterInput.departmentName || null),
        assigneeName: this.formBuilder.control(this.filterService.filterInput.assigneeName || null),
        customerName: this.formBuilder.control(this.filterService.filterInput.customerName || null),
        vehicleRegNumber: this.formBuilder.control(this.filterService.filterInput.vehicleRegNumber || null),
        includeDeactivate: this.formBuilder.control(this.filterService.filterInput.includeDeactivate || null),
        status: this.formBuilder.control(this.filterService.filterInput.status || 'all'),
        overdue: this.formBuilder.control(this.filterService.filterInput.overdue || ''),
      });
    } else {
      this.searchFormGroup = this.formBuilder.group({
        ticketNumber: this.formBuilder.control(null),
        groupName: this.formBuilder.control(null),
        categoryName: this.formBuilder.control(null),
        typeName: this.formBuilder.control(null),
        departmentName: this.formBuilder.control(null),
        assigneeName: this.formBuilder.control(userName),
        customerName: this.formBuilder.control(null),
        vehicleRegNumber: this.formBuilder.control(null),
        includeDeactivate: this.formBuilder.control(null),
        status: this.formBuilder.control(action || 'all'),
        overdue: this.formBuilder.control(overdue || ''),
      });
    }
    this.departmentService
      .isDefaultDepartment(this.authService.getCurInsId())
      .subscribe({
        next: (value) => {
          if (value) {
            this.overdueList = this.defaultDepartmentOverdueEnums;
            this.hiddenDefaultDepartment = false;
          } else {
            this.overdueList = this.overdueEnums;
          }
          this.search();
        },
      });
  }

  refreshForm() {
    this.searchFormGroup = this.formBuilder.group({
      ticketNumber: this.formBuilder.control(null),
      groupName: this.formBuilder.control(null),
      categoryName: this.formBuilder.control(null),
      typeName: this.formBuilder.control(null),
      departmentName: this.formBuilder.control(null),
      assigneeName: this.formBuilder.control(null),
      customerName: this.formBuilder.control(null),
      vehicleRegNumber: this.formBuilder.control(null),
      includeDeactivate: this.formBuilder.control(null),
      status: this.formBuilder.control('all'),
      overdue: this.formBuilder.control(''),
    });
    this.departmentService
      .isDefaultDepartment(this.authService.getCurInsId())
      .subscribe({
        next: (value) => {
          if (value) {
            this.overdueList = this.defaultDepartmentOverdueEnums;
            this.hiddenDefaultDepartment = false;
          } else {
            this.overdueList = this.overdueEnums;
          }
          this.search();
        },
      });
  }

  sortPage(event): void {
    this.sortOrder = `${event.active},${event.direction}`;
    this.search();
  }

  navigateToPage(event) {
    this.currentPage = event.pageIndex;
    this.filterService.paginationPage = this.currentPage
    this.pageSize = event.pageSize;
    this.search();
  }

  openVOCNew() {
    this.ticketService.ticketValue = null;
    this.router.navigate(['/operations/voice-of-customer-new'], {
      queryParams: { 
        fromListing: true
      }
    });
  }

  statusValue(value: string): string {
    return this.actionEnums.find((status) => status.value === value).name;
  }

  getStatusColor(value: string) {
    if (value === 'NEW') {
      return 'status-color-new';
    }
    if (value === 'ASSIGNED') {
      return 'status-color-assigned';
    }
    if (value === 'IN_PROGRESS') {
      return 'status-color-inprogress';
    }
    if (value === 'RESOLVED') {
      return 'status-color-resolved';
    }
    if (value === 'CLOSED') {
      return 'status-color-closed';
    }
    if (value === 'ON_HOLD') {
      return 'status-color-onhold';
    }
    if (value === 'CANCEL') {
      return 'status-color-cancel';
    }
    if (value === 'RE_OPEN') {
      return 'status-color-reopen';
    }
    if (value === 'RESUME') {
      return 'status-color-resume';
    }
    if (value === 'OPEN') {
      return 'status-color-open';
    }
  }

  openVOCDetail(ticketModel?: TicketModel) {
    this.ticketService.ticketValue = ticketModel;
    this.router.navigate(['/operations/voice-of-customer-edit'], {
      queryParams: {
        id: ticketModel?.id,
        fromListing: true,
      },
    });
  }

  keyBoard(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    }
  }

  @HostListener('window:resize')
    onResize() {
        this.windowWidth = window.innerWidth;
  }
}
