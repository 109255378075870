import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { VehicleModelModel } from 'src/app/model/vehicle-dialog.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { VehicleModelService } from 'src/app/services/vehicle-model.service';
import { VehicleModelDialogDetailComponent } from '../vehicle-model-dialog-detail/vehicle-model-dialog-detail.component';
import { VehicleModelDialogNewOrEditComponent } from '../vehicle-model-dialog-new-or-edit/vehicle-model-dialog-new-or-edit.component';

@Component({
    selector: 'app-vehicle-model-listing',
    templateUrl: './vehicle-model-listing.component.html',
    styleUrls: ['./vehicle-model-listing.component.scss']
})
export class VehicleModelListingComponent implements OnInit {

    readonly CREATE_VEHICLE_MODEL = ApplicationPermissionsService.CREATE_VEHICLE_MODEL;
    readonly UPDATE_VEHICLE_MODEL = ApplicationPermissionsService.UPDATE_VEHICLE_MODEL;
    pageSize: number = 20;
    sortOrder: string = 'name,asc';
    currentPage: number = 0;
    searchFormGroup: FormGroup;
    numberOfRecords: number = 0;
    dataSource: MatTableDataSource<VehicleModelModel> = new MatTableDataSource<VehicleModelModel>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayColumns: string[] = [
        'brand',
        'name',
        'active',
        'actions'
    ];

    constructor(
        private matDialog: MatDialog,
        private vehicleModelService: VehicleModelService,
        public authService: AuthService,
        public activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.currentPage = this.activatedRoute?.snapshot?.queryParams['page'] ? this.activatedRoute?.snapshot?.queryParams['page'] : 0;
        this.sortOrder = this.activatedRoute?.snapshot?.queryParams['sort'] ? this.activatedRoute?.snapshot?.queryParams['sort'] : 'name,asc';
        this.pageSize = this.activatedRoute?.snapshot?.queryParams['pageSize'] ? this.activatedRoute?.snapshot?.queryParams['pageSize'] : 20;
        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(null)
        });
        this.refreshForm();
        this.dataSource.paginator = this.paginator;
    }

    openVechicleModelDialogCreateOrEdit(data?: VehicleModelModel) {
        const modalRef = this.matDialog.open(VehicleModelDialogNewOrEditComponent, {
            data: data,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(0);
            }
        })
    }

    search(currentPage: number) {
        const requestGet = {
            search: this.searchFormGroup.get('searchName').value,
            currentPage: currentPage,
            pageSize: this.pageSize,
            sortOrder: this.sortOrder,
            currentInstitution: this.authService.getCurInsId()
        }
        this.vehicleModelService.getAllModels(requestGet).subscribe({
            next: (value) => {
                this.dataSource.data = value.body;
                this.numberOfRecords = +value.headers.get('X-Total-Count');
            },
            error: (value) => {
                this.snackBar.open(value.error.message, 'Close', {
                    duration: 60000
                });
            },
        })
    }

    sortPage(event): void {
        this.sortOrder = `${event.active},${event.direction}`;
        this.search(this.currentPage);
    }

    navigateToPage(event): void {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        this.search(this.currentPage);
    }

    refreshForm() {
        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(null),
        });
        this.search(0);
    }

    openVehicleModelDialogDetail(data?: VehicleModelModel) {
        const modalRef = this.matDialog.open(VehicleModelDialogDetailComponent, {
            data: data,
            panelClass: 'mat-dialog-md'
        })

        modalRef.afterClosed().subscribe({
            next: (edit) => {
                if (edit) this.openVechicleModelDialogCreateOrEdit(data);
            }
        })
    }
}
