<div class="dashboard-action-container">
    <mat-form-field appearance="outline">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="picker" [max]="today" [formControl]="departmentDateFormControl"
            (dateChange)="dateChange()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker>
            <mat-datepicker-actions>
                <button mat-button matDatepickerCancel>Cancel</button>
                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            </mat-datepicker-actions>
        </mat-datepicker>
    </mat-form-field>
    <ng-container *ngIf="!defaultDepartment">
    <mat-form-field appearance="outline">
        
            <mat-label>Department</mat-label>
            <mat-select #departmentSelect [formControl]="departmentFormControl"
            [(ngModel)]="departmentMultiSelected" multiple>
            <mat-select-trigger
                *ngIf="departmentFormControl.value?.length == departmentModels?.length">
                All Department
            </mat-select-trigger>
            <div class="nbt-select-all">
                <mat-checkbox [(ngModel)]="departmentAllSelected"
                    [ngModelOptions]="{standalone: true}"
                    (change)="toggleDepartmentAllSelection()">Select All</mat-checkbox>
            </div>
            <mat-option *ngFor="let departmentModel of departmentModels"
                (click)="toggleDepartmentSingleSelection()" [value]="departmentModel.id">
                {{
                departmentModel.name }}</mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>

</div>
