import { Component, OnInit, Input } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Subject, Subscription, interval } from 'rxjs'
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { DashboardService } from 'src/app/services/dashboard.service';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-voc-sla-compliance-department',
  templateUrl: './voc-sla-compliance-department.component.html',
  styleUrls: ['./voc-sla-compliance-department.component.scss']
})
export class VocSlaComplianceDepartmentComponent implements OnInit {

  readonly READ_TICKET_SLA_COMPLIANCE_DEPARTMENT = ApplicationPermissionsService.READ_TICKET_SLA_COMPLIANCE_DEPARTMENT;
  readonly READ_TICKET_SLA_COMPLIANCE_DEFAULT_DEPARTMENT = ApplicationPermissionsService.READ_TICKET_SLA_COMPLIANCE_DEFAULT_DEPARTMENT;

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
  ) { }

  @Input() public startDateSubject: Subject<string>;
  @Input() public departmentIdsSubject: Subject<any>;
  @Input() public defaultDepartment: boolean;
  @Input() public refreshRateSubject: Subject<number>;

  refreshRate: number;
  refreshRateSub: Subscription
  startDate: string;
  departmentIds: any;

  root: am5.Root
  chart: am5xy.XYChart
  legend: am5.Legend
  series: am5xy.ColumnSeries[] = []

  data = [
    {
      "priority": "LOW",
      "departmentResponseComplyCount": 2,
      "departmentResponseNonComplyCount": 3,
      "departmentResolutionComplyCount": 4,
      "departmentResolutionNonComplyCount": 5
    },
    {
      "priority": "HIGH",
      "departmentResponseComplyCount": 5,
      "departmentResponseNonComplyCount": 4,
      "departmentResolutionComplyCount": 3,
      "departmentResolutionNonComplyCount": 2
    }
  ]


  // data = []

  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>
  xAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>

  ngOnInit(): void {
    this.startDateSubject.subscribe(newValue => {
      this.startDate = newValue;
      this.requestData()
    })

    this.departmentIdsSubject.subscribe(newValue => {
      this.departmentIds = newValue;
      this.requestData()
    })

    this.refreshRateSubject.subscribe(newValue => {
      this.refreshRate = newValue;
      if (this.refreshRateSub) {
        this.refreshRateSub.unsubscribe()
      }
      if (this.refreshRate > 0) {
        this.refreshRateSub = interval(this.refreshRate * 1000).subscribe((val) => {
          this.requestData()
        })
      }
    })

  }

  ngAfterViewInit(): void {
    this.initChart()
  }

  initChart(){
    this.root = am5.Root.new(this.defaultDepartment ? "chartdiv3" : "chartdiv2");
    var responsive = am5themes_Responsive.newEmpty(this.root);

    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panY: false,
        wheelY: "zoomX",
        layout: this.root.horizontalLayout
      })
    );

    this.legend = this.chart.children.push(am5.Legend.new(this.root, {
      centerY: am5.p50,
      y: am5.p50,
      centerX: null,
      x: null,
      layout: this.root.verticalLayout
    }));

    //workaround, rule doesnt accept this. variable
    let responsiveChart = this.chart
    let responsiveRoot = this.root
    let responsiveLegend = this.legend
    responsive.addRule({
      relevant: am5themes_Responsive.widthL,
      applying: function () {
        responsiveChart.set("layout", responsiveRoot.verticalLayout);
        responsiveLegend.setAll({
          y: null,
          centerY: null,
          x: am5.p50,
          centerX: am5.p50
        });

      },
      removing: function () {
        responsiveChart.set("layout", responsiveRoot.horizontalLayout);
        responsiveLegend.setAll({
          centerY: am5.p50,
          y: am5.p50,
          centerX: null,
          x: null,
        });


      }
    });

    this.root.setThemes([
      am5themes_Animated.new(this.root),
      responsive

    ]);

    // this.chart.get("colors").set("colors", [
    //   am5.color(0x002239),
    //   am5.color(0xf2e400),
    //   am5.color(0x18bde6),
    //   am5.color(0x9e61a4),
    //   am5.color(0xe30421),
    //   am5.color(0xb6cd00),
    //   am5.color(0x394650),
    //   am5.color(0x848a8c),
    // ]);

    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(this.root, {})
      })
    );

    this.xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        maxDeviation: 0.2,
        renderer: am5xy.AxisRendererX.new(this.root, {
          cellStartLocation: 0.1,
          cellEndLocation: 0.9
        }),
        categoryField: "priority"
      })
    );
    this.xAxis.data.setAll(this.data);

    this.createSeries("Response: Comply", "departmentResponseComplyCount", false);
    this.createSeries("Response: Non-Comply", "departmentResponseNonComplyCount", true);
    this.createSeries("Resolution: Comply", "departmentResolutionComplyCount", false);
    this.createSeries("Resolution: Non-Comply", "departmentResolutionNonComplyCount", true);
  }

  createSeries(name, field, stacked) {
    var series = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: name,
        // maxWidth: 200,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: field,
        categoryXField: "priority",
        stacked: stacked
      })
    );
    series.columns.template.setAll({
      tooltipText: "{name}, {categoryX}:{valueY}",
      // maxWidth: 100,
      width: am5.percent(90),
      tooltipY: am5.percent(10)
    });
    series.appear(2000, 500);
    series.data.setAll(this.data);
    series.data.processor = am5.DataProcessor.new(this.root, {
      numericFields: [field]
    });
    this.series.push(series)
    this.legend.data.push(series);
  }

  updateChart(){
    this.series.forEach(series=> {
      series.data.setAll(this.data);
    })
  }

  requestData(){
    if (this.startDate && this.departmentIds) {
      if(this.defaultDepartment){
        if (this.authService.hasAuthorityMenu(this.READ_TICKET_SLA_COMPLIANCE_DEFAULT_DEPARTMENT)) {
          this.dashboardService.getVocSlaComplianceByDefaultDepartment(this.authService.getCurInsId(), this.startDate, this.departmentIds).subscribe((value)=>{
            this.data = value.body

            // this.data.forEach(x => {
            //   x.departmentResolutionComplyCount = Math.floor(Math.random() * 10)
            //   x.departmentResolutionNonComplyCount = Math.floor(Math.random() * 10)
            //   x.departmentResponseComplyCount = Math.floor(Math.random() * 10)
            //   x.departmentResponseNonComplyCount = Math.floor(Math.random() * 10)
            // })

            this.updateChart()
          })
        }
      } else {
        if (this.authService.hasAuthorityMenu(this.READ_TICKET_SLA_COMPLIANCE_DEPARTMENT)) {
          this.dashboardService.getVocSlaComplianceByDepartment(this.authService.getCurInsId(), this.startDate, this.departmentIds).subscribe((value)=>{
            this.data = value.body

            // this.data.forEach(x => {
            //   x.departmentResolutionComplyCount = Math.floor(Math.random() * 10)
            //   x.departmentResolutionNonComplyCount = Math.floor(Math.random() * 10)
            //   x.departmentResponseComplyCount = Math.floor(Math.random() * 10)
            //   x.departmentResponseNonComplyCount = Math.floor(Math.random() * 10)
            // })

            this.updateChart()
          })
        }
      }
    }
    
  }

}


