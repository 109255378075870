<div class="container-fluid nbt-container">
    <div class="nbt-header">
        <div class="nbt-header-title">Customer Satisfactory Index</div>
        <div class="nbt-btn-right">
            <button class="nbt-btn-primary" appScreenPermission [permissionsInput]="[CREATE_CSI]"
                (click)="openCsiDialogCreateOrEdit()">
                <mat-icon class="mr8">add</mat-icon>
                New CSI
            </button>
        </div>
    </div>
    <br>
    <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px;">
        <div class="row left-list-detail">
            <div class="col-xl-4 list-section">
                <div class="nbt-details">
                    <div class="staff-list">
                        <label for="">Staff List</label>
                        <br>
                        <hr>
                        <mat-accordion multi="false" >
                            <mat-expansion-panel  *ngFor="let sr of departments" [value]="sr">
                                <mat-expansion-panel-header #meph (click)="selectDepartment(sr,meph)">
                                    <mat-panel-title >
                                        <label>{{ sr.name }}</label>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-selection-list>
                                        <mat-list-option *ngFor="let sc of salesConsultants" [value]="sc" class="hide-checkbox" (click)="filterList(sc,sr)">
                                            <ng-container *ngIf="salesConsultants.length > 0; else elseData">
                                                {{ sc.username }}
                                            </ng-container>
                                            <ng-template #elseData>
                                                <div class="value">-</div>
                                            </ng-template>
                                          </mat-list-option>
                                </mat-selection-list>
                            </mat-expansion-panel>
                          </mat-accordion>
                    </div>
                </div>
            </div>
            <div class="col-xl left-detail-section">
                <mat-tab-group>
                    <mat-tab label="Issued">
                        <br>
                        <br>
                        <div *ngIf="loading" class="nbt-empty-result">
                            <div class="nbt-data-loading"></div>
                        </div>
                        <div><b>{{issuingRecords}}</b> result<span *ngIf="issuingRecords > 1">s</span></div>
                        <br>
                        <div *ngIf="!loading">
                            <ng-container>
                                <mat-card-content class="mat-card-content-table">
                                    <table mat-table matSort (matSortChange)="sortPageIssue($event)" [dataSource]="dataSource" *ngIf="dataSource.data" aria-label="table">
                                        <ng-container matColumnDef="csiNo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CSI Number</th>
                                            <td [ngClass] ="{'isActiveText':element.isActive ,'isNotActiveText':!element.isActive}" mat-cell *matCellDef="let element">
                                                {{ element.surveyNumber || '-'}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="regNo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Number</th>
                                            <td [ngClass] ="{'isActiveText':element.isActive ,'isNotActiveText':!element.isActive}" mat-cell *matCellDef="let element">
                                                {{ element.vehicle.registrationNo || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="issuedBy">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Issued By</th>
                                            <td [ngClass] ="{'isActiveText':element.isActive ,'isNotActiveText':!element.isActive}" mat-cell *matCellDef="let element">
                                                {{ element.createdBy || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="serviceAdvisor">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Service Advisor</th>
                                            <td [ngClass] ="{'isActiveText':element.isActive ,'isNotActiveText':!element.isActive}" mat-cell *matCellDef="let element">
                                                <ng-container *ngIf="element.salesConsultant.firstName !== null && element.salesConsultant.firstName !== undefined; else elseData">
                                                    {{ element.salesConsultant.firstName + ' ' + (element.salesConsultant.lastName || '') }}
                                                </ng-container>
                                                <ng-template #elseData>
                                                    {{ element.salesConsultant.username }}
                                                </ng-template>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="issuedDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Issued Date</th>
                                            <td [ngClass] ="{'isActiveText':element.isActive ,'isNotActiveText':!element.isActive}" mat-cell *matCellDef="let element">
                                                {{ (element.createdDate| date: 'd MMMM yyyy')  || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="custLinked">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header matColumnDef="surveyHeader">Customer Linked</th>
                                            <td [ngClass] ="{'isActiveText':element.isActive ,'isNotActiveText':!element.isActive}" mat-cell *matCellDef="let element" class="text-center">
                                                <mat-icon style="cursor: default" *ngIf="element.customer?.id  !== null "  >check_circle</mat-icon>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="isActive">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header  matColumnDef="isActiveHeader">Active</th>
                                            <td [ngClass] ="{'isActiveText':element.isActive ,'isNotActiveText':!element.isActive}" mat-cell *matCellDef="let element" class="text-center">
                                                <mat-icon style="cursor: default" *ngIf="element.isActive">check_circle</mat-icon>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                                            <td [ngClass] ="{'isActiveText':element.isActive ,'isNotActiveText':!element.isActive}" mat-cell *matCellDef="let element">
                                                <div *ngIf="isEnableViewDetail(element.salesConsultant.username); else fullReadIssuedTemplate">
                                                    <mat-icon appScreenPermission [permissionsInput]="[CREATE_CSI]" (click)="openSurveyForm(element)" matTooltip="View" >remove_red_eye</mat-icon>
                                                </div>
                                                <ng-template #fullReadIssuedTemplate>
                                                    <mat-icon appScreenPermission [permissionsInput]="[CREATE_CSI,READ_FULL_CSI]" (click)="openSurveyForm(element)" matTooltip="View" >remove_red_eye</mat-icon>
                                                </ng-template>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayColumnsIssue"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayColumnsIssue"></tr>
                                    </table>
                                </mat-card-content>
                                <mat-paginator #paginatorIssue="matPaginator" (page)="navigateToPageIssue($event)" [length]="issuingRecords" [pageSize]="pageSizeIssue"
                                    [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                </mat-paginator>
                            </ng-container>
                        </div>
                    </mat-tab>
                    <mat-tab label="Received">
                        <br>
                        <br>
                        <div *ngIf="loading" class="nbt-empty-result">
                            <div class="nbt-data-loading"></div>
                        </div>
                        <div><b>{{receivedRecords}}</b> result<span *ngIf="receivedRecords > 1">s</span></div>
                        <br>
                        <div *ngIf="!loading">
                            <ng-container>
                                <mat-card-content class="mat-card-content-table">
                                    <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="dataSourceReceived" *ngIf="dataSourceReceived.data" aria-label="table">
                                        <ng-container matColumnDef="csiNo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CSI Number</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.surveyNumber || '-'}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="regNo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Number</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.vehicle.registrationNo || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="issuedBy">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Issued By</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.createdBy || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="serviceAdvisor">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Service Advisor</th>
                                            <td mat-cell *matCellDef="let element">
                                                <ng-container *ngIf="element.salesConsultant.firstName !== null && element.salesConsultant.firstName !== undefined; else elseData">
                                                    {{ element.salesConsultant.firstName + ' ' + (element.salesConsultant.lastName || '') }}
                                                </ng-container>
                                                <ng-template #elseData>
                                                    {{ element.salesConsultant.username }}
                                                </ng-template>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="issuedDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Issued Date</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ (element.createdDate| date: 'd MMMM yyyy')  || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="receivedDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Received Date</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ (element.receiveDatetime| date: 'd MMMM yyyy')  || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="custLinked">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header matColumnDef="surveyHeader">Customer Linked</th>
                                            <td mat-cell *matCellDef="let element" class="text-center">
                                                <mat-icon *ngIf="element.customer?.id  !== null">check_circle</mat-icon>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="isEnableViewDetail(element.salesConsultant.username); else fullReadReceivedTemplate">
                                                    <mat-icon (click)="openSurveyForm(element)" matTooltip="View" >remove_red_eye</mat-icon>
                                                </div>
                                                <ng-template #fullReadReceivedTemplate>
                                                    <mat-icon appScreenPermission [permissionsInput]="[READ_FULL_CSI]" (click)="openSurveyForm(element)" matTooltip="View" >remove_red_eye</mat-icon>
                                                </ng-template>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
                                    </table>
                                </mat-card-content>
                                <mat-paginator #paginatorReceive="matPaginator" (page)="navigateToPage($event)" [length]="receivedRecords" [pageSize]="pageSize"
                                    [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                </mat-paginator>
                            </ng-container>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-card-content>
</div>
