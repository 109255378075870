import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MysteryShopperService } from 'src/app/services/mystery-shopper.service';
import { UpdateMysteryShopperModel } from 'src/app/model/mystery-shopper-update.model';

@Component({
    selector: 'app-customer-records-mystery-shopper-detail-dialog-submit',
    templateUrl: './customer-records-mystery-shopper-detail-dialog-submit.component.html',
    styleUrls: ['./customer-records-mystery-shopper-detail-dialog-submit.component.scss'],
})
export class CustomerRecordsMysteryShopperDetailDialogSubmitComponent implements OnInit {

    readonly snackBarConfig: MatSnackBarConfig = { duration: 60000 };
    loading:boolean = false;
    close = "Close";

    constructor(
        @Inject(MAT_DIALOG_DATA) public updateMysteryShopperModel: UpdateMysteryShopperModel,
        public dialogRef: MatDialogRef<CustomerRecordsMysteryShopperDetailDialogSubmitComponent>,
        private snackBar: MatSnackBar,
        private mysteryShopperService: MysteryShopperService
    ) {}

    ngOnInit(): void {

    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    save() {
        this.activeLoading();
        this.mysteryShopperService.updateMysteryShopperSurvey(this.updateMysteryShopperModel).subscribe({
            next: () => {
                this.deactiveLoading();
                this.snackBar.open("Mystery Shopper Detail saved", this.close, this.snackBarConfig);
                this.dialogRef.close('ok');
            },
            error: error => {
                let theMessage  = '';
                error.error.subErrors?.forEach(subError => {
                    theMessage += subError.message + ' \n ';
                });
                if(theMessage){
                    this.snackBar.open(theMessage, this.close, this.snackBarConfig);
                }else{
                    if(error?.error?.message) {
                        this.snackBar.open(error?.error?.message, this.close, this.snackBarConfig);
                    } else {
                        theMessage = error?.message;
                        if(theMessage.includes("413")) {
                            theMessage = "Attachment Max file size reached";
                        }
                        this.snackBar.open(theMessage, this.close, this.snackBarConfig);
                    }
                }
                this.deactiveLoading();
            }
        });
    }
}
