<div class="login-page">
    <mat-card class="login-card">
        <div class="forgot-password-title">
            Forgot your password?
        </div>
        <div>
            Enter the email address you're using for your account and we will send a link to reset your password.
        </div>
        <br><br>
        <div [formGroup]="applicationUserFormGroup">
            <span *ngIf="!resetPasswordKey; else resetPassword">
                <div class="nbt-form">
                    <label>Email</label>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="email" placeholder="Enter email"/>
                        <mat-error>Email is required</mat-error>
                    </mat-form-field>
                </div>
            </span>
            <ng-template #resetPassword>
                <div class="nbt-form">
                    <label>Reset Key</label>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" formControlName="key"/>
                        <mat-error>Reset Key is required</mat-error>
                    </mat-form-field>
                </div>
                <div class="nbt-form">
                    <label>New Password</label>
                    <mat-form-field appearance="outline">
                        <input matInput type="password" formControlName="newPassword" placeholder="Enter new password"/>
                        <mat-error>Password is required</mat-error>
                    </mat-form-field>
                </div>
                <div class="nbt-form">
                    <label>Re-enter New Password</label>
                    <mat-form-field appearance="outline">
                        <input matInput type="password" formControlName="repeatPassword" placeholder="Re-enter new password"/>
                        <mat-error>Re-enter password is required</mat-error>
                    </mat-form-field>
                </div>
            </ng-template>
        </div>
    
    
        <button class="nbt-btn-primary w-100" [disabled]="isSaving || applicationUserFormGroup.invalid" (click)="save()">
            <ng-container *ngIf="!resetPasswordKey; else elseFalseCheckFlagWord">
                Send me reset password link
            </ng-container>
            <ng-template #elseFalseCheckFlagWord>
                Submit
            </ng-template>
        </button>
        <button class="nbt-btn-secondary w-100 mt8" *ngIf="!resetPasswordKey" (click)="dontHaveEmailDialog()">
            Don't have email?
        </button>
        <div class="forgot-password-link" routerLink="..">
            <span>Back to sign in</span>
        </div>
    </mat-card>
</div>