import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/auth/auth.service';
import { StatisticsDepartmentDatasource } from 'src/app/model/statistics-department.datasource';
import { StatisticsDepartmentModel } from 'src/app/model/statistics-department.model';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ActionEnum } from '../../home.component';
import { Subject, Subscription, interval } from 'rxjs'
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';

@Component({
  selector: 'app-statistic-department',
  templateUrl: './statistic-department.component.html',
  styleUrls: ['./statistic-department.component.scss'],
})
export class StatisticDepartmentComponent implements OnInit {

  readonly READ_TICKET_DEPARTMENT_STATISTIC = ApplicationPermissionsService.READ_TICKET_DEPARTMENT_STATISTIC;
  readonly READ_TICKET_DEFAULT_DEPARTMENT_STATISTIC = ApplicationPermissionsService.READ_TICKET_DEFAULT_DEPARTMENT_STATISTIC;
  
  @Input() public startDateSubject: Subject<string>;
  @Input() public departmentIdsSubject: Subject<any>;
  @Input() public defaultDepartment: boolean;
  @Input() public refreshRateSubject: Subject<number>;
  
  refreshRate: number;
  refreshRateSub: Subscription
  startDate: string;
  departmentIds: any;

  statisticDepartmentDataSource: MatTableDataSource<StatisticsDepartmentDatasource>;
  statisticsDepartmentModels: StatisticsDepartmentModel[];

  displayColumns: string[];

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.statisticDepartmentDataSource = new MatTableDataSource<StatisticsDepartmentDatasource>();
    if(this.defaultDepartment){
      this.displayColumns = [
        'departments',
        'assigned',
        'inProgress',
        'responseOverdue',
        'resolutionOverdue',
        'onHold',
        'new',
        'open',
        'resolved',
        'closed',
        'reOpen'
      ];
    } else{
      this.displayColumns = [
        'departments',
        'assigned',
        'inProgress',
        'responseOverdue',
        'resolutionOverdue',
        'onHold',
        'resolved',
        'closed',
        'reOpen'
      ];
    }
    
    
    // this.requestData()

    this.startDateSubject.subscribe(newValue => {
      this.startDate = newValue;
      this.requestData()
    })

    this.departmentIdsSubject.subscribe(newValue => {
      this.departmentIds = newValue;
      this.requestData()
    })

    this.refreshRateSubject.subscribe(newValue => {
      this.refreshRate = newValue;
      if (this.refreshRateSub) {
        this.refreshRateSub.unsubscribe()
      }
      if (this.refreshRate > 0) {
        this.refreshRateSub = interval(this.refreshRate * 1000).subscribe((val) => {
          this.requestData()
        })
      }
    })
  }

  requestData() {
    if (this.startDate && this.departmentIds) {
      if (this.defaultDepartment) {
        if (this.authService.hasAuthorityMenu(this.READ_TICKET_DEFAULT_DEPARTMENT_STATISTIC)) {
          this.dashboardService.getStatisticsDefaultDepartment(this.authService.getCurInsId(), this.startDate, this.departmentIds).subscribe({
            next: (value) => {
              this.statisticsDepartmentModels = value.body
              this.statisticDepartmentDataSource.data = this.convertStatisticModelToDataSource(this.statisticsDepartmentModels)
              this.changeDetectorRef.detectChanges()
            }
          })
        }
        
      } else {
        if (this.authService.hasAuthorityMenu(this.READ_TICKET_DEPARTMENT_STATISTIC)) {
          this.dashboardService.getStatisticsDepartment(this.authService.getCurInsId(), this.startDate, this.departmentIds).subscribe({
            next: (value) => {
              this.statisticsDepartmentModels = value.body
              this.statisticDepartmentDataSource.data = this.convertStatisticModelToDataSource(this.statisticsDepartmentModels)
              this.changeDetectorRef.detectChanges()
            }
          })
        }
        
      }

    }

  }

  convertStatisticModelToDataSource(models: StatisticsDepartmentModel[]) {
    let data: StatisticsDepartmentDatasource[] = []
    models.forEach((model) => {
      let newData = {
        department: model.name,
        assigned: 0,
        inProgress: 0,
        responseOverdue: 0,
        resolutionOverdue: 0,
        onHold: 0,
        new: 0,
        open: 0,
        resolved: 0,
        closed: 0,
        reOpen: 0,
      }
      model.ticketStatistics.forEach((ticketStatistic) => {
        switch (ticketStatistic.status) {
          case ActionEnum.ASSIGNED:
            newData.assigned = ticketStatistic.count
            break;
          case ActionEnum.IN_PROGRESS:
            newData.inProgress = ticketStatistic.count
            break;
          case ActionEnum.RESPONSE_OVERDUE:
            newData.responseOverdue = ticketStatistic.count
            break;
          case ActionEnum.RESOLUTION_OVERDUE:
            newData.resolutionOverdue = ticketStatistic.count
            break;
          case ActionEnum.ON_HOLD:
            newData.onHold = ticketStatistic.count
            break;
          case ActionEnum.NEW:
            newData.new = ticketStatistic.count
            break;
          case ActionEnum.OPEN:
            newData.open = ticketStatistic.count
            break;
          case ActionEnum.RESOLVED:
            newData.resolved = ticketStatistic.count
            break;
          case ActionEnum.CLOSED:
            newData.closed = ticketStatistic.count
            break;
          case ActionEnum.RE_OPEN:
            newData.reOpen = ticketStatistic.count
            break;
          default:
            break;
        }

      })
      data.push(newData);
    })

    return data

  }

}
