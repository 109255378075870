import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NpsModel } from '../model/nps.model';


@Injectable({
  providedIn: 'root'
})
export class NpsService {

    npsValue: NpsModel;

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) { }

    createNPS(
        type: string,
        fromDate: string,
        toDate: string,
        branchId: number,
        size: number,
    ):Observable<HttpResponse<NpsModel>>{
        let httpParams = new HttpParams();
        if (type) httpParams = httpParams.set('type', type);
        if (fromDate) httpParams = httpParams.set('fromDate', fromDate);
        if (toDate) httpParams = httpParams.set('toDate', toDate);
        if (branchId) httpParams = httpParams.set('branchId', branchId);
        if (size) httpParams = httpParams.set('size', size);
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());
        return this.httpClient.post<NpsModel>(`/web/nps`,null, {
            observe: 'response',
            params: httpParams,
        });
    }

    updateNPS(
        npsModel: NpsModel,
        curIns: number
    ): Observable<HttpResponse<NpsModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.put<NpsModel>(`/web/nps`, npsModel, {
            observe: 'response',
            params: httpParams
        });
    }

    getAllNpsRecord(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        type: string,
        npsOrder: string,
        branchId: number,
        promoterId:number,
        active:boolean,
    ):Observable<HttpResponse<NpsModel[]>>{
        let httpParams = new HttpParams();
        if (pageNumber) httpParams = httpParams.set('page', `${pageNumber}`);
        if (pageSize) httpParams = httpParams.set('size', `${pageSize}`);
        if (sortOrder) httpParams = httpParams.set('sort', sortOrder);
        if (type) httpParams = httpParams.set('type', type);
        if (npsOrder) httpParams = httpParams.set('npsOrder', npsOrder);
        if (branchId) httpParams = httpParams.set('branchId', branchId);
        if (promoterId) httpParams = httpParams.set('promoterId', promoterId);
        httpParams = httpParams.set('active', active);
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());

        return this.httpClient.get<NpsModel[]>(`/web/nps/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getOne(id: string, curIns: number): Observable<HttpResponse<NpsModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('id', id);
        httpParams = httpParams.set('curIns', curIns);
        return this.httpClient.get<NpsModel>(`/web/nps`, {
            observe: 'response',
            params: httpParams,
        });
    }
}
