import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleModelListingComponent } from './vehicle-model-listing/vehicle-model-listing.component';
import { VehicleModelDialogDetailComponent } from './vehicle-model-dialog-detail/vehicle-model-dialog-detail.component';
import { VehicleModelDialogNewOrEditComponent } from './vehicle-model-dialog-new-or-edit/vehicle-model-dialog-new-or-edit.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';

const routes: Routes = [
    {
        path: 'vehicle-model',
        component: VehicleModelListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    }
];

@NgModule({
    declarations: [
        VehicleModelListingComponent,
        VehicleModelDialogDetailComponent,
        VehicleModelDialogNewOrEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes)
    ]
})
export class VehicleModelModule { }
