import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitutionsListingComponent } from './institutions-listing/institutions-listing.component';
import { InstitutionsDialogDetailComponent } from './institutions-dialog-detail/institutions-dialog-detail.component';
import { InstitutionsDialogNewOrEditComponent } from './institutions-dialog-new-or-edit/institutions-dialog-new-or-edit.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
    {
        path: 'institutions',
        component: InstitutionsListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    }
];

@NgModule({
    declarations: [
        InstitutionsListingComponent,
        InstitutionsDialogDetailComponent,
        InstitutionsDialogNewOrEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes),

        FormsModule,
		ReactiveFormsModule,
    ]
})
export class InstitutionsModule { }
