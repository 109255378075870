<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create New Customer Record
        </ng-container>
        <ng-template #elseTemplate>
            Edit Customer Record '{{customerRecordModel.firstName}} {{customerRecordModel.lastName}}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>

<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="customerRecordFormGroup">
        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Salutation</label>
                <mat-form-field appearance="outline">
                    <mat-select name="applicationPosition" formControlName="salutation" placeholder="Select salutation">
                        <mat-option [value]="null">
                            Select salutation
                        </mat-option>
                        <mat-option *ngFor="let satulation of sysConfigSal" [value]="satulation">
                            {{satulation}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3 nbt-form label-flex">
                <mat-checkbox formControlName="isBuyer">Buyer</mat-checkbox>
                <ng-container *ngIf="isShowMysteryShopperCheckbox">
                    <mat-checkbox formControlName="isMysteryShopper" (change)="setMysteryShopper($event)" [disabled]="customerRecordModel.isMysteryShopper">
                        Mystery Shopper
                    </mat-checkbox>
                </ng-container>
            </div>
            <br>
            <div class="col-md-6 nbt-form">
                <label>Full Name <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="firstName" placeholder="Enter full name" maxlength="200"/>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Contact Number (Main) <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="mainPhone" placeholder="Enter contact number (main)" maxlength="20"/>
                    <mat-error *ngIf="customerRecordFormGroup.get('mainPhone').errors?.pattern">Invalid input</mat-error>
                    <mat-icon class="material-symbols-text" [matTooltip]="phoneContent" matTooltipPosition="above">
                        info
                    </mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Identity Card Number/Passport <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="ic" placeholder="Enter identity card number/passport" maxlength="50"/>
                    <mat-error *ngIf="customerRecordFormGroup.get('ic').errors?.pattern">Invalid input</mat-error>
                    <mat-icon class="material-symbols-text" [matTooltip]="icContent" matTooltipPosition="above">
                        info
                    </mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Email <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="email" placeholder="Enter email" maxlength="100"/>
                    <mat-error *ngIf="customerRecordFormGroup.get('email').errors?.email">Invalid input</mat-error>
                    <mat-icon class="material-symbols-text" [matTooltip]="emailContent" matTooltipPosition="above">
                        info
                    </mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Contact Number (Home)</label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="homePhone" placeholder="Enter contact number (home)" maxlength="20"/>
                    <mat-error *ngIf="customerRecordFormGroup.get('homePhone').errors?.pattern">Invalid input</mat-error>
                    <mat-icon class="material-symbols-text" [matTooltip]="phoneContent" matTooltipPosition="above">
                        info
                    </mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Contact Number (Office)</label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="officePhone" placeholder="Enter contact number (office)" maxlength="20"/>
                    <mat-error *ngIf="customerRecordFormGroup.get('officePhone').errors?.pattern">Invalid input</mat-error>
                    <mat-icon class="material-symbols-text" [matTooltip]="phoneContent" matTooltipPosition="above">
                        info
                    </mat-icon>
            </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form-textarea">
                <label>Remarks</label>
                <mat-form-field appearance="outline">
                    <textarea matInput appAutofocus type="text" formControlName="remarks"
                        placeholder="Enter description" rows="5" maxlength="1000" #myInput>
                    </textarea>
                    <span >{{myInput.value.length}} / 1000 </span>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form" *ngIf="isShowing">
                <label>Registered Branch <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select matInput appAutofocus  name="type" formControlName="registeredDepartment" placeholder="Select Incident Branch"
                        [compareWith]="compareObjects">
                        <mat-option [value]="null">
                            Select Incident Branch
                        </mat-option>
                        <mat-option *ngFor="let branch of branchs" [value]="branch">
                            {{ branch.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-12 nbt-form-textarea">
                <div class="customerPhoto">Customer Photo
                    <mat-icon class="material-symbols-text" [matTooltip]="customerAttContent" matTooltipPosition="above">
                        info
                    </mat-icon>
                </div>
                <br>
                <input type="file" (change)="onFileSelected($event)" class="attachment-voc" accept="image/png, image/jpeg, image/jpg">
            </div>
            <div class="col-md-12">
                <img *ngIf="imgUrl && dialogType === 'update'" style="max-width: 250px;" [src]="imgUrl" alt="profile-img"/>
                <br>
            </div>

            <div class="col-md-12 nbt-form" *ngIf="isMysteryShopper">
                <br><br>
                <div class="row form-mystery-shopper">
                    <h2>Please confirm nomination of mystery shopper</h2>
                    <br><br><br>
                    <div class="col-md-6 nbt-form mat-form-field-wrapper">
                        <label>Start Date <span class="required">*</span></label>
                        <input class="date-picker" formControlName="mysteryShopperStartDate"
                               type="date" placeholder="Please select date"/>
                    </div>
                    <div class="col-md-6 nbt-form mat-form-field-wrapper">
                        <label>End Date <span class="required">*</span></label>
                        <input class="date-picker" formControlName="mysteryShopperEndDate"
                               type="date" placeholder="Please select date"/>
                    </div>
                    <br><br><br><br>
                    <div class="col-md-12 nbt-form">
                        <label>Showroom to Visit <span class="required">*</span></label>
                        <mat-form-field appearance="outline">
                            <mat-select [(ngModel)]="this.customerRecordModel.mysteryShopperDepartmentList"
                                        formControlName="mysteryShopperDepartmentList"
                                        [compareWith]="compareObjects" multiple>
                                <mat-option *ngFor="let dept of showrooms" [value]="dept" placeholder="Please select department">
                                    {{dept.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
        <button class="nbt-btn-secondary" (click)="addOrEditCustomerVehicle()">
            <mat-icon class="mr8">add</mat-icon>
            Add Customer Vehicle
        </button>
        <br>
        <div class="overflow-scroll" *ngIf="formArrayVehicles().length > 0">
            <table class="customer-record-table" aria-label="table-customer-records">
                <thead>
                    <tr>
                        <th><div style="padding-left: 16px;">Plate Number</div></th>
                        <th><div style="padding-left: 16px;">Model</div></th>
                        <th><div style="padding-left: 16px;">Purchase Date</div></th>
                        <th><div style="padding-left: 16px;">Last Service Date</div></th>
                        <th><div style="padding-left: 16px;">Actions</div></th>
                    </tr>
                </thead>
                <tbody formArrayName="vehicles">
                    <tr *ngFor="let vehicle of formArrayVehicles(); let i = index" [formGroupName]="i">
                        <td><div style="padding-left: 16px;">{{vehicle.value?.registrationNo}}</div></td>
                        <td><div style="padding-left: 16px;">{{vehicle.value?.model?.brand}} {{vehicle.value?.model?.name}}</div></td>
                        <td><div style="padding-left: 16px;">{{(vehicle.value?.purchaseDate | date: 'd MMMM yyyy') || '-'}}</div></td>
                        <td><div style="padding-left: 16px;">{{(vehicle.value?.lastServiceDate | date: 'd MMMM yyyy') || '-'}}</div></td>
                        <td>
                            <div style="padding-left: 16px;">
                                <mat-icon (click)="addOrEditCustomerVehicle(vehicle.value, i)" matTooltip="Edit">edit</mat-icon>
                                <mat-icon (click)="changeOwnershipCustomerVehicle(vehicle.value, i)" matTooltip="Transfer Ownership">arrow_circle_right</mat-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br>
    </form>
    <span class="required">* Required Field For Submission</span>
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || customerRecordFormGroup.invalid" (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>

<ng-template #elseNoDepartment>
    <div class="value">-</div>
</ng-template>
