import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class NpsReportService {
    constructor(private httpClient: HttpClient) {}

    generateFullNpsListingReport(request: NpsListingParams) {
        const params = new HttpParams({ fromObject: { ...request } });
        return this.httpClient.get(`/web/report/nps/listing/full`, {
            observe: 'response',
            params,
            responseType: 'blob',
        });
    }

    generateIndividualNpsListingReport(request: NpsListingParams) {
        const params = new HttpParams({ fromObject: { ...request } });
        return this.httpClient.get(`/web/report/nps/listing/individual`, {
            observe: 'response',
            params,
            responseType: 'blob',
        });
    }
}

interface NpsListingParams {
    curIns: number;
    departmentIds: number[];
    startDate: string;
    endDate: string;
}
