import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { HolidayModel } from 'src/app/model/holiday.model';
import { HolidayService } from 'src/app/services/holiday.service';
import { DatePipe } from '@angular/common';


@Component({
    selector: 'app-holiday-dialog-new-or-edit',
    templateUrl: './holiday-dialog-new-or-edit.component.html',
    styleUrls: ['./holiday-dialog-new-or-edit.component.scss']
})
export class HolidayDialogNewOrEditComponent implements OnInit {

    dialogType: string = '';
    isSaving: boolean = false;
    holidayFormGroup: FormGroup;
    institutions: InstitutionModel[] = [];
    recurrent:boolean = false;
    loading: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public holidayModel: HolidayModel,
        public dialogRef: MatDialogRef<HolidayDialogNewOrEditComponent>,
        private holidayService: HolidayService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private institutionsService: InstitutionsService,
        private datePipe: DatePipe,
    ) { }

    ngOnInit(): void {
        if (this.holidayModel && this.holidayModel.id) {
            this.dialogType = 'update';
            this.holidayFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.holidayModel.id),
                name: this.formBuilder.control(this.holidayModel.name, Validators.required),
                date: this.formBuilder.control(this.holidayModel.date),
                recurrent: this.formBuilder.control(this.holidayModel.recurrent),
                active: this.formBuilder.control(this.holidayModel.active),
                institution: this.formBuilder.control(this.holidayModel.institution),
                lastModifiedBy: this.formBuilder.control(this.holidayModel.lastModifiedBy),
                lastModifiedDate: this.formBuilder.control(this.holidayModel.lastModifiedDate),
            });
        } else {
            this.dialogType = 'create';
            this.holidayFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null, Validators.required),
                date: this.formBuilder.control(this.holidayModel ? this.holidayModel.date : null, [Validators.required]),
                recurrent: this.formBuilder.control(true),
                active: this.formBuilder.control(true),
                institution: this.formBuilder.control(this.authService.getCurIns(), Validators.required),
                createdBy: this.formBuilder.control(null),
                createdDate: this.formBuilder.control(null)
            });
        }

        this.institutionsService.getAll().subscribe({
            next: (value) => {
                this.institutions = value.body;
                this.deactiveLoading();
            }
        });
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    save(): void {
        this.isSaving = true;
        this.activeLoading();
        const holidayForm = this.holidayFormGroup.getRawValue();
        holidayForm.date = this.datePipe.transform(holidayForm.date, 'yyyy-MM-dd', '+800')

        if (this.dialogType === 'create') {
            this.holidayService.save(holidayForm, this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                }, error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                    });
                }
            });
        } else {
            this.holidayService.update(holidayForm, this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                    });
                }
            });
        }
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id == object2.id;
    }

}
