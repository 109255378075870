export const REGEXS = {

    PHONE_FILTER: new RegExp("^[\+0-9]*$"),
    IC: new RegExp("^[A-Z0-9-]*$"),
    DATE: 'dd/MM/yy, h:mm a',
    VEHICLE: new RegExp("^([a-zA-Z0-9]*)$"),

}

export const SnackBarStatus =  {
    CLOSE : 'Close',
    OPEN: 'Open',
}

