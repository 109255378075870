import { Property, propertyNameCompare } from '../util';

export const OVERDUE_ENUM: Property[] = [
    {
        value: 'RESPONSE_OVERDUE',
        name: 'Response',
    },
    {
        value: 'RESOLUTION_OVERDUE',
        name: 'Resolution',
    },
].sort(propertyNameCompare);
