<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create New Institution
        </ng-container>
        <ng-template #elseTemplate>
            Edit Institution '{{institutionModel.name}}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="institutionFormGroup">
        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Name <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="name" placeholder="Enter name" maxlength="100" required/>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form label-flex">
                <mat-checkbox formControlName="active">Active</mat-checkbox>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Short Name</label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="shortName" placeholder="Enter short name" maxlength="20"/>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Parent Institution <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select name="parentInstitution" formControlName="parentInstitution"
                        [compareWith]="compareObjects" [disabled]="checkInstitution()" placeholder="Select parent institution" required>
                        <mat-option [value]="null">
                            Select parent institution
                        </mat-option>
                        <mat-option *ngFor="let ins of parentInstitution" [value]="ins">
                            {{ ins.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="institutionFormGroup.get('parentInstitution').dirty">Parent institution is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Business Registration Number</label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="businessRegNo" placeholder="Enter business registration number" maxlength="50"/>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Industry</label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="industry" placeholder="Enter insdustry" maxlength="50"/>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Address</label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="address" placeholder="Enter address" maxlength="500"/>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Phone</label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="phone" placeholder="Enter phone number" maxlength="50"/>
                    <mat-error *ngIf="institutionFormGroup.get('phone').errors?.pattern">Invalid Input</mat-error>
                    <mat-icon class="material-symbols-text" [matTooltip]="phoneContent" matTooltipPosition="above">
                        info
                    </mat-icon>
                </mat-form-field>
            </div>
            <!-- TODO, further investigate -->
            <!-- <div class="col-md-5 nbt-form">
                <label>Phone </label>
                    <ngx-intl-tel-input
                        [cssClass]="''"
                        [preferredCountries]="false"
                        [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true"
                        [customPlaceholder]="enablePlaceholder"
                        [searchCountryFlag]="false"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.Malaysia"
                        maxLength="20"
                        [phoneValidation]="false"
                        [separateDialCode]="true"
                        formControlName="phoneObject"
                        matInput 
                        appAutofocus 
                        type="text"
                      >
                    </ngx-intl-tel-input>
                    <mat-error *ngIf="institutionFormGroup.get('phoneObject').errors?.pattern">Invalid Input</mat-error>
            </div> -->
            <!-- <div class="col-md-1 nbt-form">
                    <mat-icon class="material-symbols-text custom-info" [matTooltip]="phoneContent" matTooltipPosition="above">
                        info
                    </mat-icon>
            </div> -->
            <div class="col-md-6 nbt-form">
                <label>Fax</label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="fax" placeholder="Enter fax number" maxlength="50"/>
                    <mat-error *ngIf="institutionFormGroup.get('fax').errors?.pattern">Invalid Input </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Email</label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="email" placeholder="Enter email" maxlength="100"/>
                    <mat-error *ngIf="institutionFormGroup.get('email').errors?.email">Invalid Input</mat-error>
                    <mat-icon class="material-symbols-text" [matTooltip]="emailContent" matTooltipPosition="above">
                        info
                    </mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Website</label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="website" placeholder="Enter website" maxlength="50"/>
                </mat-form-field>
            </div>
        </div>
    </form>
    <span class="required">* Required Field For Submission</span> 
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || institutionFormGroup.invalid"
        (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>