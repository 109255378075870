<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        Change Ownership
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <div class="row">
        <div class="col-md-12 nbt-form">
            <label>Customer</label>
            <mat-form-field appearance="outline">
                <input matInput type="text" (ngModelChange)="searchCustomers(customerControl.value)"
                    [formControl]="customerControl" [matAutocomplete]="auto" placeholder="Search customer"/>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionsScroll)="onScroll()">
                    <mat-option *ngFor="let option of customerRecords" [value]="option">
                        {{option.firstName}} {{option.lastName}} ({{option.mainPhone}})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <div class="nbt-details row" *ngIf="customer">
        <div class="col-md-6">
            <label>Full Name</label>
            <div class="value">{{customer?.salutation ? customer?.salutation + ' ' : ''}}{{customer?.firstName | titlecase}} {{customer?.lastName | titlecase}}</div>
        </div>
        <div class="col-md-6">
            <label>IC Number</label>
            <div class="value">{{customer?.ic || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Phone Number (Main)</label>
            <div class="value">{{customer?.mainPhone || '-'}}</div>
        </div>
        <div class="col-md-6">
            <label>Email</label>
            <div class="value">{{customer?.email || '-'}}</div>
        </div>
    </div>
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="!customer" (click)="update()">
        <mat-icon class="mr8">published_with_changes</mat-icon>
        {{windowWidth < 720 ? 'Save' : 'Change Ownership'}}
    </button>
</div>
