<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        Reset Password '{{applicationUserModel.username}}'
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body">
    <form [formGroup]="applicationUserFormGroup">
        <div class="nbt-form">
            <label>Username</label>
            <mat-form-field appearance="outline">
                <input matInput appAutofocus type="text" formControlName="username" />
            </mat-form-field>
        </div>
        <div class="nbt-form" *ngIf="checkUser()">
            <label>Old Password</label>
            <mat-form-field appearance="outline">
                <input matInput [type]="showPwd ? 'text' : 'password'" formControlName="oldPassword" />
                <span><mat-icon (click)="showPassword()" class="icon" matSuffix>{{showPwd?'visibility_off':'visibility'}}</mat-icon></span>
            </mat-form-field>
        </div>
        <div class="nbt-form">
            <label>New Password</label>
            <mat-form-field appearance="outline">
                <input matInput [type]="showNewPwd ? 'text' : 'password'" formControlName="newPassword" />
                <span><mat-icon (click)="showNewPassword()"  matSuffix class="icon">{{showNewPwd?'visibility_off':'visibility'}}</mat-icon></span>
            </mat-form-field>
        </div>
        <div class="nbt-form">
            <label>Repeat Password</label>
            <mat-form-field appearance="outline">
                <input matInput [type]="showRepeatPwd ? 'text' : 'password'" formControlName="repeatPassword" />
                <span><mat-icon (click)="showRepeatPassword()"  matSuffix class="icon">{{showRepeatPwd?'visibility_off':'visibility'}}</mat-icon></span>
                <mat-error *ngIf="applicationUserFormGroup.get('repeatPassword').errors?.mismatch">Passwords do not match</mat-error>
            </mat-form-field>
        </div>
    </form>
</div>
<div class="nbt-dialog-footer">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || applicationUserFormGroup.invalid"
        (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>