<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Add Customer Vehicle
        </ng-container>
        <ng-template #elseTemplate>
            Edit Customer Vehicle '{{customerRecordVehiclesModel.name}}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="customerRecordVehiclesFormGroup">
        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Model <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select name="applicationPosition" formControlName="model" placeholder="Select model">
                        <mat-option [value]="null" selected>
                            Select model
                        </mat-option>
                        <mat-option *ngFor="let model of models" [value]="model">
                            {{model.brand}} {{model.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="customerRecordVehiclesFormGroup.get('model').dirty">Model is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Registration Number <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="registrationNo" placeholder="Enter registration number" maxlength="20">
                    <mat-error *ngIf="customerRecordVehiclesFormGroup.get('registrationNo').dirty">Registration number is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form mat-form-field-wrapper">
                <label>Purchase Date</label>
                    <input class="date-picker" formControlName="purchaseDate" type="date" placeholder="Select purchase date"/>
            </div>
            <div class="col-md-6 nbt-form mat-form-field-wrapper">
                <label>Last Service Date</label>
                    <input class="date-picker" formControlName="lastServiceDate" type="date" placeholder="Select last service date"/>
            </div>
        </div>
    </form>
    <span class="required">* Required Field For Submission</span> 
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || customerRecordVehiclesFormGroup.invalid" (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>