import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { MysteryShopperModel, MysteryShopperAttachment } from '../model/mystery-shopper.model';
import { UpdateMysteryShopperModel } from '../model/mystery-shopper-update.model';
import { Model } from '../model/model.model';
import { ApplicationUserModel } from '../model/application-user.model';

@Injectable({
    providedIn: 'root'
})
export class MysteryShopperService {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) { }

    getAllMysteryShopperRecord(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        customerId: number
    ):Observable<HttpResponse<MysteryShopperModel[]>>{
        let httpParams = new HttpParams();
        if (pageNumber) httpParams = httpParams.set('page', `${pageNumber}`);
        if (pageSize) httpParams = httpParams.set('size', `${pageSize}`);
        if (sortOrder) httpParams = httpParams.set('sort', sortOrder);
        if (customerId) httpParams = httpParams.set('customerId', customerId);
        httpParams = httpParams.set('curIns', this.authService.getCurInsId());

        return this.httpClient.get<MysteryShopperModel[]>(`/web/mystery-shopper/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getMysteryShopperDetail(
        token: string
    ):Observable<HttpResponse<MysteryShopperModel>>{
        let httpParams = new HttpParams();
        httpParams = httpParams.set('token', token);

        return this.httpClient.get<MysteryShopperModel>(`/web/mystery-shopper/get-detail`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllMysteryShopperVehicleList(
        token: string
    ):Observable<HttpResponse<Model[]>>{
        let httpParams = new HttpParams();
        httpParams = httpParams.set('token', token);

        return this.httpClient.get<Model[]>(`/web/mystery-shopper/get-vehicle`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllMysteryShopperSalesConsultantListNoPagination(
        token: string
    ):Observable<HttpResponse<ApplicationUserModel[]>>{
        let httpParams = new HttpParams();
        httpParams = httpParams.set('token', token);

        return this.httpClient.get<ApplicationUserModel[]>(`/web/mystery-shopper/get-sales-consultant-no-pagination`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllMysteryShopperSalesConsultantList(
        sortOrder?: string,
        page?: number,
        size?: number,
        username?: string,
        token?: string
    ):Observable<HttpResponse<ApplicationUserModel[]>>{
        let httpParams = new HttpParams();
        if (sortOrder)  httpParams = httpParams.set('sort', sortOrder);
        if (page) httpParams = httpParams.set('page', page);
        if (size) httpParams = httpParams.set('size', size);
        if (username) httpParams = httpParams.set('username', username);
        if (token) httpParams = httpParams.set('token', token);

        return this.httpClient.get<ApplicationUserModel[]>(`/web/mystery-shopper/get-sales-consultant`, {
            observe: 'response',
            params: httpParams,
        });
    }

    updateMysteryShopperSurvey(
        updateMysteryShopperModel: UpdateMysteryShopperModel
    ): Observable<HttpResponse<MysteryShopperModel>> {
        let httpParams = new HttpParams();

        return this.httpClient.post<MysteryShopperModel>(`/web/mystery-shopper/save-or-submit`, updateMysteryShopperModel, {
            observe: 'response',
            params: httpParams
        });
    }

    getAttachment(
        sectionId: string,
        fileName: string
    ): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('sectionId', sectionId);
        httpParams = httpParams.set('fileName', fileName);
        return this.httpClient.get<any>(`/web/mystery-shopper/attachment`, {
            responseType: 'blob' as 'json',
            params: httpParams,
            observe: 'response'
        });
    }

    deleteAttachment (
        sectionId: string,
        fileName: string
    ): Observable<HttpResponse<MysteryShopperAttachment>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('sectionId', sectionId);
        httpParams = httpParams.set('fileName', fileName);
        return this.httpClient.delete<any>(`/web/mystery-shopper/delete-attachment`, {
            observe: 'response',
           params: httpParams,
        });
    }
}
