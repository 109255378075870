import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { DashboardPersonalTicketModel } from 'src/app/model/dashboard-personal-ticket.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { ActionEnum } from '../../home.component';
import { Subject, Subscription, interval } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-personal-task',
  templateUrl: './personal-task.component.html',
  styleUrls: ['./personal-task.component.scss']
})
export class PersonalTaskComponent implements OnInit {

  @Input() public defaultDepartment: boolean;
  @Input() public refreshRateSubject: Subject<number>;
  refreshRate: number;

  refreshRateSub: Subscription


  readonly READ_PERSONAL_TICKET = ApplicationPermissionsService.READ_PERSONAL_TICKET;
  readonly READ_PERSONAL_TICKET_DEFAULT_DEPARTMENT = ApplicationPermissionsService.READ_PERSONAL_TICKET_DEFAULT_DEPARTMENT;

  assigned: DashboardPersonalTicketModel;
  inProgress: DashboardPersonalTicketModel;
  responseOverdue: DashboardPersonalTicketModel;
  resolutionOverdue: DashboardPersonalTicketModel;
  defaultDepartmentResponseOverdue: DashboardPersonalTicketModel;
  defaultDepartmentResolutionOverdue: DashboardPersonalTicketModel;
  onHold: DashboardPersonalTicketModel;
  new: DashboardPersonalTicketModel;
  open: DashboardPersonalTicketModel;
  closed: DashboardPersonalTicketModel;
  reOpen: DashboardPersonalTicketModel;
  resolved: DashboardPersonalTicketModel;


  constructor(
    private dashboardService: DashboardService,
    private institutionsService: InstitutionsService,
    private authService: AuthService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (!this.authService.getCurIns()) {
      this.institutionsService.getAll().subscribe({
        next: (value) => {
          this.authService.setCurIns(value.body[0]);
          this.reloadPersonalTask()
          this.refreshRateSubject.subscribe(newValue => {
            this.refreshRate = newValue;
            if (this.refreshRateSub) {
              this.refreshRateSub.unsubscribe()
            }
            if (this.refreshRate > 0) {
              this.refreshRateSub = interval(this.refreshRate * 1000).subscribe((val) => {
                this.reloadPersonalTask()
              })
            }
          })
        },
      });
    } else {
      this.reloadPersonalTask()
      this.refreshRateSubject.subscribe(newValue => {
        this.refreshRate = newValue;
        if (this.refreshRateSub) {
          this.refreshRateSub.unsubscribe()
        }
        if (this.refreshRate > 0) {
          this.refreshRateSub = interval(this.refreshRate * 1000).subscribe((val) => {
            this.reloadPersonalTask()
          })
        }
      })
    }
  }

  reloadPersonalTask() {
    if (this.defaultDepartment) {
      if (this.authService.hasAuthorityMenu(this.READ_PERSONAL_TICKET_DEFAULT_DEPARTMENT)) {
        this.initAllPersonalTaskDefaultDepartment();
      }
    } else {
      if (this.authService.hasAuthorityMenu(this.READ_PERSONAL_TICKET)) {
        this.initAllPersonalTask();
      }
    }
  }

  initAllPersonalTask() {
    this.dashboardService.getPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.ASSIGNED).subscribe({
      next: (value) => (this.assigned = value.body),
    });
    this.dashboardService.getPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.IN_PROGRESS).subscribe({
      next: (value) => (this.inProgress = value.body),
    });
    this.dashboardService.getPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.RESPONSE_OVERDUE).subscribe({
      next: (value) => (this.responseOverdue = value.body),
    });
    this.dashboardService.getPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.RESOLUTION_OVERDUE).subscribe({
      next: (value) => (this.resolutionOverdue = value.body),
    });
    this.dashboardService.getPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.ON_HOLD).subscribe({
      next: (value) => (this.onHold = value.body),
    });
    this.dashboardService.getPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.CLOSED).subscribe({
      next: (value) => (this.closed = value.body),
    });
    this.dashboardService.getPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.RE_OPEN).subscribe({
      next: (value) => (this.reOpen = value.body),
    });
    this.dashboardService.getPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.RESOLVED).subscribe({
      next: (value) => (this.resolved = value.body),
    });
  }

  initAllPersonalTaskDefaultDepartment() {
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.ASSIGNED).subscribe({
      next: (value) => (this.assigned = value.body),
    });
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.IN_PROGRESS).subscribe({
      next: (value) => (this.inProgress = value.body),
    });
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.RESPONSE_OVERDUE).subscribe({
      next: (value) => (this.responseOverdue = value.body),
    });
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.RESOLUTION_OVERDUE).subscribe({
      next: (value) => (this.resolutionOverdue = value.body),
    });
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.DEFAULT_DEPARTMENT_RESPONSE_OVERDUE).subscribe({
      next: (value) => (this.defaultDepartmentResponseOverdue = value.body),
    });
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.DEFAULT_DEPARTMENT_RESOLUTION_OVERDUE).subscribe({
      next: (value) => (this.defaultDepartmentResolutionOverdue = value.body),
    });
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.ON_HOLD).subscribe({
      next: (value) => (this.onHold = value.body),
    });
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.NEW).subscribe({
      next: (value) => (this.new = value.body),
    });
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.OPEN).subscribe({
      next: (value) => (this.open = value.body),
    });
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.CLOSED).subscribe({
      next: (value) => (this.closed = value.body),
    });
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.RE_OPEN).subscribe({
      next: (value) => (this.reOpen = value.body),
    });
    this.dashboardService.getDefaultDepartmentPersonalTicketCountByStatus(this.authService.getCurInsId(), ActionEnum.RESOLVED).subscribe({
      next: (value) => (this.resolved = value.body),
    });
  }

  goToVocListing(status: string) {
    this.router
      .navigate(['operations/voice-of-customer'], {
        relativeTo: this.activatedRoute,
        queryParams: { 
          status: status
      }
      })
      .then();
  }
}
