import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { CustomerRecordModel } from 'src/app/model/customer-records.model';
import { DepartmentModel } from 'src/app/model/department.model';
import {
    SYS_CONFIG,
    SystemConfigurationModel,
} from 'src/app/model/system-configuration.model';
import { VehicleModelModel } from 'src/app/model/vehicle-dialog.model';
import { CustomerRecordsService } from 'src/app/services/customer-records.service';
import { DepartmentService } from 'src/app/services/department.service';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { UsersService } from 'src/app/services/users.service';
import { VehicleModelService } from 'src/app/services/vehicle-model.service';
import { REGEXS } from 'src/app/shared/constant';

@Component({
    selector: 'app-customer-records-dialog-add-activity',
    templateUrl: './customer-records-dialog-add-activity.component.html',
    styleUrls: ['./customer-records-dialog-add-activity.component.scss'],
})
export class CustomerRecordsDialogAddActivityComponent implements OnInit {
    activityFormGroup: FormGroup;
    types = SYS_CONFIG.ACTIVITY_TYPE;
    sysConfig: SystemConfigurationModel[];
    isSaving = false;
    departments: DepartmentModel[] = [];
    models: VehicleModelModel[] = [];
    users: ApplicationUserModel[] = [];
    loading = true;
    currentDate = new Date();

    constructor(
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA)
        public customerRecordModel: CustomerRecordModel,
        public dialogRef: MatDialogRef<CustomerRecordsDialogAddActivityComponent>,
        private departmentService: DepartmentService,
        public authService: AuthService,
        private vehicleModelService: VehicleModelService,
        private usersService: UsersService,
        private datePipe: DatePipe,
        private customerRecordsService: CustomerRecordsService,
        private snackBar: MatSnackBar,
        private sysConfigService: SystemConfigurationService
    ) {}

    ngOnInit(): void {
        this.activityFormGroup = this.formBuilder.group({
            type: this.formBuilder.control('AFTER_SALES'),
            details: this.formBuilder.control(null, Validators.required),
            actualDate: this.formBuilder.control(
                this.currentDate,
                Validators.required
            ),
            isNps: this.formBuilder.control(true),
            model: this.formBuilder.control(null),
            department: this.formBuilder.control(null, Validators.required),
            applicationUser: this.formBuilder.control(
                null,
                Validators.required
            ),
            customer: this.formBuilder.control(null),
            registrationNo: this.formBuilder.control(
                { value: null, disabled: true },
                [Validators.pattern(REGEXS.VEHICLE)]
            ),
        });

        this.departmentService.getAllNoPagination(true).subscribe({
            next: (value) => {
                this.departments = value.body;
            },
        });

        this.vehicleModelService.getAllModelsNoPagination().subscribe({
            next: (value) => {
                this.models = value.body;
            },
        });

        this.sysConfigService
            .getSystemConfigurationActivityByCode(
            )
            .subscribe({
                next: (value) => {
                    this.sysConfig = value.body;
                    this.deactiveLoading();
                },
            });
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    save() {
        this.isSaving = true;
        this.activeLoading();
        const data = this.activityFormGroup.getRawValue();
        data.actualDate = this.datePipe.transform(
            data.actualDate,
            'yyyy-MM-dd',
            '+800'
        );
        data.customer = this.customerRecordModel;

        if (data.model && data.registrationNo) {
            data.vehicle = {
                model: data.model,
                registrationNo: data.registrationNo,
            };
        }

        this.customerRecordsService
            .createActivity(this.authService.getCurInsId(), data)
            .subscribe({
                next: (value) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close(value.body);
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000,
                    });
                },
            });
    }

    selectDepartment(event: any) {
        this.usersService
            .getAllByDepartment(this.authService.getCurInsId(), event.id, {
                reminderInd: false,
            })
            .subscribe({
                next: (value) => {
                    this.users = value.body;
                    this.deactiveLoading();
                },
            });
    }
    modelSelectionChange(event: MatSelectChange) {
        if (event.value) {
            this.activityFormGroup.get('registrationNo').enable();
        } else {
            this.activityFormGroup.get('registrationNo').disable();
        }
    }
}
