import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { BusinessHourModel } from 'src/app/model/business-hours.model';
import { InstitutionModel } from 'src/app/model/institution.model';
import { BusinessHoursService } from 'src/app/services/business-hours.service';
import { InstitutionsService } from 'src/app/services/institutions.service';

@Component({
    selector: 'app-business-hours-dialog-new-or-edit',
    templateUrl: './business-hours-dialog-new-or-edit.component.html',
    styleUrls: ['./business-hours-dialog-new-or-edit.component.scss']
})
export class BusinessHoursDialogNewOrEditComponent implements OnInit {

    windowWidth: number = window.innerWidth;
    businessHourFormGroup: FormGroup;
    dialogType: string = '';
    isSaving: boolean = false;
    institutions: InstitutionModel[] = [];
    loading: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public businessHourModel: BusinessHourModel,
        public dialogRef: MatDialogRef<BusinessHoursDialogNewOrEditComponent>,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private institutionsService: InstitutionsService,
        private snackBar: MatSnackBar,
        private businessHoursServices: BusinessHoursService,
    ) { }

    ngOnInit(): void {
        if (this.businessHourModel) {
            this.dialogType = 'update';
            this.businessHourFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.businessHourModel.id),
                name: this.formBuilder.control(this.businessHourModel.name, Validators.required),
                description: this.formBuilder.control(this.businessHourModel.description),
                active: this.formBuilder.control(this.businessHourModel.active),
                institution: this.formBuilder.control(this.authService.getCurIns(), Validators.required),
                businessHourDetails: this.formBuilder.array(this.businessHourModel.businessHourDetails.map(value => this.formBuilder.group({
                    id: value.id,
                    day: value.day,
                    startTime: value.startTime,
                    endTime: value.endTime
                })), Validators.required)
            });

        } else {
            this.dialogType = 'create';
            this.businessHourFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null, Validators.required),
                active: this.formBuilder.control(true),
                description: this.formBuilder.control(null),
                institution: this.formBuilder.control(this.authService.getCurIns(), Validators.required),
                businessHourDetails: this.formBuilder.array([], Validators.required)
            });
        }
        this.institutionsService.getAll().subscribe({
            next: (value) => {
                this.institutions = value.body;
                this.deactiveLoading();
            },
        });
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    save(): void {
        this.isSaving = true;
        this.activeLoading();
        if (this.dialogType === 'create') {
            this.businessHoursServices.save(this.businessHourFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                      });
                }
            });
        } else {
            this.businessHoursServices.update(this.businessHourFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                      });
                }
            });
        }
    }

    checkInstitution() {
        return true;
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    selectDays(day: string, seq: number) {
        const businessHourDetails = this.businessHourFormGroup.get('businessHourDetails') as FormArray;
        if (this.isSelectedDay(day)) {
            const i = businessHourDetails.controls.findIndex((bus: FormGroup) => bus.value.day === day);
            if (i > -1) businessHourDetails.controls.splice(i, 1);
        } else {
            const businessHourDetail = this.formBuilder.group({
                seq: seq,
                day: day,
                startTime: '',
                endTime: ''
            })
            businessHourDetails.push(businessHourDetail)
        }
    }

    isSelectedDay(day: string): boolean {
        const businessHourDetails = this.businessHourFormGroup.get('businessHourDetails') as FormArray;
        let selected = businessHourDetails.controls.find((bus: FormGroup) => bus.value.day === day);
        return selected ? true : false;
    }

    formArrayBusinessHourDetails() {
        const array = (this.businessHourFormGroup.get('businessHourDetails') as FormArray).controls;
        array.sort((a, b) => a.value.seq - b.value.seq)
        return array;
    }

    copyAllTime() {
        const businessHourDetails = this.businessHourFormGroup.get('businessHourDetails') as FormArray;
        for (let i in businessHourDetails.controls) {
            if (+i > 0) {
                businessHourDetails.controls[i].patchValue({
                    startTime: businessHourDetails.controls[0].value.startTime,
                    endTime: businessHourDetails.controls[0].value.endTime
                })
            }
        }
    }

    @HostListener('window:resize')
      onResize() {
          this.windowWidth = window.innerWidth;
    }
}