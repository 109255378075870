<div class="nbt-header" *ngIf="!loading">
    <div class="container-fluid nbt-container">
        <div class="nbt-header" *ngIf="!loading">
            <div class="d-flex">
                <div class="nbt-header-title">
                    New NPS Call List
                </div>
            </div>
        </div>
        <br>
        <br>
        <mat-card-content *ngIf="!loading" class="mat-card-content-table" style="overflow: unset; padding: 25px;">
            <ng-container>
                <form [formGroup]="npsFormGroup">
                    <div class="row form-title">
                        Please confirm generation of NPS Call List
                    </div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-md-12 nbt-form">
                            <label for="">NPS Type <span class="required">*</span></label>
                            <mat-form-field appearance="outline">
                                <mat-select name="npsType" formControlName="type" placeholder="Please select NPS Type(Sales(Buyer/Non-Buyer), After Sales)"
                                    [compareWith]="compareObjects" >
                                    <mat-option *ngFor="let type of sysConfig" [value]="type.code">
                                        {{ type.value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 nbt-form">
                            <label for="">Start Date <span class="required">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event)"  formControlName="startDate" [(ngModel)]="startDate" placeholder="Select start activity date"/>
                                <mat-datepicker-toggle matSuffix [for]="picker"  ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 nbt-form">
                            <label for="">End Date <span class="required">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput [matDatepicker]="datepicker" [min]="startDate" [max]="max" [(ngModel)]="endDate" formControlName="endDate"  placeholder="Select end activity date" [disabled]="!startDate"/>
                                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                                <mat-datepicker #datepicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 nbt-form">
                            <label for="">Branch <span class="required">*</span></label>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="department" placeholder="Select branch">
                                    <mat-option *ngFor="let department of departments" [value]="department">
                                        {{ department.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 nbt-form">
                            <label for="">List Size <span class="required">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput appAutofocus type="number"  min="1" max="9999"  formControlName="listSize"  placeholder="Enter list size" />
                                <mat-error *ngIf="npsFormGroup.get('listSize').errors?.pattern">Invalid input</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <span class="required">* Required Field For Submission</span>
                <div class="nbt-dialog-footer" *ngIf="!loading">
                    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="back()">
                        <mat-icon class="mr8">clear</mat-icon>
                        Cancel
                    </button>
                    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || npsFormGroup.invalid" (click)="save()">
                        <mat-icon class="mr8">save</mat-icon>
                        Confirm and Proceed
                    </button>
                </div>
            </ng-container>
        </mat-card-content>
    </div>
