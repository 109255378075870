import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { DepartmentModel } from 'src/app/model/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { TicketService } from 'src/app/services/ticket.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'app-voice-of-customer-dialog-confirmation',
    templateUrl: './voice-of-customer-dialog-confirmation.component.html',
    styleUrls: ['./voice-of-customer-dialog-confirmation.component.scss']
})
export class VoiceOfCustomerDialogConfirmationComponent implements OnInit {

    readonly snackBarConfig: MatSnackBarConfig = { duration: 60000 };

    onHoldReason: string = undefined;
    cancelReason: string = undefined;
    openReason: string = undefined;
    reOpenDepartment: any;
    reOpenAssignee: any;
    reOpenReason: string = '';
    reOpenPriority: string = "HIGH"
    assignees: ApplicationUserModel[] = [];
    registeredDepts: DepartmentModel[] = [];
    loading: boolean = true;
    assigneesManager: ApplicationUserModel[] = [];
    assigneesHod: ApplicationUserModel[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<VoiceOfCustomerDialogConfirmationComponent>,
        private ticketService: TicketService,
        private authService: AuthService,
        private snackBar: MatSnackBar,
        private departmentService: DepartmentService,
        private usersService: UsersService,
    ) { }

    ngOnInit(): void {
        if (this.data.action === 'RE_OPEN') {
            this.departmentService.getAllNoPagination().subscribe({
                next: value => {
                    this.registeredDepts = value.body;
                }
            });
        }
        this.deactiveLoading();
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    changeSelectDepartment(event: any) {
        this.reOpenAssignee = null;
        this.assigneesManager = [];
        this.assigneesHod = [];
        this.assignees = [];

        this.usersService.getAllByDepartment(this.authService.getCurInsId(),event.value.id,{ reminderInd: false, isChampion: true }).subscribe({
            next: response => {
                this.assignees = response.body;
            }
        })

        this.usersService.getAllByDepartment(this.authService.getCurInsId(),event.value.id,{ reminderInd: false, officerType: 'manager' }).subscribe({
            next: response => {
                this.assigneesManager = response.body;
            }
        })

        this.usersService.getAllByDepartment(this.authService.getCurInsId(),event.value.id,{ reminderInd: false, officerType: 'head of department' }).subscribe({
            next: response => {
                this.assigneesHod = response.body;
            }
        })
    }

    submitConfirmation() {
        this.activeLoading();
        let request;
        if (this.data.action === 'RE_OPEN') {
            request = {
                ...this.data,
                reOpenDepartment: this.reOpenDepartment,
                reOpenAssignee: this.reOpenAssignee,
                reOpenReason: this.reOpenReason,
                reOpenPriority: this.reOpenPriority,
                reviewDatetimeString: this.data.reviewDatetimeString,
                reviewDetail: this.data.reviewDetail
            }
        } else {
            request = {
                ...this.data,
                onHoldReason: this.onHoldReason,
                cancelReason: this.cancelReason,
                openReason: this.openReason,
            }
        }
        this.ticketService.update(request, this.authService.getCurInsId()).subscribe({
            next: response => {
                this.deactiveLoading();
                this.dialogRef.close('ok');
            },
            error: error => {
                this.deactiveLoading();
                this.snackBar.open(error.error.message, 'Close', this.snackBarConfig);
            }
        })
    }
}
