import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { ApplicationRoleModel } from '../model/application-role.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationRolesService {
  constructor(private httpClient: HttpClient) {}

  public save(
    applicationRoleModel: ApplicationRoleModel,
    curIns: string
  ): Observable<HttpResponse<ApplicationRoleModel>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('curIns', curIns);
    return this.httpClient.post<ApplicationRoleModel>(
      '/web/application-role',
      applicationRoleModel,
      {
        observe: 'response',
        params: httpParams
      }
    );
  }

  public getAllbyInstitution(
    pageNumber: number,
    pageSize: number,
    sortOrder: string,
    criteria
  ): Observable<HttpResponse<ApplicationRoleModel[]>> {
    let httpParams = new HttpParams();
    if (pageNumber) {
      httpParams = httpParams.set('page', `${pageNumber}`);
    }
    if (pageSize) {
      httpParams = httpParams.set('size', `${pageSize}`);
    }
    if (sortOrder) {
      httpParams = httpParams.set('sort', sortOrder);
    }

    if (criteria) {
      if (criteria.name) {
        httpParams = httpParams.set('name', criteria.name);
      }
      if (criteria.institution) {
        httpParams = httpParams.set('institutionId', criteria.institution);
      }
      if (criteria.currentInstitution) {
        httpParams = httpParams.set('curIns', criteria.currentInstitution);
      }
    }
    return this.httpClient.get<ApplicationRoleModel[]>(
      `/web/application-role/all`,
      {
        observe: 'response',
        params: httpParams,
      }
    );
  }

  public getAllByInstitutionAndChild(
    pageNumber: number,
    pageSize: number,
    sortOrder: string,
    criteria
  ): Observable<HttpResponse<ApplicationRoleModel[]>> {
    let httpParams = new HttpParams();
    if (pageNumber) {
      httpParams = httpParams.set('page', `${pageNumber}`);
    }
    if (pageSize) {
      httpParams = httpParams.set('size', `${pageSize}`);
    }
    if (sortOrder) {
      httpParams = httpParams.set('sort', sortOrder);
    }

    if (criteria) {
      if (criteria.name) {
        httpParams = httpParams.set('name', criteria.name);
      }
      if (criteria.institution) {
        httpParams = httpParams.set('institutionId', criteria.institution);
      }
      if (criteria.currentInstitution) {
        httpParams = httpParams.set('curIns', criteria.currentInstitution);
      }
    }
    return this.httpClient.get<ApplicationRoleModel[]>(
      `/web/application-role/institution/all`,
      {
        observe: 'response',
        params: httpParams,
      }
    );
  }

  public update(
    applicationRoleModel: ApplicationRoleModel,
    curIns: string
  ): Observable<HttpResponse<ApplicationRoleModel>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('curIns', curIns);
    return this.httpClient.put<ApplicationRoleModel>(
      '/web/application-role',
      applicationRoleModel,
      {
        observe: 'response',
        params: httpParams
      }
    );
  }

  public delete(
    id: string,
    curIns: string
  ): Observable<HttpResponse<ApplicationRoleModel[]>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('id', id);
    httpParams = httpParams.set('curIns', curIns);
    return this.httpClient.delete<ApplicationRoleModel[]>(
      '/web/application-role',
      {
        observe: 'response',
        params: httpParams
      }
    );
  }
}
