import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { REGEXS } from 'src/app/shared/constant/regex.constant';
import { SurveyModel } from 'src/app/model/survey.model';
import { SurveyService } from 'src/app/services/survey.service';
import { SSI_FORM_CONTENT } from 'src/app/shared/constant/satisfactory-form.constant';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { IMAGE_ENUM } from 'src/app/shared/constant/image-enum.constant';
import { TOOLTIPS } from 'src/app/shared/constant';
import { MatDialog,  } from '@angular/material/dialog';
import { SalesDialogNewOrEditComponent } from '../sales-dialog-new-or-edit/sales-dialog-new-or-edit.component';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';

@Component({
    selector: 'app-sales-form',
    templateUrl: './sales-form.component.html',
    styleUrls: ['./sales-form.component.scss']
})

export class SalesFormComponent implements OnInit {
    readonly snackBarConfig: MatSnackBarConfig = { duration: 60000 };
    readonly MODIFY_SSI = ApplicationPermissionsService.MODIFY_SSI;
    formContent: any;
    formContentM: any;
    ssiFormGroup: FormGroup;
    surveyModel: SurveyModel;
    surveyHeaderId: any;
    surveyForm: SurveyModel;
    surveyData: any;
    loading: boolean = false;
    dialogType: string = '';
    imageEnum = IMAGE_ENUM;
    na: string = "../assets/survey/na.png";
    respond =" has been successfully responded.";
    close = "Close";
    isSaving: boolean = false;
    phoneContent: string = TOOLTIPS.phoneContent;
    brandToyota: string = "TOYOTA";
    brandLexus: string = "LEXUS";
    disableCheckbox = [];

    constructor(
        private route: ActivatedRoute,
        private surveyService: SurveyService,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.activeLoading();
        this.getSurveyFormDetail();
        this.ssiFormGroup = this.formBuilder.group({
            phoneNumber : this.formBuilder.control(null),
            remark: this.formBuilder.control(null),
            surveyDetails: this.formBuilder.array([]),
        });
    }

    getSurveyFormDetail(){
        this.route.queryParams.subscribe({
            next: param =>{
                if(param.id){
                    this.dialogType = 'read';
                    this.surveyHeaderId = param.id;
                    this.surveyService.getOneSurvey(this.surveyHeaderId).subscribe({
                        next: value => {
                            this.surveyModel = (value.body);
                            this.checkLanguage(this.surveyModel.surveyForm?.brand);
                            if(this.surveyModel.isReceive){
                            this.ssiFormGroup = this.formBuilder.group({
                                id: this.surveyHeaderId,
                                phoneNumber : this.formBuilder.control(this.surveyModel.phoneNumber, [Validators.required, Validators.pattern(REGEXS.PHONE_FILTER)]),
                                remark: this.formBuilder.control(this.surveyModel.remark),
                                surveyDetails: this.formBuilder.array(value.body.surveyDetails.map(detail =>this.formBuilder.group({
                                    id: detail.id,
                                    isReceive: this.surveyModel.isReceive,
                                    question: detail.surveyFormQuestion.question,
                                    surveyFormQuestion: detail.surveyFormQuestion,
                                    surveyFormAnswer: detail.surveyFormAnswer==null ? null : this.formBuilder.array(detail.surveyFormAnswer.map(ans => this.formBuilder.group({
                                        id: ans.id,
                                        answerImage: ans.answerImage,
                                        score: ans.score
                                    }))),
                                    answerURL: this.processImage(detail.surveyFormAnswer==null ? undefined : detail.surveyFormAnswer[0].answerImage),
                                    answers: this.formBuilder.array(detail.surveyFormQuestion.surveyFormAnswers.map(ans => this.formBuilder.group({
                                        id: ans.id,
                                        answerImage: ans.answerImage,
                                        score: ans.score,
                                        selected: false,
                                    }))),
                                    remark: detail.remark,
                                }))),
                            });
                            this.ssiFormGroup.disable();
                            }else{
                            this.dialogType = 'create';
                            this.ssiFormGroup = this.formBuilder.group({
                                id: this.surveyHeaderId,
                                phoneNumber : this.formBuilder.control(null, [Validators.required, Validators.pattern(REGEXS.PHONE_FILTER)]),
                                remark: this.formBuilder.control(null),
                                surveyDetails: this.formBuilder.array(value.body.surveyDetails.map(detail =>this.formBuilder.group({
                                    id: detail.id,
                                    question: detail.surveyFormQuestion.question,
                                    surveyFormQuestion: detail.surveyFormQuestion,
                                    surveyFormAnswer: [],
                                    answers: this.formBuilder.array(detail.surveyFormQuestion.surveyFormAnswers.map(ans => this.formBuilder.group({
                                        id: ans.id,
                                        answerImage: ans.answerImage,
                                        answerURL: this.processImage(ans.answerImage),
                                        score: ans.score,
                                        selected: false,
                                    }))),
                                    remark: detail.remark,
                                }))),
                            });
                            this.disableCheckbox = value.body.surveyDetails.map(x => false);
                            if(!this.surveyModel.isActive) {
                                this.ssiFormGroup.disable();
                            }
                            }
                            this.deactiveLoading();
                        }
                    });
                }
            }
        })
    }

    checkLanguage(brand: string){
        switch(brand){
            case this.brandToyota:
                this.formContent = SSI_FORM_CONTENT.english_toyota;
                this.formContentM = SSI_FORM_CONTENT.malay_toyota;
                break;
            case this.brandLexus:
                this.formContent = SSI_FORM_CONTENT.english_lexus;
                this.formContentM = SSI_FORM_CONTENT.malay_lexus;
                break;
            default:
                this.formContent = SSI_FORM_CONTENT.english_toyota;
                this.formContentM = SSI_FORM_CONTENT.malay_toyota;
        }
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    formArraySurvey() {
        const array = (this.ssiFormGroup.get('surveyDetails') as FormArray).controls;
        return array;
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    questionChecking(question: String, langInd: number){
        return question.split('|')[langInd];
    }

    saveSurvey():void{
        this.activeLoading();
        this.surveyService.updateSurvey(this.ssiFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
            next: () => {
                this.openDialog();
            },
            error: error => {
                this.isSaving = false;
                let theMessage  = '';
                error.error.subErrors?.forEach(subError => {
                    theMessage += subError.message + ' \n ';
                });
                if(theMessage){
                    this.snackBar.open(theMessage, this.close, this.snackBarConfig);
                }else{
                    this.snackBar.open(error?.error?.message, this.close, this.snackBarConfig);
                }
                this.deactiveLoading();
            }
        });
    }

    processImage(imageName: any){
        let image;
        if(imageName != undefined){
            image = this.imageEnum.find((image) => image.name === imageName).value;
        }
        if(image===undefined){
        image = this.na;
        }
        return image;
    }

    back() {
        window.history.back();
    }

    checkRadio(event:boolean, i:number){
        const array = (this.ssiFormGroup.get('surveyDetails') as FormArray);
        if(event){
            array.controls[i].patchValue({ surveyFormAnswer:null });
            array.controls[i].get('surveyFormAnswer').disable();
            array.controls[i].get('remark').disable();
        }else{
            array.controls[i].get('surveyFormAnswer').enable();
            array.controls[i].get('remark').enable();
        }
    }

    openDialog() {
        const dialogRef = this.dialog.open(DialogContentExampleDialog);
        dialogRef.afterClosed().subscribe(result => {
            this.deactiveLoading();
            this.back();
        });
    }

    updateSelectedCheckboxAnswer(event:boolean, surveyAnswer: any, i:number): void {
        const array = (this.ssiFormGroup.get('surveyDetails') as FormArray);
        var currentQuestionAnswer = [];
        if(this.ssiFormGroup.get('surveyDetails').value[i]?.surveyFormAnswer) {
            currentQuestionAnswer = this.ssiFormGroup.get('surveyDetails').value[i]?.surveyFormAnswer;
        }

        surveyAnswer.selected = event;
        if(event){
           currentQuestionAnswer.push(surveyAnswer);
           array.controls[i].patchValue({ surveyFormAnswer: currentQuestionAnswer });
        } else {
           currentQuestionAnswer = currentQuestionAnswer.filter(item => item.id !== surveyAnswer.id);
           array.controls[i].patchValue({ surveyFormAnswer: currentQuestionAnswer });
        }

        this.disableCheckbox[i] = this.ssiFormGroup.get('surveyDetails').value[i]?.surveyFormAnswer?.length === 3;
    }

    checkIfContainsOtherAnswer(i: number): boolean {
        return this.ssiFormGroup.get('surveyDetails').value[i]?.surveyFormAnswer?.filter(o=>o.answerImage==='OTHER').length>0;
    }

    isNASize(answer: string) {
        if(!answer)
            return false;
        return answer.includes("na.png");
    }

    modification() {
        const modalRef = this.dialog.open(SalesDialogNewOrEditComponent, {
            data: this.surveyModel,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.ngOnInit();
            }
        })
    }
}

@Component({
    selector: 'dialog-content-example-dialog',
    templateUrl: 'dialog-content-example-dialog.html',
})
export class DialogContentExampleDialog {}
