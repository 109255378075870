import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { SlaModel } from 'src/app/model/sla.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { BusinessHoursService } from 'src/app/services/business-hours.service';
import { DepartmentService } from 'src/app/services/department.service';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { SlaService } from 'src/app/services/sla.service';
import { DialogSimpleComponent } from 'src/app/shared/dialog-simple/dialog-simple.component';
import { SlaDialogDetailComponent } from '../sla-dialog-detail/sla-dialog-detail.component';
import { SlaDialogNewOrEditComponent } from '../sla-dialog-new-or-edit/sla-dialog-new-or-edit.component';

@Component({
    selector: 'app-sla-listing',
    templateUrl: './sla-listing.component.html',
    styleUrls: ['./sla-listing.component.scss']
})
export class SlaListingComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    readonly CREATE_SLA = ApplicationPermissionsService.CREATE_SLA;
    readonly UPDATE_SLA = ApplicationPermissionsService.UPDATE_SLA;
    readonly DELETE_SLA = ApplicationPermissionsService.DELETE_SLA;
    searchFormGroup: FormGroup;
    numberOfRecords: number = 0;
    dataSource: MatTableDataSource<SlaModel> = new MatTableDataSource<SlaModel>();
    pageSize: number = 20;
    currentPage: number = 0;
    displayColumns: string[] = [
        'name',
        'description',
        'actions'
    ]
    institutions: InstitutionModel[] = [];
    sortOrder: string = 'name,asc';
    obs: BehaviorSubject<SlaModel[]>;
    loading: boolean = true;

    constructor(
        private departmentService: DepartmentService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private institutionsService: InstitutionsService,
        public authService: AuthService,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private slaService: SlaService,
        private businessHourService: BusinessHoursService
    ) { }

    ngOnInit(): void {
        this.institutionsService.getAll().subscribe({
            next: (value) => (this.institutions = value.body),
        });

        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.currentPage = this.activatedRoute.snapshot.queryParams['page'] ? this.activatedRoute.snapshot.queryParams['page'] : undefined;
            this.sortOrder = this.activatedRoute.snapshot.queryParams['sort'] ? this.activatedRoute.snapshot.queryParams['sort'] : undefined;
            this.pageSize = this.activatedRoute.snapshot.queryParams['pageSize'] ? this.activatedRoute.snapshot.queryParams['pageSize'] : undefined;
        }
        this.createForm();
        this.refreshForm();
        this.dataSource.paginator = this.paginator;
    }

    private createForm() {
        let name: string;
        let institution: string;
        let createdDate: string;
        let updatedDate: string;

        if (this.activatedRoute.snapshot.queryParams['page']) {
            name = this.activatedRoute.snapshot.queryParams['name']
                ? this.activatedRoute.snapshot.queryParams['name']
                : null;
            institution = this.activatedRoute.snapshot.queryParams['institutions']
                ? this.activatedRoute.snapshot.queryParams['institutions']
                : null;
            createdDate = this.activatedRoute.snapshot.queryParams['createdDate']
                ? this.activatedRoute.snapshot.queryParams['createdDate']
                : null;
            updatedDate = this.activatedRoute.snapshot.queryParams['updatedDate']
                ? this.activatedRoute.snapshot.queryParams['updatedDate']
                : null;
        }

        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(name ? name : null),
            searchInstitution: this.formBuilder.control(
                institution ? institution : null
            ),
            searchCreatedDate: this.formBuilder.control(
                createdDate ? createdDate : null
            ),
            searchUpdatedDate: this.formBuilder.control(
                updatedDate ? updatedDate : null
            ),
        });
    }

    openSlaDialogCreateOrEdit(slaModel?: SlaModel) {
        const modalRef = this.matDialog.open(SlaDialogNewOrEditComponent, {
            data: slaModel,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
            if (yes) this.search(0);
        }})
    }

    refreshForm() {
        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(null),
            searchInstitution: this.formBuilder.control(null),
            searchCreatedDate: this.formBuilder.control(null),
            searchUpdatedDate: this.formBuilder.control(null),
        });
        this.search(0);
    }

    search(currentPage: number) {
        let name: string;
        let institution: string;
        let createdDate: string;
        let updatedDate: string;

        name = this.searchFormGroup.controls.searchName.value
            ? this.searchFormGroup.controls.searchName.value
            : null;

        institution = this.searchFormGroup.controls.searchInstitution.value
            ? this.searchFormGroup.controls.searchInstitution.value
            : null;

        this.slaService.getAll(
            this.currentPage,
            this.pageSize,
            this.sortOrder,
            {
                name: name,
                institution: institution,
                createdDate: createdDate ? createdDate : null,
                updatedDate: updatedDate ? updatedDate : null,
                currentInstitution: this.authService.getCurInsId()
            }
        ).subscribe({
            next: (value) => {
                this.dataSource.data = value.body;
                this.loading = false;
                this.numberOfRecords = Number(value.headers.get('X-Total-Count'));
            },
            error: (value) => {
                this.snackBar.open(value.error.message, 'Close', {
                    duration: 60000
                });
            },
        });
    }

    navigateToPage(event): void {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        this.search(this.currentPage);
    }

    openSlaDialogDetail(slaModel?: SlaModel) {
        const modalRef = this.matDialog.open(SlaDialogDetailComponent, {
            data: slaModel,
            panelClass: 'mat-dialog-md'
        })

        modalRef.afterClosed().subscribe({
            next: (edit) => {
                if (edit) this.openSlaDialogCreateOrEdit(slaModel);
            }
        })
    }

    openSlaDialogDelete(slaModel: SlaModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete SLA Policy?',
                message: `Are you sure want to delete ${slaModel.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        })

        modalRef.afterClosed().subscribe((yes) => {
            if (yes) {
                this.slaService.delete(slaModel.id.toString(), this.authService.getCurInsId()).subscribe({
                    next: () => {
                        this.search(0);
                    },
                    error: (value) => {
                        this.search(0);
                        if (value.error.message) this.snackBar.open(value.error.message, 'Close', {
                            duration: 60000
                        });
                    },
                });
            }
        })
    }

    sortPage(event): void {
        this.sortOrder = `${event.active},${event.direction}`;
        this.search(this.currentPage);
    }

}
