import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormGroup } from '@angular/forms';
import { ApplicationRoleModel } from 'src/app/model/application-role.model';

@Directive({
  selector: '[appApplicationPermissionSelected]'
})
export class ApplicationPermissionSelectedDirective implements OnChanges {

  @Input() applicationRoleFormGroupInput: FormGroup;
  @Input() applicationPermissionModelNameInput: string;

  constructor(private checkbox: MatCheckbox) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const applicationRoleFormGroup = changes.applicationRoleFormGroupInput.currentValue as FormGroup;
    const applicationPermissionModelName = changes.applicationPermissionModelNameInput.currentValue as string;
    const applicationRoleModel = applicationRoleFormGroup.getRawValue() as ApplicationRoleModel;
    const configuredApplicationPermissionModels = applicationRoleModel.applicationPermissions;

    this.checkbox.writeValue(configuredApplicationPermissionModels.find(value => value.name === applicationPermissionModelName));
  }
}
