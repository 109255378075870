<div class="container-fluid nbt-container">
    <div class="nbt-header">
        <div class="nbt-header-title">SLA Policy</div>
        <div class="nbt-btn-right">
            <button class="nbt-btn-primary" appScreenPermission [permissionsInput]="[CREATE_SLA]"
                (click)="openSlaDialogCreateOrEdit()">
                <mat-icon class="mr8">add</mat-icon>
                Add SLA Policy
            </button>
        </div>
    </div>
    <br>
    <form [formGroup]="searchFormGroup" (ngSubmit)="search(0)">
        <div class="nbt-form nbt-search-bar">
            <mat-form-field appearance="outline">
                <input matInput id="username" formControlName="searchName" placeholder="Search name" />
                <mat-icon class="mr8 close" (click)="refreshForm()"
                    *ngIf="searchFormGroup?.value?.searchName?.length > 0">close</mat-icon>
                <mat-icon class="mr8 search" (click)="search(0)">search</mat-icon>
            </mat-form-field>
        </div>
    </form>
    <br>
    <div *ngIf="loading" class="nbt-empty-result">
        <div class="nbt-data-loading"></div>
    </div>
    <div *ngIf="!loading">
        <div><b>{{numberOfRecords}}</b> result<span *ngIf="numberOfRecords > 1">s</span></div>
        <br>
        <mat-card-content class="mat-card-content-table">
            <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="dataSource" *ngIf="dataSource.data">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.name || '-'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Description
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.description || '-'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_SLA)" (click)="openSlaDialogCreateOrEdit(element)" matTooltip="Edit">edit</mat-icon>
                        <mat-icon *ngIf="authService.hasAuthorityMenu(DELETE_SLA)" (click)="openSlaDialogDelete(element)" matTooltip="Delete">delete</mat-icon>
                        <mat-icon (click)="openSlaDialogDetail(element)" matTooltip="View">remove_red_eye</mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
            </table>
        </mat-card-content>
        <mat-paginator (page)="navigateToPage($event)" [length]="numberOfRecords" [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
        </mat-paginator>
    </div>
</div>