import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { Model } from 'src/app/model/model.model';
import { SystemConfigurationModel, SYS_CONFIG } from 'src/app/model/system-configuration.model';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { VehicleModelService } from 'src/app/services/vehicle-model.service';

@Component({
    selector: 'app-vehicle-model-dialog-new-or-edit',
    templateUrl: './vehicle-model-dialog-new-or-edit.component.html',
    styleUrls: ['./vehicle-model-dialog-new-or-edit.component.scss']
})
export class VehicleModelDialogNewOrEditComponent implements OnInit {

    vehicleModelFormGroup: FormGroup;
    dialogType: string = '';
    isSaving: boolean = false;
    sysConfig: SystemConfigurationModel[];
    sysConfigBrand = SYS_CONFIG.BRAND;
    loading: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public vmodel: Model,
        public dialogRef: MatDialogRef<VehicleModelDialogNewOrEditComponent>,
        private formBuilder: FormBuilder,
        private vehicleModelService: VehicleModelService,
        public authService: AuthService,
        private snackBar: MatSnackBar,
        private sysConfigService: SystemConfigurationService

    ) { }

    ngOnInit(): void {
        if (this.vmodel) {
            this.dialogType = 'update';
            this.vehicleModelFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.vmodel.id),
                brand: this.formBuilder.control(this.vmodel.brand, Validators.required),
                name: this.formBuilder.control(this.vmodel.name, Validators.required),
                active: this.formBuilder.control(this.vmodel.active)
            })
        } else {
            this.dialogType = 'create';
            this.vehicleModelFormGroup = this.formBuilder.group({
                brand: this.formBuilder.control(null, Validators.required),
                name: this.formBuilder.control(null, Validators.required),
                active: this.formBuilder.control(true),
            });
        }
        this.sysConfigService.getSystemConfigurationValueByCode(this.sysConfigBrand, this.authService.getCurInsId()).subscribe({
            next: (value) => {
                this.sysConfig = value.body;
                this.deactiveLoading();
            }
        });
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    save() {
        this.isSaving = true;
        this.activeLoading();
        if (this.dialogType === 'create') {
            this.vehicleModelService.create(this.vehicleModelFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                      });
                }
            })
        } else {
            this.vehicleModelService.update(this.vehicleModelFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000
                      });
                }
            })
        }
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1 === object2;
    }
}
