import { Component, OnInit, ViewChild  } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatExpansionPanelHeader } from '@angular/material/expansion';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { DepartmentModel } from 'src/app/model/department.model';
import { InstitutionModel } from 'src/app/model/institution.model';
import { SurveyModel } from 'src/app/model/survey.model';
import { ApplicationPermissionsService } from 'src/app/services/application-permissions.service';
import { DepartmentService } from 'src/app/services/department.service';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { SurveyService } from 'src/app/services/survey.service';
import { UsersService } from 'src/app/services/users.service';
import { SnackBarStatus, SurveyType } from 'src/app/shared/constant';

@Component({
    selector: 'app-sales-listing',
    templateUrl: './sales-listing.component.html',
    styleUrls: ['./sales-listing.component.scss']
})

export class SalesListingComponent implements OnInit {
    readonly CREATE_SSI = ApplicationPermissionsService.CREATE_SSI;
    readonly READ_INDIVIDUAL_SSI = ApplicationPermissionsService.READ_INDIVIDUAL_SSI;
    readonly READ_FULL_SSI = ApplicationPermissionsService.READ_FULL_SSI;
    readonly snackBarConfig: MatSnackBarConfig = { duration:60000 };

    dataSource: MatTableDataSource<SurveyModel> = new MatTableDataSource<SurveyModel>();
    dataSourceReceived: MatTableDataSource<SurveyModel> = new MatTableDataSource<SurveyModel>();

    loading: boolean = false;
    receiveLoading: boolean = false;
    displayColumnsIssue: string[] = [
        'ssiNo',
        'regNo',
        'issuedBy',
        'salesConsultant',
        'issuedDate',
        'custLinked',
        'isActive',
        'actions',
        ];

    displayColumns: string[] = [
        'ssiNo',
        'regNo',
        'issuedBy',
        'salesConsultant',
        'issuedDate',
        'receivedDate',
        'custLinked',
        'actions',
        ];

    sortOrder: string = '';
    receivedRecords: number = 0;
    currentPage: number = 0;
    pageSize: number = 20;

    currentPageIssue: number = 0;
    issuingRecords: number = 0;
    pageSizeIssue: number = 20;
    sortOrderIssue: string = '';

    selectedDepartment: number = null;
    selectedSalesConsultant: number = null;

    institutions: InstitutionModel[] = [];
    surveyRecords : SurveyModel[];
    departments: DepartmentModel[];
    salesConsultants: ApplicationUserModel[];
    ssi = SurveyType.SSI;
    close = SnackBarStatus.CLOSE;

    @ViewChild('paginatorIssue') paginatorIssue: MatPaginator;
    @ViewChild('paginatorReceive') paginatorReceive: MatPaginator;

    constructor(
        private institutionsService:InstitutionsService,
        private departmentService: DepartmentService,
        private userService: UsersService,
        private authService: AuthService,
        public activatedRoute: ActivatedRoute,
        private surveyService : SurveyService,
        private snackBar: MatSnackBar,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.selectedDepartment=null;
        this.selectedSalesConsultant=null;

        this.activeLoading();
        this.activeReceiveLoading();
        this.institutionsService.getAll().subscribe({
            next: (value) => (this.institutions = value.body),
        });

        //GET ALL ISSUING
        this.getSurveyRecord(this.currentPageIssue,this.pageSizeIssue, this.sortOrderIssue,this.ssi,false,null,null);

        //GET ALL RECEIVED
        this.getSurveyRecord(this.currentPage,this.pageSize, this.sortOrder,this.ssi,true,null,null);

        // GET All ShowRoom
        this.getSsiDepartments();
    }

    getSurveyRecord(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        surveyType: string,
        isReceive: boolean,
        departmentId: number,
        salesConsultantId: number){
        this.surveyService.getAllSurveyRecord(pageNumber,pageSize, sortOrder,surveyType,isReceive,departmentId,salesConsultantId, null).subscribe({
            next: (value) =>{
                if(isReceive === false){
                    this.dataSource.data = value.body,
                    this.issuingRecords = Number(value.headers.get('X-Total-Count'));
                    this.deactiveLoading();
                }else{
                    this.dataSourceReceived.data = value.body,
                    this.receivedRecords = Number(value.headers.get('X-Total-Count'));
                    this.deactiveReceiveLoading();
                }
            },
            error: (value) => {
                this.snackBar.open(value.error.message, this.close, this.snackBarConfig);
                this.deactiveLoading();
                this.deactiveReceiveLoading();
            },
        });
    }

    sortPageIssue(event): void {
        this.sortOrderIssue = `${event.active},${event.direction}`;
    }

    sortPage(event): void {
        this.sortOrder = `${event.active},${event.direction}`;
    }

    navigateToPageIssued(event): void {
        this.currentPageIssue = event.pageIndex;
        this.pageSizeIssue = event.pageSize;
        this.getSurveyRecord(event.pageIndex,event.pageSize, this.sortOrder,this.ssi,false,this.selectedDepartment,this.selectedSalesConsultant);
    }

    navigateToPage(event): void {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getSurveyRecord(event.pageIndex,event.pageSize, this.sortOrder,this.ssi,true,this.selectedDepartment,this.selectedSalesConsultant);
    }

    refreshForm(currentPage: number){
        //GET ALL ISSUING
        this.getSurveyRecord(this.currentPageIssue,this.pageSizeIssue, this.sortOrder,this.ssi,false,null,null);

        //GET ALL RECEIVED
        this.getSurveyRecord(this.currentPage,this.pageSize, this.sortOrder,this.ssi,true,null,null);
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    activeReceiveLoading(){
        this.receiveLoading = true;
    }

    deactiveReceiveLoading(){
        this.receiveLoading = false;
    }

    openSsiDialogCreateOrEdit(surveyModel?: SurveyModel) {
        this.surveyService.surveyValue = surveyModel;
        this.router.navigate(['/operations/sales-satisfactory-new-page'], {
            queryParams: {
                id: surveyModel?.id
            }
        })
    }

    openSurveyForm(surveyModel?: SurveyModel){
        this.router.navigate(['/operations/sales-satisfactory-form'], {
            queryParams: { id: surveyModel.id }
        })
    }

    selectDepartment(event: any, el: MatExpansionPanelHeader){
        if(!el._isExpanded()) {
            this.ngOnInit();
            return;
        }
        this.activeLoading();
        this.activeReceiveLoading();
        this.selectedDepartment = event.id;
        this.selectedSalesConsultant = null;

        this.userService.getAllSurveySalesConsultantByDepartment(event.id,SurveyType.SSI).subscribe({
        next: (value) => {
                this.salesConsultants = value.body;
            }
        });

        //GET ALL ISSUING
        this.getSurveyRecord(0,this.pageSizeIssue, this.sortOrder,this.ssi,false,event.id,null);

        //GET ALL RECEIVED
        this.getSurveyRecord(0,this.pageSize, this.sortOrder,this.ssi,true,event.id,null);
    }

    filterList(sc: any, department: any){
        this.activeLoading();
        this.activeReceiveLoading();
        this.selectedDepartment = department.id;
        this.selectedSalesConsultant = sc.id;

        //GET ALL ISSUING
        this.getSurveyRecord(0,this.pageSizeIssue, this.sortOrder,this.ssi,false,this.selectedDepartment,this.selectedSalesConsultant);

        //GET ALL RECEIVED
        this.getSurveyRecord(0,this.pageSize, this.sortOrder,this.ssi,true,this.selectedDepartment,this.selectedSalesConsultant);
    }

    getSsiDepartments() {
        this.departmentService.getSurveyDepartments(null, false, true).subscribe({
            next: (res) => {
                this.departments = res.body;
            },
            error: (res) => {
                this.snackBar.open(
                    res.error.message,
                    'Close',
                    this.snackBarConfig
                );
            },
        });
    }

    isEnableViewDetail(salesConsultantUsername : string) {
        return this.authService.getUsername() === salesConsultantUsername;
    }

}
