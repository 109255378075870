import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Authority, AuthService } from '../../auth/auth.service';

@Directive({
  selector: '[appScreenPermission]',
})
export class ScreenPermissionDirective implements OnChanges {
  @Input() permissionsInput: string[];

  constructor(
    private authService: AuthService,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const permissions: string[] = changes.permissionsInput.currentValue;
    const authorities = this.authService.getAuthorities();

    this.elementRef.nativeElement.hidden = this.isHidden(
      permissions,
      authorities
    );
  }

  isHidden(permissions: string[], authorities: Authority[]): boolean {
    for (const permission of permissions) {
      if (
        !authorities.some((authority) => authority.authority === permission)
      ) {
        return true;
      }
    }
    return false;
  }
}
