import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriesListingComponent } from './categories-listing/categories-listing.component';
import { CategoriesDialogNewOrEditComponent } from './categories-dialog-new-or-edit/categories-dialog-new-or-edit.component';
import { GroupsDialogNewOrEditComponent } from './groups-dialog-new-or-edit/groups-dialog-new-or-edit.component';
import { TypesDialogNewOrEditComponent } from './types-dialog-new-or-edit/types-dialog-new-or-edit.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { SharedModule } from 'src/app/shared/shared.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  {
      path: 'categories',
      component: CategoriesListingComponent,
      canActivate: [
          AuthenticatedGuard
      ]
  }
];

@NgModule({
  declarations: [
    CategoriesListingComponent,
    CategoriesDialogNewOrEditComponent,
    GroupsDialogNewOrEditComponent,
    TypesDialogNewOrEditComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DirectivesModule,
    RouterModule.forChild(routes),
    FormsModule
  ]
})
export class CategoriesModule { }
