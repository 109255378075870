import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { IMAGE_ENUM } from 'src/app/shared/constant/image-enum.constant';
import { TOOLTIPS } from 'src/app/shared/constant/tooltips-enum.constant';
import { Model } from 'src/app/model/model.model';
import { AuthService } from 'src/app/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { MysteryShopperModel, MysteryShopperSalesConsultant } from 'src/app/model/mystery-shopper.model';
import { MysteryShopperService } from 'src/app/services/mystery-shopper.service';
import { VoiceOfCustomerDialogPreviewImageComponent } from 'src/app/components/operations/voice-of-customer/voice-of-customer-dialog-preview-image/voice-of-customer-dialog-preview-image.component';
import { dataURLtoBlob } from 'src/app/shared/util';
import { UpdateMysteryShopperModel } from 'src/app/model/mystery-shopper-update.model';
import { CustomerRecordsMysteryShopperDetailDialogSubmitComponent } from '../customer-records-mystery-shopper-detail-dialog-submit/customer-records-mystery-shopper-detail-dialog-submit.component';

@Component({
    selector: 'app-customer-records-mystery-shopper-detail',
    templateUrl: './customer-records-mystery-shopper-detail.component.html',
    styleUrls: ['./customer-records-mystery-shopper-detail.component.scss'],
})
export class CustomerRecordsMysteryShopperDetailComponent implements OnInit {

    readonly snackBarConfig: MatSnackBarConfig = { duration: 60000 };
    close = "Close";

    attContent:string = TOOLTIPS.attContent;
    imageEnum = IMAGE_ENUM;

    msIsReceived: boolean = false;
    currentDate :Date;
    localCurrentDate: string;
    sortOrder: string = 'username,asc';

    digitalSalesConsultant: ApplicationUserModel[];
    digitalSalesConsultantRecords: ApplicationUserModel[];
    totalDigitalSalesConsultant = 0;
    currentTotalDigitalSalesConsultant = 0;
    pageDigitalSalesConsultant = 0;
    sizeDigitalSalesConsultant = 20;
    digitalSalesConsultantControl = new FormControl('', Validators.required);

    showroomSalesConsultant: ApplicationUserModel[];
    showroomSalesConsultantRecords: ApplicationUserModel[];
    totalShowroomSalesConsultant = 0;
    currentTotalShowroomSalesConsultant = 0;
    pageShowroomSalesConsultant = 0;
    sizeShowroomSalesConsultant = 20;
    showroomSalesConsultantControl = new FormControl('', Validators.required);

    phoneReceptionStaffList: ApplicationUserModel[];
    phoneSalesConsultantStaffList: ApplicationUserModel[];

    mysteryShopperModel: MysteryShopperModel;
    token: string;
    departmentId: string;
    detailsFormGroup: FormGroup;

    appointmentPhoneSectionFormGroup: FormGroup;
    appointmentDigitalSectionFormGroup: FormGroup;
    welcomeSectionFormGroup: FormGroup;
    consultationSectionFormGroup: FormGroup;
    demonstrationSectionFormGroup: FormGroup;
    communicationSectionFormGroup: FormGroup;
    agreementAndPotentialPurchaseSectionFormGroup: FormGroup;
    presentationSectionFormGroup: FormGroup;

    modelsRequested: Model[];
    modelsOffered: Model[];
    loading:boolean = true;

    appointmentPhoneSectionAttachments: any[] = [];
    appointmentPhoneSectionAttachmentUrls: any[] = [];
    appointmentDigitalSectionAttachments: any[] = [];
    appointmentDigitalSectionAttachmentUrls: any[] = [];
    welcomeSectionAttachments: any[] = [];
    welcomeSectionAttachmentUrls: any[] = [];
    consultationSectionAttachments: any[] = [];
    consultationSectionAttachmentUrls: any[] = [];
    demonstrationSectionAttachments: any[] = [];
    demonstrationSectionAttachmentUrls: any[] = [];
    communicationSectionAttachments: any[] = [];
    communicationSectionAttachmentUrls: any[] = [];
    presentationSectionAttachments: any[] = [];
    presentationSectionAttachmentUrls: any[] = [];

    remarkControl = new FormControl('', Validators.required);

    updateMysteryShopperModel: UpdateMysteryShopperModel;

    isShowCancelButton: boolean = true;

    dummySalesConsultant =
    {
        id: -2,
        username: 'No Name',
        password: null, newPassword: null,
        repeatPassword: null, code: null,firstName: null,lastName: null,
        phone: null,email: null,gender: null,applicationPosition: null,
        active: null,isChampion:null,key: null,applicationRoles: null,
        institutions: null,departments: null,clearance: null,
    }

    constructor(
        private route: ActivatedRoute,
        private matDialog: MatDialog,
        private router: Router,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private sanitizer: DomSanitizer,
        private datePipe: DatePipe,
        private mysteryShopperService: MysteryShopperService,
        private authService : AuthService
    ) {}

    ngOnInit(): void {
        this.appointmentPhoneSectionAttachmentUrls = [];
        this.appointmentDigitalSectionAttachmentUrls = [];
        this.welcomeSectionAttachmentUrls = [];
        this.consultationSectionAttachmentUrls = [];
        this.demonstrationSectionAttachmentUrls = [];
        this.communicationSectionAttachmentUrls = [];
        this.presentationSectionAttachmentUrls = [];

        this.activeLoading();
        this.currentDate = new Date();
        this.localCurrentDate = new Date(this.currentDate.getTime() - (this.currentDate.getTimezoneOffset() * 60000)).toISOString();

        if(this.authService.getUser()==null) {
            this.isShowCancelButton = false;
        }

        this.route.queryParams.subscribe({
            next: (param) => {
                if (param.token) {
                    this.token = param.token;
                }
            },
        });

        this.mysteryShopperService.getMysteryShopperDetail(this.token).subscribe({
            next: (value) => {
                this.mysteryShopperModel = value.body;
                this.departmentId = this.mysteryShopperModel.department.id;
                this.msIsReceived = this.mysteryShopperModel.isReceive;

                const msStartDate = new Date(this.mysteryShopperModel.startDate);
                msStartDate.setHours(0,0,0,0);
                const msEndDate = new Date(this.mysteryShopperModel.endDate);
                msEndDate.setHours(0,0,0,0);
                const dateTimeNow = new Date();
                dateTimeNow.setHours(0,0,0,0);

                if(dateTimeNow < msStartDate || dateTimeNow > msEndDate) {
                    this.msIsReceived = true;
                }

                this.mysteryShopperService.getAllMysteryShopperVehicleList(this.token).subscribe({
                    next: (value) => {
                        this.modelsRequested = value.body;
                        this.modelsOffered = value.body;
                    },
                    error: (error) => {
                        this.snackBar.open(error.error.message, 'Close', {
                            duration: 60000,
                        });
                    },
                });

                this.searchDigitalSalesConsultant();
                this.searchShowroomSalesConsultant();
                this.getSalesConsultantDropdown();

                this.buildDefaultFormGroup();
                this.deactiveLoading();
            },
            error: (error) => {
                this.snackBar.open(error.error.message, 'Close', {
                    duration: 60000,
                });
            },
        });
    }

    buildDefaultFormGroup() {
        var phoneRs = this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'PHONE_RS')?.salesConsultant;
        var phoneSc = this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'PHONE_SC')?.salesConsultant;
        var digitalSc = this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'DIGITAL')?.salesConsultant;
        var showroomSc = this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'SHOWROOM')?.salesConsultant;

        if(phoneRs==null) phoneRs = this.dummySalesConsultant;
        if(phoneSc==null) phoneSc = this.dummySalesConsultant;
        if(digitalSc==null) digitalSc = this.dummySalesConsultant;
        if(showroomSc==null) showroomSc = this.dummySalesConsultant;

        this.digitalSalesConsultantControl = new FormControl(digitalSc, Validators.required);
        this.showroomSalesConsultantControl = new FormControl(showroomSc, Validators.required);

        this.detailsFormGroup = this.formBuilder.group({
            phoneReceptionStaff: this.formBuilder.control(phoneRs, Validators.required),
            phoneSalesConsultantStaff: this.formBuilder.control(phoneSc, Validators.required),
            phoneDatetimeString: this.formBuilder.control(this.checkDate(this.mysteryShopperModel.phoneDatetime, "PHONE"), Validators.required),
            digitalSalesConsultant: this.digitalSalesConsultantControl,
            digitalSocialMedia: this.formBuilder.control(this.mysteryShopperModel.digitalSocialMedia),
            digitalDatetimeString: this.formBuilder.control(this.checkDate(this.mysteryShopperModel.digitalDatetime, "DIGITAL"), Validators.required),
            showroomSalesConsultant: this.showroomSalesConsultantControl,
            showroomDatetimeString: this.formBuilder.control(this.checkDate(this.mysteryShopperModel.showroomDatetime, "SHOWROOM"), Validators.required),
            modelRequested: this.formBuilder.control(this.mysteryShopperModel.modelRequested, Validators.required),
            modelOffered: this.formBuilder.control(this.mysteryShopperModel.modelRecommended, Validators.required),
        });

        let section = this.mysteryShopperModel.mysteryShopperSections.find(el => el.sectionType === 'APPOINTMENT_PHONE');
        if(section) {   this.appointmentPhoneSectionFormGroup = this.buildDefaultSectionFormGroup(section); }
        section = this.mysteryShopperModel.mysteryShopperSections.find(el => el.sectionType === 'APPOINTMENT_DIGITAL');
        if(section) {   this.appointmentDigitalSectionFormGroup = this.buildDefaultSectionFormGroup(section);   }
        section = this.mysteryShopperModel.mysteryShopperSections.find(el => el.sectionType === 'WELCOME');
        if(section) {   this.welcomeSectionFormGroup = this.buildDefaultSectionFormGroup(section);  }
        section = this.mysteryShopperModel.mysteryShopperSections.find(el => el.sectionType === 'CONSULTATION');
        if(section) {   this.consultationSectionFormGroup = this.buildDefaultSectionFormGroup(section);    }
        section = this.mysteryShopperModel.mysteryShopperSections.find(el => el.sectionType === 'DEMONSTRATION');
        if(section) {   this.demonstrationSectionFormGroup = this.buildDefaultSectionFormGroup(section);    }
        section = this.mysteryShopperModel.mysteryShopperSections.find(el => el.sectionType === 'COMMUNICATION');
        if(section) {   this.communicationSectionFormGroup = this.buildDefaultSectionFormGroup(section);    }
        section = this.mysteryShopperModel.mysteryShopperSections.find(el => el.sectionType === 'AGREEMENT_AND_POTENTIAL_PURCHASE');
        if(section) {   this.agreementAndPotentialPurchaseSectionFormGroup = this.buildDefaultSectionFormGroup(section);    }
        section = this.mysteryShopperModel.mysteryShopperSections.find(el => el.sectionType === 'PRESENTATION');
        if(section) {   this.presentationSectionFormGroup = this.buildDefaultSectionFormGroup(section)  }

        this.remarkControl = new FormControl(this.mysteryShopperModel.remark, Validators.required);
    }

    buildDefaultSectionFormGroup(section: any) {
        if (section.mysteryShopperAttachments?.length > 0) {
            for (let att of section.mysteryShopperAttachments) {
                this.mysteryShopperService.getAttachment(section.id, att.attachment).subscribe({
                    next: response => {
                        if(section.sectionType === "APPOINTMENT_PHONE") {
                            this.createImageFromBlob(response.body, att.actualName, 'APPOINTMENT_PHONE', att.id, att.attachment);
                        } else if(section.sectionType === "APPOINTMENT_DIGITAL") {
                           this.createImageFromBlob(response.body, att.actualName, 'APPOINTMENT_DIGITAL', att.id, att.attachment);
                        } else if(section.sectionType === "WELCOME") {
                           this.createImageFromBlob(response.body, att.actualName, 'WELCOME', att.id, att.attachment);
                        } else if(section.sectionType === "CONSULTATION") {
                           this.createImageFromBlob(response.body, att.actualName, 'CONSULTATION', att.id, att.attachment);
                        } else if(section.sectionType === "DEMONSTRATION") {
                           this.createImageFromBlob(response.body, att.actualName, 'DEMONSTRATION', att.id, att.attachment);
                        } else if(section.sectionType === "COMMUNICATION") {
                           this.createImageFromBlob(response.body, att.actualName, 'COMMUNICATION', att.id, att.attachment);
                        } else if(section.sectionType === "PRESENTATION") {
                           this.createImageFromBlob(response.body, att.actualName, 'PRESENTATION', att.id, att.attachment);
                        }
                    }
                })
            }
        }

        return this.formBuilder.group({
            id: section.id,
            score: section.score,
            remark: this.formBuilder.control(section.remark, Validators.required),
            sectionType: section.sectionType,
            mysteryShopperForm: section.mysteryShopperForm,
            mysteryShopperDetails: this.formBuilder.array(section.mysteryShopperDetails.map(detail =>this.formBuilder.group({
                id: detail.id,
                question: detail.mysteryShopperFormQuestion.question,
                mysteryShopperFormQuestion: detail.mysteryShopperFormQuestion,
                mysteryShopperFormAnswer: detail.mysteryShopperFormAnswer,
                answers: this.formBuilder.array(detail.mysteryShopperFormQuestion.mysteryShopperFormAnswers.map(ans => this.formBuilder.group({
                    id: ans.id,
                    answerImage: ans.answerImage,
                    answerURL: this.processImage(ans.answerImage),
                    score: ans.score
                }))),
            })))
        });
    }

    onScrollDigitalSalesConsultant() {
        if (this.totalDigitalSalesConsultant > this.currentTotalDigitalSalesConsultant) {
            this.pageDigitalSalesConsultant++;
            this.searchDigitalSalesConsultantRequest(false);
        }
    }

    onScrollShowroomSalesConsultant() {
        if (this.totalShowroomSalesConsultant > this.currentTotalShowroomSalesConsultant) {
            this.pageShowroomSalesConsultant++;
            this.searchShowroomSalesConsultantRequest(false);
        }
    }

    searchDigitalSalesConsultant() {
        this.digitalSalesConsultantRecords = [];
        this.digitalSalesConsultant = null;
        this.pageDigitalSalesConsultant = 0;
        this.currentTotalDigitalSalesConsultant = 0;
        if(this.digitalSalesConsultantControl.value) {
            this.searchDigitalSalesConsultantRequest(false);
        } else {
            this.searchDigitalSalesConsultantRequest(true);
        }
    }

    searchShowroomSalesConsultant() {
        this.showroomSalesConsultantRecords = [];
        this.showroomSalesConsultant = null;
        this.pageShowroomSalesConsultant = 0;
        this.currentTotalShowroomSalesConsultant = 0;
        if(this.showroomSalesConsultantControl.value) {
            this.searchShowroomSalesConsultantRequest(false);
        } else {
            this.searchShowroomSalesConsultantRequest(true);
        }
    }

    searchDigitalSalesConsultantRequest(getListWithNoName: boolean) {
        if(this.departmentId!=null) {
            this.mysteryShopperService
                .getAllMysteryShopperSalesConsultantList(
                    this.sortOrder,
                    this.pageDigitalSalesConsultant,
                    this.sizeDigitalSalesConsultant,
                    this.digitalSalesConsultantControl.value,
                    this.token)
                .subscribe({
                    next: (value) => {
                        this.totalDigitalSalesConsultant = Number(value.headers.get('X-Total-Count'));
                        this.currentTotalDigitalSalesConsultant = this.currentTotalDigitalSalesConsultant + value.body.length;

                        if(this.pageDigitalSalesConsultant==0 && getListWithNoName) {
                            this.totalDigitalSalesConsultant = this.totalDigitalSalesConsultant+1;
                            this.currentTotalDigitalSalesConsultant = this.currentTotalDigitalSalesConsultant+1;
                            this.digitalSalesConsultantRecords.push(this.dummySalesConsultant);
                        }

                        this.digitalSalesConsultantRecords.push(...value.body);
                    },
                    error: (error) => {
                        this.snackBar.open(error.error.message, 'Close', {
                            duration: 60000,
                        });
                    },
            });
        }
    }

    searchShowroomSalesConsultantRequest(getListWithNoName: boolean) {
        if(this.departmentId!=null) {
            this.mysteryShopperService
                .getAllMysteryShopperSalesConsultantList(
                    this.sortOrder,
                    this.pageShowroomSalesConsultant,
                    this.sizeShowroomSalesConsultant,
                    this.showroomSalesConsultantControl.value,
                    this.token)
                .subscribe({
                    next: (value) => {
                        this.totalShowroomSalesConsultant = Number(value.headers.get('X-Total-Count'));
                        this.currentTotalShowroomSalesConsultant = this.currentTotalShowroomSalesConsultant + value.body.length;

                        if(this.pageShowroomSalesConsultant==0 && getListWithNoName) {
                            this.totalShowroomSalesConsultant = this.totalShowroomSalesConsultant+1;
                            this.currentTotalShowroomSalesConsultant = this.currentTotalShowroomSalesConsultant+1;
                            this.showroomSalesConsultantRecords.push(this.dummySalesConsultant);
                        }

                        this.showroomSalesConsultantRecords.push(...value.body);
                    },
                    error: (error) => {
                        this.snackBar.open(error.error.message, 'Close', {
                            duration: 60000,
                        });
                    },
            });
        }
    }

    getSalesConsultantDropdown() {
        if(this.departmentId!=null) {
             this.mysteryShopperService.getAllMysteryShopperSalesConsultantListNoPagination(this.token)
                .subscribe({
                    next: (res) => {
                        this.phoneReceptionStaffList = res.body;
                        this.phoneSalesConsultantStaffList = res.body;
                    },
                    error: (error) => {
                        this.snackBar.open(error.error.message, 'Close', {
                            duration: 60000,
                        });
                    },
            });
        }
    }

    displayFn(salesConsultant: ApplicationUserModel): string {
        return salesConsultant && salesConsultant.username ? `${salesConsultant.username}` : '';
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    back() {
        window.history.back();
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    formArrayAppointmentPhoneSection() {
        return (this.appointmentPhoneSectionFormGroup.get('mysteryShopperDetails') as FormArray).controls;
    }

    formArrayAppointmentDigitalSection() {
        return (this.appointmentDigitalSectionFormGroup.get('mysteryShopperDetails') as FormArray).controls;
    }

    formArrayWelcomeSection() {
        return (this.welcomeSectionFormGroup.get('mysteryShopperDetails') as FormArray).controls;
    }

    formArrayConsultationSection() {
        return (this.consultationSectionFormGroup.get('mysteryShopperDetails') as FormArray).controls;
    }

    formArrayDemonstrationSection() {
        return (this.demonstrationSectionFormGroup.get('mysteryShopperDetails') as FormArray).controls;
    }

    formArrayCommunicationSection() {
        return (this.communicationSectionFormGroup.get('mysteryShopperDetails') as FormArray).controls;
    }

    formArrayAgreementAndPotentialPurchaseSection() {
        return (this.agreementAndPotentialPurchaseSectionFormGroup.get('mysteryShopperDetails') as FormArray).controls;
    }

    formArrayPresentationSection() {
        return (this.presentationSectionFormGroup.get('mysteryShopperDetails') as FormArray).controls;
    }

    questionChecking(question: String, langInd: number){
        return question.split('|')[langInd];
    }

    processImage(imageName: any){
        let image;
        if(imageName != undefined){
            image = this.imageEnum.find((image) => image.name === imageName).value;
        }
        return image;
    }

    onFileSelected(event: any, arrayType: string) {
        if (event?.target?.files?.length > 0) {
            for (let file of event?.target?.files) {
                this.createImageFromBlob(file, file.name, arrayType,null, null);
            }
        }
        event.target.value = null;
    }

    triggerFileUpload(id: string){
        document.getElementById(id).click();
    }

    createImageFromBlob(image: any,
                        name: string,
                        arrayType: string,
                        id: string,
                        attachment: string) {
        let reader = new FileReader(); // for image type (png, jpeg)
        let appUrl = URL.createObjectURL(image); // for application type (pdf)

        if (image) reader.readAsDataURL(image);

        if (arrayType === 'APPOINTMENT_PHONE') {
            reader.addEventListener("load", () => {
                this.appointmentPhoneSectionAttachmentUrls.push({
                    url: image.type === "application/pdf" ? this.sanitizer.bypassSecurityTrustUrl(appUrl) : reader.result,
                    type: image.type.split("/")[0],
                    name: name,
                    attachment: attachment,
                    id: id
                });
                if(id===null) {
                    this.appointmentPhoneSectionAttachments.push({
                        blobFile: reader.result.toString().split(",")[1],
                        fileName: name
                    })
                }
            }, false);
        } else if (arrayType === 'APPOINTMENT_DIGITAL') {
            reader.addEventListener("load", () => {
                this.appointmentDigitalSectionAttachmentUrls.push({
                    url: image.type === "application/pdf" ? this.sanitizer.bypassSecurityTrustUrl(appUrl) : reader.result,
                    type: image.type.split("/")[0],
                    name: name,
                    attachment: attachment,
                    id: id
                });
                if(id===null) {
                    this.appointmentDigitalSectionAttachments.push({
                        blobFile: reader.result.toString().split(",")[1],
                        fileName: name
                    })
                }
            }, false);
        } else if (arrayType === 'WELCOME') {
            reader.addEventListener("load", () => {
                this.welcomeSectionAttachmentUrls.push({
                    url: image.type === "application/pdf" ? this.sanitizer.bypassSecurityTrustUrl(appUrl) : reader.result,
                    type: image.type.split("/")[0],
                    name: name,
                    attachment: attachment,
                    id: id
                });
                if(id===null) {
                    this.welcomeSectionAttachments.push({
                        blobFile: reader.result.toString().split(",")[1],
                        fileName: name
                    })
                }
            }, false);
        } else if (arrayType === 'CONSULTATION') {
            reader.addEventListener("load", () => {
                this.consultationSectionAttachmentUrls.push({
                    url: image.type === "application/pdf" ? this.sanitizer.bypassSecurityTrustUrl(appUrl) : reader.result,
                    type: image.type.split("/")[0],
                    name: name,
                    attachment: attachment,
                    id: id
                });
                if(id===null) {
                    this.consultationSectionAttachments.push({
                        blobFile: reader.result.toString().split(",")[1],
                        fileName: name
                    })
                }
            }, false);
        } else if (arrayType === 'DEMONSTRATION') {
            reader.addEventListener("load", () => {
                this.demonstrationSectionAttachmentUrls.push({
                    url: image.type === "application/pdf" ? this.sanitizer.bypassSecurityTrustUrl(appUrl) : reader.result,
                    type: image.type.split("/")[0],
                    name: name,
                    attachment: attachment,
                    id: id
                });
                if(id===null) {
                    this.demonstrationSectionAttachments.push({
                        blobFile: reader.result.toString().split(",")[1],
                        fileName: name
                    })
                }
            }, false);
        } else if (arrayType === 'COMMUNICATION') {
            reader.addEventListener("load", () => {
                this.communicationSectionAttachmentUrls.push({
                    url: image.type === "application/pdf" ? this.sanitizer.bypassSecurityTrustUrl(appUrl) : reader.result,
                    type: image.type.split("/")[0],
                    name: name,
                    attachment: attachment,
                    id: id
                });
                if(id===null) {
                    this.communicationSectionAttachments.push({
                        blobFile: reader.result.toString().split(",")[1],
                        fileName: name
                    })
                }
            }, false);
        } else if (arrayType === 'PRESENTATION') {
            reader.addEventListener("load", () => {
                this.presentationSectionAttachmentUrls.push({
                    url: image.type === "application/pdf" ? this.sanitizer.bypassSecurityTrustUrl(appUrl) : reader.result,
                    type: image.type.split("/")[0],
                    name: name,
                    attachment: attachment,
                    id: id
                });
                if(id===null) {
                    this.presentationSectionAttachments.push({
                        blobFile: reader.result.toString().split(",")[1],
                        fileName: name
                    })
                }
            }, false);
        }
    }

    removeImage(i: any, img: any, arrayType:string, sectionId: string) {
        if(img.attachment!=null) {
            this.mysteryShopperService.deleteAttachment(sectionId, img.attachment).subscribe({
                next: response => {
                },  error: (error) => {
                    this.snackBar.open(error.error.message, 'Close', {
                        duration: 60000,
                    });
                },
            })
        }

        if (arrayType === 'APPOINTMENT_PHONE') {
            this.appointmentPhoneSectionAttachments.splice(i, 1);
            this.appointmentPhoneSectionAttachmentUrls.splice(i, 1);
        } else if (arrayType === 'APPOINTMENT_DIGITAL') {
            this.appointmentDigitalSectionAttachments.splice(i, 1);
            this.appointmentDigitalSectionAttachmentUrls.splice(i, 1);
        } else if (arrayType === 'WELCOME') {
            this.welcomeSectionAttachments.splice(i, 1);
            this.welcomeSectionAttachmentUrls.splice(i, 1);
        } else if (arrayType === 'CONSULTATION') {
            this.consultationSectionAttachments.splice(i, 1);
            this.consultationSectionAttachmentUrls.splice(i, 1);
        } else if (arrayType === 'DEMONSTRATION') {
            this.demonstrationSectionAttachments.splice(i, 1);
            this.demonstrationSectionAttachmentUrls.splice(i, 1);
        } else if (arrayType === 'COMMUNICATION') {
            this.communicationSectionAttachments.splice(i, 1);
            this.communicationSectionAttachmentUrls.splice(i, 1);
        } else if (arrayType === 'PRESENTATION') {
            this.presentationSectionAttachments.splice(i, 1);
            this.presentationSectionAttachmentUrls.splice(i, 1);
        }
    }

    previewImage(url: any) {
        if (url.type === "image") {
            this.matDialog.open(VoiceOfCustomerDialogPreviewImageComponent, {
                data: url,
                panelClass: 'mat-dialog-xl'
            })
        }
    }

    downloadFile(file: {url: string | {changingThisBreaksApplicationSecurity: string}; name: string; type: string}) {
        if(typeof file.url !== 'string') {
            window.open(file.url.changingThisBreaksApplicationSecurity);
            return;
        }

        const url = URL.createObjectURL(dataURLtoBlob(file.url));
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file.name;
        a.click();
        this.snackBar.open(`File Downloaded: ${file.name}`, 'Close', this.snackBarConfig);
    }

    checkDate(inputDate: string, type: string):string {
        if(inputDate !== null && inputDate !== undefined) {
            if(type === "PHONE") {
                return this.datePipe.transform(new Date(this.mysteryShopperModel.phoneDatetime),"yyyy-MM-dd'T'HH:mm");
            } else if (type === "DIGITAL") {
                return this.datePipe.transform(new Date(this.mysteryShopperModel.digitalDatetime),"yyyy-MM-dd'T'HH:mm");
            } else if (type === "SHOWROOM") {
                return this.datePipe.transform(new Date(this.mysteryShopperModel.showroomDatetime),"yyyy-MM-dd'T'HH:mm");
            }
        }
        return this.localCurrentDate.substring(0, 16);
    }

    saveMsDetail(isSubmit: boolean) {
        const detailValue = this.detailsFormGroup.getRawValue();

        if(this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'PHONE_RS')) {
            this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'PHONE_RS').salesConsultant = detailValue.phoneReceptionStaff;
        } else {
            var msSalesConsultant = {
                id: null,
                type: "PHONE_RS",
                salesConsultant: null
            };

            if(detailValue.phoneReceptionStaff) {
                msSalesConsultant.salesConsultant = detailValue.phoneReceptionStaff;
            }

            this.mysteryShopperModel.mysteryShopperSalesConsultants.push(msSalesConsultant);
        }

        if(this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'PHONE_SC')) {
            this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'PHONE_SC').salesConsultant = detailValue.phoneSalesConsultantStaff;
        } else {
            var msSalesConsultant = {
                id: null,
                type: "PHONE_SC",
                salesConsultant: null
            };

            if(detailValue.phoneSalesConsultantStaff) {
                msSalesConsultant.salesConsultant = detailValue.phoneSalesConsultantStaff;
            }

            this.mysteryShopperModel.mysteryShopperSalesConsultants.push(msSalesConsultant);
        }

        if(this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'DIGITAL')) {
            if(!detailValue.digitalSalesConsultant) {
                this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'DIGITAL').salesConsultant = this.dummySalesConsultant;
            } else {
                this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'DIGITAL').salesConsultant = detailValue.digitalSalesConsultant;
            }
        } else {
            var msSalesConsultant = {
                id: null,
                type: "DIGITAL",
                salesConsultant: null
            };

            if(detailValue.digitalSalesConsultant) {
                msSalesConsultant.salesConsultant = detailValue.digitalSalesConsultant;
            }

            this.mysteryShopperModel.mysteryShopperSalesConsultants.push(msSalesConsultant);
        }

        if(this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'SHOWROOM')) {
            if(!detailValue.showroomSalesConsultant) {
                this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'SHOWROOM').salesConsultant = this.dummySalesConsultant;
            } else {
                this.mysteryShopperModel.mysteryShopperSalesConsultants.find(el => el.type === 'SHOWROOM').salesConsultant = detailValue.showroomSalesConsultant;
            }

        } else {
            var msSalesConsultant = {
                id: null,
                type: "SHOWROOM",
                salesConsultant: null
            };

            if(detailValue.showroomSalesConsultant) {
                msSalesConsultant.salesConsultant = detailValue.showroomSalesConsultant;
            }

            this.mysteryShopperModel.mysteryShopperSalesConsultants.push(msSalesConsultant);
        }

        ///fetch mystery shopper sections
        let appointmentPhoneSectionValue = null; let appointmentDigitalSectionValue = null; let welcomeSectionValue = null;
        let consultationSectionValue = null; let demonstrationSectionValue = null; let communicationSectionValue = null;
        let agreementAndPotentialPurchaseSectionValue = null; let presentationSectionValue = null;

        if(this.appointmentPhoneSectionFormGroup) { appointmentPhoneSectionValue = this.appointmentPhoneSectionFormGroup.getRawValue(); }
        if(this.appointmentDigitalSectionFormGroup) { appointmentDigitalSectionValue = this.appointmentDigitalSectionFormGroup.getRawValue(); }
        if(this.welcomeSectionFormGroup) { welcomeSectionValue = this.welcomeSectionFormGroup.getRawValue(); }
        if(this.consultationSectionFormGroup) { consultationSectionValue = this.consultationSectionFormGroup.getRawValue(); }
        if(this.demonstrationSectionFormGroup) { demonstrationSectionValue = this.demonstrationSectionFormGroup.getRawValue(); }
        if(this.communicationSectionFormGroup) { communicationSectionValue = this.communicationSectionFormGroup.getRawValue(); }
        if(this.agreementAndPotentialPurchaseSectionFormGroup) { agreementAndPotentialPurchaseSectionValue = this.agreementAndPotentialPurchaseSectionFormGroup.getRawValue(); }
        if(this.presentationSectionFormGroup) { presentationSectionValue = this.presentationSectionFormGroup.getRawValue(); }

        if(this.appointmentPhoneSectionAttachments && appointmentPhoneSectionValue) { appointmentPhoneSectionValue.attachments = this.appointmentPhoneSectionAttachments; }
        if(this.appointmentDigitalSectionAttachments && appointmentDigitalSectionValue) { appointmentDigitalSectionValue.attachments = this.appointmentDigitalSectionAttachments; }
        if(this.welcomeSectionAttachments && welcomeSectionValue) { welcomeSectionValue.attachments = this.welcomeSectionAttachments; }
        if(this.consultationSectionAttachments && consultationSectionValue) { consultationSectionValue.attachments = this.consultationSectionAttachments; }
        if(this.demonstrationSectionAttachments && demonstrationSectionValue) {  demonstrationSectionValue.attachments = this.demonstrationSectionAttachments; }
        if(this.communicationSectionAttachments && communicationSectionValue) { communicationSectionValue.attachments = this.communicationSectionAttachments; }
        if(this.presentationSectionAttachments && presentationSectionValue) {  presentationSectionValue.attachments = this.presentationSectionAttachments; }

        this.mysteryShopperModel.mysteryShopperSections = [];
        if(appointmentPhoneSectionValue) { this.mysteryShopperModel.mysteryShopperSections.push(appointmentPhoneSectionValue); }
        if(appointmentDigitalSectionValue) { this.mysteryShopperModel.mysteryShopperSections.push(appointmentDigitalSectionValue); }
        if(welcomeSectionValue) { this.mysteryShopperModel.mysteryShopperSections.push(welcomeSectionValue); }
        if(consultationSectionValue) { this.mysteryShopperModel.mysteryShopperSections.push(consultationSectionValue); }
        if(presentationSectionValue) { this.mysteryShopperModel.mysteryShopperSections.push(presentationSectionValue); }
        if(demonstrationSectionValue) { this.mysteryShopperModel.mysteryShopperSections.push(demonstrationSectionValue); }
        if(communicationSectionValue) { this.mysteryShopperModel.mysteryShopperSections.push(communicationSectionValue); }
        if(agreementAndPotentialPurchaseSectionValue) { this.mysteryShopperModel.mysteryShopperSections.push(agreementAndPotentialPurchaseSectionValue); }

        this.updateMysteryShopperModel = {
            token: this.mysteryShopperModel.token,
            isReceive: isSubmit,
            remark: this.remarkControl.value,
            phoneDatetimeString: detailValue.phoneDatetimeString + ':00+0800',
            digitalSocialMedia: detailValue.digitalSocialMedia,
            digitalDatetimeString: detailValue.digitalDatetimeString + ':00+0800',
            showroomDatetimeString: detailValue.showroomDatetimeString + ':00+0800',
            modelRecommended: detailValue.modelOffered,
            modelRequested: detailValue.modelRequested,
            mysteryShopperSections: this.mysteryShopperModel.mysteryShopperSections,
            mysteryShopperSalesConsultants: this.mysteryShopperModel.mysteryShopperSalesConsultants,
        }

        if(isSubmit) {
            const modalRef = this.matDialog.open(
                CustomerRecordsMysteryShopperDetailDialogSubmitComponent,
                {
                    data: this.updateMysteryShopperModel,
                    panelClass: 'mat-dialog-sm',
                }
            );

            modalRef.afterClosed().subscribe({
                next: () => {
                    this.ngOnInit();
                },
            });
        } else {
            this.activeLoading();
            this.mysteryShopperService.updateMysteryShopperSurvey(this.updateMysteryShopperModel).subscribe({
                next: () => {
                    this.deactiveLoading();
                    this.snackBar.open("Mystery Shopper Detail saved", this.close, this.snackBarConfig);
                    this.ngOnInit();
                },
                error: error => {
                    let theMessage  = '';
                    error.error.subErrors?.forEach(subError => {
                        theMessage += subError.message + ' \n ';
                    });
                    if(theMessage){
                        this.snackBar.open(theMessage, this.close, this.snackBarConfig);
                    }else{
                        if(error?.error?.message) {
                            this.snackBar.open(error?.error?.message, this.close, this.snackBarConfig);
                        } else {
                            theMessage = error?.message;
                            if(theMessage.includes("413")) {
                                theMessage = "Attachment Max file size reached";
                            }
                            this.snackBar.open(theMessage, this.close, this.snackBarConfig);
                        }
                    }
                    this.deactiveLoading();
                }
            });
        }

    }

}
