<div class="container-fluid nbt-container">
    <div class="nbt-header">
        <div class="nbt-header-title">Net Promoter Score</div>
        <div class="nbt-btn-right">
            <button class="nbt-btn-primary"
                appScreenPermission
                [permissionsInput]="[CREATE_NPS]"
                (click)="openNewNPS()">
                <mat-icon class="mr8">add</mat-icon>
                New NPS List
            </button>
        </div>
    </div>
    <br>
    <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px;">
        <div class="row left-list-detail">
            <div class="col-xl-4 list-section">
                <div class="nbt-details">
                    <div class="promoter-list">
                        <label for="">Promoter List</label>
                        <br>
                        <hr>
                        <mat-accordion multi="false" >
                            <mat-expansion-panel  *ngFor="let br of departments" [value]="br" >
                                <mat-expansion-panel-header #meph (click)="selectDepartment(br, meph)">
                                    <mat-panel-title >
                                        <label>{{ br.name }}</label>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-selection-list>
                                        <mat-list-option *ngFor="let pr of promoters" [value]="pr" class="hide-checkbox" (click)="filterList(pr,br)">
                                            <ng-container *ngIf="promoters.length > 0; else elseData">
                                                {{ pr.username }}
                                            </ng-container>
                                            <ng-template #elseData>
                                                <div class="value">-</div>
                                            </ng-template>
                                          </mat-list-option>
                                </mat-selection-list>
                              </mat-expansion-panel>
                          </mat-accordion>
                    </div>
                </div>
            </div>
            <div class="col-xl left-detail-section">
                <mat-tab-group>
                    <mat-tab  label="Active">
                        <br>
                        <div *ngIf="loading" class="nbt-empty-result">
                            <div class="nbt-data-loading"></div>
                        </div>
                        <mat-accordion multi>
                            <!-- Sales NPS Buyer -->
                            <mat-expansion-panel [expanded]="isExpandSalesBuyer">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Sales NPS (Buyer)
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <br>
                                <div>
                                    <div><b>{{acBuyerRecords}}</b> result<span *ngIf="acBuyerRecords > 1">s</span></div>
                                    <br>
                                    <mat-card-content class="mat-card-content-table">
                                        <table mat-table matSort (matSortChange)="sortActiveSalesBuyer($event)" [dataSource]="acBuyerDataSource" *ngIf="acBuyerDataSource.data">
                                            <ng-container [matColumnDef]="NUMBER">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    #
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index">
                                                    {{i + 1}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="NPS_NUMBER">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    NPS Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{ element.npsNumber || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="CUSTOMER_MAIN_PHONE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Customer Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.customer.mainPhone || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="REGISTRATION_NO">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Vehicle Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.vehicle?.registrationNo || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTUAL_DATE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    SSI Received Date
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.actualDate || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="BRANCH">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Branch
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.department.name || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="STATUS">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Status
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <div class="nbt-status" [ngClass]="getStatusColor(element.status)">
                                                        {{ statusValue(element.status) }}
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTIONS">
                                                <th mat-header-cell *matHeaderCellDef>Actions</th>
                                                <td mat-cell *matCellDef="let element">
                                                    <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_NPS)" (click)="openNPSEditDialog(element)" matTooltip="Edit">edit</mat-icon>
                                                    <mat-icon (click)="openNPSDialogDetail(element)"  matTooltip="View">remove_red_eye</mat-icon>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: tableColumns;" [ngClass]="row.denseRank %2 === 0 ? 'mat-row-light-grey': 'mat-row-mid-grey'"></tr>
                                        </table>
                                    </mat-card-content>
                                    <mat-paginator (page)="navigateToPageAcBuyer($event)" [length]="acBuyerRecords" [pageSize]="acBuyerPageSize"
                                        [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                    </mat-paginator>
                                </div>
                            </mat-expansion-panel>
                            <br>
                            <!-- Sales NPS  Non-Buyer -->
                            <mat-expansion-panel [expanded]="isExpandSalesNonBuyer">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Sales NPS(Non Buyer)
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <br>
                                <div>
                                    <div><b>{{acNonBuyerRecords}}</b> result<span *ngIf="acNonBuyerRecords > 1">s</span></div>
                                    <br>
                                    <mat-card-content class="mat-card-content-table">
                                        <table mat-table matSort (matSortChange)="sortActiveSalesNonBuyer($event)" [dataSource]="acNonBuyerDataSource" *ngIf="acNonBuyerDataSource.data">
                                            <ng-container [matColumnDef]="NUMBER">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    #
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index">
                                                    {{i + 1}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="NPS_NUMBER">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    NPS Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{ element.npsNumber || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="CUSTOMER_MAIN_PHONE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Customer Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.customer.mainPhone || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="REGISTRATION_NO">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Vehicle Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.vehicle?.registrationNo || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTUAL_DATE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                   Activity Date
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.actualDate || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="BRANCH">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Branch
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.department.name || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="STATUS">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Status
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <div class="nbt-status" [ngClass]="getStatusColor(element.status)">
                                                        {{ statusValue(element.status) }}
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTIONS">
                                                <th mat-header-cell *matHeaderCellDef >Actions</th>
                                                <td mat-cell *matCellDef="let element">
                                                    <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_NPS)" (click)="openNPSEditDialog(element)" matTooltip="Edit">edit</mat-icon>
                                                    <mat-icon (click)="openNPSDialogDetail(element)"  matTooltip="View">remove_red_eye</mat-icon>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: tableColumns;" [ngClass]="row.denseRank %2 === 0 ? 'mat-row-light-grey': 'mat-row-mid-grey'"></tr>
                                        </table>
                                    </mat-card-content>
                                    <mat-paginator (page)="navigateToPageAcNonBuyer($event)" [length]="acNonBuyerRecords" [pageSize]="acNonBuyerPageSize"
                                        [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                    </mat-paginator>
                                </div>
                            </mat-expansion-panel>
                            <br>
                             <!-- After Sales -->
                             <mat-expansion-panel [expanded]="isExpandAfterSales">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        After Sales NPS
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <br>
                                <div>
                                    <div><b>{{acSalesRecords}}</b> result<span *ngIf="acSalesRecords > 1">s</span></div>
                                    <br>
                                    <mat-card-content class="mat-card-content-table">
                                        <table mat-table matSort (matSortChange)="sortActiveAfterSales($event)" [dataSource]="acSalesDataSource" *ngIf="acSalesDataSource.data">
                                            <ng-container [matColumnDef]="NUMBER">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    #
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index">
                                                    {{i + 1}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="NPS_NUMBER">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    NPS Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{ element.npsNumber || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="CUSTOMER_MAIN_PHONE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Customer Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.customer.mainPhone || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="REGISTRATION_NO">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Vehicle Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.vehicle?.registrationNo || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTUAL_DATE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    CSI Received Date
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.actualDate || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="BRANCH">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Branch
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.department.name || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="STATUS">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Status
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <div class="nbt-status" [ngClass]="getStatusColor(element.status)">
                                                        {{ statusValue(element.status) }}
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTIONS">
                                                <th mat-header-cell *matHeaderCellDef>Actions</th>
                                                <td mat-cell *matCellDef="let element">
                                                    <mat-icon *ngIf="authService.hasAuthorityMenu(UPDATE_NPS)" (click)="openNPSEditDialog(element)" matTooltip="Edit">edit</mat-icon>
                                                    <mat-icon (click)="openNPSDialogDetail(element)"  matTooltip="View">remove_red_eye</mat-icon>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: tableColumns;" [ngClass]="row.denseRank %2 === 0 ? 'mat-row-light-grey': 'mat-row-mid-grey'"></tr>
                                        </table>
                                    </mat-card-content>
                                    <mat-paginator (page)="navigateToPageAcSales($event)" [length]="acSalesRecords" [pageSize]="acSalesPageSize"
                                        [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                    </mat-paginator>
                                </div>
                            </mat-expansion-panel>
                            <br>
                        </mat-accordion>
                    </mat-tab>
                    <mat-tab  label="Archived">
                        <br>
                        <div *ngIf="archivedLoading" class="nbt-empty-result">
                            <div class="nbt-data-loading"></div>
                        </div>
                        <mat-accordion multi>
                            <!-- Sales NPS Buyer -->
                            <mat-expansion-panel [expanded]="isExpandSalesBuyer">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Sales NPS (Buyer)
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <br>
                                <div>
                                    <div><b>{{arBuyerRecords}}</b> result<span *ngIf="arBuyerRecords > 1">s</span></div>
                                    <br>
                                    <mat-card-content class="mat-card-content-table">
                                        <table mat-table matSort (matSortChange)="sortArchivedSalesBuyer($event)" [dataSource]="arBuyerDataSource" *ngIf="arBuyerDataSource.data">
                                            <ng-container [matColumnDef]="NUMBER">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    #
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index">
                                                    {{i + 1}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="NPS_NUMBER">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    NPS Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{ element.npsNumber || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="CUSTOMER_MAIN_PHONE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Customer Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.customer.mainPhone || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="REGISTRATION_NO">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Vehicle Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.vehicle?.registrationNo || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTUAL_DATE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    SSI Received Date
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.actualDate || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="BRANCH">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Branch
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.department.name || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="STATUS">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Status
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <div class="nbt-status" [ngClass]="getStatusColor(element.status)">
                                                        {{ statusValue(element.status) }}
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTIONS">
                                                <th mat-header-cell *matHeaderCellDef>Actions</th>
                                                <td mat-cell *matCellDef="let element">
                                                    <mat-icon (click)="openNPSDialogDetail(element)"  matTooltip="View">remove_red_eye</mat-icon>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: tableColumns;" [ngClass]="row.denseRank %2 === 0 ? 'mat-row-light-grey': 'mat-row-mid-grey'"></tr>
                                        </table>
                                    </mat-card-content>
                                    <mat-paginator (page)="navigateToPageArBuyer($event)" [length]="arBuyerRecords" [pageSize]="arBuyerPageSize"
                                        [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                    </mat-paginator>
                                </div>
                            </mat-expansion-panel>
                            <br>
                            <!-- Sales NPS  Non-Buyer -->
                            <mat-expansion-panel [expanded]="isExpandSalesNonBuyer">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Sales NPS(Non Buyer)
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <br>
                                <div>
                                    <div><b>{{arNonBuyerRecords}}</b> result<span *ngIf="arNonBuyerRecords > 1">s</span></div>
                                    <br>
                                    <mat-card-content class="mat-card-content-table">
                                        <table mat-table matSort (matSortChange)="sortArchivedSalesNonBuyer($event)" [dataSource]="arNonBuyerDataSource" *ngIf="arNonBuyerDataSource.data">
                                            <ng-container [matColumnDef]="NUMBER">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    #
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index">
                                                    {{i + 1}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="NPS_NUMBER">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    NPS Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{ element.npsNumber || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="CUSTOMER_MAIN_PHONE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Customer Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.customer.mainPhone || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="REGISTRATION_NO">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Vehicle Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.vehicle?.registrationNo || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTUAL_DATE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                   Activity Date
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.actualDate || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="BRANCH">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Branch
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.department.name || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="STATUS">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Status
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <div class="nbt-status" [ngClass]="getStatusColor(element.status)">
                                                        {{ statusValue(element.status) }}
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTIONS">
                                                <th mat-header-cell *matHeaderCellDef>Actions</th>
                                                <td mat-cell *matCellDef="let element">
                                                    <mat-icon (click)="openNPSDialogDetail(element)"  matTooltip="View">remove_red_eye</mat-icon>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: tableColumns;" [ngClass]="row.denseRank %2 === 0 ? 'mat-row-light-grey': 'mat-row-mid-grey'"></tr>
                                        </table>
                                    </mat-card-content>
                                    <mat-paginator (page)="navigateToPageArNonBuyer($event)" [length]="acNonBuyerRecords" [pageSize]="arNonBuyerPageSize"
                                        [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                    </mat-paginator>
                                </div>
                            </mat-expansion-panel>
                            <br>
                             <!-- After Sales -->
                             <mat-expansion-panel [expanded]="isExpandAfterSales">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        After Sales NPS
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <br>
                                <div>
                                    <div><b>{{arSalesRecords}}</b> result<span *ngIf="arSalesRecords > 1">s</span></div>
                                    <br>
                                    <mat-card-content class="mat-card-content-table">
                                        <table mat-table matSort (matSortChange)="sortArchivedAfterSales($event)" [dataSource]="arSalesDataSource" *ngIf="arSalesDataSource.data">
                                            <ng-container [matColumnDef]="NUMBER">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    #
                                                </th>
                                                <td mat-cell *matCellDef="let element; let i = index">
                                                    {{i + 1}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="NPS_NUMBER">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    NPS Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{ element.npsNumber || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="CUSTOMER_MAIN_PHONE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Customer Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.customer.mainPhone || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="REGISTRATION_NO">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Vehicle Number
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.vehicle?.registrationNo || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTUAL_DATE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    CSI Received Date
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.actualDate || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="BRANCH">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Branch
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.activity.department.name || '-'}}
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="STATUS">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Status
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <div class="nbt-status" [ngClass]="getStatusColor(element.status)">
                                                        {{ statusValue(element.status) }}
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container [matColumnDef]="ACTIONS">
                                                <th mat-header-cell *matHeaderCellDef>Actions</th>
                                                <td mat-cell *matCellDef="let element">
                                                    <mat-icon (click)="openNPSDialogDetail(element)"  matTooltip="View">remove_red_eye</mat-icon>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: tableColumns;" [ngClass]="row.denseRank %2 === 0 ? 'mat-row-light-grey': 'mat-row-mid-grey'"></tr>
                                        </table>
                                    </mat-card-content>
                                    <mat-paginator (page)="navigateToPageArSales($event)" [length]="arSalesRecords" [pageSize]="arSalesPageSize"
                                        [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="nbt-paginator">
                                    </mat-paginator>
                                </div>
                            </mat-expansion-panel>
                            <br>
                        </mat-accordion>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-card-content>
</div>
