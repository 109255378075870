import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoiceOfCustomerListingComponent } from './voice-of-customer-listing/voice-of-customer-listing.component';
import { VoiceOfCustomerDetailComponent } from './voice-of-customer-detail/voice-of-customer-detail.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { RouterModule, Routes } from '@angular/router';
import { VoiceOfCustomerDialogConfirmationComponent } from './voice-of-customer-dialog-confirmation/voice-of-customer-dialog-confirmation.component';
import { VoiceOfCustomerDialogPreviewImageComponent } from './voice-of-customer-dialog-preview-image/voice-of-customer-dialog-preview-image.component';
import { VoiceOfCustomerDialogModificationComponent } from './voice-of-customer-dialog-modification/voice-of-customer-dialog-modification.component';


const routes: Routes = [
    {
        path: 'voice-of-customer',
        component: VoiceOfCustomerListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'voice-of-customer-edit',
        component: VoiceOfCustomerDetailComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'voice-of-customer-new',
        component: VoiceOfCustomerDetailComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
];


@NgModule({
    declarations: [
        VoiceOfCustomerListingComponent,
        VoiceOfCustomerDetailComponent,
        VoiceOfCustomerDialogConfirmationComponent,
        VoiceOfCustomerDialogPreviewImageComponent,
        VoiceOfCustomerDialogModificationComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes)
    ]
})
export class VoiceOfCustomerModule { }
