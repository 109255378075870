<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create New Vehicle Model
        </ng-container>
        <ng-template #elseTemplate>
            Edit Vehicle Model '{{ vmodel.name}}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="vehicleModelFormGroup">
        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Vehicle Brand <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <!-- <input matInput appAutofocus type="text" formControlName="brand" placeholder="Enter name"/>
                    <mat-error *ngIf="vehicleModelFormGroup.get('brand').dirty">Brand is required</mat-error> -->
                    <mat-select name="brand" [compareWith]="compareObjects"
                            formControlName="brand" placeholder="Select Vehicle Model">
                            <mat-option [value]="null">
                                Select Vehicle Model
                            </mat-option>
                            <mat-option *ngFor="let model of sysConfig" [value]="model">
                                {{ model }}
                            </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form label-flex">
                <mat-checkbox formControlName="active">Active</mat-checkbox>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Vehicle Model Name <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="name" placeholder="Enter name" required maxlength="100"/>
                    <mat-error *ngIf="vehicleModelFormGroup.get('name').dirty">Model is required</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <span class="required">* Required Field For Submission</span> 
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || vehicleModelFormGroup.invalid"
        (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>