import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    subscription: Subscription;
    constructor(private router: Router, private authService: AuthService) {}
    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 403) {
                    this.router.navigate(['/login']).then(() => {
                        if (this.subscription) {
                            this.subscription.unsubscribe();
                        }
                        localStorage.removeItem(
                            this.authService.USER_ITEM_ATTRIBUTE
                        );
                        localStorage.removeItem(
                            this.authService.USER_CURRENT_INSTITUTION_ATTRIBUTE
                        );
                        this.authService.deregisterUser();
                    });
                }

                return throwError(() => err);
            })
        );
    }
}
