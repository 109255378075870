<div class="nbt-dialog-header">
    <div class="nbt-dialog-title">
        {{data.title}}
    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div class="nbt-dialog-body" [innerHtml]="data.message"></div>
<div class="nbt-dialog-footer">
    <button *ngIf="data.buttonNegative" type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        {{data.buttonNegative}}
    </button>
    <button type="button" (click)="closeButtonPositive()" class="nbt-btn-primary ml8">
        <mat-icon class="mr8">check</mat-icon>
        {{data.buttonPositive}}
    </button>
</div>