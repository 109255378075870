import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-pin-entry',
    templateUrl: './pin-entry.component.html',
    styleUrls: [
        './pin-entry.component.scss',
        '../login.component.scss'
    ]
})
export class PinEntryComponent implements OnInit {

    invalidCredentials: boolean;
    loginError: boolean;
    pinCodeFormGroup: FormGroup;
    private readonly username: string;
    private readonly password: string;

    constructor(private authService: AuthService,
        private formBuilder: FormBuilder,
        private router: Router) {
        this.username = router.getCurrentNavigation().extras.state.data.username;
        this.password = router.getCurrentNavigation().extras.state.data.password;
        this.invalidCredentials = false;
        this.loginError = false;
    }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        if (event.code === 'Enter') {
            this.login();
        }
    }

    ngOnInit(): void {
        this.pinCodeFormGroup = this.formBuilder.group({
            username: this.username,
            password: this.password,
            pinCode: this.formBuilder.control(null, Validators.required)
        });

        this.authService.user.subscribe({
            next: (value) => {
                this.loginError = false;
                this.invalidCredentials = false;

                if (value) {
                    if (value.error === true) {
                        this.loginError = true;
                    } else if (!value.authenticated) {
                        this.invalidCredentials = true;
                    }
                }
            }
        });
    }

    login(): void {
        this.loginError = false;
        this.invalidCredentials = false;
        this.authService.validatePinCode(this.pinCodeFormGroup.getRawValue());
    }

    cancel(): void {
        this.authService.logout();
    }
}
