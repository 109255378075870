import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './home.component';
import { Routes, RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CalendarCommonModule, CalendarMonthModule } from 'angular-calendar';
import { PersonalTaskComponent } from './dashboard-component/personal-task/personal-task.component';
import { StatisticDepartmentComponent } from './dashboard-component/statistic-department/statistic-department.component';
import { MatSliderModule } from '@angular/material/slider';
import { VocBreakdownCategoryComponent } from './dashboard-component/voc-breakdown-category/voc-breakdown-category.component';
import { VocSlaComplianceDepartmentComponent } from './dashboard-component/voc-sla-compliance-department/voc-sla-compliance-department.component';
import { DashboardActionComponent } from './dashboard-component/dashboard-action/dashboard-action.component';

const routes: Routes = [{
    path: '',
    component: HomeComponent
}];

@NgModule({
    declarations: [
        HomeComponent,
        PersonalTaskComponent,
        StatisticDepartmentComponent,
        VocBreakdownCategoryComponent,
        VocSlaComplianceDepartmentComponent,
        DashboardActionComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        DirectivesModule,
        RouterModule.forChild(routes),
        CalendarCommonModule,
        CalendarMonthModule,
        MatSliderModule,
    ]
})
export class HomeModule { }
