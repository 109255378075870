import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormGroup } from '@angular/forms';
import { DepartmentModel } from 'src/app/model/department.model';
import { ApplicationUserModel } from 'src/app/model/application-user.model';

@Directive({
  selector: '[appDepartmentSelected]'
})
export class DepartmentSelectedDirective implements OnChanges {

  @Input() applicationUserFormGroupInput: FormGroup;
  @Input() departmentModelInput: DepartmentModel;

  constructor(private checkbox: MatCheckbox) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const applicationUserFormGroup = changes.applicationUserFormGroupInput.currentValue as FormGroup;
    const departmentModel = changes.departmentModelInput.currentValue as DepartmentModel;
    const applicationUserModel = applicationUserFormGroup.getRawValue() as ApplicationUserModel;
    const configuredDepartments = applicationUserModel.departments;

    this.checkbox.writeValue(configuredDepartments.find(value => value.id === departmentModel.id));
  }
}
