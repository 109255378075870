import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, NextObserver, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { InstitutionModel } from '../model/institution.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly USER_ITEM_ATTRIBUTE = 'user';
  readonly USER_CURRENT_INSTITUTION_ATTRIBUTE = 'current_institution';
  user: BehaviorSubject<ApplicationUserAuthenticationResponse>;
  institutions: InstitutionModel[];
  curIns: string;

  private loginSubscription: Subscription;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.user = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem(this.USER_ITEM_ATTRIBUTE))
    );
  }

  login(username: string, password: string): void {
    let url;
    let body;
    if (environment.security.oauth2Enabled) {
      url =
        environment.security.oauth2Configuration.oauth2AuthorizationServerUrl;
      body = new HttpParams()
        .set('grant_type', 'password')
        .set('username', username)
        .set('password', password)
        .set('client_id', environment.security.oauth2Configuration.clientId)
        .set(
          'client_secret',
          environment.security.oauth2Configuration.clientSecret
        );
    } else {
      url = `/login`;
      body = {
        username,
        password,
      };
    }

    this.loginSubscription = this.httpClient
      .post(url, body, {
        observe: 'response',
      })
      .subscribe(
        environment.security.oauth2Enabled
          ? this.oauth2NextObserver(username, password)
          : this.applicationUserNextObserver(username, password)
      );
  }

  getUser() {
    return JSON.parse(localStorage.getItem(this.USER_ITEM_ATTRIBUTE));
  }

  setUser(authenticationDetails: ApplicationUserAuthenticationResponse): void {
    localStorage.setItem(
      this.USER_ITEM_ATTRIBUTE,
      JSON.stringify(authenticationDetails)
    );
  }

  getCurIns() {
    return JSON.parse(
      localStorage.getItem(this.USER_CURRENT_INSTITUTION_ATTRIBUTE)
    );
  }

  getCurInsId() {
    return JSON.parse(
      localStorage.getItem(this.USER_CURRENT_INSTITUTION_ATTRIBUTE)
    ).id;
  }

  setCurIns(curIns: InstitutionModel): void {
    localStorage.setItem(
      this.USER_CURRENT_INSTITUTION_ATTRIBUTE,
      JSON.stringify(curIns)
    );
  }

  getUsername() {
    return JSON.parse(localStorage.getItem(this.USER_ITEM_ATTRIBUTE)).username;
  }

  getInstitutions() {
    return JSON.parse(localStorage.getItem(this.USER_ITEM_ATTRIBUTE))
      .institutions;
  }

  registerUser(
    authenticationDetails: ApplicationUserAuthenticationResponse
  ): void {
    this.user.next(authenticationDetails);
  }

  deregisterUser(): void {
    this.user.next(null);
  }

  logout(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
    this.httpClient.post('/logout', {}, { observe: 'response' }).subscribe();

    this.router.navigate(['login']).then(() => {
      localStorage.removeItem(this.USER_ITEM_ATTRIBUTE);
      localStorage.removeItem(this.USER_CURRENT_INSTITUTION_ATTRIBUTE);
      this.deregisterUser();
    });
  }

  isLoggedIn(): boolean {
    return this.user.value?.authenticated;
  }

  getAuthorities(): Authority[] {
    return this.user.value.authorities;
  }

  hasAuthorityMenu(screenAuthority: any): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    for (const authority of this.user.value.authorities) {
      if (screenAuthority instanceof Array) {
        if (screenAuthority.includes(authority.authority)) {
          return true;
        }
      } else {
        if (screenAuthority === authority.authority) {
          return true;
        }
      }
    }
    return false;
  }

  validatePinCode(pinCodeDetails: PINCodeDetails): void {
    this.httpClient
      .post<ApplicationUserAuthenticationResponse>(
        'http://localhost:8080/pin-code-authentication',
        pinCodeDetails,
        {
          observe: 'response',
        }
      )
      .subscribe({
        next: () => this.router.navigate(['']).then(),
        error: (httpErrorResponse: HttpErrorResponse) => {
          this.registerUser({
            username: null,
            password: null,
            principal: null,
            authorities: [],
            institutions: [],
            token: null,
            authenticated: false,
            error:
              httpErrorResponse.status !== 401 &&
              httpErrorResponse.status !== 403,
            httpErrorResponse,
          });

          this.router
            .navigate(['..'], { relativeTo: this.activatedRoute })
            .then();
        },
      });
  }

  private oauth2NextObserver(
    username: string,
    password: string
  ): NextObserver<HttpResponse<OAuth2AuthenticationResponse>> {
    return {
      next: (response) => {
        const oauth2Authentication = response.body;
        const accessToken = oauth2Authentication.access_token;
        const details: OAuth2TokenDetails = JSON.parse(
          atob(accessToken.split('.')[1])
        );
        const authenticationDetails: ApplicationUserAuthenticationResponse = {
          username,
          password,
          token: accessToken,
          authenticated: false,
          authorities: details.scope.split(' ').map((scope) => ({
            authority: `SCOPE_${scope}`,
          })),
          error: false,
          institutions: [],
          principal: username,
          httpErrorResponse: null,
        };
        this.user.next(authenticationDetails);
        this.handleSuccessLogin(username, password, authenticationDetails);
      },
      error: (errorResponse) => this.handleLoginError(errorResponse),
    };
  }

  private applicationUserNextObserver(
    username: string,
    password: string
  ): NextObserver<HttpResponse<ApplicationUserAuthenticationResponse>> {
    return {
      next: (response: HttpResponse<ApplicationUserAuthenticationResponse>) => {
        const authorizationHeader = response.headers.get('Authorization');
        const authenticationDetails = response.body;
        authenticationDetails.authorities =
          authenticationDetails.authorities.sort((a, b) => {
            if (a.authority > b.authority) {
              return 1;
            }

            if (a.authority < b.authority) {
              return -1;
            }

            return 0;
          });
        authenticationDetails.username = username;
        authenticationDetails.token = authorizationHeader;

        this.handleSuccessLogin(username, password, authenticationDetails);
      },
      error: (httpErrorResponse: HttpErrorResponse) =>
        this.handleLoginError(httpErrorResponse),
    };
  }

  private handleLoginError(httpErrorResponse: HttpErrorResponse): void {
    this.registerUser({
      username: null,
      password: null,
      principal: null,
      authorities: [],
      institutions: [],
      token: null,
      authenticated: false,
      error: httpErrorResponse.status === 500,
      httpErrorResponse,
    });

    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  private handleSuccessLogin(
    username: string,
    password: string,
    authenticationDetails: ApplicationUserAuthenticationResponse
  ): void {
    authenticationDetails.authenticated = true;

    if (environment.security.twoFactorAuthenticationEnabled) {
      this.router
        .navigate(['pin-entry'], {
          state: {
            data: {
              username,
              password,
            },
          },
        })
        .then(() => this.registerUser(authenticationDetails));
    } else {
      this.registerUser(authenticationDetails);
      localStorage.setItem(
        this.USER_ITEM_ATTRIBUTE,
        JSON.stringify(authenticationDetails)
      );
      this.router.navigate(['']).then();
    }
  }
}

interface ApplicationUserAuthenticationResponse {
  principal?: string;
  username: string;
  password: string;
  authorities: Authority[];
  institutions: InstitutionModel[];
  authenticated: boolean;
  token: string;
  error: boolean;
  httpErrorResponse: HttpErrorResponse;
}

interface OAuth2AuthenticationResponse {
  access_token: string;
}

interface OAuth2TokenDetails {
  scope: string;
}

export interface Authority {
  authority: string;
}

interface PINCodeDetails {
  username: string;
  password: string;
  pinCode: string;
}
