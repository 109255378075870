import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { InstitutionModel } from '../model/institution.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstitutionsService {
  constructor(private httpClient: HttpClient) {}

  public save(
    model: InstitutionModel
  ): Observable<HttpResponse<InstitutionModel>> {
    return this.httpClient.post<InstitutionModel>(`/web/institution`, model, {
      observe: 'response',
    });
  }

  public getAllSearch(
    // pageNumber: number = 0,
    // pageSize: number = 20,
    // sortOrder: string = null,
    // criteria = null

    pageNumber: number,
    pageSize: number,
    sortOrder: string,
    criteria
  ): Observable<HttpResponse<InstitutionModel[]>> {
    let httpParams = new HttpParams();
    if (pageNumber) {
      httpParams = httpParams.set('page', `${pageNumber}`);
    }
    if (pageSize) {
      httpParams = httpParams.set('size', `${pageSize}`);
    }
    if (sortOrder) {
      httpParams = httpParams.set('sort', sortOrder);
    }

    if (criteria) {
      if (criteria.name) {
        httpParams = httpParams.set('name', criteria.name);
      }
      if (criteria.institution) {
        httpParams = httpParams.set('institutionId', criteria.institution);
      }
    }

    return this.httpClient.get<InstitutionModel[]>(`/web/institution/all`, {
      observe: 'response',
      params: httpParams,
    });
  }

  public getAll(): Observable<HttpResponse<InstitutionModel[]>> {
    return this.httpClient.get<InstitutionModel[]>(`/web/institution/user`, {
      observe: 'response',
    });
  }

  public update(
    model: InstitutionModel
  ): Observable<HttpResponse<InstitutionModel>> {
    return this.httpClient.put<InstitutionModel>(`/web/institution`, model, {
      observe: 'response',
    });
  }

  public getByParentId(
    id: number
  ): Observable<HttpResponse<InstitutionModel[]>> {
    const params = new HttpParams().set('id', id);
    return this.httpClient.get<InstitutionModel[]>(`/web/institution/parent`, {
      observe: 'response',
      params,
    });
  }
}
