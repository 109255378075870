<div class="nbt-dialog-header" *ngIf="!loading">
    <div class="nbt-dialog-title">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create New User
        </ng-container>
        <ng-template #elseTemplate>
            Edit User '{{applicationUserModel.username}}'
        </ng-template>

    </div>
    <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
</div>
<div *ngIf="loading" class="nbt-empty-result">
    <div class="nbt-data-loading"></div>
</div>
<div class="nbt-dialog-body" *ngIf="!loading">
    <form [formGroup]="applicationUserFormGroup">
        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Username <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput appAutofocus type="text" formControlName="username" placeholder="Enter username" maxlength="100"/>
                </mat-form-field>
            </div>
            <div class="col-md-3 nbt-form label-flex">
                <mat-checkbox formControlName="active">Active</mat-checkbox>
            </div>
            <div class="col-md-3 nbt-form label-flex">
                <mat-checkbox formControlName="isChampion">Champion</mat-checkbox>
            </div>
        </div>
        <span *ngIf="checkUser()">
            <div class="row">
                <div class="col-md-6 nbt-form">
                    <label>New Password <span class="required">*</span></label>
                    <mat-form-field appearance="outline">
                        <input matInput type="password" formControlName="newPassword" placeholder="Enter new password"/>
                </mat-form-field>
                </div>
                <div class="col-md-6 nbt-form">
                    <label>Repeat Password <span class="required">*</span></label>
                    <mat-form-field appearance="outline">
                        <input matInput type="password" formControlName="repeatPassword" placeholder="Re-enter new password"/>
                        <mat-error *ngIf="applicationUserFormGroup.get('repeatPassword').errors?.mismatch">Passwords do not match</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </span>

        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Staff Code</label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="code" placeholder="Enter staff code" maxlength="100"/>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Clearance <span class="required">*</span></label>
                <mat-form-field appearance="outline">
                    <mat-select name="clearance" formControlName="clearance"
                        [compareWith]="compareCodes" placeholder="Select clearance">
                        <mat-option [value]="null">
                            Select clearance
                        </mat-option>
                        <mat-option *ngFor="let clr of clearance" [value]="clr.code" [matTooltip]="clr.description">
                            {{clr.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>First Name</label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="firstName" placeholder="Enter first name" maxlength="100"/>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Last Name</label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="lastName" placeholder="Enter last name" maxlength="100"/>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Phone</label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="phone" placeholder="Enter phone number" maxlength="20"/>
                    <mat-error *ngIf="applicationUserFormGroup.get('phone').errors?.pattern">Invalid Input</mat-error>
                    <mat-icon class="material-symbols-text" [matTooltip]="phoneContent" matTooltipPosition="above">
                        info
                    </mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form">
                <label>Email</label>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="email" placeholder="Enter email" maxlength="100"/>
                    <mat-error *ngIf="applicationUserFormGroup.get('email').errors?.email">Invalid Input</mat-error>
                    <mat-icon class="material-symbols-text" [matTooltip]="emailContent" matTooltipPosition="above">
                        info
                    </mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Gender</label>
                <mat-form-field appearance="outline">
                    <mat-select name="gender" formControlName="gender" placeholder="Select gender">
                        <mat-option [value]="null">
                            Select gender
                        </mat-option>
                        <mat-option *ngFor="let gen of genders" [value]="gen.value">
                            {{gen.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <hr>
        <br>
        <div class="row">
            <div class="col-md-6 nbt-form">
                <label>Position</label>
                <mat-form-field appearance="outline">
                    <mat-select name="applicationPosition" formControlName="applicationPosition"
                                [compareWith]="compareObjects" placeholder="Select position">
                        <mat-option [value]="null">
                            Select position
                        </mat-option>
                        <ng-container *ngFor="let appPosition of applicationPositionModels">
                            <mat-option *ngIf="appPosition.parentApplicationPosition === null" [value]="appPosition">
                                {{appPosition.code}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 nbt-form" *ngIf="applicationUserFormGroup.get('applicationPosition').value?.code==='Officer'">
                <label>Officer - Sales Consultant/Service Advisor/Others</label>
                <mat-form-field appearance="outline">
                    <mat-select name="applicationPositionlv2" formControlName="applicationPositionlv2"
                                [compareWith]="compareObjects" placeholder="Select position level 2">
                        <mat-option [value]="null">
                            Select position level 2
                        </mat-option>
                        <ng-container *ngFor="let appPosition of applicationPositionModels">
                            <mat-option *ngIf="appPosition.parentApplicationPosition !== null" [value]="appPosition">
                                {{appPosition.code}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <label>Roles <span class="required">*</span></label>
            <div class="col-md-6 nbt-form checkbox-label">
                <div *ngFor="let applicationRoleModel of applicationRoleModels; let i = index">
                    <mat-checkbox appApplicationRoleSelected
                    [applicationUserFormGroupInput]="applicationUserFormGroup"
                    [applicationRoleModelInput]="applicationRoleModel"
                    (click)="updateApplicationUserApplicationRoles(applicationRoleModel)">
                        {{ applicationRoleModel.institution.name }} : {{ applicationRoleModel.name }}
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="row">
            <label>Departments</label>
            <div class="col-md-6 nbt-form checkbox-label">
                <div *ngFor="let departmentModel of departmentModels; let i = index">
                    <mat-checkbox appDepartmentSelected
                    [applicationUserFormGroupInput]="applicationUserFormGroup"
                    [departmentModelInput]="departmentModel"
                    (click)="updateApplicationUserDepartments(departmentModel)">
                        {{ departmentModel.name }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </form>
    <span class="required">* Required Field For Submission</span>
</div>
<div class="nbt-dialog-footer" *ngIf="!loading">
    <button type="button" class="nbt-btn-secondary" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="mr8">clear</mat-icon>
        Cancel
    </button>
    <button type="button" class="nbt-btn-primary ml8" [disabled]="isSaving || applicationUserFormGroup.invalid" (click)="save()">
        <mat-icon class="mr8">save</mat-icon>
        Save
    </button>
</div>
