import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { CategoryModel } from 'src/app/model/category.model';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-groups-dialog-new-or-edit',
  templateUrl: './groups-dialog-new-or-edit.component.html',
  styleUrls: ['./groups-dialog-new-or-edit.component.scss']
})
export class GroupsDialogNewOrEditComponent implements OnInit {

  dialogType: string ='';
  groupFormGroup: FormGroup;
  isSaving: boolean = false;
  loading: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public group: CategoryModel,
    public dialogRef: MatDialogRef<GroupsDialogNewOrEditComponent>,
    private formBuilder : FormBuilder,
    private categoryService: CategoryService,
    private authService: AuthService,
    private snackBar: MatSnackBar,

  ) { }

  ngOnInit(): void {
    if(this.group){
      this.dialogType = 'update';
      this.groupFormGroup = this.formBuilder.group({
        id: this.formBuilder.control(this.group.id),
        name: this.formBuilder.control(this.group.name,Validators.required),
        description: this.formBuilder.control(this.group.description),
        active: this.formBuilder.control(this.group.active),

      })

    }else{
      this.dialogType = 'create';
      this.groupFormGroup = this.formBuilder.group({
        name: this.formBuilder.control(null,Validators.required),
        description: this.formBuilder.control(null),
        active: this.formBuilder.control(true),
      })

    }
    this.deactiveLoading();
  }

  activeLoading(){
    this.loading = true;
  }

  deactiveLoading(){
      this.loading = false;
  }

  save(): void {
    this.isSaving = true;
    this.activeLoading();
    if (this.dialogType === 'create') {
        this.categoryService.save(this.groupFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
            next: () => {
                this.isSaving = false;
                this.deactiveLoading();
                this.dialogRef.close('ok');
            },
            error: error => {
                this.isSaving = false;
                this.deactiveLoading();
                this.snackBar.open(error.error.message, 'Close', {
                  duration: 60000
                });
            }
        });
    } else {
        this.categoryService.update(this.groupFormGroup.getRawValue(), this.authService.getCurInsId()).subscribe({
            next: () => {
                this.isSaving = false;
                this.deactiveLoading();
                this.dialogRef.close('ok');
            },
            error: error => {
                this.isSaving = false;
                this.deactiveLoading();
                this.snackBar.open(error.error.message, 'Close', {
                  duration: 60000
                });
            }
        });
    }
  }
}