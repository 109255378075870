<div *ngIf="!loading">
    <div class="nbt-dialog-header">
        
        <div class="nbt-dialog-title">
            Calling - {{customerRecordActivityModel.customer.firstName}} {{customerRecordActivityModel.customer.lastName}}
        </div>
        <button type="button" data-dismiss="modal" class="nbt-dialog-close" (click)="dialogRef.close()">&times;</button>
    </div>
    <div class="nbt-dialog-body">
        <form [formGroup]="npsCallInDetailFormGroup">
            <div class="row">
                <div class="col-md-4">
                    <label><b>Status</b></label>
                    <div class="nbt-status" [ngClass]="getStatusColor(npsModel.status)" style="margin-right: 15px;">
                        {{ statusValue(npsModel.status) }}
                    </div>
                    <br>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label><b>Date/Time of Callings</b></label>
                    <div>{{this.callDatetimeString || '-'}}</div>
                    <br>
                </div>
                <div class="col-md-12">
                    <label><b>Call Details</b></label>
                    <div>{{npsModel.callDetail || '-'}}</div>
                    <br>
                </div>
            </div>
            <!-- INFORMATION -->
            <div class="information">
                <label><b>Information</b></label>
                <mat-card-content class="mat-card-content-table" style="overflow: unset; padding: 25px; display:flex;">
                    <div class="row left-list-detail">
                        <div class="col-xl-12 nbt-details row">
                            <div class="col-xl-7">
                                <div class="nbt-details row">
                                    <div class="col-md-4">
                                        <label>Phone Number(Main)</label>
                                        <div>{{customerRecordActivityModel.customer?.mainPhone || '-'}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Phone Number(Home)</label>
                                        <div>{{customerRecordActivityModel.customer?.homePhone || '-'}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Phone Number(Office)</label>
                                        <div>{{customerRecordActivityModel.customer?.officePhone || '-'}}</div>
                                    </div>
                                </div>
                                <div class="nbt-details row">
                                    <label>Remarks</label>
                                    <div>{{customerRecordActivityModel.customer?.remarks || '-'}}</div>
                                </div>
                                <br>
                                <div class="nbt-details row">
                                    <div class="col-md-4">
                                        <label>Vehicle Model</label>
                                        <div>{{customerRecordActivityModel.model?.name || '-'}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Vehicle Number</label>
                                        <div>{{customerRecordActivityModel.vehicle?.registrationNo || '-'}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-checkbox disabled [checked]="customerRecordActivityModel.customer.isBuyer">Buyer</mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="windowWidth >= 1200" class="col-xl-1" style="text-align: -webkit-center;">
                                <hr width="3" size="40" style="margin-top: -5px; height: 100%;">
                            </div>
                            <div class="col-xl-4" style="align-self: center;">
                                <div class="nbt-details row">
                                    <label>(SSI/CSI/Activity) Date</label>
                                    <div>{{customerRecordActivityModel.actualDate || '-'}}</div>
                                </div>
                                <br>
                                <div class="nbt-details row">
                                    <label>Branch</label>
                                    <div>{{customerRecordActivityModel.department?.name || '-'}}</div>
                                </div>
                                <br>
                                <div class="nbt-details row">
                                    <label>SC/SA/Staff Name</label>
                                    <div>{{customerRecordActivityModel.applicationUser?.username || '-'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </div>
        </form>
    </div>
    <div class="nbt-dialog-footer" *ngIf="!loading">
        <button type="button" class="nbt-btn-secondary" (click)="dialogRef.close()">
            <mat-icon class="mr8">clear</mat-icon>
            Close
        </button>
    </div>
</div>
