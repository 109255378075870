import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesDialogNewOrEditComponent } from './sales-dialog-new-or-edit/sales-dialog-new-or-edit.component';
import { SalesListingComponent } from './sales-listing/sales-listing.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/auth/authenticated.guard';
import { SharedModule } from 'src/app/shared/shared.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SalesPageComponent } from './sales-page/sales-page.component';
import { DialogContentExampleDialog, SalesFormComponent } from './sales-form/sales-form.component';


const routes: Routes = [
    {
        path: 'sales-satisfactory',
        component: SalesListingComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'sales-satisfactory-new-page',
        component: SalesPageComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'sales-satisfactory-form',
        component: SalesFormComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
    {
        path: 'sales-satisfactory-dialog',
        component: SalesDialogNewOrEditComponent,
        canActivate: [
            AuthenticatedGuard
        ]
    },
];

@NgModule({
  declarations: [
    SalesDialogNewOrEditComponent,
    SalesListingComponent,
    SalesPageComponent,
    SalesFormComponent,
    DialogContentExampleDialog,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DirectivesModule,
    RouterModule.forChild(routes)
]
})
export class SalesSatisfactoryModule { }
