import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { DepartmentModel } from 'src/app/model/department.model';
import {
    SYS_CONFIG,
    SystemConfigurationModel,
} from 'src/app/model/system-configuration.model';
import { DepartmentService } from 'src/app/services/department.service';
import { NpsService } from 'src/app/services/nps.service';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { REGEXS, SnackBarStatus } from 'src/app/shared/constant';
@Component({
    selector: 'app-nps-page-new',
    templateUrl: './nps-page-new.component.html',
    styleUrls: ['./nps-page-new.component.scss'],
})
export class NpsPageNewComponent implements OnInit {
    readonly snackBarConfig: MatSnackBarConfig = { duration: 60000 };
    loading = false;
    isSaving = false;
    npsFormGroup: FormGroup;
    sysConfig: SystemConfigurationModel[];
    departments: DepartmentModel[] = [];
    created = 'This NPS has been successfully created';
    close = SnackBarStatus.CLOSE;
    startDate: Date;
    endDate: Date;
    max = new Date();
    addDate = 0;

    constructor(
        public authService: AuthService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private departmentService: DepartmentService,
        private sysConfigService: SystemConfigurationService,
        private datePipe: DatePipe,
        private npsService: NpsService
    ) {}

    ngOnInit(): void {
        this.npsFormGroup = this.formBuilder.group({
            type: this.formBuilder.control('AFTER_SALES', Validators.required),
            startDate: this.formBuilder.control(null, Validators.required),
            endDate: this.formBuilder.control(null, Validators.required),
            department: this.formBuilder.control(null, Validators.required),
            listSize: this.formBuilder.control(null, [
                Validators.required,
                Validators.pattern(REGEXS.PHONE_FILTER),
                Validators.max(9999),
                Validators.min(1),
            ]),
        });

        this.sysConfigService
            .getSystemConfigurationActivityByCode(
            )
            .subscribe({
                next: (value) => {
                    this.sysConfig = value.body;
                    this.sysConfig = this.sysConfig.filter(
                        (obj) =>
                            obj.code === 'AFTER_SALES' ||
                            obj.code === 'SALES_BUYER' ||
                            obj.code === 'SALES_NON_BUYER'
                    );
                    this.deactiveLoading();
                },
            });

        this.departmentService.getAllNoPagination(true, null, null).subscribe({
            next: (value) => {
                this.departments = value.body;
            },
        });
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    back() {
        window.history.back();
    }

    save() {
        this.isSaving = true;
        this.activeLoading();
        const data = this.npsFormGroup.getRawValue();
        data.startDate = this.datePipe.transform(
            data.startDate,
            'yyyy-MM-dd',
            '+800'
        );
        data.endDate = this.datePipe.transform(
            data.endDate,
            'yyyy-MM-dd',
            '+800'
        );
        this.npsService
            .createNPS(
                data.type,
                data.startDate,
                data.endDate,
                data.department.id,
                data.listSize
            )
            .subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    let theMessage = '';
                    theMessage = this.created;
                    this.snackBar.open(
                        theMessage,
                        this.close,
                        this.snackBarConfig
                    );
                    this.back();
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.snackBar.open(
                        error.error.message,
                        this.close,
                        this.snackBarConfig
                    );
                },
            });
    }

    onDateChange(event: MatDatepickerInputEvent<Date>): void {
        const today = event.value;
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
        this.max = lastDayOfMonth;
    }
}
