import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationModule } from './configuration/configuration.module';
import { HomeModule } from './home/home.module';
import { CustomerRecordsModule } from './customer-records/customer-records.module';
import { OperationsModule } from './operations/operations.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HomeModule,
        ConfigurationModule,
        CustomerRecordsModule,
        OperationsModule
    ]
})
export class ComponentsModule { }
